import React from 'react'
import classnames from 'classnames'

const Travel = ({show}) => {
  const _Travel = classnames('co2-category_travel', {
    show
  })
  return (
    <svg className={_Travel} width="162" height="101" viewBox="0 0 162 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#co2-category_travel_clip0)">
        <path d="M67.7293 62.8662H77.0045C67.1979 62.8662 59.2754 70.8371 59.2754 80.6437C59.2754 90.4502 67.2462 98.3728 77.0528 98.3728H67.7776C62.8985 98.3728 58.4542 96.3921 55.2175 93.2038C51.9809 89.9671 50.0002 85.5711 50.0002 80.692C49.9519 70.8371 57.9711 62.8662 67.7293 62.8662Z" fill="#FFC466"/>
        <path d="M59.2749 80.5953C59.2749 70.7888 67.1975 62.8662 77.004 62.8179L96.1341 62.7696C92.8974 62.7696 89.9023 64.0739 87.4869 66.2478C83.9121 69.4845 81.545 74.6534 81.545 80.547C81.545 86.3923 83.9121 91.6096 87.4869 94.8463C89.9023 97.0201 92.8974 98.2761 96.1341 98.2761L77.0523 98.3245C77.004 98.3245 77.004 98.3245 76.9557 98.3245C67.2458 98.3245 59.2749 90.4019 59.2749 80.5953Z" fill="#C1904F"/>
        <path d="M129.95 80.4987C129.95 74.6051 127.583 69.4362 123.96 66.1995C121.544 64.0256 118.549 62.7696 115.361 62.7696L134.491 62.7213C144.297 62.7213 152.22 70.6439 152.268 80.4504C152.268 90.2087 144.394 98.1795 134.636 98.2278C134.588 98.2278 134.587 98.2278 134.587 98.2278L115.506 98.2761C118.694 98.2761 121.641 96.9718 124.056 94.798C127.631 91.5613 129.95 86.3923 129.95 80.4987Z" fill="#C1904F"/>
        <path d="M152.22 80.4504C152.22 70.6439 144.249 62.7213 134.442 62.7213H143.718C148.597 62.7213 153.041 64.702 156.278 67.9386C159.514 71.1269 161.495 75.5713 161.495 80.4504C161.495 90.2087 153.524 98.1795 143.766 98.2278H134.539C144.346 98.1795 152.268 90.257 152.22 80.4504Z" fill="#FFC466"/>
        <path d="M81.5933 80.5953C81.5933 74.7501 83.912 69.5328 87.5352 66.2961C89.9506 64.1223 92.9457 62.8179 96.1823 62.8179C103.139 62.8179 108.453 62.8179 115.409 62.7696C118.646 62.7696 121.592 64.0256 124.008 66.1995C127.631 69.3878 129.95 74.6051 129.998 80.4987C129.998 86.344 127.679 91.5613 124.056 94.798C121.689 96.9718 118.694 98.2278 115.506 98.2761H115.457C108.501 98.2761 103.235 98.2761 96.279 98.3245H96.2306C92.994 98.3245 89.9989 97.0684 87.5835 94.8946C83.9121 91.6579 81.5933 86.4406 81.5933 80.5953Z" fill="#FFC466"/>
        <path d="M151.882 50.5477C151.641 50.3061 151.593 50.9341 151.641 51.3206C151.737 51.7071 151.882 52.5283 152.027 52.48C152.172 52.48 152.172 51.7554 152.172 51.7071C152.124 51.2723 152.027 50.8858 151.882 50.5477Z" fill="#F5F2F4"/>
        <path d="M134.442 51.7554C134.442 52.2385 134.829 52.5766 135.36 52.5766C135.843 52.5766 136.278 52.1902 136.278 51.7554C136.278 51.2723 135.892 50.9341 135.36 50.9341C134.829 50.9341 134.442 51.3206 134.442 51.7554Z" fill="#F5F2F4"/>
        <path d="M151.641 51.3206C151.592 50.9341 151.641 50.3061 151.882 50.5476C151.689 50.1129 151.399 49.6781 150.964 49.4366C151.544 51.2723 151.882 53.2046 151.882 55.1852C151.882 55.1852 152.414 53.3495 152.172 51.6587C152.172 51.707 152.172 52.4317 152.027 52.4317C151.882 52.48 151.689 51.707 151.641 51.3206Z" fill="#5B3D55"/>
        <path d="M134.346 56.2963C132.607 56.2963 131.158 54.9437 131.158 53.2529C131.158 51.5621 132.559 50.2095 134.298 50.2095C136.037 50.2095 137.486 51.5621 137.486 53.2529C137.534 54.9437 136.085 56.2963 134.346 56.2963ZM135.361 52.6249C135.844 52.6249 136.278 52.2384 136.278 51.8037C136.278 51.3206 135.892 50.9824 135.361 50.9824C134.877 50.9824 134.443 51.3689 134.443 51.8037C134.443 52.2384 134.829 52.6249 135.361 52.6249Z" fill="#5B3D55"/>
        <path d="M140.191 58.8567C138.5 59.0982 138.307 59.7262 140.191 61.2721C142.075 62.818 146.278 62.7697 148.017 62.3832C148.935 62.19 149.804 61.9484 150.577 61.5136C151.302 61.1272 151.882 60.5475 152.172 59.6296C152.51 58.6152 152.22 58.1321 151.64 57.7456C151.157 57.4075 150.481 57.1659 149.901 56.6345C148.597 55.4751 147.727 54.0259 146.713 53.9776C145.94 53.9776 144.007 56.1514 143.331 56.8761C142.703 57.649 141.882 58.6152 140.191 58.8567Z" fill="#C64E73"/>
        <path d="M149.901 56.6828C148.597 55.5234 147.728 54.0742 146.713 54.0259C145.94 54.0259 144.008 56.1997 143.332 56.9244C142.655 57.649 141.834 58.6151 140.191 58.8567C138.501 59.0982 138.307 59.7262 140.191 61.2721C142.076 62.818 146.278 62.7696 148.017 62.3832C148.935 62.1899 149.805 61.9484 150.578 61.5136C149.273 64.6054 147.196 66.8275 145.505 68.0835C144.877 68.5666 144.298 68.9048 143.815 69.1463C141.544 70.2574 134.25 69.2913 128.646 67.9869C125.023 67.1657 117.583 64.2189 116.907 63.8807C116.617 63.7358 116.231 63.4943 115.796 62.9629C115.313 62.3349 114.733 61.2721 114.25 59.2915C113.332 55.7167 112.801 50.596 115.506 45.2338C118.26 39.9199 123.718 37.0697 128.549 36.2968C133.38 35.5238 138.018 36.2484 142.655 38.6155C145.94 40.3063 149.322 44.3159 150.868 49.2917C151.447 51.1274 151.785 53.0597 151.785 55.0403C151.785 55.2819 151.785 55.4751 151.785 55.7166C151.737 56.393 151.689 57.021 151.592 57.649C151.157 57.4557 150.529 57.1659 149.901 56.6828ZM137.535 53.2529C137.535 51.5621 136.134 50.2095 134.346 50.2095C132.607 50.2095 131.158 51.5621 131.206 53.2529C131.206 54.9437 132.607 56.2964 134.395 56.2964C136.085 56.2964 137.535 54.9437 137.535 53.2529Z" fill="#FFC466"/>
        <path d="M107.691 27.7888C107.574 26.8795 106.755 26.1408 105.762 26.1408H104.651H93.3115H93.6038C93.9545 26.1408 94.3052 26.5386 94.3052 27.1069H105.762C106.288 27.1069 106.755 27.5046 106.755 28.0729V97.5165C106.755 98.0279 106.346 98.4826 105.762 98.4826H94.1298L93.896 99.4486H104.651H105.762C106.755 99.4486 107.574 98.7099 107.691 97.8006C108.158 93.9932 110.321 90.2993 113.77 90.2993V35.4037C110.321 35.2901 108.1 31.6531 107.691 27.7888Z" fill="#6B68B3"/>
        <path d="M107.746 97.6301C107.632 98.5394 106.836 99.2781 105.87 99.2781H104.791C104.791 99.5623 105.018 99.8464 105.359 99.8464H108.2H112.69C113.542 99.8464 114.224 99.1645 114.224 98.312V90.5266C114.224 90.2425 113.997 90.072 113.712 90.072H113.656C110.303 90.1288 108.143 93.7658 107.746 97.6301ZM111.951 91.4359C112.576 91.2654 113.201 91.6632 113.372 92.2315C113.542 92.8566 113.144 93.4817 112.576 93.6522C111.951 93.8226 111.326 93.4249 111.155 92.8566C110.985 92.2315 111.326 91.5495 111.951 91.4359ZM111.042 98.1416C110.871 98.7667 110.246 99.1076 109.621 98.9371C108.996 98.7667 108.655 98.1416 108.825 97.5164C108.996 96.8913 109.621 96.5504 110.246 96.7209C110.871 96.8913 111.212 97.5165 111.042 98.1416Z" fill="#C0C6CC"/>
        <path d="M107.746 27.6183C108.2 31.4258 110.303 35.1196 113.656 35.1764H113.712C113.997 35.1764 114.224 34.9491 114.224 34.7218V26.9932C114.224 26.1408 113.542 25.4588 112.69 25.4588H108.2H105.359C105.075 25.4588 104.791 25.6861 104.791 26.0271H105.87C106.836 26.0271 107.575 26.709 107.746 27.6183ZM110.246 28.5275C109.621 28.698 108.996 28.3002 108.825 27.7319C108.655 27.1068 109.053 26.4817 109.621 26.3112C110.246 26.1408 110.871 26.5386 111.042 27.1068C111.212 27.7319 110.871 28.357 110.246 28.5275ZM113.372 33.0169C113.201 33.642 112.576 33.983 111.951 33.8125C111.326 33.642 110.985 33.0169 111.155 32.3918C111.326 31.7667 111.951 31.4258 112.576 31.5962C113.201 31.7667 113.542 32.3918 113.372 33.0169Z" fill="#C0C6CC"/>
        <path d="M112.576 93.6522C113.201 93.4817 113.542 92.8566 113.371 92.2315C113.201 91.6064 112.576 91.2654 111.951 91.4359C111.326 91.6064 110.985 92.2315 111.155 92.8566C111.326 93.4249 111.951 93.8227 112.576 93.6522Z" fill="#E4EBF2"/>
        <path d="M108.826 97.5733C108.655 98.1984 109.053 98.8235 109.622 98.994C110.247 99.1645 110.872 98.7667 111.042 98.1984C111.213 97.5733 110.815 96.9482 110.247 96.7777C109.622 96.5504 108.996 96.9482 108.826 97.5733Z" fill="#E4EBF2"/>
        <path d="M112.575 31.5962C111.95 31.4258 111.325 31.8236 111.155 32.3918C110.984 33.0169 111.382 33.642 111.95 33.8125C112.575 33.983 113.2 33.5852 113.371 33.0169C113.541 32.3918 113.2 31.7667 112.575 31.5962Z" fill="#E4EBF2"/>
        <path d="M111.042 27.1068C110.871 26.4817 110.246 26.1408 109.621 26.3113C108.996 26.4817 108.655 27.1068 108.825 27.7319C108.996 28.3571 109.621 28.698 110.246 28.5275C110.871 28.3571 111.212 27.7319 111.042 27.1068Z" fill="#E4EBF2"/>
        <path d="M18.6389 99.5054C18.7526 100.017 19.2072 100.415 19.7755 100.415H20.8552C21.3667 100.415 21.8781 100.017 21.9918 99.5054L22.2759 98.3121H18.4116L18.6389 99.5054Z" fill="#606366"/>
        <path d="M31.2554 98.3121L31.4827 99.2781L31.5395 99.5054C31.5395 99.6191 31.5963 99.7328 31.6532 99.8464C31.8805 100.187 32.2214 100.415 32.6192 100.415H33.699C34.2104 100.415 34.7219 100.017 34.8355 99.5054L34.8923 99.2781L35.1197 98.3121H31.2554Z" fill="#606366"/>
        <path d="M79.0472 98.8804C78.7631 98.8804 78.4789 98.5962 78.5358 98.3121H77.9106L78.138 99.2781L78.1948 99.5054C78.3084 100.017 78.7631 100.415 79.3313 100.415H80.4111C80.9225 100.415 81.434 100.017 81.5476 99.5054L81.6045 99.2781L81.7181 98.8804H79.0472Z" fill="#606366"/>
        <path d="M93.8222 98.3121C93.8222 98.5962 93.5949 98.8804 93.3108 98.8804H90.8672L90.9808 99.2781L91.0377 99.5054C91.1513 100.017 91.606 100.415 92.1742 100.415H93.254C93.7654 100.415 94.2769 100.017 94.3905 99.5054L94.4473 99.2781L94.6746 98.3121H93.8222Z" fill="#606366"/>
        <path d="M87.0652 36.0252C86.7787 36.0252 86.6641 36.0252 86.4922 36.0252V89.6792V97.9602V89.6217C86.6641 89.6217 87.6287 97.1755 87.6287 97.1755C92.6143 97.463 86.4922 98.8803 89.3336 98.8803C92.175 98.8803 94.5722 98.8803 94.5722 98.8803H105.976C106.492 98.8803 106.95 98.4778 106.95 97.9027V27.6867C106.95 27.1691 106.549 26.709 105.976 26.709H94.7442H93.8846C93.5407 31.7122 92.0508 35.7376 87.0652 36.0252Z" fill="#4A4A7C"/>
        <path d="M84.2759 26.0271H84.56C84.7305 26.0271 84.901 26.1408 85.0146 26.3112C85.0146 26.3681 85.0715 26.3681 85.0715 26.4249H86.549H93.3115C93.5956 26.4249 93.8798 26.709 93.8229 26.9932H94.6754C94.6754 26.4817 94.3912 26.0271 93.9934 26.0271H93.7093C93.7093 25.5157 93.4251 25.061 93.0273 25.061H83.5371C83.9917 25.061 84.2759 25.5157 84.2759 26.0271Z" fill="#C0C6CC"/>
        <path d="M77.2859 26.0271C76.945 26.0271 76.604 26.4249 76.604 26.9932H78.4793C78.4793 26.709 78.7066 26.4249 78.9908 26.4249H85.0145C85.0145 26.3681 84.9577 26.3681 84.9577 26.3112C84.844 26.1408 84.6735 26.0271 84.5031 26.0271H84.2189C84.2189 25.5157 83.9348 25.061 83.537 25.061H81.8322H78.1384C77.7974 25.061 77.4564 25.4588 77.4564 26.0271H77.2859Z" fill="#CED5DC"/>
        <path d="M25.1746 26.0271H25.4587C25.7997 26.0271 26.1407 26.4249 26.1407 26.9932H35.4036C35.4036 26.8227 35.4604 26.5954 35.5173 26.4818C35.4036 26.2545 35.1763 26.084 34.949 26.084H34.6649C34.6649 25.5725 34.3807 25.1179 33.9829 25.1179H24.4927C24.6063 25.1179 24.7768 25.1747 24.8905 25.2884C25.061 25.402 25.1746 25.6862 25.1746 26.0271Z" fill="#B2B5CF"/>
        <path d="M26.0833 26.9364C26.0833 26.4249 25.7992 25.9703 25.4014 25.9703H25.1172C25.1172 25.6293 25.0036 25.3452 24.8331 25.1747C24.7195 25.061 24.6058 25.0042 24.4353 25.0042H22.7305H19.0367C18.6957 25.0042 18.3547 25.402 18.3547 25.9703H18.0706C17.7296 25.9703 17.3887 26.3681 17.3887 26.9364H26.0833Z" fill="#B9BFD7"/>
        <path d="M86.5483 27.9024V36.1425C86.7188 36.1425 86.7757 36.1425 87.1166 36.1425C92.0606 35.8583 93.5382 31.9372 93.8223 26.9932C93.8223 26.709 93.595 26.4249 93.3108 26.4249H86.5483V26.9932V27.9024ZM88.8215 34.7786C88.1963 34.9491 87.5712 34.5513 87.4008 33.983C87.2303 33.3579 87.6281 32.7328 88.1963 32.5623C88.8214 32.3918 89.4466 32.7896 89.617 33.3579C89.7875 33.9262 89.3897 34.6081 88.8215 34.7786ZM92.5153 28.6412C92.3448 29.2663 91.7197 29.6073 91.0946 29.4368C90.4695 29.2663 90.1285 28.6412 90.299 28.0161C90.4695 27.391 91.0946 27.05 91.7197 27.2205C92.2879 27.391 92.6857 28.0161 92.5153 28.6412Z" fill="#C0C6CC"/>
        <path d="M31.2549 98.3121H35.1192H77.9105H78.5356C78.8197 93.3681 81.0928 89.4469 86.0369 89.1628C86.321 89.1628 86.4346 89.1628 86.6051 89.1628V36.1425C86.4346 36.1425 86.3778 36.1425 86.0369 36.1425C81.0928 35.8583 78.8197 31.9372 78.5356 26.9932H76.6603H76.3761H68.5907H60.0666H51.656H43.8706H35.5738H35.3465H26.0835H17.5026H10.0581H8.75107C8.29645 26.9932 7.84183 27.391 7.785 27.8456C7.33038 32.3918 5.17092 35.8583 0.567871 36.1425V89.1628C5.17092 89.4469 7.33038 92.9134 7.785 97.4597C7.84183 97.9143 8.23962 98.3121 8.75107 98.3121H10.0581H18.4118H22.2761H25.2311H26.0267H31.2549Z" fill="#6B68B3"/>
        <path d="M7.78541 27.7888C7.84224 27.3341 8.24003 26.9364 8.75148 26.9364H10.0585C10.0585 26.5954 9.77438 26.3681 9.49024 26.3681H7.38761H5.96692H1.53435C0.681934 26.3681 0 27.05 0 27.9024V35.631C0 35.9151 0.227312 36.1424 0.511451 36.0856H0.568278C5.17133 35.8583 7.33079 32.335 7.78541 27.7888ZM2.21628 34.7786C1.59118 34.9491 0.966073 34.5513 0.79559 33.983C0.625107 33.3579 1.0229 32.7328 1.59118 32.5623C2.21628 32.3918 2.84139 32.7896 3.01187 33.3579C3.23918 33.983 2.84139 34.6081 2.21628 34.7786ZM4.26208 28.0161C4.43257 27.391 5.05767 27.05 5.68278 27.2205C6.30788 27.391 6.64885 28.0161 6.47837 28.6412C6.30788 29.2663 5.68278 29.6073 5.05767 29.4368C4.43257 29.2663 4.0916 28.6412 4.26208 28.0161Z" fill="#CED1E2"/>
        <path d="M7.78541 97.4596C7.33079 92.9134 5.17133 89.4469 0.568278 89.1628H0.511451C0.227312 89.1628 0 89.3901 0 89.6174V97.346C0 98.1984 0.681934 98.8803 1.53435 98.8803H9.49024C9.83121 98.8803 10.0585 98.653 10.0585 98.3121H8.75148C8.24003 98.3121 7.78541 97.9143 7.78541 97.4596ZM5.05767 95.8116C5.68278 95.6411 6.30788 96.0389 6.47837 96.6072C6.64885 97.2323 6.25106 97.8574 5.68278 98.0279C5.05767 98.1984 4.43257 97.8006 4.26208 97.2323C4.0916 96.6072 4.43257 95.9821 5.05767 95.8116ZM0.79559 91.2654C0.966073 90.6403 1.59118 90.2993 2.21628 90.4698C2.84139 90.6403 3.18236 91.2654 3.01187 91.8905C2.84139 92.5156 2.21628 92.8566 1.59118 92.6861C0.966072 92.5156 0.625107 91.8905 0.79559 91.2654Z" fill="#CED1E2"/>
        <path d="M86.5481 89.106C86.3776 89.106 86.3208 89.106 85.9798 89.106C81.0358 89.3901 78.7627 93.3112 78.4785 98.2552C78.4785 98.5394 78.7058 98.8235 78.99 98.8235H81.5472H86.4912V97.346V89.106H86.5481ZM84.3318 90.4698C84.9569 90.2993 85.582 90.6971 85.7525 91.2654C85.923 91.8905 85.5252 92.5156 84.9569 92.6861C84.3318 92.8566 83.7067 92.4588 83.5362 91.8905C83.3089 91.2654 83.7067 90.6403 84.3318 90.4698ZM79.8424 96.6072C80.0129 95.9821 80.638 95.6411 81.2631 95.8116C81.8882 95.9821 82.2292 96.6072 82.0587 97.2323C81.8882 97.8574 81.2631 98.1984 80.638 98.0279C80.0697 97.8574 79.6719 97.2323 79.8424 96.6072Z" fill="#CED5DC"/>
        <path d="M86.5484 26.9364V26.3681H85.0708H79.0471C78.7629 26.3681 78.4788 26.6522 78.5356 26.9364C78.8198 31.8804 81.0929 35.8015 86.0369 36.0856C86.321 36.0856 86.4347 36.0856 86.6052 36.0856V27.8456V26.9364H86.5484ZM81.2634 29.4368C80.6383 29.6073 80.0132 29.2095 79.8427 28.6412C79.6722 28.0161 80.07 27.391 80.6383 27.2205C81.2634 27.05 81.8885 27.4478 82.059 28.0161C82.2863 28.6412 81.8885 29.2663 81.2634 29.4368ZM85.7528 33.983C85.5823 34.6081 84.9572 34.9491 84.3321 34.7786C83.707 34.6081 83.366 33.983 83.5365 33.3579C83.707 32.7328 84.3321 32.3918 84.9572 32.5623C85.5255 32.676 85.9232 33.3579 85.7528 33.983Z" fill="#CED5DC"/>
        <path d="M1.6483 92.6861C2.2734 92.8566 2.89851 92.4588 3.06899 91.8905C3.23947 91.2654 2.84168 90.6403 2.2734 90.4698C1.64829 90.2993 1.02319 90.6971 0.852706 91.2654C0.625395 91.8905 1.02319 92.5724 1.6483 92.6861Z" fill="#E1E3EE"/>
        <path d="M5.68269 98.0279C6.3078 97.8574 6.64876 97.2323 6.47828 96.6072C6.3078 95.9821 5.68269 95.6411 5.05759 95.8116C4.43248 95.9821 4.09151 96.6072 4.262 97.2323C4.43248 97.8574 5.05759 98.1984 5.68269 98.0279Z" fill="#E1E3EE"/>
        <path d="M1.64792 32.5623C1.02281 32.7328 0.681847 33.3579 0.85233 33.983C1.02281 34.6081 1.64792 34.9491 2.27302 34.7786C2.89813 34.6081 3.2391 33.983 3.06861 33.3579C2.89813 32.7328 2.27303 32.3918 1.64792 32.5623Z" fill="#E1E3EE"/>
        <path d="M6.47925 28.6412C6.64973 28.0161 6.25194 27.391 5.68366 27.2205C5.05855 27.05 4.43345 27.4478 4.26296 28.0161C4.09248 28.6412 4.49028 29.2663 5.05855 29.4368C5.68366 29.6073 6.30876 29.2663 6.47925 28.6412Z" fill="#E1E3EE"/>
        <path d="M84.9005 92.6861C85.5256 92.5156 85.8665 91.8905 85.6961 91.2654C85.5256 90.6403 84.9005 90.2993 84.2754 90.4698C83.6503 90.6403 83.3093 91.2654 83.4798 91.8905C83.6503 92.5156 84.2754 92.8566 84.9005 92.6861Z" fill="#E4EBF2"/>
        <path d="M80.6957 98.0279C81.3208 98.1984 81.9459 97.8006 82.1164 97.2323C82.2868 96.664 81.889 95.9821 81.3208 95.8116C80.6957 95.6411 80.0706 96.0389 79.9001 96.6072C79.6728 97.2323 80.0706 97.8574 80.6957 98.0279Z" fill="#E4EBF2"/>
        <path d="M84.9009 32.5623C84.2758 32.3918 83.6507 32.7896 83.4802 33.3579C83.3098 33.983 83.7076 34.6081 84.2758 34.7786C84.9009 34.9491 85.5261 34.5513 85.6965 33.983C85.9238 33.3579 85.526 32.676 84.9009 32.5623Z" fill="#E4EBF2"/>
        <path d="M80.6958 27.2205C80.0707 27.391 79.7297 28.0161 79.9002 28.6412C80.0707 29.2663 80.6958 29.6073 81.3209 29.4368C81.946 29.2663 82.287 28.6412 82.1165 28.0161C81.946 27.391 81.3209 27.05 80.6958 27.2205Z" fill="#E4EBF2"/>
        <path d="M87.1166 89.1628C86.8325 89.1628 86.7188 89.1628 86.5483 89.1628V97.346V98.8803H90.8672H93.3108C93.595 98.8803 93.8791 98.5962 93.8223 98.3121C93.5381 93.368 92.0606 89.3901 87.1166 89.1628ZM87.4008 91.2654C87.5712 90.6403 88.1963 90.2993 88.8215 90.4698C89.4466 90.6403 89.7875 91.2654 89.617 91.8905C89.4466 92.5156 88.8214 92.8566 88.1963 92.6861C87.5712 92.5724 87.2303 91.8905 87.4008 91.2654ZM92.5153 96.6072C92.6857 97.2323 92.2879 97.8574 91.7197 98.0279C91.0946 98.1984 90.4695 97.8006 90.299 97.2323C90.1285 96.6072 90.5263 95.9821 91.0946 95.8116C91.7197 95.6411 92.3448 95.9821 92.5153 96.6072Z" fill="#C0C6CC"/>
        <path d="M88.1967 92.6861C88.8218 92.8566 89.447 92.4588 89.6174 91.8905C89.7879 91.2654 89.3901 90.6403 88.8219 90.4698C88.1967 90.2993 87.5716 90.6971 87.4012 91.2654C87.2307 91.8905 87.5716 92.5724 88.1967 92.6861Z" fill="#E4EBF2"/>
        <path d="M90.2425 97.2323C90.4129 97.8574 91.038 98.1984 91.6632 98.0279C92.2883 97.8574 92.6292 97.2323 92.4587 96.6072C92.2883 95.9821 91.6631 95.6411 91.038 95.8116C90.4698 95.9821 90.072 96.6072 90.2425 97.2323Z" fill="#E4EBF2"/>
        <path d="M88.1967 32.5623C87.5716 32.7328 87.2307 33.3579 87.4012 33.983C87.5716 34.6081 88.1967 34.9491 88.8219 34.7786C89.447 34.6081 89.7879 33.983 89.6174 33.3579C89.447 32.7328 88.8218 32.3918 88.1967 32.5623Z" fill="#E4EBF2"/>
        <path d="M91.6632 27.2205C91.038 27.05 90.4129 27.4478 90.2425 28.0161C90.072 28.6412 90.4698 29.2663 91.038 29.4368C91.6631 29.6073 92.2883 29.2095 92.4587 28.6412C92.686 28.0161 92.2883 27.391 91.6632 27.2205Z" fill="#E4EBF2"/>
        <path d="M63.2826 7.89908H52.4854C55.3836 7.89908 57.7703 11.2519 57.7703 15.3435V87.117C57.7703 91.2654 55.3836 94.5614 52.4854 94.5614H63.2826C66.1808 94.5614 68.5676 91.2086 68.5676 87.117V15.3435C68.5676 11.2519 66.1808 7.89908 63.2826 7.89908Z" fill="#C64E73"/>
        <path d="M56.4636 87.1738V15.4003C56.4636 11.2519 54.0768 7.89908 51.1786 7.89908H40.3813C43.2796 7.89908 45.6663 11.2519 45.6663 15.3435V87.117C45.6663 91.2654 43.2796 94.5614 40.3813 94.5614H51.1786C54.0768 94.5614 56.4636 91.2654 56.4636 87.1738Z" fill="#C64E73"/>
        <path d="M57.7707 87.117V15.3435C57.7707 11.1951 55.384 7.89908 52.4857 7.89908H51.1787C54.0769 7.89908 56.4637 11.2519 56.4637 15.4003V87.1738C56.4637 91.3223 54.0769 94.6183 51.1787 94.6183H52.4857C55.384 94.5614 57.7707 91.2654 57.7707 87.117Z" fill="#F291B0"/>
        <path d="M40.3817 7.89908H6.28498C3.38677 7.89908 1 11.2519 1 15.3435V87.117C1 91.2654 3.38677 94.5614 6.28498 94.5614H40.3817C43.2799 94.5614 45.6666 91.2086 45.6666 87.117V15.3435C45.6666 11.2519 43.2799 7.89908 40.3817 7.89908ZM42.9957 87.117C42.9957 89.1628 41.8023 90.8108 40.3817 90.8108H6.28498C4.86429 90.8108 3.67091 89.1628 3.67091 87.117V15.3435C3.67091 13.2977 4.86429 11.6497 6.28498 11.6497H40.3817C41.8023 11.6497 42.9957 13.2977 42.9957 15.3435V87.117Z" fill="#F291B0"/>
        <path d="M40.3821 11.6497H6.28547C4.86477 11.6497 3.67139 13.2977 3.67139 15.3435V87.117C3.67139 89.1628 4.86477 90.8108 6.28547 90.8108H40.3821C41.8028 90.8108 42.9962 89.1628 42.9962 87.117V15.3435C42.9962 13.2977 41.8028 11.6497 40.3821 11.6497Z" fill="#F65B7D"/>
        <path d="M28.8125 6.81931C27.8932 6.81931 27.1411 7.33076 27.1411 7.95586H29.9825V6.81931H28.8125Z" fill="#606366"/>
        <path d="M54.113 6.81931H28.8457V7.95586H56.6913C56.6913 7.33076 55.5311 6.81931 54.113 6.81931Z" fill="#606366"/>
        <path d="M37.5973 0H35.7788C38.7907 0 42.5981 2.6709 42.5981 5.00084V7.89906H44.4166V5.00084C44.4166 2.6709 40.5523 0 37.5973 0Z" fill="#7C8084"/>
        <path d="M32.4572 2.27313C32.0722 2.27313 31.6873 2.40228 31.3573 2.72517C30.7523 3.24179 29.9824 3.7584 29.9824 4.66248V7.95591H31.7423V4.66248C31.7423 3.7584 32.5122 3.24179 33.1171 2.72517C33.2271 2.66059 33.3371 2.59602 33.3921 2.53144C33.1721 2.46686 33.0071 2.40229 32.7871 2.33771C32.7322 2.27313 32.6222 2.27313 32.4572 2.27313Z" fill="#7C8084"/>
        <path d="M35.8615 0C32.7628 0 28.8457 2.69012 28.8457 5.03682V7.95589H30.3073V5.03682C30.3073 4.23551 31.1258 3.77761 31.7689 3.31972C32.1197 3.03354 32.529 2.91907 32.9382 2.91907C33.1136 2.91907 33.2306 2.91907 33.406 2.9763C33.5814 3.03354 33.8152 3.09078 34.0491 3.14801C34.6337 3.26249 35.3938 3.37696 35.9784 3.37696C36.7969 3.37696 37.8493 3.14801 38.5509 2.9763C38.7263 2.91907 38.8432 2.91907 39.0186 2.91907C39.3109 2.91907 39.6032 2.9763 39.8371 3.09078C39.954 3.14801 40.0709 3.20525 40.1294 3.26249C40.7725 3.72038 41.591 4.17827 41.591 4.97958V7.89865H43.0526V4.97958C42.9357 2.69012 38.9601 0 35.8615 0Z" fill="#606366"/>
        <path d="M8.61452 94.5614V96.1526C8.61452 96.3799 8.5577 96.5504 8.44404 96.7209L8.21673 97.1187C8.04625 97.4028 8.04625 97.8006 8.21673 98.0848C8.33039 98.2552 8.44404 98.3121 8.61452 98.3121C8.67135 98.3121 8.78501 98.3121 8.84184 98.2552L10.6035 97.005L11.0013 96.7209C11.2286 96.5504 11.3423 96.2094 11.3423 95.8685V94.5614H8.61452Z" fill="#412139"/>
        <path d="M8.44431 94.5614C7.25093 94.5614 6.22803 95.9821 6.22803 97.687C6.22803 99.3918 7.1941 100.812 8.44431 100.812C9.6377 100.812 10.6606 99.3918 10.6606 97.687C10.6606 97.4596 10.6606 97.2323 10.6038 97.005L8.84211 98.2552C8.78528 98.3121 8.67162 98.3121 8.61479 98.3121C8.44431 98.3121 8.33066 98.1984 8.217 98.0848C8.04652 97.8006 8.04652 97.4028 8.217 97.1187L8.44431 96.7209C8.55797 96.5504 8.61479 96.3799 8.61479 96.1526V94.5614C8.55797 94.5614 8.50114 94.5614 8.44431 94.5614Z" fill="#5A384D"/>
        <path d="M8.44515 94.5614H6.11521C4.92183 94.5614 3.89893 95.9821 3.89893 97.687C3.89893 99.3918 4.865 100.812 6.11521 100.812H8.44515C7.25177 100.812 6.22887 99.3918 6.22887 97.687C6.22887 95.9821 7.25177 94.5614 8.44515 94.5614Z" fill="#4E2C42"/>
        <path d="M31.5159 94.5614V96.1526C31.5159 96.3799 31.4591 96.5504 31.3454 96.7209L31.1181 97.1187C30.9476 97.4028 30.9476 97.8006 31.1181 98.0848C31.2318 98.2552 31.3454 98.3121 31.5159 98.3121C31.5727 98.3121 31.6864 98.3121 31.7432 98.2552L33.5049 97.005L33.9027 96.7209C34.13 96.5504 34.2436 96.2094 34.2436 95.8685V94.5614H31.5159Z" fill="#32132B"/>
        <path d="M31.3457 94.5614C30.1523 94.5614 29.1294 95.9821 29.1294 97.687C29.1294 99.3918 30.0955 100.812 31.3457 100.812C32.5391 100.812 33.562 99.3918 33.562 97.687C33.562 97.4596 33.562 97.2323 33.5051 97.005L31.7435 98.2552C31.6866 98.3121 31.573 98.3121 31.5162 98.3121C31.3457 98.3121 31.232 98.1984 31.1184 98.0848C30.9479 97.8006 30.9479 97.4028 31.1184 97.1187L31.3457 96.7209C31.4593 96.5504 31.5162 96.3799 31.5162 96.1526V94.5614C31.4593 94.5614 31.4025 94.5614 31.3457 94.5614Z" fill="#4C2940"/>
        <path d="M31.3455 94.5614H29.0156C27.8222 94.5614 26.7993 95.9821 26.7993 97.687C26.7993 99.3918 27.7654 100.812 29.0156 100.812H31.3455C30.1522 100.812 29.1293 99.3918 29.1293 97.687C29.1293 95.9821 30.1522 94.5614 31.3455 94.5614Z" fill="#3F1E35"/>
        <path d="M37.6551 94.5614V96.1526C37.6551 96.3799 37.5982 96.5504 37.4846 96.7209L37.2573 97.1187C37.0868 97.4028 37.0868 97.8006 37.2573 98.0848C37.3709 98.2552 37.4846 98.3121 37.6551 98.3121C37.7119 98.3121 37.8255 98.3121 37.8824 98.2552L39.644 97.005L40.0418 96.7209C40.2691 96.5504 40.3828 96.2094 40.3828 95.8685V94.5614H37.6551Z" fill="#412139"/>
        <path d="M37.4843 94.5614C36.291 94.5614 35.2681 95.9821 35.2681 97.687C35.2681 99.3918 36.2341 100.812 37.4843 100.812C38.6777 100.812 39.7006 99.3918 39.7006 97.687C39.7006 97.4596 39.7006 97.2323 39.6438 97.005L37.8821 98.2552C37.8253 98.3121 37.7117 98.3121 37.6548 98.3121C37.4844 98.3121 37.3707 98.1984 37.257 98.0848C37.0866 97.8006 37.0866 97.4028 37.257 97.1187L37.4843 96.7209C37.598 96.5504 37.6548 96.3799 37.6548 96.1526V94.5614C37.598 94.5614 37.5412 94.5614 37.4843 94.5614Z" fill="#5A384D"/>
        <path d="M37.4837 94.5614H35.1538C33.9604 94.5614 32.9375 95.9821 32.9375 97.687C32.9375 99.3918 33.9036 100.812 35.1538 100.812H37.4837C36.2903 100.812 35.2674 99.3918 35.2674 97.687C35.2674 95.9821 36.2335 94.5614 37.4837 94.5614Z" fill="#4E2C42"/>
        <path d="M60.5554 94.5614V96.1526C60.5554 96.3799 60.4986 96.5504 60.385 96.7209L60.1576 97.1187C59.9872 97.4028 59.9872 97.8006 60.1576 98.0848C60.2713 98.2552 60.385 98.3121 60.5554 98.3121C60.6123 98.3121 60.7259 98.3121 60.7828 98.2552L62.5444 97.005L62.9422 96.7209C63.1695 96.5504 63.2832 96.2094 63.2832 95.8685V94.5614H60.5554Z" fill="#412139"/>
        <path d="M60.3852 94.5614C59.1918 94.5614 58.1689 95.9821 58.1689 97.687C58.1689 99.3918 59.135 100.812 60.3852 100.812C61.5786 100.812 62.6015 99.3918 62.6015 97.687C62.6015 97.4596 62.6015 97.2323 62.5447 97.005L60.783 98.2552C60.7262 98.3121 60.6125 98.3121 60.5557 98.3121C60.3852 98.3121 60.2716 98.1984 60.1579 98.0848C59.9874 97.8006 59.9874 97.4028 60.1579 97.1187L60.3852 96.7209C60.4989 96.5504 60.5557 96.3799 60.5557 96.1526V94.5614C60.4989 94.5614 60.4421 94.5614 60.3852 94.5614Z" fill="#5A384D"/>
        <path d="M60.3856 94.5614H58.0556C56.8623 94.5614 55.8394 95.9821 55.8394 97.687C55.8394 99.3918 56.8054 100.812 58.0556 100.812H60.3856C59.1922 100.812 58.1693 99.3918 58.1693 97.687C58.1693 95.9821 59.1354 94.5614 60.3856 94.5614Z" fill="#4E2C42"/>
      </g>
      <defs>
      <clipPath id="co2-category_travel_clip0">
        <rect width="161.495" height="100.813" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default Travel
