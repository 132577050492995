import React from 'react'
import classnames from 'classnames'

const Home = ({ show }) => {

  const _Home = classnames('co2-category_home', {
    show
  })
  
  return (
    <svg className={_Home} width="106" height="157" viewBox="0 0 106 157" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#co2-category_home_clip0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.22852 156.276H103.453V7.98865H3.22852V156.276Z" fill="url(#co2-category_home_paint0_linear)"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.722778 7.98854H105.959V0H0.722778V7.98854Z" fill="#3277DE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.224 115.834H105.458V111.84H1.224V115.834Z" fill="#266396"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.224 148.786H105.458V144.792H1.224V148.786Z" fill="#266396"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2488 44.4363H29.287V21.4692H12.2488V44.4363Z" fill="#00B0B2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.7701 42.4392H27.2825V23.4664H21.7701V42.4392ZM14.2532 42.4392H19.7655V23.4665H14.2532V42.4392Z" fill="#F8FCFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M44.822 44.4363H61.8603V21.4692H44.822V44.4363Z" fill="#00B0B2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M54.3432 42.4392H59.8555V23.4664H54.3432V42.4392ZM46.8263 42.4392H52.3387V23.4665H46.8263V42.4392Z" fill="#F8FCFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M77.395 44.4363H94.4333V21.4692H77.395V44.4363Z" fill="#00B0B2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M86.9163 42.4392H92.4287V23.4664H86.9163V42.4392ZM79.3995 42.4392H84.9119V23.4665H79.3995V42.4392Z" fill="#F8FCFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2488 76.8896H29.287V53.9226H12.2488V76.8896Z" fill="#00B0B2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.7701 74.8925H27.2825V55.9197H21.7701V74.8925ZM14.2532 74.8925H19.7655V55.9197H14.2532V74.8925Z" fill="#F8FCFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M44.822 76.8896H61.8603V53.9226H44.822V76.8896Z" fill="#00B0B2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M54.3432 74.8925H59.8555V55.9197H54.3432V74.8925ZM46.8263 74.8925H52.3387V55.9197H46.8263V74.8925Z" fill="#F8FCFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M77.395 76.8896H94.4333V53.9226H77.395V76.8896Z" fill="#00B0B2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M86.9163 74.8925H92.4287V55.9197H86.9163V74.8925ZM79.3995 74.8925H84.9119V55.9197H79.3995V74.8925Z" fill="#F8FCFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2488 109.343H29.287V86.376H12.2488V109.343Z" fill="#00B0B2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.7701 107.346H27.2825V88.3731H21.7701V107.346ZM14.2532 107.346H19.7655V88.373H14.2532V107.346Z" fill="#F8FCFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M44.822 109.343H61.8603V86.376H44.822V109.343Z" fill="#00B0B2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M54.3432 107.346H59.8555V88.3731H54.3432V107.346ZM46.8263 107.346H52.3387V88.373H46.8263V107.346Z" fill="#F8FCFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M77.395 109.343H94.4333V86.376H77.395V109.343Z" fill="#00B0B2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M86.9163 107.346H92.4287V88.3731H86.9163V107.346ZM79.3995 107.346H84.9119V88.373H79.3995V107.346Z" fill="#F8FCFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2488 141.797H29.287V118.829H12.2488V141.797Z" fill="#00B0B2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.7701 139.799H27.2825V120.827H21.7701V139.799ZM14.2532 139.799H19.7655V120.827H14.2532V139.799Z" fill="#F8FCFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M44.822 141.797H61.8603V118.829H44.822V141.797Z" fill="#00B0B2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M54.3432 139.799H59.8555V120.827H54.3432V139.799ZM46.8263 139.799H52.3387V120.827H46.8263V139.799Z" fill="#F8FCFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M77.395 141.797H94.4333V118.829H77.395V141.797Z" fill="#00B0B2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M86.9163 139.799H92.4287V120.827H86.9163V139.799ZM79.3995 139.799H84.9119V120.827H79.3995V139.799Z" fill="#F8FCFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M91.677 14.4792H97.6905V10.9842H91.677V14.4792ZM64.1152 14.4792H70.1287V10.9842H64.1152V14.4792ZM36.5531 14.4792H42.5666V10.9842H36.5531V14.4792ZM8.99146 14.4791H15.005V10.9841H8.99146V14.4791Z" fill="#266396"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.22852 10.1591L103.453 17.475V7.98865H3.22852V10.1591Z" fill="#7C8084" fillOpacity="0.5"/>
      </g>
      <defs>
      <linearGradient id="co2-category_home_paint0_linear" x1="-19.0371" y1="43.3925" x2="53.6371" y2="107.303" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E4EBF2"/>
        <stop offset="1" stopColor="#CED5DC"/>
      </linearGradient>
      <clipPath id="co2-category_home_clip0">
        <rect x="0.722778" width="105.236" height="156.276" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default Home
