import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import { Button, Modal } from 'spd-gds/components/common'

const ACTION_CONFIG_DEFAULT = {
	ok: {
		label: 'Yes'
	},
	cancel: {
		label: 'No'
	}
}

export default class ConfirmationDialog extends PureComponent {

	static getDerivedStateFromProps(props, state) {
		return {
			processing: props.processing
		}
	}

	state = {
		processing: false
	}
	_okHandler = () => {
		const {confirmOkHandler, processing} = this.props
		/**
		 * When `Ok` button is clicked, invoke this handler if available
		 */
		if (processing) {
			this.setState( prevState => ({
				...prevState,
				processing: true
			}))
		}
		if (confirmOkHandler) {
			if (processing) {
				setTimeout(()=>{
					confirmOkHandler()
				}, 1000)
			} else {
				confirmOkHandler()
			}
		}
	}

	_cancelHandler = () => {
		const {confirmCancelHandler} = this.props
		/**
		 * When `Cancel` button is clicked, invoke this handler if available
		 */
		if(confirmCancelHandler) {
			confirmCancelHandler()
		}
	}
	render () {
		const { className, onCloseHandler, onEscapeKeyDownHandler, title, content, actionConfig, confirmOkHandler, confirmCancelHandler, closeBtn, processing: processingProp, ...rest } = this.props
		const { processing } = this.state
		const config = {
			...ACTION_CONFIG_DEFAULT,
			...actionConfig
		}
		return (
			<Modal
				className = {`app-confirmation_dialog ${className}`}
				onClose = { onCloseHandler }
				onEscapeKeyDown = { onEscapeKeyDownHandler }
				closeBtn={closeBtn}
				{...rest}
			>
				{
					title !== '' && (<h3>{title}</h3>)
				}

				{
					content  && (<div>
						{content}
					</div>)
				}
				{
					(config.ok || config.cancel) && (
						<div className = {`modal-ctas`}
							style={config.styles}
						>
							{
								(config.ok) && (
									<Button
										variant={`${config.ok.variant || 'solid'}`}
										onClickHandler={this._okHandler}
										className={`lm--button--large`}
										disabled={processing}
										styles={config.ok.styles}
									>
										{processing ? 'processing..' : config.ok.label}
									</Button>
								)
							}
							{
								(config.cancel || !processing) && (
									<Button
										variant={`${config.cancel.variant} || 'solid'`}
										onClickHandler={this._cancelHandler}
										className={`lm--button--large`}
										styles={config.cancel.styles}
										disabled={processing}
									>
										{config.cancel.label}
									</Button>
								)
							}
						</div>
					)
				}

			</Modal>
		)
	}
}

ConfirmationDialog.propTypes = {
	/**
	 * Title of the Dialog component
	 */
	title: PropTypes.string.isRequired,
	/**
	 * if available, renders any content inside the Dialog component
	 */
	content: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.element,
		PropTypes.node,
	]),
	/**
	 * Close handler whenever modal is closing
	 */
	onCloseHandler: PropTypes.func,
	/**
	 * When esc key is pressed will invoke this handler
	 */
	onEscapeKeyDownHandler: PropTypes.func,
	/**
	 * Invoke this handler when `Ok` is clicked from the CTAs
	 */
	confirmOkHandler: PropTypes.func,
	/**
	 * Invoke this handler when `Cancel` is clicked from the CTAs
	 */
	confirmCancelHandler: PropTypes.func,
	actionConfig: PropTypes.object
}

ConfirmationDialog.defaultProps = {
	title: '',
	content: null,
	actionConfig: ACTION_CONFIG_DEFAULT,
	processing: true,
	closeBtn: true
}
