import React from 'react'

const FormSwitchRadio = ({ id, name, value, checked, onChange, label, ...props }) => {
  return (
    <div className='lm--switch'>
      <input type='radio' id={id} name={name} className='lm--switch-input' onChange={onChange} value={value} checked={checked} {...props} />
      <label htmlFor={id} className='lm--switch-label'>
        {label}
      </label>
    </div>
  )
}

export default FormSwitchRadio
