import moment from 'moment'

const getWindowSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

const isEmpty = (obj) => {
  const entries = Object.keys(obj)
  if (entries.length > 0) {
    return false
  }
  return true
}

const parseStrTemplate = (template, data = {}, replaceChar = {}) => {
  const keys = Object.keys(data)
  const chars = Object.keys(replaceChar)
  let result = template
  if (keys.length === 0) {
    return result
  }

  keys.forEach(key => {
    let re = new RegExp(`{${key}}`, 'g')
    let d = data[key]
    if (chars.length > 0) {
      chars.forEach(c => {
        let reC = new RegExp(`${c}`, 'g')
        d = d.replace(reC, replaceChar[c])
      })
    }
    result = result.replace(re, d)
  })
  return result
}

const parseDate = (date, format = 'DD MMM YYYY', timezone = 'Asia/Singapore') => {
  if (timezone) {
    return moment(date).tz(timezone).format(format)
  }
  return moment(date).format(format)
}

const checkIfValIsNum = (v) => {
  return Number.isInteger(parseInt(v))
}

const transformWithZero = (v, padOffset) => {
  if (v.length < 2) {
    if (v.charAt(0) !== '0' && checkIfValIsNum(v.charAt(0))) {
      return `0${v}`
    } else {
      return v.slice(padOffset)
    }
  }
  if (v.length > 2) {
    if (v.charAt(0) === '0' && checkIfValIsNum(v.charAt(0))) {
      let sliceIt = true
      for (let i = 1;i < v.length;i++) {
        if (v.charAt(i) && !checkIfValIsNum(v.charAt(i))) {
          sliceIt = false
        }
      }
      if (sliceIt) {
        return v.slice(padOffset + 1)
      }
      return v
    }
  }
  return v
}

const trailWithZero = (v, joining = '/') => {
  let padOffset = -(v.length)
  if (v === '') {
    return ''
  }
  let vJoin = v.split(joining)
  if (vJoin.length > 1) {
    let vSplit = ''
    vJoin.forEach((vJ, vIndex) => {
      let r = transformWithZero(vJ, padOffset)
      if (r) {
        vSplit += `${r}`
      }
      if (vIndex < (vJoin.length - 1)) {
        vSplit += `/`
      }
    })
    return vSplit
  }

  let res = transformWithZero(v, padOffset)
  if (res) {
    return res
  }
  return v.slice(padOffset)
}

const capitalise = (str, firstOnly = true, splitBy = ' ') => {
  if (firstOnly) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  str = str.split(splitBy)
  for (let i = 0;i < str.length;i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(splitBy)
}

const formatNumber = (num, decimal) => {
  if(typeof decimal == 'undefined') {
    return Number(num).toLocaleString('en-US')
  }

  let result = Number(num).toLocaleString('en-US', {maximumFractionDigits: decimal})
  const dotIndex = result.indexOf('.')

  // forcing zero decimal on round number
  if(dotIndex < 0) {
    const requiredPadEnd = result.length + decimal + 1
    return (result + '.').padEnd(requiredPadEnd,'0')
  }
  
  // adding additional zero if not enough.
  const totalPadRequired = decimal + dotIndex + 1
  return result.padEnd(totalPadRequired , '0')
}

const detectIE = () => {
  let ua = window.navigator.userAgent

  let msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  let trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  let edge = ua.indexOf('Edge/')
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
  }

  // other browser
  return false
}

/**
 * Convert a base64 string in a Blob according to the data and contentType.
 *
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @return Blob
 */
const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || ""
  sliceSize = sliceSize || 512
  let byteCharacters = atob(b64Data)
  let byteArrays = []
  for (let offset = 0;offset < byteCharacters.length;offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize)
    let byteNumbers = new Array(slice.length)
    for (let i = 0;i < slice.length;i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    let byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  let blob = new Blob(byteArrays, {
    type: contentType,
  })
  return blob
}

const utils = {
  getWindowSize,
  isEmpty,
  parseStrTemplate,
  parseDate,
  transformWithZero,
  trailWithZero,
  capitalise,
  formatNumber,
  detectIE,
  b64toBlob
}

export default utils
