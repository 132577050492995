import React from 'react'
import classnames from 'classnames'

const LandTransport = ({show}) => {
  const _LandTransport = classnames('co2-category_landtransport', {
    show
  })
  return (
    <svg className={_LandTransport} width="199" height="88" viewBox="0 0 199 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M97.7897 8.35067H145.581C145.581 3.73503 142.226 0 138.097 0H101.874C99.6115 0 97.7897 2.03453 97.7897 4.55491V8.35067Z" fill="#008587"/>
      <path d="M143.987 3.21883H97.957C97.9722 3.14292 98.0026 3.067 98.0329 2.99109H143.82C143.881 3.05182 143.942 3.12774 143.987 3.21883Z" fill="#52CFD1"/>
      <path d="M46.3397 70.7958H73.9093C74.9871 70.7958 75.8677 69.8241 75.8677 68.6095V50.1165H44.3965V68.6246C44.3813 69.8241 45.2618 70.7958 46.3397 70.7958Z" fill="#2A2C2D"/>
      <path d="M167.746 70.8897L146.386 71.2085L138.84 60.7626L149.893 45.1392L167.184 44.2131L170.418 53.9606L167.746 70.8897Z" fill="#2A2C2D"/>
      <path d="M167.746 70.8897H191.551C192.674 70.8897 193.585 69.8724 193.585 68.6122V49.5727L192.72 40.0074C191.945 31.3834 190.002 22.9416 186.951 14.9705L185.22 10.4004C184.749 9.17059 183.747 8.30516 182.563 8.10778L169.887 5.99734C167.063 5.52666 164.194 5.28373 161.339 5.26855L8.21892 4.75233C5.16743 4.73714 2.60178 7.28789 2.25261 10.6585L1.114 21.6814C0.749634 25.2646 0.552261 28.863 0.552261 32.4766V66.5321C0.552261 68.0504 1.61497 69.3106 2.96613 69.4017L20.2882 70.6164C22.7628 70.7834 25.2525 70.8745 27.7423 70.8745H48.6776C48.6776 70.8745 47.4175 55.1145 58.3786 55.1145C69.3396 55.1145 70.6756 64.6039 70.038 71.1782H146.401C146.401 71.1782 144.928 54.9475 156.861 54.9475C168.794 54.9778 167.807 69.174 167.746 70.8897Z" fill="url(#co2-category_landtransport_paint0_linear)"/>
      <path d="M171.071 19.3584V48.9198C171.071 50.1951 171.648 51.3794 172.604 52.0778C174.198 53.2469 175.99 54.0061 177.872 54.3401L189.183 56.3139L188.985 42.4518C188.864 33.843 187.422 25.3253 184.704 17.2783L173.136 17.0809C172.012 17.0657 171.071 18.083 171.071 19.3584Z" fill="#008587"/>
      <path d="M182.745 11.9946L183.246 13.6344L171.572 13.3156C169.173 13.2548 167.078 15.1527 166.653 17.7946L164.117 33.6305C163.89 35.0881 162.69 36.1205 161.354 35.9839L143.41 34.2378C140.844 33.9949 138.279 33.8582 135.698 33.8582H5.80476V33.008C5.80476 24.7028 6.7005 16.4129 8.47673 8.35071H157.119H160.762C167.609 8.35071 174.456 9.07949 181.181 10.5067C181.91 10.6585 182.502 11.2355 182.745 11.9946Z" fill="#008587"/>
      <path d="M9.43374 10.9318H39.6753V32.0969H9.43374V10.9318Z" fill="#CED5DC"/>
      <path d="M43.4704 10.9318H73.7119V32.0969H43.4704V10.9318Z" fill="#CED5DC"/>
      <path d="M77.507 10.9318H107.749V32.0969H77.507V10.9318Z" fill="#CED5DC"/>
      <path d="M111.544 10.9318H141.786V32.0969H111.544V10.9318Z" fill="#CED5DC"/>
      <path d="M145.581 32.0969H162.539L164.406 16.4432C164.755 13.5281 162.721 10.9318 160.095 10.9318H145.581V32.0969Z" fill="#CED5DC"/>
      <path d="M9.43374 10.9318H39.6753V13.8317H9.43374V10.9318Z" fill="#F8FCFF"/>
      <path d="M43.4704 10.9318H73.7119V13.8317H43.4704V10.9318Z" fill="#F8FCFF"/>
      <path d="M77.507 10.9318H107.749V13.8317H77.507V10.9318Z" fill="#F8FCFF"/>
      <path d="M111.544 10.9318H141.786V13.8317H111.544V10.9318Z" fill="#F8FCFF"/>
      <path d="M145.581 10.9318V13.8317H154.766C158.971 13.8317 162.554 16.838 163.859 21.0589L164.406 16.4432C164.755 13.5281 162.736 10.9318 160.094 10.9318H145.581Z" fill="#F8FCFF"/>
      <path d="M5.85045 69.6142V33.8582H6.06299V69.6294L5.85045 69.6142Z" fill="#52CFD1"/>
      <path d="M22.7777 50.4381L14.1395 41.0246H0.552017V40.7968H14.2305L22.8688 50.1951H171.48V70.8896H171.268V50.4381H22.7777Z" fill="#52CFD1"/>
      <path d="M138.734 50.1952H138.947V71.2085H138.734V50.1952Z" fill="#52CFD1"/>
      <path d="M106.702 50.2407H106.914V71.2084H106.702V50.2407Z" fill="#52CFD1"/>
      <path d="M74.6683 50.3166H74.8809V71.2085H74.6683V50.3166Z" fill="#52CFD1"/>
      <path d="M42.6351 50.2559H42.8477V70.8896H42.6351V50.2559Z" fill="#52CFD1"/>
      <path d="M24.3867 70.8441C24.3108 70.8441 24.2501 70.8441 24.1742 70.8441V50.3166H24.3867V70.8441Z" fill="#52CFD1"/>
      <path d="M189.061 45.0329H192.158V45.2606H189.061V45.0329Z" fill="#52CFD1"/>
      <path d="M188.939 56.2076H189.152V70.8897H188.939V56.2076Z" fill="#52CFD1"/>
      <path d="M189.031 61.5369H193.585V61.7646H189.031V61.5369Z" fill="#52CFD1"/>
      <path d="M191.05 45.1392L190.822 41.146C190.306 31.9603 188.363 22.9416 185.099 14.4998L184.309 12.4501C184.021 11.6909 184.461 10.8255 185.19 10.7192L185.326 10.704L186.966 14.9705C190.017 22.9416 191.961 31.3833 192.735 40.0073L193.524 48.7831C192.143 48.2365 191.156 46.8245 191.05 45.1392Z" fill="#CED5DC"/>
      <path d="M157.119 76.5984C152.189 76.5984 148.192 72.1392 148.192 66.6384C148.192 61.1376 152.189 56.6783 157.119 56.6783C162.049 56.6783 166.046 61.1376 166.046 66.6384C166.046 72.1392 162.049 76.5984 157.119 76.5984Z" fill="#606366"/>
      <path d="M157.119 73.5314C153.706 73.5314 150.94 70.4453 150.94 66.6383C150.94 62.8314 153.706 59.7452 157.119 59.7452C160.531 59.7452 163.298 62.8314 163.298 66.6383C163.298 70.4453 160.531 73.5314 157.119 73.5314Z" fill="#C0C6CC"/>
      <path d="M157.119 67.7922C159.533 67.7922 161.673 66.4713 162.979 64.4368C162.159 61.7039 159.852 59.7452 157.119 59.7452C154.401 59.7452 152.079 61.719 151.259 64.4368C152.564 66.4561 154.705 67.7922 157.119 67.7922Z" fill="#CED5DC"/>
      <path d="M59.4107 76.5984C54.4806 76.5984 50.484 72.1392 50.484 66.6384C50.484 61.1376 54.4806 56.6783 59.4107 56.6783C64.3408 56.6783 68.3374 61.1376 68.3374 66.6384C68.3374 72.1392 64.3408 76.5984 59.4107 76.5984Z" fill="#606366"/>
      <path d="M59.411 73.5314C55.9985 73.5314 53.2321 70.4453 53.2321 66.6383C53.2321 62.8314 55.9985 59.7452 59.411 59.7452C62.8235 59.7452 65.5898 62.8314 65.5898 66.6383C65.5898 70.4453 62.8235 73.5314 59.411 73.5314Z" fill="#C0C6CC"/>
      <path d="M59.4114 67.7922C61.8253 67.7922 63.9659 66.4713 65.2715 64.4368C64.4517 61.7039 62.1441 59.7452 59.4114 59.7452C56.6939 59.7452 54.3712 61.719 53.5514 64.4368C54.8722 66.4561 56.9976 67.7922 59.4114 67.7922Z" fill="#CED5DC"/>
      <path d="M178.798 61.2939H181.607V62.4934H178.798V61.2939Z" fill="#FFC466"/>
      <path d="M154.568 51.592H157.741V53.1407H154.568V51.592Z" fill="#FFC466"/>
      <path d="M121.108 62.4023H124.433V64.8924H121.108V62.4023Z" fill="#01AAAC"/>
      <path d="M121.503 61.3698H124.053V62.4023H121.503V61.3698Z" fill="#FFC466"/>
      <path d="M89.1215 62.4024H92.4463V64.8924H89.1215V62.4024Z" fill="#01AAAC"/>
      <path d="M89.5159 61.3699H92.0664V62.4024H89.5159V61.3699Z" fill="#FFC466"/>
      <path d="M31.4311 62.4023H34.7559V64.8924H31.4311V62.4023Z" fill="#01AAAC"/>
      <path d="M31.826 61.3699H34.3765V62.4023H31.826V61.3699Z" fill="#BFB670"/>
      <path d="M17.4492 46.9156L22.0796 52.3664V67.352C22.0796 68.1871 21.442 68.84 20.6981 68.764L13.0162 67.9745C12.3634 67.9138 11.8624 67.2913 11.8624 66.5625V48.3429C11.8624 47.5533 12.4242 46.9308 13.1377 46.9308H17.4492V46.9156Z" fill="#2A2C2D"/>
      <path d="M11.9538 47.7963H18.1934L17.4495 46.9156H13.1379C12.6066 46.9156 12.1511 47.28 11.9538 47.7963Z" fill="#585C5E"/>
      <path d="M11.8624 48.7376V49.846H19.9541L18.9977 48.7376H11.8624Z" fill="#585C5E"/>
      <path d="M11.8626 50.7874V51.9109H21.7002L20.7438 50.7874H11.8626Z" fill="#585C5E"/>
      <path d="M11.8619 52.8523H22.0791V53.9758H11.8619V52.8523Z" fill="#585C5E"/>
      <path d="M11.8619 54.9019H22.0791V56.0255H11.8619V54.9019Z" fill="#585C5E"/>
      <path d="M11.8619 56.9669H22.0791V58.0904H11.8619V56.9669Z" fill="#585C5E"/>
      <path d="M11.8619 59.0166H22.0791V60.1401H11.8619V59.0166Z" fill="#585C5E"/>
      <path d="M11.8619 61.0814H22.0791V62.205H11.8619V61.0814Z" fill="#585C5E"/>
      <path d="M11.8619 63.1311H22.0791V64.2547H11.8619V63.1311Z" fill="#585C5E"/>
      <path d="M11.8619 65.196H22.0791V66.3196H11.8619V65.196Z" fill="#585C5E"/>
      <path d="M12.0294 67.2458C12.2267 67.6405 12.5911 67.9138 13.0162 67.9594L16.9786 68.3693H21.7C21.9429 68.1112 22.0947 67.7468 22.0947 67.3369V67.2458H12.0294Z" fill="#585C5E"/>
      <path d="M7.26213 61.1118H10.4351V62.6604H7.26213V61.1118Z" fill="#BFB670"/>
      <path d="M172.392 24.5358V44.2131H187.725L187.543 39.3848C187.346 34.3289 186.495 29.3185 184.992 24.5358H172.392Z" fill="#CED5DC"/>
      <path d="M193.585 60.9903H191.414C191.019 60.9903 190.685 60.6259 190.685 60.1856V57.1338C190.685 56.6935 191.004 56.3291 191.414 56.3291H193.585V60.9903Z" fill="#CED5DC"/>
      <path d="M193.585 67.4431H191.217C190.928 67.4431 190.7 67.185 190.7 66.8661V65.6667C190.7 65.3478 190.928 65.0897 191.217 65.0897H193.585V67.4431Z" fill="#FFC466"/>
      <path d="M0.552344 63.0097H3.11802V52.4726C2.08568 52.6244 0.931882 54.9323 0.552344 55.7673V55.2663C1.0837 54.1883 2.17676 52.2449 3.22428 52.2449H3.33057V63.2526H0.552344V63.0097Z" fill="#52CFD1"/>
      <path d="M184.461 13.2092V17.2783H187.801L189.547 16.3825H196.09V17.7186H198.048V13.2092H184.461Z" fill="#7C8084"/>
      <path d="M196.545 31.353H198.58V17.7186H196.606C196.166 17.7186 195.801 18.1134 195.801 18.6144V30.5331C195.801 30.9886 196.135 31.353 196.545 31.353Z" fill="#019698"/>
      <path d="M172.392 24.5358H184.992C186.328 28.8023 187.163 33.2509 187.467 37.7603L186.996 36.7582C184.704 31.8085 180.119 28.696 175.124 28.696H172.407V24.5358H172.392Z" fill="#F8FCFF"/>
      <path d="M145.224 75.7191H56.6846V78.6039H145.224V75.7191Z" fill="#2A2C2D"/>
      <path d="M65.9258 77.509C69.0834 77.509 62.1156 77.4879 61.9472 77.4879C58.9159 77.4248 37.4232 78.0144 37.0233 76.793C36.6022 75.4875 30.2239 65.8854 34.3077 63.5902C38.3705 61.295 43.6963 57.6521 43.6963 57.6521L56.0109 54.0934L65.8205 62.5374L66.2415 68.686L65.7153 73.6976L65.9258 77.509Z" fill="#2A2C2D"/>
      <path d="M55.6197 82.9726C60.9366 80.7695 63.4614 74.672 61.259 69.3535C59.0567 64.035 52.9611 61.5094 47.6442 63.7124C42.3273 65.9155 39.8025 72.0129 42.0048 77.3315C44.2072 82.65 50.3028 85.1756 55.6197 82.9726Z" fill="#606366"/>
      <path d="M51.5905 65.2537C58.1162 65.2537 61.7369 68.665 63.7578 72.2658C62.6631 67.6753 59.6739 60.7896 51.5484 60.7896C42.7492 60.7896 39.9074 67.949 38.897 72.371C40.8547 68.7492 44.5596 65.2537 51.5905 65.2537Z" fill="#C1904F"/>
      <path d="M51.6324 80.3306C55.4922 80.3306 58.6212 77.2007 58.6212 73.3397C58.6212 69.4787 55.4922 66.3487 51.6324 66.3487C47.7725 66.3487 44.6436 69.4787 44.6436 73.3397C44.6436 77.2007 47.7725 80.3306 51.6324 80.3306Z" fill="#CED5DC"/>
      <path d="M51.6324 80.3517C55.5057 80.3517 58.6212 77.2142 58.6212 73.3607C58.6212 72.8132 58.558 72.2657 58.4317 71.7393C56.2003 74.1188 51.3377 75.9718 47.6327 73.2975C46.6434 72.5816 45.675 72.1815 44.7699 71.992C44.6857 72.4342 44.6436 72.8975 44.6436 73.3607C44.6436 77.2142 47.7801 80.3517 51.6324 80.3517Z" fill="#C0C6CC"/>
      <path d="M128.383 77.509C128.72 77.4669 156.822 76.8141 156.822 76.8141L158.864 71.8446L159.496 65.4854C159.496 65.4854 156.886 58.8102 151.56 56.0939C146.234 53.3775 133.519 55.5674 133.519 55.5674L131.646 54.4093L125.078 59.5262L128.383 77.509Z" fill="#2A2C2D"/>
      <path d="M147.875 82.2172C152.779 79.2042 154.312 72.7852 151.3 67.88C148.288 62.9748 141.871 61.4409 136.967 64.4539C132.064 67.4669 130.53 73.8859 133.542 78.7911C136.554 83.6963 142.971 85.2303 147.875 82.2172Z" fill="#606366"/>
      <path d="M142.424 80.3306C146.284 80.3306 149.413 77.2007 149.413 73.3397C149.413 69.4787 146.284 66.3487 142.424 66.3487C138.564 66.3487 135.435 69.4787 135.435 73.3397C135.435 77.2007 138.564 80.3306 142.424 80.3306Z" fill="#CED5DC"/>
      <path d="M142.36 80.3517C146.234 80.3517 149.349 77.2142 149.349 73.3607C149.349 72.8132 149.286 72.2657 149.16 71.7393C146.928 74.1188 142.066 75.9718 138.361 73.2975C137.371 72.5816 136.403 72.1815 135.498 71.992C135.414 72.4342 135.372 72.8975 135.372 73.3607C135.351 77.2142 138.487 80.3517 142.36 80.3517Z" fill="#C0C6CC"/>
      <path d="M143.224 64.5588C152.002 64.5588 156.885 69.6125 159.601 75.0032C158.127 68.1596 154.107 57.9258 143.16 57.9258C131.309 57.9258 127.478 68.5597 126.109 75.1506C128.783 69.781 133.751 64.5588 143.224 64.5588Z" fill="#C1904F"/>
      <path d="M155.518 76.8141C156.591 76.8141 171.685 76.2666 172.211 75.7401C172.737 75.2137 172.127 67.0225 171.6 66.096C171.074 65.1905 170.358 65.0221 170.211 64.8115C170.085 64.5799 169.811 60.0105 169.601 59.6314C169.39 59.2524 161.096 50.8085 132.257 46.8287C126.068 43.4806 116.49 36.2159 104.154 32.0676C98.723 30.2357 92.7236 29.0143 86.24 29.0143C65.0841 29.0143 49.1909 34.7208 33.6555 44.9967C32.7714 47.1445 29.3612 51.6718 28.6455 53.0195C27.9297 54.3671 24.8985 68.7071 24.6879 69.1703C24.4774 69.6125 23.9512 71.0655 23.9512 71.5287C23.9512 71.9709 24.6037 72.2447 24.6037 72.2447C24.6037 72.2447 23.8249 73.6344 25.2563 74.4135C26.6878 75.1927 36.6237 76.7719 38.8761 76.793C39.2129 75.4032 38.1604 61.5266 51.5486 61.5266C64.9368 61.5266 63.7579 77.5089 63.7579 77.5089H129.499C129.499 77.5089 126.552 61.6529 142.656 61.6529C158.78 61.6529 155.518 76.8141 155.518 76.8141Z" fill="url(#co2-category_landtransport_paint1_linear)"/>
      <path d="M29.4025 65.4853C30.455 64.4324 31.8654 52.9563 31.8654 52.9563L28.9394 52.5352C28.8131 52.7247 28.7289 52.8931 28.6447 53.0405C28.1605 53.967 26.5817 60.958 25.5713 65.4432C26.9606 65.8012 28.771 66.117 29.4025 65.4853Z" fill="#C0C6CC"/>
      <path d="M154.086 55.0621C154.022 55.02 148.107 51.7772 142.655 50.8928C141.645 50.7243 140.697 50.598 139.813 50.4716C135.877 49.9452 133.267 49.5872 132.151 47.0393L132.319 46.9761C133.393 49.4398 135.961 49.7978 139.834 50.3032C140.74 50.4295 141.666 50.5558 142.697 50.7243C148.191 51.6087 154.128 54.8726 154.191 54.8936L154.086 55.0621Z" fill="#FFDEB3"/>
      <path opacity="0.28" d="M52.875 49.503C53.6118 51.3771 56.4115 55.6096 58.6429 59.3998C61.5268 62.9585 63.4214 66.4119 65.5264 68.7282C76.9359 69.6547 117.395 72.4763 128.447 73.1923C128.699 70.3496 129.162 66.454 132.383 64.0956C118.911 61.7372 55.7168 50.2189 52.875 49.503Z" fill="#FBAB41"/>
      <path d="M153.581 54.683C159.075 54.1566 169.6 59.6314 169.6 59.6314C169.811 60.0105 170.063 64.5799 170.211 64.8115C164.043 62.8321 153.791 55.5885 153.581 54.683Z" fill="#606366"/>
      <path d="M163.453 61.653C165.811 63.0006 168.211 64.1798 170.211 64.8115C170.084 64.5799 169.811 60.0105 169.6 59.6315C169.6 59.6315 166.274 57.9048 162.401 56.494C162.717 58.0311 163.096 59.8631 163.453 61.653Z" fill="#C0C6CC"/>
      <path d="M125.141 49.3556C125.141 49.3556 100.196 45.839 86.7449 45.5653C73.3146 45.2915 58.0529 45.102 48.7485 42.7857C50.0537 41.775 60.3685 31.7307 84.9346 31.7307C84.9346 31.7307 97.7965 30.9937 107.396 35.7526C116.995 40.5116 125.141 49.3556 125.141 49.3556Z" fill="#CED5DC"/>
      <path d="M49.3589 42.9121C55.211 44.2808 63.2523 44.8704 71.6726 45.1652C68.3887 41.796 65.3995 38.3005 62.7892 35.0156C55.5057 37.4793 51.3376 40.6379 49.5904 42.0698C49.5273 42.3646 49.4431 42.6383 49.3589 42.9121Z" fill="#F8FCFF"/>
      <path d="M90.6816 31.7939C87.2714 31.5833 84.9348 31.7096 84.9348 31.7096C84.6612 31.7096 84.3875 31.7096 84.1138 31.7096C83.9875 36.3001 84.3244 40.9116 84.577 45.5021C85.3137 45.5231 86.0295 45.5231 86.7452 45.5442C91.7342 45.6495 98.323 46.197 104.617 46.8497C99.1019 42.2382 94.2603 36.4896 90.6816 31.7939Z" fill="#F8FCFF"/>
      <path d="M86.8922 31.6675V32.7204C90.534 32.7204 99.7542 33.0994 106.932 36.6791C113.374 39.8798 119.205 45.0178 122.236 47.9026C115.142 46.955 97.3334 44.723 86.787 44.5124C85.945 44.4913 85.1029 44.4703 84.2609 44.4703C72.7462 44.2176 59.8001 43.9649 51.1062 42.2172C52.7271 40.9748 55.4636 39.1007 59.6317 37.353C66.8099 34.2997 75.3354 32.7625 84.9766 32.7625H84.9977H85.0187C85.0187 32.7625 85.7345 32.7204 86.9133 32.7204L86.8922 31.6675ZM86.8922 31.6675C85.6713 31.6675 84.9556 31.7097 84.9556 31.7097C60.4105 31.7097 50.0747 41.7539 48.7695 42.7647C58.0739 45.0809 73.3146 45.2705 86.7659 45.5442C100.196 45.8179 125.162 49.3345 125.162 49.3345C125.162 49.3345 116.995 40.4905 107.417 35.7316C100.091 32.1097 90.8498 31.6675 86.8922 31.6675Z" fill="#C1904F"/>
      <path d="M33.0449 46.197C33.2975 45.7548 33.508 45.3336 33.6554 44.9967C40.7284 40.322 47.8856 36.5739 55.5902 33.8996C55.0639 35.0367 54.0745 36.5107 52.2221 37.5846C48.9382 39.5219 39.4022 47.5236 33.0449 46.197Z" fill="#DED1FD"/>
      <path d="M33.0449 46.197C33.2975 45.7548 33.508 45.3336 33.6554 44.9967C40.7284 40.322 47.8856 36.5739 55.5902 33.8996C55.0639 35.0367 54.0745 36.5107 52.2221 37.5846C48.9382 39.5219 39.4022 47.5236 33.0449 46.197Z" fill="#CED5DC"/>
      <path d="M51.9693 37.1424C51.3378 37.5004 50.4747 38.09 49.4643 38.7849C45.212 41.6486 38.2022 46.3865 33.2763 45.7969C33.1079 46.0917 32.9185 46.4286 32.729 46.7655C33.1711 46.8497 33.6552 46.8919 34.1183 46.8919C39.3179 46.8919 45.9488 42.4067 50.0537 39.6271C51.0431 38.9533 51.9061 38.3848 52.4955 38.0268C54.5585 36.8265 55.9479 34.6998 56.432 33.6259L56.411 33.6048C55.9268 33.7733 55.4426 33.9417 54.9585 34.1102C54.327 35.0999 53.2955 36.3633 51.9693 37.1424Z" fill="#C1904F"/>
      <path d="M122.931 44.6388C116.595 39.3745 108.196 35.3526 106.154 34.2787C105.291 33.8365 104.638 32.931 104.154 32.0677C116.342 36.1528 125.836 43.2911 132.025 46.7024C132.046 46.7866 132.067 46.8287 132.067 46.8287H132.235C132.025 47.5657 126.91 47.1446 122.931 44.6388Z" fill="#CED5DC"/>
      <path d="M86.1973 31.6675C87.0182 34.6577 89.018 42.0066 89.9443 45.6495C88.8286 45.5863 87.7761 45.5653 86.7656 45.5442C85.5447 45.5231 84.3238 45.5021 83.0818 45.46L82.0503 31.7518C82.9976 31.7307 83.9659 31.7097 84.9553 31.7097C84.9553 31.7097 85.3973 31.6886 86.1973 31.6675Z" fill="#C1904F"/>
      <path d="M111.058 74.2451C106.743 74.2451 101.817 74.203 96.8911 74.1187C88.913 73.9924 70.1989 73.5713 68.578 72.4131C67.1676 71.4024 63.0417 65.9275 59.7157 61.0001C56.3897 56.1149 52.8953 50.3663 52.9795 48.9133C53.1479 46.1338 53.169 46.0496 55.1267 44.1123C56.8949 42.3435 58.9368 39.8588 58.9368 36.4896H59.1053C59.1053 39.9219 57.0423 42.4277 55.2319 44.2387C53.2953 46.1759 53.2953 46.218 53.1479 48.9344C53.0006 51.5665 65.9467 70.3285 68.6833 72.2657C69.7779 73.0448 80.3243 73.6765 96.8911 73.9292C109.964 74.1398 123.457 74.0556 124.91 73.7397C129.73 62.0951 124.994 49.4819 124.931 49.3555L125.099 49.2924C125.141 49.4187 129.941 62.1162 125.057 73.845L125.036 73.8871L124.994 73.9082C124.215 74.1398 118.552 74.2451 111.058 74.2451Z" fill="#FFDEB3"/>
      <path d="M86.787 73.803L86.6186 73.7819C89.9657 54.4725 84.0505 32.0045 83.9873 31.7729L84.1557 31.7308C84.1978 31.9413 90.1341 54.4514 86.787 73.803Z" fill="#FFDEB3"/>
      <path d="M97.5649 51.735C97.5649 50.8717 96.8702 50.1768 96.0071 50.1768H90.2814C89.4183 50.1768 88.7236 50.8717 88.7236 51.735H97.5649Z" fill="#C1904F"/>
      <path d="M65.6733 51.735C65.6733 50.8717 64.9786 50.1768 64.1155 50.1768H58.3898C57.5267 50.1768 56.832 50.8717 56.832 51.735H65.6733Z" fill="#C1904F"/>
      <path d="M132.256 46.8287C132.088 46.7445 131.898 46.6392 131.73 46.5339C130.277 46.7023 126.341 46.2812 123.183 44.3018C117.479 39.564 110.279 35.9 107.206 34.3418C106.848 34.1733 106.574 34.0259 106.343 33.8996C105.922 33.689 105.522 33.0363 105.206 32.4045C104.827 32.2782 104.448 32.1519 104.09 32.0255C104.111 32.0676 104.132 32.0887 104.132 32.1308C104.596 33.0573 105.143 34.2155 105.964 34.6366C106.174 34.7419 106.469 34.8893 106.827 35.0788C109.879 36.637 117.036 40.2589 122.678 44.9546L122.72 44.9967C125.457 46.7234 128.93 47.3972 131.014 47.3972C131.667 47.3972 132.172 47.3341 132.488 47.2077C132.677 47.1445 132.803 47.0393 132.909 46.9129C132.698 46.8919 132.488 46.8708 132.256 46.8287Z" fill="#C1904F"/>
      <path d="M119.871 44.3593C119.143 43.5991 117.151 41.8713 114.405 42.3014C110.89 42.8489 111.943 48.2606 111.943 48.2606C111.943 48.2606 113.711 52.4931 118.216 51.8614C122.72 51.2086 125.12 49.524 124.762 49.3556C124.738 49.3441 124.679 49.3173 124.59 49.2773C124.949 49.3278 125.141 49.3556 125.141 49.3556C125.141 49.3556 123.12 47.1446 119.879 44.3229C119.876 44.335 119.873 44.3471 119.871 44.3593Z" fill="#C1904F"/>
      <defs>
        <linearGradient id="co2-category_landtransport_paint0_linear" x1="97.0686" y1="4.75226" x2="97.0686" y2="71.1782" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00B0B2"/>
          <stop offset="1" stopColor="#008587"/>
        </linearGradient>
        <linearGradient id="co2-category_landtransport_paint1_linear" x1="96.4821" y1="30.0895" x2="95.9449" y2="61.5378" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFDEB3"/>
          <stop offset="1" stopColor="#FFC466"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LandTransport
