import React from 'react'
import classnames from 'classnames'

const FormCTA = ({
  isInline,
  isCenter,
  children,
  ...props
}) => {
  const _formCta = classnames('lm--formItem form-item form-cta field-cta', {
    'lm--formItem--inline': isInline,
    'lm--formItem--center': isCenter,
  })
  return (
    <div className={_formCta} {...props}>
      { children }
    </div>
  )
}

export default FormCTA
