import React from 'react'

import TreeHero from './TreeHero'
import Podium from './Podium'
import CO2 from './CO2'

const HeroTree = () => {
  return (
    <div className='hero-tree'>
      <Podium className='gfx-featpodium'>
        <TreeHero />
        <CO2 />
      </Podium>
    </div>
  )
}

export default HeroTree
