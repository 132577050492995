import React from 'react'

const Grass1 = () => {
  return (
    <svg className='gfx-grass1' width="334" height="75" viewBox="0 0 334 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Grass">
        <path id="Oval" fillRule="evenodd" clipRule="evenodd" d="M10.5318 62.1958C15.1071 62.1958 18.8162 61.2685 18.8162 60.1247C18.8162 58.9809 15.1071 58.0536 10.5318 58.0536C5.95647 58.0536 2.24744 58.9809 2.24744 60.1247C2.24744 61.2685 5.95647 62.1958 10.5318 62.1958Z" fill="url(#gfx-grass1-paint0_linear)"/>
        <path id="Oval Copy 2" fillRule="evenodd" clipRule="evenodd" d="M322.138 74.6223C326.714 74.6223 330.423 73.695 330.423 72.5512C330.423 71.4074 326.714 70.4801 322.138 70.4801C317.563 70.4801 313.854 71.4074 313.854 72.5512C313.854 73.695 317.563 74.6223 322.138 74.6223Z" fill="url(#gfx-grass1-paint1_linear)"/>
        <path id="Oval Copy 3" fillRule="evenodd" clipRule="evenodd" d="M268.607 35.2715C275.47 35.2715 281.033 34.3443 281.033 33.2005C281.033 32.0566 275.47 31.1294 268.607 31.1294C261.744 31.1294 256.18 32.0566 256.18 33.2005C256.18 34.3443 261.744 35.2715 268.607 35.2715Z" fill="url(#gfx-grass1-paint2_linear)"/>
        <g id="Group 13 Copy 2" className='gfx-grass1-g1'>
          <g className='gfx-grass1-leaf2'>
            <g id="Group 3">
              <path id="Fill 1" fillRule="evenodd" clipRule="evenodd" d="M285.61 4.55269C280.092 5.61139 275.18 7.21512 271.789 10.511C274.595 15.8278 274.216 22.9856 268.125 33.6476C276.263 26.5988 294.332 20.2059 285.61 4.55269Z" fill="url(#gfx-grass1-paint3_linear)"/>
            </g>
            <g id="Group 6">
              <path id="Fill 4" fillRule="evenodd" clipRule="evenodd" d="M271.789 10.511C274.595 15.8277 274.216 22.9856 268.125 33.6476C279.453 21.0342 284.397 9.60195 285.61 4.55269C280.092 5.61139 275.18 7.21512 271.789 10.511Z" fill="url(#gfx-grass1-paint4_linear)"/>
            </g>
          </g>
          <g className='gfx-grass1-leaf1'>
            <g id="Group 9">
              <path id="Fill 7" fillRule="evenodd" clipRule="evenodd" d="M260.487 0.573607C247.354 12.7655 262.563 24.4289 268.125 33.6477C279.006 14.6 271.661 6.73277 260.487 0.573607Z" fill="#A4C663"/>
            </g>
            <g id="Group 12">
              <path id="Fill 10" fillRule="evenodd" clipRule="evenodd" d="M260.487 0.573607C247.354 12.7655 262.563 24.4289 268.125 33.6477C272.349 17.5229 264.216 4.41708 260.487 0.573607Z" fill="url(#gfx-grass1-paint5_linear)"/>
            </g>
          </g>
        </g>
        <g id="Group 13 Copy 4" className='gfx-grass1-g2'>
          <g className='gfx-grass1-leaf2'>
            <g id="Group 3_2">
              <path id="Fill 1_2" fillRule="evenodd" clipRule="evenodd" d="M18.1867 39.4393C14.9056 40.6897 12.056 42.21 10.3268 44.6066C12.6306 47.5746 13.174 52.0215 10.5823 59.245C14.8266 54.0232 25.2539 48.1273 18.1867 39.4393Z" fill="url(#gfx-grass1-paint6_linear)"/>
            </g>
            <g id="Group 6_2">
              <path id="Fill 4_2" fillRule="evenodd" clipRule="evenodd" d="M10.3268 44.6066C12.6306 47.5746 13.174 52.0215 10.5823 59.245C16.1857 50.252 17.988 42.6788 18.1867 39.4393C14.9056 40.6897 12.056 42.21 10.3268 44.6066Z" fill="url(#gfx-grass1-paint7_linear)"/>
            </g>
          </g>
          <g className='gfx-grass1-leaf1'>
            <g id="Group 9_2">
              <path id="Fill 7_2" fillRule="evenodd" clipRule="evenodd" d="M2.29137 39.7168C-4.46848 48.6462 6.15815 54.1746 10.5823 59.2451C15.2125 46.3402 9.83791 42.295 2.29137 39.7168Z" fill="#A4C663"/>
            </g>
            <g id="Group 12_2">
              <path id="Fill 10_2" fillRule="evenodd" clipRule="evenodd" d="M2.29137 39.7167C-4.46848 48.6461 6.15815 54.1744 10.5823 59.245C11.432 48.8617 5.00393 41.6776 2.29137 39.7167Z" fill="url(#gfx-grass1-paint8_linear)"/>
            </g>
          </g>
        </g>
        <g id="Group 13 Copy 5" className='gfx-grass1-g3'>
          <g className='gfx-grass1-leaf2'>
            <g id="Group 3_3">
              <path id="Fill 1_3" fillRule="evenodd" clipRule="evenodd" d="M311.724 50.5743C315.44 51.63 318.708 53.0227 320.833 55.4832C318.593 58.9564 318.424 63.8815 321.956 71.5487C316.804 66.2334 304.813 60.7723 311.724 50.5743Z" fill="url(#gfx-grass1-paint9_linear)"/>
            </g>
            <g id="Group 6_3">
              <path id="Fill 4_3" fillRule="evenodd" clipRule="evenodd" d="M320.833 55.4832C318.593 58.9564 318.424 63.8815 321.956 71.5487C314.954 62.2311 312.253 54.1051 311.724 50.5743C315.44 51.63 318.708 53.0227 320.833 55.4832Z" fill="url(#gfx-grass1-paint10_linear)"/>
            </g>
          </g>
          <g className='gfx-grass1-leaf1'>
            <g id="Group 9_3">
              <path id="Fill 7_3" fillRule="evenodd" clipRule="evenodd" d="M329.169 49.3544C337.433 58.4913 326.318 65.5682 321.956 71.5487C315.645 57.8512 321.147 52.9032 329.169 49.3544Z" fill="#A4C663"/>
            </g>
            <g id="Group 12_3">
              <path id="Fill 10_3" fillRule="evenodd" clipRule="evenodd" d="M329.169 49.3544C337.433 58.4913 326.318 65.5681 321.956 71.5487C320.029 60.252 326.385 51.7633 329.169 49.3544Z" fill="url(#gfx-grass1-paint11_linear)"/>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <linearGradient id="gfx-grass1-paint0_linear" x1="19.05" y1="59.5363" x2="17.2985" y2="57.042" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F8FCFF"/>
          <stop offset="1" stopColor="#E4EBF2"/>
        </linearGradient>
        <linearGradient id="gfx-grass1-paint1_linear" x1="330.657" y1="71.9628" x2="328.905" y2="69.4685" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F8FCFF"/>
          <stop offset="1" stopColor="#E4EBF2"/>
        </linearGradient>
        <linearGradient id="gfx-grass1-paint2_linear" x1="281.384" y1="32.6121" x2="279.954" y2="29.5574" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F8FCFF"/>
          <stop offset="1" stopColor="#E4EBF2"/>
        </linearGradient>
        <linearGradient id="gfx-grass1-paint3_linear" x1="262.592" y1="16.6934" x2="285.742" y2="33.8256" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4EDA7"/>
          <stop offset="1" stopColor="#B0CF7A"/>
        </linearGradient>
        <linearGradient id="gfx-grass1-paint4_linear" x1="268.032" y1="16.0412" x2="280.046" y2="20.8661" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4EDA7"/>
          <stop offset="1" stopColor="#B0CF7A"/>
        </linearGradient>
        <linearGradient id="gfx-grass1-paint5_linear" x1="246.32" y1="14.1163" x2="269.593" y2="31.5015" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4EDA7"/>
          <stop offset="1" stopColor="#B0CF7A"/>
        </linearGradient>
        <linearGradient id="gfx-grass1-paint6_linear" x1="5.33642" y1="49.4101" x2="21.4453" y2="57.4425" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4EDA7"/>
          <stop offset="1" stopColor="#B0CF7A"/>
        </linearGradient>
        <linearGradient id="gfx-grass1-paint7_linear" x1="8.61417" y1="48.4183" x2="16.5327" y2="50.0841" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4EDA7"/>
          <stop offset="1" stopColor="#B0CF7A"/>
        </linearGradient>
        <linearGradient id="gfx-grass1-paint8_linear" x1="-4.95849" y1="49.5898" x2="11.2529" y2="57.7647" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4EDA7"/>
          <stop offset="1" stopColor="#B0CF7A"/>
        </linearGradient>
        <linearGradient id="gfx-grass1-paint9_linear" x1="326.762" y1="60.2685" x2="309.879" y2="70.6149" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4EDA7"/>
          <stop offset="1" stopColor="#B0CF7A"/>
        </linearGradient>
        <linearGradient id="gfx-grass1-paint10_linear" x1="323.075" y1="59.4959" x2="314.557" y2="62.0805" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4EDA7"/>
          <stop offset="1" stopColor="#B0CF7A"/>
        </linearGradient>
        <linearGradient id="gfx-grass1-paint11_linear" x1="338.06" y1="59.4785" x2="321.079" y2="69.9908" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4EDA7"/>
          <stop offset="1" stopColor="#B0CF7A"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Grass1
