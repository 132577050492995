import React, { PureComponent } from 'react'

import Button from '../common/Button'
import FormErrorBlock from './FormErrorBlock'

class FormMobileNumber extends PureComponent {
  state = {
    errorPrefix: null,
    errorNumber: null,
  }
  render() {
    const {
      id,
      label,
      errorPrefix,
      errorNumber,
      valuePrefix,
      value,
      name,
      handleChange,
      setFieldValue,
      ctaHandler,
      ctaLabel,
      handleBlur,
      prefixMaxLength,
      maxLength,
      ...props
    } = this.props

    return (
      <div
        className={`lm--formItem lm--formItem--inline field-mobile ${
          errorNumber ? 'is-error' : ''
        }${ctaHandler ? 'with-cta' : ''}`}
      >
        <div className="lm--formItem-label label">{label}</div>
        <div className={`lm--formItem-control control`}>
          <div className="control-wrap">
            <input
              disabled
              id={id + '_prefix'}
              className="mobile_prefix"
              type="text"
              value={valuePrefix}
              maxLength={prefixMaxLength}
              name={name}
              onChange={handleChange}
              onBlur={this._blurHandler}
              {...props}
            />
            <input
              id={id}
              className="number"
              type="tel"
              value={value}
              name={name}
              maxLength={maxLength}
              onChange={this._changeHandler}
              onBlur={this._blurHandler}
              {...props}
            />
            {ctaHandler && (
              <div className="field-cta">
                <Button
                  link={true}
                  onClickHandler={ctaHandler}
                  variant={'secondary'}
                >
                  {ctaLabel || 'Continue'}
                </Button>
              </div>
            )}
          </div>
          <FormErrorBlock id={`${id}-field_error_prefix`} error={errorPrefix} />
          <FormErrorBlock id={`${id}-field_error_number`} error={errorNumber} />
        </div>
      </div>
    )
  }
  _blurHandler = e => {
    const { handleBlur, errorPrefix, errorNumber } = this.props

    if (handleBlur) {
      handleBlur(e)
    }
    if (errorPrefix || errorNumber) {
      this.setState(prevState => ({
        ...prevState,
        errorPrefix,
        errorNumber,
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        errorPrefix: null,
        errorNumber: null,
      }))
    }
  }

  _changeHandler = e => {
    const { id, setFieldValue } = this.props
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      setFieldValue(id, e.target.value)
    }
  }
}

FormMobileNumber.defaultProps = {
  valuePrefix: '+65',
  autoComplete: 'off',
  autoCorrect: 'off',
  autoCapitalize: 'off',
  prefixMaxLength: '3',
  maxLength: '8',
  setFieldValue: () => {},
}

export default FormMobileNumber
