import React from 'react'
import classnames from 'classnames'

const ArticleHero = ({ children }) => {
  const _ArticleHero = classnames('app-article__hero')
  return (
    <div className={_ArticleHero}>
      {children}
    </div>
  )
}

export default ArticleHero
