import React from 'react'
import classnames from 'classnames'

const Blade = ({ id, className, withContainer, title, children }) => {
  const _Blade = classnames('app-page__blade section-blade', className)

  function renderContent() {
    if (withContainer) {
      return (
        <div className='blade-container'>
          {children}
        </div>
      )
    }
    return children
  }

  function renderTitle() {
    if (!title) {
      return null
    }
    return <h1>{ title }</h1>
  }

  return (
    <section className={_Blade} id={id}>
      { renderTitle() }
      { renderContent() }
    </section>
  )
}

Blade.defaultProps = {
  className: '',
  id: '',
  title: null,
  withContainer: true
}

export default Blade
