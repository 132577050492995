import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import FormSwitchRadio from './FormSwitchRadio'
import FormErrorBlock from './FormErrorBlock'

class FormChoose extends PureComponent {
  state = {
    error: null,
  }
  render() {
    const { title, children, multiple, error, notes, onlyElem } = this.props
    // const {
    //   error:stateError
    // } = this.state
    if (onlyElem) {
      return (
        <div className={`form-choose`}>
          {children ? (
            typeof children === 'function' ? (
              children()
            ) : (
              children
            )
          ) : (
            <div className="choices">{this._renderChoices()}</div>
          )}
          {error && (
            <FormErrorBlock
              id={`${multiple ? 'checks' : 'rads'}-field_error`}
              error={error}
            />
          )}
          {notes.length > 0 && this._renderNotes()}
        </div>
      )
    }
    return (
      <Fragment>
        <div className="lm--formItem lm--formItem--inline field-choose">
          <div className="lm--formItem-label label">{title}</div>
          <div className="lm--formItem-control control">
            {children ? (
              typeof children === 'function' ? (
                children()
              ) : (
                children
              )
            ) : (
              <div className="choices">{this._renderChoices()}</div>
            )}
            {error && (
              <FormErrorBlock
                id={`${multiple ? 'checks' : 'rads'}-field_error`}
                error={error}
              />
            )}
            {notes.length > 0 && this._renderNotes()}
          </div>
        </div>
      </Fragment>
    )
  }
  _renderChoices() {
    const { choices, multiple, selected, id, chooseAll } = this.props

    if (choices.length > 0) {
      if (!multiple) {
        return choices.map((c, i) => {
          return (
            <FormSwitchRadio
              key={`rad--${c.name}-${i}`}
              label={c.label}
              id={c.id}
              name={c.name || id}
              error={c.error}
              value={c.value}
              checked={c.value === selected[0]}
              onChange={this._onChangeHandler}
              onBlur={this._blurHandler}
              onTouchCancel={this._blurHandler}
              disabled={c.disabled}
            />
          )
        })
      }

      let multipleChoice = choices.map((c, i) => {
        return (
          <div
            key={`check--${c.name}-${i}`}
            className={`choose ${c.error ? 'is-error' : ''} ${
              c.disabled ? 'is-disabled' : ''
            }`}
          >
            <input
              type="checkbox"
              className="choose-input"
              value={c.id || c.value}
              id={c.id}
              name={c.name || id}
              onChange={this._onChangeHandler}
              onBlur={this._blurHandler}
              onTouchCancel={this._blurHandler}
              checked={selected.includes(c.id || c.value)}
              disabled={c.disabled}
            />
            <label htmlFor={c.id} className="choose-label">
              <div dangerouslySetInnerHTML={{ __html: c.label || c.value }} />
            </label>
          </div>
        )
      })

      if (chooseAll) {
        const chooseAllActive =
          selected.length === choices.length ? 'active' : ''
        const chooseAllBtn = (
          <div key={`check--chooseall-0`} className={`choose`}>
            <button
              className={`choose-label ${chooseAllActive}`}
              onClick={this._chooseAllHandler}
              type="button"
            >
              Select All
            </button>
          </div>
        )
        multipleChoice.unshift(chooseAllBtn)
      }

      return multipleChoice
    }
    return null
  }

  _renderNotes = () => {
    const { id, selected, notes } = this.props
    let filteredNotes = notes.filter((note, i) => {
      let _values = JSON.stringify(note.values.sort())
      let _selected = JSON.stringify([...selected].sort())
      return _values === _selected
    })
    if (filteredNotes && filteredNotes.length > 0) {
      return (
        <div className="notes">
          {filteredNotes[0].notes.map((note, i) => {
            return <div key={`${id}-choose--note--${i}`}>{note}</div>
          })}
        </div>
      )
    }
    return null
  }
  _onChangeHandler = e => {
    const { setFieldValue, multiple, selected, handleChange } = this.props
    const { value, name, checked } = e.target
    let fieldValue = value
    if (multiple) {
      fieldValue = [...selected]
      if (checked) {
        fieldValue.push(value)
      } else {
        fieldValue.splice(fieldValue.indexOf(value), 1)
      }
    }
    if (setFieldValue) {
      setFieldValue(name, fieldValue)
    }
    if (handleChange) {
      handleChange(e)
    }
  }

  _blurHandler = e => {
    const { handleBlur, error } = this.props

    if (handleBlur) {
      handleBlur(e)
    }
    if (error) {
      this.setState(prevState => ({
        ...prevState,
        error,
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        error: null,
      }))
    }
  }

  _chooseAllHandler = () => {
    const { setFieldValue, id, choices, selected } = this.props
    if (selected.length === choices.length) {
      setFieldValue(id, [])
    } else {
      const all = choices.map(choice => choice.id || choice.value)
      setFieldValue(id, all)
    }
  }
}

FormChoose.defaultProps = {
  id: '',
  title: '',
  choices: [],
  multiple: false,
  selected: [],
  notes: [],
  onlyElem: false,
}

FormChoose.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.object),
  multiple: PropTypes.bool,
  selected: PropTypes.array,
  notes: PropTypes.array,
  onlyElem: PropTypes.bool,
}

export default FormChoose
