import React, { useEffect, useContext, useState } from 'react'
import { useDyipne } from 'spd-gds/Dyipne'
import { Co2eActions } from 'spd-gds/actions'
import Helpers from 'spd-gds/helpers'
import { ResultsPage, LoadingPage } from 'spd-gds/components/pages'
import { UserSessionTokenContext } from 'spd-gds/components/MobileBridge'

const ResultsLatest = () => {
  const { co2e } = useDyipne()
  const [co2eState, dispatcher] = co2e

  const [showResult, setShowResult] = useState(false)
  const [isFetchResult, setIsFetchResult] = useState(false)
  const userSessionToken = useContext(UserSessionTokenContext)
  useEffect(() => {
    if (Helpers.isInfinity() && userSessionToken && !isFetchResult) {
      dispatcher(Co2eActions.FETCH_RESULT())
      setIsFetchResult(true)
    }
    setShowResult(!!co2eState.data.total.value)
  }, [dispatcher, isFetchResult, userSessionToken, co2eState.data.total.value])

  return (
    showResult && !co2eState.errors
      ? <ResultsPage />
      : <LoadingPage />
  )
}

export default ResultsLatest