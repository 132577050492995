import Chart, { ChartBreakdown } from './Chart'
import ChartDetail from './ChartDetail'
import './styles.scss'

export {
  ChartDetail,
  ChartBreakdown
}

export default Chart
