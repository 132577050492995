import React from 'react'
import classnames from 'classnames'
import "./styles.scss"

const SelectionBox = ({ data, latest, className, title, handleChange }) => {
  const _Selection = classnames('selection-box', className)
  return (
    <div className={_Selection}>
      <span>{title}</span>
      <div className='selectionBx'>
        <select className='selection-box__select' onChange={(e) => handleChange(e.target.value)}>
          {
            data.map((el, id) => {
              return (
                <option key={id} value={el.value} disabled={el.disabled}>{el.label}</option>
              )
            })
          }
        </select>
      </div>
    </div>
  )
}

export default SelectionBox
