import React, { useEffect, useState } from 'react'
import Visiblity from 'react-visibility-sensor'

import { useDyipne } from 'spd-gds/Dyipne'
import { Co2eActions } from 'spd-gds/actions'

import { Page } from 'spd-gds/components/pages'
import { Blade, Article, HeroLanding } from 'spd-gds/components'
import { GlobeCC } from 'spd-gds/components/gfx'
import {
  Temperature,
  SeaLevels,
  WeatherPatterns,
} from 'spd-gds/components/gfx/carbon_effects'

const LandingPage = () => {
  const { co2e } = useDyipne()
  const [, dispatcher] = co2e

  const [scrolledBlade, setScrolledBlade] = useState({
    blade1: false,
    blade2: false,
    blade3: false,
  })

  useEffect(() => {
    document.body.classList.add('__landing')
    dispatcher(Co2eActions.RESET())
    return () => {
      document.body.classList.remove('__landing')
    }
  }, [])

  const handleBladeVisiblity = blade => isVisible => {
    if (isVisible) {
      setScrolledBlade(prevState => ({
        ...prevState,
        [blade]: isVisible,
      }))
    }
  }

  return (
    <Page className="page-landing" type="landing">
      <Blade id="co2-landing-heroblade" withContainer={false}>
        <HeroLanding animate />
      </Blade>

      <Blade id="co2-landing-blade2">
        <Visiblity
          onChange={handleBladeVisiblity('blade2')}
          scrollCheck
          scrollThrottle={100}
          partialVisibility
        >
          <Article
            id="what-is-climate-change"
            className={`animate ${scrolledBlade.blade2 ? 'appear' : ''}`}
            isCrosswise
          >
            <Article.Content>
              <Article.Header title="What is Climate change?" />
              <p>
                Climate change refers to big changes in global weather patterns
                that continued over a long period of time.
              </p>
              <p>
                Over the past 100 years, global temperatures have been
                increasing faster than ever before. Increased human activities
                have led to higher amounts of carbon dioxide in the air. Carbon
                dioxide traps heat and makes the Earth warmer. Rain patterns are
                changing, sea levels are rising. Year after year, snow and ice
                are melting at a faster rate. Climate conditions will be
                intensified if we do not do something about it.
              </p>
            </Article.Content>
            <Article.Hero>
              <GlobeCC />
            </Article.Hero>
          </Article>
        </Visiblity>
      </Blade>

      <Blade
        id="co2-landing-blade3"
        title="How does my carbon footprint affect Singapore?"
      >
        <Visiblity
          onChange={handleBladeVisiblity('blade3')}
          scrollCheck
          scrollThrottle={100}
          partialVisibility
        >
          <div
            className={`featured-list animate animate-stagger ${
              scrolledBlade.blade3 ? 'appear' : ''
            }`}
          >
            <Article id="co2-effect-increase-temp" align="center">
              <Article.Hero>
                <Temperature />
              </Article.Hero>
              <Article.Header
                titleVariant="medium"
                title="Rising Temperatures"
              />
              <Article.Content>
                The average daily temperatures could reach a high of 35 to 37
                degrees Celsius in Singapore by 2100.
              </Article.Content>
            </Article>

            <Article id="co2-effect-rising-sea-levels" align="center">
              <Article.Hero>
                <SeaLevels />
              </Article.Hero>
              <Article.Header titleVariant="medium" title="Rising Sea Levels" />
              <Article.Content>
                As a low-lying island, Singapore’s sea level is estimated to
                rise by half to one metre by 2100.
              </Article.Content>
            </Article>

            <Article id="co2-effect-extreme-weather" align="center">
              <Article.Hero>
                <WeatherPatterns />
              </Article.Hero>
              <Article.Header
                titleVariant="medium"
                title="Extreme Weather Patterns"
              />
              <Article.Content>
                Singapore will likely experience frequent dry spells during
                drier months and flash floods due to more intense and frequent
                heavy rainfall during the wet months.
              </Article.Content>
            </Article>
          </div>
        </Visiblity>
      </Blade>
    </Page>
  )
}

export default LandingPage
