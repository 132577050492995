import React, { Component } from 'react'

export default class TermsAndConditions extends Component {
  render() {
    const { children } = this.props

    return (
      <React.Fragment>
        <div className="container tnc-container">
          <h2>SAMPLE TERMS & CONDITIONS</h2>
          <ol>
            <li data-title='Definitions'>
              Sample content for definitions
              <ol>
                <li><b>“Agreement”</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </li>
                <li><b>“Customer”</b> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </li>
              </ol>
            </li>
            <li data-title='Term Item 2'>
              Sample term item 2 content
            </li>
            <li data-title='Term Item 3'>
              Sample term item 3 content:
              <ol>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </li>
              </ol>
            </li>
          </ol>
          { children }
        </div>
      </React.Fragment>
    )
  }
}
