import React, { PureComponent } from 'react'

import FormCheckBox from '../form/FormCheckBox'

class TermsControl extends PureComponent {
  render() {
    const {
      id,
      name,
      value,
      byClickMessage,
      handleChange,
      items,
      withCheckbox,
      children
    } = this.props
    return (
      <div className='terms-node'>
        {
          withCheckbox && (
            <div className='terms-control'>
              <FormCheckBox
                onlyElem
                id={id}
                name={name}
                handleChange={handleChange}
                checked={value}
              />
            </div>
          )
        }
        <div className='terms-text'>
          <span className='by-clicking'>
            {byClickMessage}
          </span>
          <ol>
            {
              items.map((item, key) => (
                <li key={`termitem--${key}`}>
                  {item}
                </li>
              ))
            }
          </ol>
          {children}
        </div>
      </div>
    )
  }
}

TermsControl.defaultProps = {
  byClickMessage: 'By clicking Submit, I:',
  id: '',
  name: '',
  value: '',
  handleChange: () => { },
  items: [],
  withCheckbox: true
}

export default TermsControl
