import React from 'react'
import classnames from 'classnames'

import Card from './Card'

const CardSelection = ({ items, refToContainer, ...props }) => {
  const _cardselection = classnames('app-cardselection', props.className)
  return (
    <div className={_cardselection}
      ref={refToContainer}
    >
      {
        items.length > 0 && items.map((item, key) => {
          return (
            <Card
              key={`card-item--${key}`}
              id={item.id}
              className='app-cardselection__item'
              title={item.title}
              subtitle={item.subtitle}
              image={item.image}
              toHandler={item.to}
              toParams={item.toParams}
              external={item.external}
            >
              {item.content}
            </Card>
          )
        })
      }
    </div>
  )
}

CardSelection.defaultProps = {
  items: []
}

export default CardSelection
