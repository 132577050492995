import React, {useContext} from 'react'
import Helpers from 'spd-gds/helpers'
import { LoadingPage } from 'spd-gds/components/pages'
import { UserSessionTokenContext } from 'spd-gds/components/MobileBridge'
import { Redirect } from "react-router-dom";

const StartQuestionnaire = () => {
  const params = window.location.search ?? ''
  const userSessionToken = useContext(UserSessionTokenContext)

  const mobileAuthDecelerator = userSessionToken ? <Redirect to={`/questionnaire/home${params}`} /> : <LoadingPage />
  return (
    Helpers.isInfinity()
      ? mobileAuthDecelerator
      : <Redirect to={`/questionnaire/utility${params}`} /> 
  )
}

export default StartQuestionnaire