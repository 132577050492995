import React, { useEffect, useCallback, useRef } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import { useDyipne } from 'spd-gds/Dyipne'

import Co2eConstants from 'spd-gds/constants/co2e.constants'

import { Form, FormCTA } from 'spd-gds/components/form'
import Button from 'spd-gds/components/common/Button'

import Helpers from 'spd-gds/helpers'
import FunFacts from './FunFacts'

const Food = ({ next, prev }) => {

  const questionnaire = Helpers.GetQuestionnaire(Co2eConstants.questionnaire.FOOD + (Helpers.isInfinity() ? '_MOBILE' : ''))
  const validations = questionnaire?.items.reduce(Helpers.createSchema, {})
  const ValidationSchema = yup.object().shape(validations)
  const questionnaireData = Helpers.GetQuestionnaireData(questionnaire?.items)

  const { co2e } = useDyipne()
  const [co2eState] = co2e

  const form = useRef(null)

  const formContinue = useCallback(() => {
    if (!co2eState.fetching_qnnre && !co2eState.calculating && !co2eState.submitting) {
      if (!co2eState.errors) {
        if (form.current) {
          form.current.setSubmitting(false)
        }
      }
    }
  }, [co2eState])

  useEffect(() => {
    formContinue()
  }, [formContinue])

  function handleNext(values) {
    next(values)
  }

  function handlePrev() {
    prev()
  }

  function renderQuestionnaire(formProps) {
    if (!questionnaire) {
      return null
    }
    const fields = Helpers.getFormFields(questionnaire.items, formProps)
    return fields
  }

  return (
    <div className='app-qnnre__category app-qnnre__food'>
      <div className='app-qnnre__categoryContainer'>
        {
          questionnaire && (
            <>
              <h3 className='app-qnnre__title'>{questionnaire.name} </h3>
              <small className='app-qnnre__desc' dangerouslySetInnerHTML={{ __html: questionnaire.description }} />
              {
                questionnaire.question && (
                  <small className='app-qnnre__desc' dangerouslySetInnerHTML={{ __html: questionnaire.question }} />
                )
              }
              <Formik
                innerRef={form}
                enableReinitialize
                validateOnMount
                validationSchema={ValidationSchema}
                initialValues={questionnaireData}
                onSubmit={handleNext}
              >
                {
                  (formProps) => {
                    return (
                      <Form
                        onSubmit={formProps.handleSubmit}
                      >
                        {renderQuestionnaire(formProps)}
                        <FormCTA>
                          <Button className="cta-button" variant="outlined" onClickHandler={handlePrev} disabled={formProps.isSubmitting}>
                            Previous
                          </Button>
                          <Button
                            className="cta-button"
                            type="submit"
                            disabled={Helpers.isSubmitDisabled(formProps.errors, formProps.isValid, formProps.isSubmitting)}
                          >
                            Next
                          </Button>
                        </FormCTA>
                      </Form>
                    )
                  }
                }
              </Formik>
              <FunFacts type="FOOD" />
            </>
          )
        }
      </div>
    </div>
  )
}

export default Food
