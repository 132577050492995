import React from 'react'

const CO2Cloud = ({ children, onlyMain }) => {
  return (
    <div className='co2-cloud_amount'>
      {
        !onlyMain && (
          <figure className='co2-gfx_cloud co2-gfx_cloud_2'>
            <svg width="136" height="67" viewBox="0 0 136 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M19.5165 32.4186C19.3793 32.4186 19.2386 32.4155 19.1014 32.4155C8.55167 32.4155 0 40.1586 0 49.7077C0 59.26 8.55167 67 19.1014 67C20.1954 67 21.2754 66.9172 22.3202 66.7547H117.757C124.81 66.7547 130.892 62.8242 133.478 57.2629C135.085 54.6288 136 51.5997 136 48.3795C136 40.4261 130.417 33.6513 122.611 31.0936C122.988 29.415 123.185 27.6759 123.185 25.8954C123.185 11.594 110.38 0 94.5854 0C85.4111 0 77.2499 3.91139 72.0155 9.99187C68.0932 7.3864 63.2528 5.84797 58.0148 5.84797C47.444 5.84797 38.4913 12.1132 35.4308 20.7673C34.8047 20.6877 34.1609 20.6463 33.5102 20.6463C26.1053 20.6463 20.0407 25.8381 19.5165 32.4186Z" fill="#7C8084" fillOpacity="0.3"/>
            </svg>
          </figure>
        )
      }
      <figure className='co2-gfx_cloud co2-gfx_cloud_1'>
        <svg width="300" height="125" viewBox="0 0 300 125" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M235.17 26.127C224.151 10.4153 204.852 0 182.887 0C166.017 0 150.718 6.1459 139.541 16.1115C134.756 14.7679 129.672 14.0406 124.406 14.0406C116.224 14.0406 108.487 15.795 101.625 18.8985C91.0873 11.992 78.1627 7.91694 64.1909 7.91694C28.7349 7.91694 0 34.1272 0 66.4557C0 98.7897 28.7349 125 64.1909 125C65.5911 125 66.9853 124.95 68.3611 124.872C69.5666 124.956 70.7902 125 72.0261 125H251.242C278.059 125 300 104.986 300 80.5241C300 57.9669 281.341 39.1961 257.403 36.4146C251.138 31.4513 243.541 27.8592 235.17 26.127Z" fill="url(#co2cloud-paint0_linear)"/>
          <defs>
            <linearGradient id="co2cloud-paint0_linear" x1="150.435" y1="125" x2="150.435" y2="11.2847" gradientUnits="userSpaceOnUse">
              <stop stopColor="#CED5DC"/>
              <stop offset="1" stopColor="#E9F0F7"/>
            </linearGradient>
          </defs>
        </svg>
      </figure>
      {
        children && (
          <div className='co2-gfx_cloud_content'>
            {children}
          </div>
        )
      }
    </div>
  )
}

CO2Cloud.defaultProps = {
  onlyMain: false
}

export default CO2Cloud
