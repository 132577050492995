import React from 'react'
import classnames from 'classnames'

const GlobeCC = ({ animate }) => {
  const _GlobeCC = classnames('gfx-globecc', {
    animate,
  })
  return (
    <svg className={_GlobeCC} width="418" height="380" viewBox="0 0 418 380" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle className="gfx-globecc-radiance rad-2" cx="223" cy="190" r="190" fill="url(#gfx-globecc-paint0_radial)"/>
      <circle className="gfx-globecc-radiance rad-1" cx="223" cy="190" r="161" fill="url(#gfx-globecc-paint1_linear)"/>
      <g className="gfx-globecc-earth" clipPath="url(#gfx-globecc-clip0)">
        <path d="M222.229 328.066C147.049 328.066 86.0546 267.071 86.0546 191.891C86.0546 116.712 147.049 55.717 222.229 55.717V328.066Z" fill="url(#gfx-globecc-paint2_linear)"/>
        <path d="M222.229 328.066C297.409 328.066 358.403 267.071 358.403 191.891C358.403 116.712 297.518 55.717 222.229 55.717V328.066Z" fill="url(#gfx-globecc-paint3_linear)"/>
        <path d="M125.232 197.78C132.98 183.147 147.603 193.011 162.336 181.846C177.832 170.14 178.268 193.336 189.29 199.406C198.894 204.608 207.078 192.577 214.499 202.332C219.737 209.269 215.481 218.808 203.04 225.528C192.455 231.164 184.27 241.57 187.108 251.433C193.219 273.003 208.388 290.779 200.531 293.923C182.743 301.185 164.518 272.461 155.024 251.758C146.73 233.549 139.31 238.752 128.833 225.961C118.357 213.171 125.232 197.78 125.232 197.78Z" fill="#86995E"/>
        <path d="M119.776 195.612C127.524 180.979 142.147 190.843 156.879 179.679C172.375 167.972 172.812 191.168 183.834 197.238C193.437 202.441 201.622 190.409 209.043 200.164C214.281 207.101 210.025 216.64 197.584 223.36C186.999 228.996 178.814 239.402 181.651 249.265C187.763 270.835 202.931 288.611 195.074 291.755C177.286 299.017 159.062 270.293 149.568 249.591C141.274 231.381 133.853 236.584 123.377 223.794C112.901 211.004 119.776 195.612 119.776 195.612Z" fill="url(#gfx-globecc-paint4_linear)"/>
        <path d="M355.602 192.794C355.602 189.109 355.493 185.423 355.165 181.738C349.381 142.717 326.901 109.116 295.145 88.305C287.615 93.6161 283.359 107.057 291.762 116.595C295.472 120.822 302.347 128.626 296.018 130.469C291.434 131.77 285.541 123.315 264.589 127.651C237.852 133.179 254.658 144.343 241.999 160.818C236.87 167.539 246.583 182.172 258.15 184.339C273.646 187.374 278.666 181.955 290.452 183.906C304.857 186.182 302.565 195.937 303.438 216.206C304.093 232.248 315.006 233.549 310.532 259.237C307.367 277.556 295.472 281.241 302.02 288.503C304.093 290.779 307.804 291.863 312.496 291.43C339.123 266.825 355.602 231.706 355.602 192.794Z" fill="#7C8084"/>
        <path d="M363.132 192.794C363.132 225.419 351.564 255.335 332.139 278.639C321.335 284.167 312.278 284.059 308.567 279.832C302.02 272.461 313.915 268.884 317.079 250.566C321.554 224.878 310.641 223.577 309.986 207.535C309.113 187.266 311.405 177.619 297 175.234C285.323 173.392 280.303 178.703 264.698 175.668C253.13 173.392 243.418 158.867 248.547 152.147C261.206 135.563 244.4 124.399 271.136 118.98C292.089 114.644 297.982 123.098 302.565 121.798C308.895 120.064 302.02 112.259 298.309 107.924C289.907 98.3854 294.163 85.0533 301.692 79.6337C338.796 103.913 363.132 145.535 363.132 192.794Z" fill="#E4EBF2"/>
        <path d="M219.41 57.522C219.301 57.522 219.082 57.522 218.973 57.522V79.7422C223.557 83.1023 221.047 89.2806 232.287 90.6897C251.057 93.0742 264.152 77.1408 256.295 62.508C244.618 59.2563 232.178 57.522 219.41 57.522Z" fill="#7C8084"/>
        <path d="M219.41 50.043C219.301 50.043 219.082 50.043 218.973 50.043V72.2632C223.557 75.6233 221.047 81.8016 232.287 83.2106C251.057 85.5952 264.152 69.6618 256.295 55.029C244.618 51.7773 232.178 50.043 219.41 50.043Z" fill="#E4EBF2"/>
        <path d="M219.41 57.522C166.264 57.522 120.212 87.7631 97.7317 131.878C92.2753 144.235 100.133 168.839 122.395 169.707C135.49 168.298 116.611 155.833 118.684 144.452C120.758 132.962 135.272 146.511 162.336 142.175C180.669 139.14 194.638 130.903 199.876 120.497C205.878 108.466 187.435 112.368 177.941 108.249C168.556 104.13 181.106 79.5254 205.114 77.7911C213.408 77.2492 217.227 78.3331 219.301 80.1757V57.522H219.41Z" fill="#86995E"/>
        <path d="M219.41 50.043C166.264 50.043 120.212 80.2841 97.8408 124.399C92.3844 136.756 100.242 161.36 122.504 162.228C135.599 160.819 116.72 148.354 118.793 136.973C120.867 125.483 135.381 139.032 162.445 134.696C180.778 131.661 194.747 123.424 199.985 113.018C205.987 100.987 187.544 104.889 178.05 100.77C168.665 96.6511 181.215 72.0464 205.223 70.3121C213.517 69.7702 217.336 70.8541 219.41 72.6967V50.043Z" fill="url(#gfx-globecc-paint5_linear)"/>
      </g>
      <g className='gfx-globecc-cloud cloudco-1'>
        <path fillRule="evenodd" clipRule="evenodd" d="M34.2553 54.7633C40.0776 46.4866 50.2747 41 61.8808 41C70.7944 41 78.8781 44.2376 84.7841 49.4873C87.3125 48.7795 89.9985 48.3964 92.781 48.3964C97.1043 48.3964 101.193 49.3205 104.818 50.9554C110.386 47.3172 117.215 45.1705 124.598 45.1705C143.332 45.1705 158.515 58.9777 158.515 76.0077C158.515 93.0407 143.332 106.848 124.598 106.848C123.858 106.848 123.121 106.822 122.394 106.781C121.758 106.824 121.111 106.848 120.458 106.848H25.763C11.5932 106.848 0 96.3046 0 83.4187C0 71.536 9.85937 61.6478 22.5077 60.1826C25.8177 57.568 29.8322 55.6758 34.2553 54.7633Z" fill="#CED5DC"/>
        <path d="M65.1352 87.39C68.1952 87.39 70.2952 86.34 71.5552 85.41L70.3552 81.72C69.3652 82.41 67.4152 83.43 65.1352 83.43C61.3252 83.43 58.5952 80.52 58.5952 76.5C58.5952 72.54 61.3252 69.57 65.1352 69.57C67.2652 69.57 69.1552 70.53 70.1752 71.25L71.3752 67.53C70.0252 66.54 67.9252 65.61 65.1352 65.61C58.8052 65.61 54.1852 70.32 54.1852 76.5C54.1852 82.68 58.5652 87.39 65.1352 87.39ZM84.236 87.39C90.686 87.39 95.156 82.68 95.156 76.5C95.156 70.38 90.686 65.61 84.236 65.61C77.786 65.61 73.316 70.38 73.316 76.5C73.316 82.68 77.786 87.39 84.236 87.39ZM84.236 69.57C88.016 69.57 90.746 72.54 90.746 76.5C90.746 80.52 88.016 83.43 84.236 83.43C80.456 83.43 77.726 80.52 77.726 76.5C77.726 72.54 80.456 69.57 84.236 69.57ZM96.7306 87H104.107V85.032H99.4026C99.8026 83.4 103.979 81.96 103.979 78.952C103.979 76.904 102.491 75.592 100.331 75.592C97.8986 75.592 96.9386 77.288 96.6826 78.408L98.6506 78.984C98.8586 78.28 99.3226 77.672 100.235 77.672C101.131 77.672 101.675 78.216 101.675 79.048C101.675 81.112 96.7306 83 96.7306 86.2V87Z" fill="#818A91"/>
      </g>
      <g className='gfx-globecc-cloud cloudco-2'>
        <path fillRule="evenodd" clipRule="evenodd" d="M293.255 214.763C299.078 206.487 309.275 201 320.881 201C329.794 201 337.878 204.238 343.784 209.487C346.312 208.779 348.998 208.396 351.781 208.396C356.104 208.396 360.193 209.321 363.818 210.955C369.386 207.317 376.215 205.171 383.598 205.171C402.332 205.171 417.515 218.978 417.515 236.008C417.515 253.041 402.332 266.848 383.598 266.848C382.858 266.848 382.121 266.822 381.394 266.781C380.758 266.824 380.111 266.848 379.458 266.848H284.763C270.593 266.848 259 256.305 259 243.419C259 231.536 268.859 221.648 281.508 220.183C284.818 217.568 288.832 215.676 293.255 214.763Z" fill="#C0C6CC"/>
        <path d="M324.135 246.39C327.195 246.39 329.295 245.34 330.555 244.41L329.355 240.72C328.365 241.41 326.415 242.43 324.135 242.43C320.325 242.43 317.595 239.52 317.595 235.5C317.595 231.54 320.325 228.57 324.135 228.57C326.265 228.57 328.155 229.53 329.175 230.25L330.375 226.53C329.025 225.54 326.925 224.61 324.135 224.61C317.805 224.61 313.185 229.32 313.185 235.5C313.185 241.68 317.565 246.39 324.135 246.39ZM343.236 246.39C349.686 246.39 354.156 241.68 354.156 235.5C354.156 229.38 349.686 224.61 343.236 224.61C336.786 224.61 332.316 229.38 332.316 235.5C332.316 241.68 336.786 246.39 343.236 246.39ZM343.236 228.57C347.016 228.57 349.746 231.54 349.746 235.5C349.746 239.52 347.016 242.43 343.236 242.43C339.456 242.43 336.726 239.52 336.726 235.5C336.726 231.54 339.456 228.57 343.236 228.57ZM355.731 246H363.107V244.032H358.403C358.803 242.4 362.979 240.96 362.979 237.952C362.979 235.904 361.491 234.592 359.331 234.592C356.899 234.592 355.939 236.288 355.683 237.408L357.651 237.984C357.859 237.28 358.323 236.672 359.235 236.672C360.131 236.672 360.675 237.216 360.675 238.048C360.675 240.112 355.731 242 355.731 245.2V246Z" fill="#818A91"/>
      </g>
      <g className='gfx-globecc-flame'>
        <path d="M368.857 159.649C368.857 159.649 382.859 148.687 376.693 121.147C373.902 108.746 370.006 95.8961 376.722 78.7598C376.722 78.7598 354.609 94.6914 345.368 111.519C340.253 120.915 338.544 138.858 338.544 138.858C338.544 138.858 351.371 110.426 329.938 93.6624C329.938 93.6624 334.403 110.993 324.965 115.784C300.923 127.992 304.079 145.95 310.939 157.884C319.075 171.991 348.523 181.557 368.857 159.649Z" fill="url(#gfx-globecc-paint6_linear)"/>
      </g>
      <defs>
        <radialGradient id="gfx-globecc-paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(223 190) rotate(90) scale(268)">
          <stop stopColor="#FFDEB3"/>
          <stop offset="0.255208" stopColor="#FFDEB3" stopOpacity="0.744792"/>
          <stop offset="1" stopColor="#FFDEB3" stopOpacity="0"/>
        </radialGradient>
        <linearGradient id="gfx-globecc-paint1_linear" x1="223" y1="368" x2="223" y2="-0.500005" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC466"/>
          <stop offset="1" stopColor="#FFC466" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="gfx-globecc-paint2_linear" x1="124.694" y1="102.47" x2="280.341" y2="327.543" gradientUnits="userSpaceOnUse">
          <stop stopColor="#65A1DB"/>
          <stop offset="1" stopColor="#3176DE"/>
        </linearGradient>
        <linearGradient id="gfx-globecc-paint3_linear" x1="290.344" y1="55.7064" x2="290.344" y2="340.206" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CED5DC"/>
          <stop offset="1" stopColor="#7C8084"/>
        </linearGradient>
        <linearGradient id="gfx-globecc-paint4_linear" x1="144.477" y1="121.35" x2="192.508" y2="333.05" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4EDA7"/>
          <stop offset="1" stopColor="#86995E"/>
        </linearGradient>
        <linearGradient id="gfx-globecc-paint5_linear" x1="160.158" y1="103.674" x2="214.757" y2="344.321" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D4EDA7"/>
          <stop offset="1" stopColor="#86995E"/>
        </linearGradient>
        <linearGradient id="gfx-globecc-paint6_linear" x1="332.291" y1="163.048" x2="359.906" y2="97.2211" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F65B7D"/>
          <stop offset="0.9804" stopColor="#FFC466"/>
        </linearGradient>
        <clipPath id="gfx-globecc-clip0">
          <rect width="279.914" height="278.023" fill="white" transform="translate(83.2177 50.043)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

GlobeCC.defaultProps = {
  animate: true
}

export default GlobeCC
