import React from 'react'
import { Route, Redirect } from 'react-router'

const ProtectedRoute = ({
  component: Component,
  path,
  protection,
  ...rest
}) => {
  
  const renderRoute = (props) => {
    const authenticated = window.sessionStorage.getItem('PROJECT_NAME:auth')
    const isCustomerAuthenticated = window.sessionStorage.getItem('PROJECT_NAME:cust_auth')
    if (protection.length > 1) {
      // assuming both admin and otp protections are available

      if (!authenticated) {
        return <Redirect to={'/'} />
      }

      if (!isCustomerAuthenticated) {
        return <Redirect to={'/menu'} />
      }
    
    } else {
      // only one protection is available
      if (protection[0] === 'admin') {
        if (authenticated === "false") {
          return <Redirect to={'/'} />
        }
      }
      if (protection[0] === 'otp') {
        if (!isCustomerAuthenticated) {
          return <Redirect to={'/menu'} />
        }
      }
    }
    
    return <Component {...props} context={{}} />
  }

  return (
    <Route 
      path={path}
      render={renderRoute}
      {...rest}
    />
  )
}

ProtectedRoute.defaultProps = {
  protection: ['admin']
}

export default ProtectedRoute
