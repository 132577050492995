import React from 'react'
import classnames from 'classnames'

const ArticleDescription = ({ children }) => {
  const _ArticleDescription = classnames('app-article__description')
  return (
    <div className={_ArticleDescription}>
      {children}
    </div>
  )
}

export default ArticleDescription
