import React, { useState, useEffect } from 'react'
import { withRouter, Switch, Route, Redirect } from 'react-router'
import classnames from 'classnames'

import Utils from 'spd-gds/utils'

import spgroupLogo from 'spd-gds/images/spgroup-logo.svg'
import spgroupLogoWhite from 'spd-gds/images/spgroup-logo-white.svg'

import {
  LandingPage,
  QuestionnairePage,
  ResultsPage,
  StartQuestionnairePage,
  ResultsLatestPage,
  MobileMockLoginPage,
  MaintenancePage,
} from 'spd-gds/components/pages'
import { useScreenSize } from 'spd-gds/components/ScreenSize'
import { Cloud1, Cloud2, Cloud3 } from 'spd-gds/components/gfx'
import { ConfirmationDialog, Icons, Button } from 'spd-gds/components/common'
import Constants from 'spd-gds/constants'
import Helpers from 'spd-gds/helpers'
import MaintenanceBanner from './components/common/MaintenanceBanner'

function App({ location, history }) {
  const screenSize = useScreenSize()
  const [isOnline, setIsOnline] = useState(true)
  const [isIELegacy, setIsIELegacy] = useState(true)
  const [showLeavingConfirm, setShowLeavingConfirm] = useState(false)

  const type = 'generic' // TODO: put this type onto context

  const _header = classnames('app-header', {
    'type-profile': type === 'profile',
    'type-generic': type === 'generic',
    'type-frame': type === 'frame',
    'type-landing': type === 'landing',
  })

  const processViewportHeight = () => {
    let vhValue = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vhValue', `${vhValue}px`)
  }

  const updateConnectivity = () => {
    if (window.navigator) {
      setIsOnline(window.navigator.onLine)
    }
  }

  const detectConnectivity = () => {
    window.addEventListener('online', updateConnectivity)
    window.addEventListener('offline', updateConnectivity)
  }

  const removeDetectConnectivity = () => {
    window.removeEventListener('online', updateConnectivity)
    window.removeEventListener('offline', updateConnectivity)
  }

  const handleShowLeave = () => {
    setShowLeavingConfirm(true)
  }

  const handleContinueLeave = () => {
    handleCloseLeave()
    history.push('/' + window.location.search)
  }

  const handleCloseLeave = () => {
    setShowLeavingConfirm(false)
  }

  function handleBack2Home() {
    window.location = 'https://www.spgroup.com.sg'
  }

  useEffect(() => {
    const ieVersion = Utils.detectIE()
    if (ieVersion) {
      if (ieVersion < 12) {
        if (document.body) {
          document.body.classList.add('ie-legacy')
          setIsIELegacy(true)
        }
      } else {
        if (document.body) {
          document.body.classList.add('ie-edge')
          setIsIELegacy(false)
        }
      }
    } else {
      if (document.body) {
        document.body.classList.add('non-ie')
        setIsIELegacy(false)
      }
    }
    processViewportHeight()
    detectConnectivity()
    return () => {
      removeDetectConnectivity()
    }
  }, [])

  useEffect(() => {
    processViewportHeight()
  }, [screenSize?.width])

  useEffect(() => {
    if (location.pathname === '/') {
      window.scrollTo(0, 0)
    }
  }, [location])

  function renderCopyright() {
    const getYear = () => new Date().getFullYear()
    return <>&copy; {`${getYear()} SP Group. All Rights Reserved.`}</>
  }

  return (
    <>
      <MaintenanceBanner />
      <div className="App">
        {Constants.ENV !== 'prod' && (
          <span className={`env-indicator ${Constants.ENV}`}></span>
        )}

        <header
          className={_header}
          style={{ display: Helpers.isInfinity() ? 'none' : 'inherit' }}
        >
          <figure className="logo" onClick={handleBack2Home}>
            <img
              src={
                type === 'generic' || type === 'landing'
                  ? spgroupLogo
                  : spgroupLogoWhite
              }
              alt="SP Group Ltd"
            />
          </figure>
        </header>

        {!isIELegacy && (
          <main className="app-main">
            {Constants.ENABLE_MAINTENANCE.PAGE ? (
              <Switch>
                <Route exact path="/" component={MaintenancePage} />
                <Redirect from="*" to="/" />
              </Switch>
            ) : (
              <Switch>
                <Route exact path="/" component={LandingPage} />
                {/* important integration url endpoint with apps for /questionnaire/start */}
                <Route
                  path="/questionnaire/start"
                  component={StartQuestionnairePage}
                />
                <Route path="/questionnaire" component={QuestionnairePage} />
                <Route path="/results/latest" component={ResultsLatestPage} />
                <Route path="/results" component={ResultsPage} />

                {process.env.REACT_APP_ENV !== 'prod' && (
                  <Route path="/_/mobile" component={MobileMockLoginPage} />
                )}

                <Route
                  path="/terms-of-use"
                  component={() => {
                    window.location.href =
                      'https://www.spgroup.com.sg/terms-of-use'
                    return null
                  }}
                />
                <Route
                  path="/personal-data-protection-policy"
                  component={() => {
                    window.location.href =
                      'https://www.spgroup.com.sg/personal-data-protection-policy'
                    return null
                  }}
                />
              </Switch>
            )}
          </main>
        )}

        {isIELegacy && (
          <div className="browser-warn">
            <div className="sky">
              <Cloud1 x={900} y={140} />
              <Cloud2 x={50} y={150} />
              <Cloud3 x={1220} y={200} />
              <Cloud2 x={330} y={390} />
            </div>
            <div className="warn-message">
              Hola! Welcome, also we apologise, it seems that you're using an
              outdated browser, the trees are best viewed in modern day
              browsers. You may browse this site using latest version of Chrome,
              Safari, Firefox or Edge. Thank you!
            </div>
            <div className="warn-mascot">
              <img src="/images/co2_tree.png" alt="CO2 logo" />
            </div>
          </div>
        )}

        <footer className="app-footer">
          {(location.pathname === '/results' ||
            location.pathname === '/results/latest') && (
            <Button
              className="app-back2home"
              variant="secondary"
              onClickHandler={handleShowLeave}
            >
              Back to My Carbon Footprint
            </Button>
          )}
          {renderCopyright()}
        </footer>

        <ConfirmationDialog
          visible={!isOnline}
          confirmOkHandler={() => {}}
          confirmCancelHandler={() => {}}
          processing={false}
          // the page will be reload when the connection is restored
          closeBtn={false}
          actionConfig={{
            ok: false,
            cancel: false,
          }}
          content={
            <>
              <figure className="alert-icon">
                <Icons.Fail width={240} />
              </figure>
              <h1>No internet connection.</h1>
              <h4>Please check your network.</h4>
            </>
          }
        />

        <ConfirmationDialog
          visible={showLeavingConfirm}
          confirmOkHandler={handleContinueLeave}
          onCloseHandler={handleCloseLeave}
          processing={false}
          actionConfig={{
            ok: {
              label: 'Leave Page',
            },
            cancel: false,
          }}
          content={
            <div>
              <h3>Results will be lost if you leave this page.</h3>
              <h4>Are you sure you want to continue?</h4>
            </div>
          }
        />

        <div className="responsive-warn">
          <div className="warn-mascot">
            <div className="sky">
              <Cloud1 x={100} y={140} />
              <Cloud2 x={0} y={150} />
              <Cloud3 x={220} y={200} />
              <Cloud2 x={30} y={290} />
            </div>
          </div>
          <div className="warn-mobile">
            Hola! For a better viewing experience, please visit the website on a
            bigger screen.
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(App)
