import React from 'react'
import classnames from 'classnames'

const Frame = ({
  source,
  className,
  frameProps,
  refFrame,
  scrolling,
  ...props
}) => {
  const _Frame = classnames('app-frame', className)
  return (
    <section className={_Frame} {...props}>
      <iframe ref={refFrame} src={source} {...frameProps}></iframe>
    </section>
  )
}

Frame.defaultProps = {
  refFrame: () => {}
}

export default Frame
