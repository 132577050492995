import React from 'react'

const WasteSite = () => {
  return (
    <svg className='gfx-wastesite' width="193" height="65" viewBox="0 0 193 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path id="Oval" fillRule="evenodd" clipRule="evenodd" d="M85.5 65C132.72 65 171 61.1944 171 56.5C171 51.8056 132.72 48 85.5 48C38.2797 48 0 51.8056 0 56.5C0 61.1944 38.2797 65 85.5 65Z" fill="url(#gfx-wastesite-paint0_linear)"/>
        <path id="Vector" d="M159.078 29.4805C162.313 33.616 164.469 42.2331 176.822 48.0993C189.176 53.9655 194.713 55.0221 192.538 55.2408C190.327 55.4229 184.114 55.8055 180.898 56.2974C178.266 57.7548 172.199 59.103 164.341 59.2487C153.816 59.4309 144.13 58.3378 139.617 57.5544C137.442 57.1719 132.928 56.4249 128.196 55.8602L97.203 57.9006L95.1015 60.0867C95.1015 60.0867 89.2904 60.6697 78.9656 58.9572C74.0864 58.1556 69.6642 57.6455 66.3748 57.3358C64.8216 57.7913 63.2866 58.4836 55.8673 58.8661C42.5639 59.5402 35.1813 55.8784 29.1691 55.1315C23.157 54.3845 16.2678 55.095 16.2678 55.095H13.6911C2.5075 57.2447 14.6048 44.1642 16.3409 41.6137C18.0769 39.0632 18.0769 37.9701 21.9692 33.7435C25.8616 29.4987 27.5793 31.4481 29.3153 27.4401C31.0514 23.4321 35.364 21.9929 41.4127 19.4606C47.4613 16.9283 52.5963 15.8535 53.2176 11.4811C53.8389 7.10883 68.1109 10.0784 70.3951 4.61296C72.6976 -0.852437 78.0336 4.26682 83.3148 3.55632C88.5959 2.84582 94.3705 5.41455 97.3491 3.24661C100.328 1.07867 106.852 2.91869 108.533 0.550349C110.214 -1.79977 113.302 4.39435 118.035 4.41256C122.768 4.41256 129.274 11.2807 137.826 13.9588C143.089 15.5984 145.501 18.8776 145.501 18.8776C145.501 18.8776 155.844 25.345 159.078 29.4805Z" fill="#CED5DC"/>
        <path id="Vector_2" d="M81 46.7695C81 46.7695 81.4568 42.0328 88.5837 44.9477C95.7105 47.8625 94.5227 50.8685 98.8171 49.0467C103.111 47.2249 100.553 41.7777 106.309 44.0003C112.066 46.2229 110.238 48.0447 113.71 50.5042C117.182 52.9636 117.091 52.5992 120.015 50.9596C122.939 49.32 131.071 53.2369 131.071 53.2369C131.071 53.2369 125.954 47.2249 123.213 47.7715C120.472 48.318 118.918 52.5081 117.365 48.8645C115.812 45.2209 112.157 41.3952 110.512 42.4882C108.868 43.5813 105.304 42.3061 104.391 40.9397C103.477 39.5734 96.5328 39.8466 96.1674 42.5793C95.8019 45.312 94.0659 43.6724 91.4161 42.215C88.7664 40.7575 82.4619 40.8486 81 46.7695Z" fill="#E4EBF2"/>
        <path id="Vector_3" d="M156.794 35.3284C156.794 35.3284 157.251 39.5368 159.005 41.9415C160.76 44.3463 158.183 43.3079 161.564 45.6216C164.944 47.9353 165.858 47.9899 167.759 49.4838C169.641 50.9594 170.628 52.9634 172.693 53.0363C174.758 53.091 174.94 52.9816 177.024 53.0363C179.088 53.091 178.248 53.0181 179.947 53.0545C181.647 53.091 183.182 51.342 179.947 50.6133C176.713 49.8846 172.382 49.2105 171.139 48.6275C169.897 48.0628 167.21 47.2612 166.242 46.1135C165.273 44.9657 161.856 44.3099 161.582 42.8707C161.308 41.4132 160.595 40.1744 159.864 38.553C159.115 36.9316 156.538 33.7252 156.794 35.3284Z" fill="#E4EBF2"/>
        <path id="Vector_4" d="M66.6352 27.6304C66.6352 27.6304 70.9295 22.7115 77.3071 26.9928C83.6847 31.274 81.2543 32.859 85.841 33.0958C90.4278 33.3326 91.4877 33.3326 96.0744 31.5108C100.661 29.689 98.5962 30.2902 103.183 31.5108C107.77 32.7314 110.2 33.2051 110.2 33.2051C110.2 33.2051 105.613 32.2942 104.042 29.5979C102.47 26.9017 99.1627 25.6811 96.1658 26.173C93.1506 26.6649 89.6237 28.5231 87.3212 26.0819C85.0187 23.6589 75.4249 24.5698 75.4249 24.5698C75.4249 24.5698 69.5955 22.7298 66.4341 25.6629C64.826 27.1567 62.4687 26.9928 62.4687 26.9928C62.4687 26.9928 54.7388 32.1302 66.6352 27.6304Z" fill="#E4EBF2"/>
        <path id="Vector_5" d="M118.638 42.3061C118.711 42.3061 119.205 41.541 120.648 41.4681C122.092 41.3952 122.311 42.2332 123.335 42.3061C124.358 42.379 126.185 42.6523 128.122 42.0875C130.059 41.5045 129.584 40.794 130.955 41.0673C132.325 41.3406 133.897 42.2879 136.601 42.5247C139.306 42.7434 141.81 39.8285 145.921 41.3588C150.033 42.8891 153.87 44.1097 154.875 44.5287C155.88 44.9477 155.058 45.2574 152.335 45.1299C149.613 44.9842 143.436 42.4519 145.866 44.0004C148.297 45.5489 144.295 46.2959 146.415 48.3727C148.534 50.4496 145.665 49.6298 144.697 48.4456C143.728 47.2614 142.723 45.5125 141.353 45.3667C139.982 45.221 140.932 45.4396 137.826 45.9862C134.719 46.5327 135.98 43.1624 132.526 43.6178C129.054 44.0733 125.272 45.4761 123.81 45.64C122.348 45.804 124.395 43.0166 120.429 43.3445C116.464 43.6725 116.939 42.1968 118.638 42.3061Z" fill="#C0C6CC"/>
        <path id="Vector_6" d="M124.943 9.82333C124.413 9.1857 123.536 8.38411 122.896 7.21816C122.64 6.76271 122.037 6.05221 121.343 5.26884C120.137 4.7223 119.004 4.39437 117.962 4.39437C113.229 4.37616 110.141 -1.81796 108.46 0.53216C108.35 0.677904 108.222 0.823648 108.076 0.932956C109.392 1.46128 110.89 2.40861 109.867 3.88427C108.204 6.32548 109.538 6.32548 111.274 6.1433C113.01 5.96112 114.746 4.77695 115.678 6.67162C116.61 8.56629 116.409 9.89621 118.784 11.3354C121.16 12.7746 124.139 11.9548 125.308 12.9386C126.459 13.9224 129.164 15.6895 129.785 15.8717C130.461 16.0539 125.473 10.461 124.943 9.82333Z" fill="#C0C6CC"/>
        <path id="Vector_7" d="M101.333 13.7584C101.388 13.9588 102.192 15.4163 103.708 15.4891C105.225 15.562 104.366 13.977 106.742 14.6329C109.118 15.2887 110.287 15.6531 111.219 15.5074C112.151 15.3616 111.292 14.3596 112.809 14.3596C114.326 14.3596 113.467 14.2503 118.072 14.6693C118.072 14.6693 118.638 14.4143 117.213 13.7949C115.788 13.1572 113.467 11.3172 112.553 12.3556C111.658 13.3941 111.731 14.2685 110.744 13.5763C109.775 12.884 107.235 11.9184 106.998 12.2463C106.742 12.5743 106.523 12.8658 105.773 12.9386C105.024 12.9568 100.62 11.135 101.333 13.7584Z" fill="#E4EBF2"/>
        <path id="Vector_8" d="M72.4129 19.4691C72.4129 19.4691 76.9448 16.536 70.6403 16.5177C64.3541 16.4995 67.4424 19.0501 63.2394 17.7019C59.0364 16.3356 58.6526 15.0239 55.9115 17.0279C53.1704 19.0318 57.4283 20.1978 53.3349 20.0156C49.2415 19.8334 50.009 20.0885 48.2913 22.5661C46.5553 25.062 45.605 29.4525 41 29.6347C41 29.6347 45.6964 31.8391 47.7614 29.1064C49.8263 26.3737 48.6933 22.7847 51.672 22.3111C54.6506 21.8374 57.41 25.8089 57.9582 22.2382C58.4881 18.6493 62.216 18.795 62.5998 19.3962C62.9652 19.9792 65.9073 20.9812 67.5337 20.7261C69.1601 20.4528 72.4129 19.4691 72.4129 19.4691Z" fill="#C0C6CC"/>
        <path id="Vector_9" d="M117.651 23.1589C117.651 23.1589 118.949 19.9161 122.238 22.9585C125.528 26.0009 127.903 26.3835 129.237 26.2195C130.553 26.0556 127.428 25.509 125.381 23.6326C123.353 21.7379 118.547 18.0761 117.651 23.1589Z" fill="#C0C6CC"/>
        <path id="Vector_10" d="M127.264 31.2659C127.921 31.1384 130.023 30.683 132.527 29.4077C135.03 28.1324 137.954 29.2255 138.63 30.1911C139.306 31.1566 143.509 35.1646 143.875 35.6018C144.24 36.039 139.489 31.8125 137.479 31.2477C135.469 30.683 135.67 31.6667 133.532 32.104C131.375 32.5776 122.476 32.2133 127.264 31.2659Z" fill="#E4EBF2"/>
        <path id="Vector_11" d="M153.688 37.8427C153.688 37.8427 156.52 40.9944 156.776 42.2332C157.032 43.472 157.891 42.634 158.494 43.3627C158.585 43.472 158.695 43.7088 158.768 43.8364C158.786 43.8546 158.658 43.9092 158.713 43.9639C159.371 44.474 159.755 44.7291 159.755 44.7291C159.755 44.7291 158.567 46.2047 158.037 45.9314C157.909 45.8768 157.617 45.5853 157.507 45.3667C157.452 45.2574 157.635 45.2756 157.58 45.1663C157.16 44.5469 156.502 43.8182 155.789 43.5631C154.711 43.1805 148.443 41.2312 148.096 39.9742C147.767 38.6989 153.688 37.8427 153.688 37.8427Z" fill="#E4EBF2"/>
        <path id="Vector_12" d="M38.7081 42.1421C37.4472 41.6502 36.9904 41.8142 36.0584 41.7959C35.1264 41.7777 35.2909 41.8324 33.3721 41.8688C31.4534 41.9052 32.1295 42.0328 31.5813 41.7959C31.0331 41.5591 31.1244 41.7777 30.1376 41.8142C29.1509 41.8506 29.2239 41.8324 28.7123 42.051C28.2006 42.2696 27.0676 42.2696 24.8382 42.2332C22.6088 42.1967 23.6139 42.4882 21.9144 43.5449C20.2149 44.6015 20.489 43.6542 20.489 43.6542L20.4342 43.7088C18.15 46.2411 22.4261 44.3829 24.491 44.2189C26.556 44.055 26.9763 44.31 29.0778 44.4193C31.1793 44.5286 32.0381 44.638 33.4818 44.055C34.9254 43.472 36.1315 43.2716 37.5934 43.1805C39.0919 43.0894 39.9873 42.634 38.7081 42.1421Z" fill="#C0C6CC"/>
        <path id="Vector_13" d="M144.514 41.4316C144.514 41.4316 144.13 40.8122 143.326 41.3587C142.522 41.9052 141.298 42.7797 139.964 43.053C138.648 43.3263 137.552 42.9801 137.936 42.9255C138.319 42.8708 137.46 42.7068 136.437 42.7615C135.414 42.8162 139.142 42.3243 139.964 41.887C140.786 41.4498 144.277 40.2656 144.514 41.4316Z" fill="#C0C6CC"/>
      </g>
      <defs>
        <linearGradient id="gfx-wastesite-paint0_linear" x1="173.413" y1="54.0853" x2="169.454" y2="39.9065" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F8FCFF"/>
          <stop offset="1" stopColor="#E4EBF2"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default WasteSite
