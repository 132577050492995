import React from 'react'

const SocialShareIcon = () => {
  return (
    <svg width="96" height="96" viewBox="0 0 110 99" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M82.696 29.6509C76.1468 29.1717 69.6267 28.1679 63.2516 26.3117C58.8394 25.0265 54.3211 24.2649 49.7832 24.0471C39.0086 23.5292 27.6241 26.4111 19.6123 35.0307C11.601 43.6503 8.10989 58.8986 13.6332 69.9586C19.1239 80.9547 30.9352 84.5103 41.5928 85.8964C51.1859 87.1434 60.8643 87.4453 70.4983 86.7965C78.9917 86.2255 88.1822 84.4974 94.0366 77.1272C98.7721 71.1655 100.41 62.2908 99.2878 54.1834C98.1649 46.0755 94.5436 38.6953 89.993 32.531C88.5427 30.5668 85.7708 29.876 82.696 29.6509Z" fill="#FAFDFF" />
      <mask id="mask0_1877_9742" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="13" width="110" height="86">
        <path fillRule="evenodd" clipRule="evenodd" d="M82.696 29.6509C76.1468 29.1717 69.6267 28.1679 63.2516 26.3117C58.8394 25.0265 54.3211 24.2649 49.7832 24.0471C39.0086 23.5292 27.6241 26.4111 19.6123 35.0307C11.601 43.6503 8.10989 58.8986 13.6332 69.9586C19.1239 80.9547 30.9352 84.5103 41.5928 85.8964C51.1859 87.1434 60.8643 87.4453 70.4983 86.7965C78.9917 86.2255 88.1822 84.4974 94.0366 77.1272C98.7721 71.1655 100.41 62.2908 99.2878 54.1834C98.1649 46.0755 94.5436 38.6953 89.993 32.531C88.5427 30.5668 85.7708 29.876 82.696 29.6509Z" fill="#FAFDFF" />
      </mask>
      <g mask="url(#mask0_1877_9742)">
        <path fillRule="evenodd" clipRule="evenodd" d="M73.911 92H36.9214C32.3137 92 28.5439 89.1778 28.5439 85.7285V22.08C28.5439 18.6304 32.3137 15.8082 36.9214 15.8082H73.911C78.5183 15.8082 82.2881 18.6304 82.2881 22.08V85.7285C82.2881 89.1778 78.5183 92 73.911 92Z" fill="url(#paint0_linear_1877_9742)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M43.3856 19.906C43.3856 20.7977 44.3514 21.5208 45.5426 21.5208H65.2888C66.4803 21.5208 67.4462 20.7977 67.4462 19.906V19.0648H74.5554C76.7624 19.0648 78.5511 20.404 78.5511 22.056V85.7524C78.5511 87.4042 76.7624 88.7436 74.5554 88.7436H36.2763C34.0693 88.7436 32.2803 87.4042 32.2803 85.7524V22.056C32.2803 20.404 34.0693 19.0648 36.2763 19.0648H43.3856V19.906Z" fill="white" />
      </g>
      <mask id="mask1_1877_9742" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="18" y="11" width="75" height="60">
        <path d="M18.6572 11.7303H92.5799V70.0192H18.6572V11.7303Z" fill="white" />
      </mask>
      <g mask="url(#mask1_1877_9742)">
        <path fillRule="evenodd" clipRule="evenodd" d="M73.911 117.583H36.9214C32.3137 117.583 28.5439 113.693 28.5439 108.937V21.1909C28.5439 16.4353 32.3137 12.5446 36.9214 12.5446H73.911C78.5183 12.5446 82.2881 16.4353 82.2881 21.1909V108.937C82.2881 113.693 78.5183 117.583 73.911 117.583Z" fill="url(#paint1_linear_1877_9742)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M43.3856 18.1938C43.3856 19.4232 44.3514 20.4201 45.5426 20.4201H65.2888C66.4803 20.4201 67.4462 19.4232 67.4462 18.1938V17.0342H74.5554C76.7624 17.0342 78.5511 18.8803 78.5511 21.1579V108.97C78.5511 111.248 76.7624 113.094 74.5554 113.094H36.2763C34.0693 113.094 32.2803 111.248 32.2803 108.97V21.1579C32.2803 18.8803 34.0693 17.0342 36.2763 17.0342H43.3856V18.1938Z" fill="white" />
      </g>
      <path d="M39.529 33.2425C39.5346 32.0551 38.6253 31.0867 37.4983 31.0796C36.3712 31.0725 35.453 32.0293 35.4475 33.2167C35.442 34.4041 36.3512 35.3724 37.4783 35.3795C38.6054 35.3867 39.5235 34.4299 39.529 33.2425Z" fill="#E7ECEF" />
      <path d="M53.7237 32.5H42.5415C42.1273 32.5 41.7871 32.1768 41.7871 31.7833C41.7871 31.3899 42.1199 31.0667 42.5415 31.0667H53.7311C54.1453 31.0667 54.4855 31.3899 54.4855 31.7833C54.4855 32.1838 54.1453 32.5 53.7237 32.5Z" fill="#E7ECEF" />
      <path d="M71.8642 35.3667H42.5415C42.1273 35.3667 41.7871 35.0435 41.7871 34.65C41.7871 34.2565 42.1199 33.9333 42.5415 33.9333H71.8716C72.2858 33.9333 72.626 34.2565 72.626 34.65C72.626 35.0505 72.2858 35.3667 71.8642 35.3667Z" fill="#E7ECEF" />
      <path d="M46.1802 66.8635H41.3382C40.9236 66.8635 40.583 66.504 40.583 66.0662C40.583 65.6285 40.9236 65.269 41.3382 65.269H46.1802C46.5948 65.269 46.9353 65.6285 46.9353 66.0662C46.9353 66.504 46.6022 66.8635 46.1802 66.8635Z" fill="#E7ECEF" />
      <path d="M70.7013 65.5894H57.0786C56.8269 65.5894 56.627 65.3784 56.627 65.1126C56.627 64.8469 56.8269 64.6358 57.0786 64.6358H70.7013C70.953 64.6358 71.1529 64.8469 71.1529 65.1126C71.1529 65.3706 70.9456 65.5894 70.7013 65.5894Z" fill="#E7ECEF" />
      <path d="M73.7368 67.1838H57.0786C56.8269 67.1838 56.627 66.9728 56.627 66.707C56.627 66.4413 56.8269 66.2302 57.0786 66.2302H73.7368C73.9885 66.2302 74.1884 66.4413 74.1884 66.707C74.1884 66.9728 73.9885 67.1838 73.7368 67.1838Z" fill="#E7ECEF" />
      <path d="M75.3466 38.7111H35.4375V62.1222H75.3466V38.7111Z" fill="#E7ECEF" />
      <path d="M37.5547 64.9484C37.5547 64.9484 37.4066 64.1511 36.644 64.1511C36.1924 64.1511 35.7334 64.6279 35.7334 65.2689C35.7334 66.5508 37.5473 67.8249 37.5473 67.8249C37.5473 67.8249 39.3612 66.543 39.3612 65.2689C39.3612 64.6279 38.9096 64.1511 38.4505 64.1511C37.7028 64.1511 37.5547 64.9484 37.5547 64.9484Z" fill="#F65B7D" />
      <path d="M18.6731 28.4133C18.6731 28.4133 18.0182 27.0551 16.6427 27.4667C15.828 27.7105 15.2319 28.8183 15.5436 29.9745C16.1671 32.2869 20.0587 33.606 20.0587 33.606C20.0587 33.606 22.7072 30.3145 22.0876 28.0163C21.7758 26.8601 20.7293 26.2438 19.9013 26.4916C18.5524 26.8952 18.6731 28.4133 18.6731 28.4133Z" fill="#F65B7D" />
      <path d="M54.2063 64.9641V65.8943C54.2063 66.4336 53.7917 66.8713 53.2809 66.8713H52.8737L52.6886 67.8328L51.8594 66.8713H51.193C50.6822 66.8713 50.2676 66.4336 50.2676 65.8943V64.9641C50.2676 64.4248 50.6822 63.987 51.193 63.987H53.2809C53.7917 63.987 54.2063 64.4248 54.2063 64.9641Z" fill="#3176DE" />
      <path d="M53.1397 65.2689H51.3258C51.2444 65.2689 51.1777 65.1986 51.1777 65.1126C51.1777 65.0266 51.2444 64.9563 51.3258 64.9563H53.1397C53.2211 64.9563 53.2878 65.0266 53.2878 65.1126C53.2952 65.1986 53.2285 65.2689 53.1397 65.2689Z" fill="#F4F4F4" />
      <path d="M53.1397 65.902H51.3258C51.2444 65.902 51.1777 65.8317 51.1777 65.7457C51.1777 65.6597 51.2444 65.5894 51.3258 65.5894H53.1397C53.2211 65.5894 53.2878 65.6597 53.2878 65.7457C53.2952 65.8317 53.2285 65.902 53.1397 65.902Z" fill="#F4F4F4" />
      <path d="M86.9103 69.0509L86.262 70.5936C85.8861 71.4881 84.8934 71.8934 84.0461 71.4983L83.3707 71.1833L82.3936 72.6347L81.6884 70.3987L80.5833 69.8832C79.736 69.488 79.3534 68.4413 79.7293 67.5468L80.3776 66.004C80.7535 65.1095 81.7462 64.7042 82.5935 65.0994L86.0564 66.7144C86.9037 67.1096 87.2862 68.1563 86.9103 69.0509Z" fill="#3176DE" />
      <path d="M84.9297 68.7317L81.9212 67.3286C81.7862 67.2656 81.7247 67.0974 81.7846 66.9548C81.8445 66.8122 82.0041 66.747 82.1391 66.81L85.1476 68.2132C85.2827 68.2762 85.3442 68.4444 85.2843 68.587C85.2366 68.7353 85.0771 68.8005 84.9297 68.7317Z" fill="#F4F4F4" />
      <path d="M84.4883 69.7819L81.4798 68.3788C81.3447 68.3158 81.2833 68.1475 81.3432 68.0049C81.4031 67.8623 81.5627 67.7972 81.6977 67.8602L84.7062 69.2633C84.8413 69.3263 84.9028 69.4946 84.8429 69.6372C84.7952 69.7855 84.6357 69.8506 84.4883 69.7819Z" fill="#F4F4F4" />
      <path d="M74.8931 71.6778H35.891C35.6405 71.6778 35.4375 71.8917 35.4375 72.1555C35.4375 72.4194 35.6405 72.6333 35.891 72.6333H74.8931C75.1436 72.6333 75.3466 72.4194 75.3466 72.1555C75.3466 71.8917 75.1436 71.6778 74.8931 71.6778Z" fill="#E7ECEF" />
      <path d="M80.6997 41.0904C79.4686 44.2232 80.9163 47.8613 83.9384 49.2248C86.9605 50.5884 90.4053 49.1526 91.6329 46.0255C92.864 42.8926 91.4107 39.2508 88.3942 37.891C85.3721 36.5275 81.9308 37.9575 80.6997 41.0904Z" fill="#FFD049" />
      <path d="M85.1575 46.1353C84.2401 45.7194 83.4835 45.0075 82.9908 44.1057C82.8618 43.8694 82.9701 43.5814 83.2059 43.4783C83.3231 43.427 83.4593 43.4864 83.5179 43.607C83.9349 44.4655 84.6281 45.1299 85.4856 45.4992C86.3425 45.8683 87.2722 45.895 88.1236 45.585C88.2444 45.5411 88.3818 45.5991 88.434 45.7235C88.5386 45.9729 88.4326 46.2624 88.1869 46.3398C87.225 46.6429 86.1938 46.5875 85.2336 46.1786C85.2147 46.1577 85.1833 46.1446 85.1575 46.1353Z" fill="#4A4A7C" />
      <path d="M83.8407 40.3168C83.5602 41.032 83.7748 41.8041 84.3215 42.0507C84.8682 42.2974 85.5387 41.9187 85.8156 41.2093C86.0926 40.4999 85.8815 39.722 85.3348 39.4754C84.7881 39.2287 84.1176 39.6074 83.8407 40.3168Z" fill="#4A4A7C" />
      <path d="M87.926 42.1401C87.6456 42.8552 87.8601 43.6274 88.4069 43.874C88.9536 44.1207 89.6241 43.7419 89.901 43.0325C90.1815 42.3174 89.9704 41.5395 89.4237 41.2928C88.8769 41.0461 88.2065 41.4249 87.926 42.1401Z" fill="#4A4A7C" />
      <path d="M17.23 61.2796C18.4981 62.6233 20.5815 62.6126 21.8889 61.2545C23.1963 59.8965 23.2267 57.7077 21.9608 56.3671C20.6927 55.0234 18.6062 55.0364 17.3019 56.3922C15.9945 57.7502 15.962 59.9359 17.23 61.2796Z" fill="#FFD049" />
      <path d="M20.642 59.9242C20.2439 60.336 19.7351 60.5964 19.1896 60.6718C19.0466 60.6916 18.9288 60.5706 18.9284 60.4196C18.9282 60.3445 18.9862 60.2829 19.0576 60.2768C19.5659 60.2334 20.0379 59.9974 20.4006 59.6083C20.763 59.2194 20.9735 58.7173 21.0026 58.1856C21.0068 58.1102 21.0643 58.0476 21.1362 58.0458C21.2804 58.0422 21.3995 58.1623 21.3852 58.3131C21.3292 58.9037 21.0831 59.4552 20.6793 59.8919C20.6651 59.8977 20.652 59.9121 20.642 59.9242Z" fill="#4A4A7C" />
      <path d="M17.5125 59.3983C17.802 59.7046 18.2244 59.7529 18.4609 59.5073C18.6973 59.2617 18.6548 58.8145 18.3674 58.5113C18.0799 58.2081 17.6554 58.1566 17.4189 58.4022C17.1825 58.6479 17.225 59.095 17.5125 59.3983Z" fill="#4A4A7C" />
      <path d="M19.2772 57.5653C19.5668 57.8716 19.9892 57.9199 20.2257 57.6743C20.4622 57.4287 20.4196 56.9815 20.1322 56.6783C19.8426 56.3719 19.4181 56.3205 19.1816 56.5661C18.9452 56.8117 18.9877 57.2589 19.2772 57.5653Z" fill="#4A4A7C" />
      <path d="M46.2087 51.1154C45.6269 52.3657 41.1032 55.1606 37.4463 56.8523L37.9924 60.2111L35.2497 57.7594C34.2761 58.1027 33.4925 58.2865 33.0651 58.2007C31.0229 57.7839 26.7366 50.8089 27.3303 48.8966C28.1377 46.31 38.3486 41.0634 40.3671 41.1002C42.3855 41.1492 47.1111 49.203 46.2087 51.1154Z" fill="#00B0B2" />
      <path d="M33.624 50.6024L35.083 53.5222C35.083 53.5222 36.2838 52.8158 37.8213 52.1447C38.8875 51.6738 39.8526 51.403 40.1669 50.8967C40.9412 49.6605 39.8639 47.1881 38.5283 47.1528C37.3836 47.1174 36.3848 48.648 36.3624 47.8945C36.3287 47.141 36.3175 46.0225 35.5319 45.528C34.578 44.9276 35.2962 46.5876 35.2176 47.4353C35.0942 48.6009 34.8024 49.4251 33.624 50.6024Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_1877_9742" x1="22.3637" y1="26.3826" x2="41.1172" y2="37.3432" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7C8084" />
          <stop offset="1" stopColor="#5E5E70" />
        </linearGradient>
        <linearGradient id="paint1_linear_1877_9742" x1="22.3637" y1="27.1226" x2="43.6902" y2="36.1639" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7C8084" />
          <stop offset="1" stopColor="#5E5E70" />
        </linearGradient>
      </defs>
    </svg>

  )
}

export default SocialShareIcon
