import React from 'react'
import classnames from 'classnames'

const ArticleContent = ({ children }) => {
  const _ArticleContent = classnames('app-article__content')
  return (
    <div className={_ArticleContent}>
      {children}
    </div>
  )
}

export default ArticleContent
