import React, {useEffect, useState} from 'react'
import {getToken as getMobileToken } from './bridge'

const UserSessionTokenContext = React.createContext('')

const UserSessionTokenProvider = ({children}) => {
  const [userSessionToken, setUserSessionToken] = useState('')
  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getMobileToken()
        setUserSessionToken(token)
      } catch (e) {
        console.error('bridge.GetToken() errors', e)
      }
    }
    getToken()
  }, [setUserSessionToken])

  return (
    <UserSessionTokenContext.Provider value={userSessionToken}>
      {children}
    </UserSessionTokenContext.Provider>
  )
}



export {
  UserSessionTokenContext,
  UserSessionTokenProvider
}
