import HomeCate from './home.svg'
import WasteCate from './waste.svg'
import FoodCate from './food.svg'
import SpendingsCate from './spendings.svg'
import CommuteCate from './commute.svg'
import HolidayCate from './holiday.svg'

const ProgressIcons = {
  HomeCate,
  WasteCate,
  FoodCate,
  SpendingsCate,
  CommuteCate,
  HolidayCate
}

export default ProgressIcons
