import React from 'react'

const DrumCans = () => {
  return (
    <svg className='gfx-drumcans' width="104" height="66" viewBox="0 0 104 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 105">
        <path id="Oval" fillRule="evenodd" clipRule="evenodd" d="M28.1869 61.2336C43.7541 61.2336 56.3738 59.2754 56.3738 56.8598C56.3738 54.4442 43.7541 52.486 28.1869 52.486C12.6197 52.486 0 54.4442 0 56.8598C0 59.2754 12.6197 61.2336 28.1869 61.2336Z" fill="url(#gfx-drumscans-paint0_linear)"/>
        <path id="Oval_2" fillRule="evenodd" clipRule="evenodd" d="M75.8131 65.1215C91.3803 65.1215 104 63.3809 104 61.2337C104 59.0865 91.3803 57.3458 75.8131 57.3458C60.2459 57.3458 47.6262 59.0865 47.6262 61.2337C47.6262 63.3809 60.2459 65.1215 75.8131 65.1215Z" fill="url(#gfx-drumscans-paint1_linear)"/>
        <g id="Group">
          <path id="Vector" d="M5.59015 19.3722L55.3866 19.3722C56.2825 22.1397 56.9276 24.7993 57.3935 27.3512C60.5114 44.6748 54.7415 56.4456 54.7415 56.4456L5.66182 56.4456C5.66182 56.4456 5.67974 56.4276 5.67974 56.4096C6.16355 55.7627 6.5936 53.3007 6.8803 49.7425C6.8803 49.7066 6.8803 49.6527 6.89822 49.5988C7.14909 46.4359 7.31035 42.4105 7.31035 38.0437C7.31035 34.5753 7.22076 31.3406 7.04157 28.5552C7.04157 28.5193 7.04157 28.4654 7.04157 28.4294C7.02365 28.052 6.98782 27.6926 6.9699 27.3512C6.62944 22.6069 6.05604 19.516 5.42888 19.516L5.41096 19.516C5.41096 19.516 5.41096 19.516 5.39304 19.516L5.37512 19.516C5.39304 19.498 5.41096 19.4621 5.42888 19.4441C5.48263 19.4082 5.53639 19.3722 5.59015 19.3722Z" fill="#CED5DC"/>
          <path id="Vector_2" d="M46.6421 18.5817C47.1976 18.5817 47.6993 18.9052 47.9323 19.3904C47.986 19.4982 48.0219 19.624 48.0577 19.7498C48.5415 22.3196 48.8999 24.8714 49.1508 27.3514C50.4767 40.7395 48.7207 52.2227 48.0577 55.8168C47.9323 56.4997 47.3409 56.9849 46.6421 56.9849L46.4092 56.9849C45.5132 56.9849 44.8323 56.1762 45.0115 55.2956C45.7283 51.414 47.2334 41.0989 46.0687 27.3514C45.8716 25.0691 45.6028 22.679 45.2444 20.217C45.2086 19.9115 45.2444 19.624 45.3699 19.3724C45.6028 18.9052 46.0687 18.5817 46.6421 18.5817Z" fill="#C0C6CC"/>
          <path id="Vector_3" d="M5.59017 19.3722C6.16357 19.3722 6.6653 22.4632 6.96992 27.2074C6.98783 27.6028 7.02367 28.0161 7.04159 28.4294C7.04159 28.4654 7.04159 28.5193 7.04159 28.5552C7.18494 31.3047 7.27454 34.4855 7.27454 37.8999C7.27454 42.3386 7.13119 46.4 6.89824 49.5988C6.89824 49.6527 6.89824 49.6886 6.88032 49.7425C6.61154 53.2827 6.21733 55.7088 5.78727 56.3018C5.75144 56.3557 5.7156 56.3916 5.67976 56.4096C5.64392 56.4276 5.60809 56.4456 5.59017 56.4456C4.64047 56.4456 3.88788 48.1431 3.88788 37.8999C3.88788 28.4653 4.53295 20.6661 5.37514 19.516C5.39306 19.498 5.41098 19.4621 5.4289 19.4441C5.48265 19.4082 5.53641 19.3722 5.59017 19.3722Z" fill="#9EA3A8"/>
          <path id="Vector_4" d="M12.041 18.5817C12.5964 18.5817 13.0982 18.9052 13.3311 19.3904C13.3849 19.4982 13.4207 19.624 13.4565 19.7498C13.9403 22.3196 14.2808 24.8714 14.5317 27.3513C15.8577 40.7394 14.1016 52.2226 13.4386 55.8347C13.3132 56.5176 12.7219 57.0028 12.023 57.0028L11.7901 57.0028C10.8941 57.0028 10.2132 56.1941 10.3924 55.3136C11.1092 51.4319 12.6144 41.1168 11.4496 27.3693C11.2525 25.087 10.9837 22.6969 10.6254 20.235C10.5895 19.9295 10.6254 19.6419 10.7508 19.3904C10.9837 18.9052 11.4675 18.5817 12.041 18.5817Z" fill="#C0C6CC"/>
        </g>
        <g id="Group_2">
          <path id="Vector_5" d="M96.2761 1.98106V59.9287C93.0557 60.9713 89.9607 61.7219 86.9911 62.2641C66.8318 65.8923 53.1343 59.178 53.1343 59.178L53.1343 2.06447C53.1343 2.06447 53.1552 2.08532 53.1761 2.08532C53.9289 2.64832 56.7939 3.14877 60.9345 3.4824C60.9764 3.4824 61.0391 3.4824 61.1018 3.50325C64.7824 3.79518 69.4667 3.98285 74.5484 3.98285C78.5844 3.98285 82.3486 3.87859 85.59 3.67007C85.6318 3.67007 85.6946 3.67007 85.7364 3.67007C86.1755 3.64922 86.5938 3.60751 86.9911 3.58666C92.5119 3.19047 96.1088 2.52321 96.1088 1.79339V1.77254C96.1088 1.77254 96.1088 1.77254 96.1088 1.75169V1.73083C96.1298 1.75169 96.1716 1.77254 96.1925 1.79339C96.2343 1.85595 96.2761 1.9185 96.2761 1.98106Z" fill="#E4EBF2"/>
          <path id="Vector_6" d="M97.1961 49.7529C97.1961 50.3993 96.8197 50.9832 96.2551 51.2543C96.1296 51.3168 95.9832 51.3585 95.8368 51.4002C92.8464 51.9632 89.8769 52.3803 86.991 52.6722C71.4114 54.2152 58.0485 52.1717 53.8661 51.4002C53.0714 51.2543 52.5068 50.5661 52.5068 49.7529V49.4818C52.5068 48.4392 53.4478 47.6469 54.4725 47.8554C58.9896 48.6895 70.9932 50.441 86.991 49.0856C89.6468 48.8563 92.4282 48.5435 95.2931 48.1265C95.6486 48.0848 95.9832 48.1265 96.276 48.2724C96.8197 48.5435 97.1961 49.0857 97.1961 49.7529Z" fill="#CED5DC"/>
          <path id="Vector_7" d="M96.2761 1.98094C96.2761 2.6482 92.6792 3.23205 87.1584 3.58654C86.6984 3.60739 86.2174 3.64909 85.7364 3.66995C85.6946 3.66995 85.6318 3.66995 85.59 3.66995C82.3904 3.83676 78.689 3.94102 74.7157 3.94102C69.5504 3.94102 64.8242 3.77421 61.1018 3.50313C61.0391 3.50313 60.9973 3.50313 60.9345 3.48228C56.8148 3.1695 53.9917 2.71076 53.3016 2.21031C53.2388 2.1686 53.197 2.1269 53.1761 2.0852C53.1552 2.04349 53.1343 2.00179 53.1343 1.98094C53.1343 0.875782 62.7957 0 74.7157 0C85.6946 0 94.7705 0.750671 96.1088 1.73071C96.1298 1.75157 96.1716 1.77242 96.1925 1.79327C96.2343 1.85582 96.2761 1.91838 96.2761 1.98094Z" fill="#9EA3A8"/>
          <path id="Vector_8" d="M97.1963 9.48775C97.1963 10.1342 96.8199 10.718 96.2553 10.9891C96.1298 11.0517 95.9834 11.0934 95.837 11.1351C92.8466 11.6981 89.877 12.0942 86.9911 12.3862C71.4116 13.9292 58.0487 11.8857 53.8453 11.1142C53.0507 10.9682 52.486 10.2801 52.486 9.4669V9.19583C52.486 8.15323 53.4271 7.36085 54.4518 7.56937C58.9688 8.40345 70.9724 10.155 86.9702 8.79964C89.6261 8.57027 92.4074 8.25749 95.2724 7.84045C95.6279 7.79874 95.9625 7.84045 96.2553 7.98641C96.8199 8.25749 97.1963 8.82049 97.1963 9.48775Z" fill="#CED5DC"/>
        </g>
      </g>
      <defs>
        <linearGradient id="gfx-drumscans-paint0_linear" x1="57.1693" y1="55.6172" x2="54.2887" y2="49.0079" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F8FCFF"/>
          <stop offset="1" stopColor="#E4EBF2"/>
        </linearGradient>
        <linearGradient id="gfx-drumscans-paint1_linear" x1="104.796" y1="60.1292" x2="102.441" y2="54.0506" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F8FCFF"/>
          <stop offset="1" stopColor="#E4EBF2"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default DrumCans
