import React from 'react'
import classnames from 'classnames'

const BGImage = (props) => {
  const _BGImage = classnames('app-bgimg', props.className)
  return (
    <section className={_BGImage} style={{
      ...props.styles
    }}>
      {
        props.overlay && (
          <div className='app-bgimg--overlay' style={{
            backgroundColor: props.overlayColor
          }}></div>
        )
      }
      {
        props.children && (
          <div className='app-bgimg--content'>{props.children}</div>
        )
      }
      {
        props.image !== '' && (
          <figure className='app-bgimg--image' style={{
            backgroundImage: `url(${props.image})`,
            ...props.imageStyles,
          }}>
            <img src={props.image} alt={props.alt} />
          </figure>
        )
      }
      {
        props.footnote && (
          <div className='app-bgimg--footnote'>
            { props.footnote }
          </div>
        )
      }
    </section>
  )
}

BGImage.defaultProps = {
  className: '',
  image: '',
  footnote: null,
  styles: {},
  imageStyles: {},
  alt:'',
  overlayColor: `rgba(0,0,0,0.2)`
}

export default BGImage
