import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ProgressBarItem from './ProgressBarItem'

import './styles.scss'

const ProgressBar = ({ children, items, id, direction, progress }) => {
  const _ProgressBar = classnames('cui-progress-v2', {
    'is-crosswise': direction === 'row',
    'is-progress': progress
  })

  const renderChildren = () => {
    if (items.length > 0) {
      const checkActive = items.map(item => item.active)
      return (
        <>
          <div className="cui-progress-v2__items">
            {items.map((item, itemIdx) => {
              const { content, active, ...itemProps } = item
              const isActive = active || checkActive.indexOf(true) > itemIdx
              return (
                <ProgressBarItem
                  key={`${id}-progressitem--${itemIdx}`}
                  active={isActive}
                  {...itemProps}
                  idx={itemIdx}
                >
                  <span
                    className='cui-progress-v2__item--cate-name'
                    style={{ fontWeight: isActive ? 600 : "unset" }}
                  >{content}</span>
                </ProgressBarItem>
              )
            })}
          </div>
          {children}
        </>
      )
    }
    return children
  }

  return <div className={_ProgressBar}>{renderChildren()}</div>
}

ProgressBar.displayName = 'CuiProgress'

ProgressBar.propTypes = {
  items: PropTypes.array,
  direction: PropTypes.string,
  id: PropTypes.string,
  progress: PropTypes.bool
}

ProgressBar.defaultProps = {
  items: [],
  direction: 'row',
  id: '',
  progress: true
}

ProgressBar.Item = ProgressBarItem

export default ProgressBar
