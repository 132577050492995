import React, { PureComponent } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Constants from 'spd-gds/constants/'
import { Co2eActions } from 'spd-gds/actions'
import { StorageServices } from 'spd-gds/services'
import Helpers from 'spd-gds/helpers'
import { withDyipne } from 'spd-gds/Dyipne'

import { Page } from 'spd-gds/components/pages'
import { useScreenSize } from 'spd-gds/components/ScreenSize'

import ProgressBar from 'spd-gds/components/common/ProgressBar'
import { Icons, ConfirmationDialog } from 'spd-gds/components/common'

import EnergyConsumption from 'spd-gds/components/pages/questionnaire/EnergyConsumption'
import HomeMobile from 'spd-gds/components/pages/questionnaire/HomeMobile'
import Recycle from 'spd-gds/components/pages/questionnaire/Recycle'
import Food from 'spd-gds/components/pages/questionnaire/Food'
import Spending from 'spd-gds/components/pages/questionnaire/Spending'
import Commute from 'spd-gds/components/pages/questionnaire/Commute'
import Travel from 'spd-gds/components/pages/questionnaire/Travel'
import { CO2Qn as CO2QnGfx, CO2Bg as CO2QnBg } from 'spd-gds/components/gfx'
import Home from 'spd-gds/components/gfx/categories/Home'
import Grocery from 'spd-gds/components/gfx/categories/Food'
import LandTransport from 'spd-gds/components/gfx/categories/LandTransport'
import RecycleBin from 'spd-gds/components/gfx/categories/RecycleBin'
import Shopping from 'spd-gds/components/gfx/categories/Shopping'
import Luggages from 'spd-gds/components/gfx/categories/Travel'
import { modeContext } from 'spd-gds/components/hooks'

const pathResolver = (path) => {
  return "/questionnaire" + path
}

const webVariant = [{
  active: true,
  id: 'pg-qn-utility-consumption',
  content: 'Utility',
  path: pathResolver('/utility'),
  component: EnergyConsumption,
  hero: Home,
}, {
  id: 'pg-qn-recycle',
  content: 'Waste',
  path: pathResolver('/waste'),
  component: Recycle,
  hero: RecycleBin,
}]

const mobileVariant = [{
  active: true,
  id: 'pg-qn-home-mobile',
  content: 'Home',
  path: pathResolver('/home'),
  component: HomeMobile,
  hero: Home,
}]

const initPages = () => [
  ...(Helpers.isInfinity() ? mobileVariant : webVariant),
  {
    id: 'pg-qn-food-consumption',
    content: 'Food',
    path: pathResolver('/food'),
    component: Food,
    hero: Grocery,
  }, {
    id: 'pg-qn-spending-habits',
    content: 'Spendings',
    path: pathResolver('/spendings'),
    component: Spending,
    hero: Shopping,
  }, {
    id: 'pg-qn-commute',
    content: 'Commute',
    path: pathResolver('/commute'),
    component: Commute,
    hero: LandTransport
  }, {
    id: 'pg-qn-holiday-travels',
    content: 'Travels',
    path: pathResolver('/travel'),
    component: Travel,
    hero: Luggages
  }]

const ScreenSizeHook = () => {
  const screenSize = useScreenSize()

  React.useEffect(() => {
    let vhValue = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vhValue', `${vhValue}px`)
  }, [screenSize.height])

  return null
}


class QuestionnairePage extends PureComponent {
  static contextType = modeContext

  pages = initPages()

  state = {
    active: {
      page: this.getActivePage(),
    },
    showTnc: false,
    submitPayload: null,
  }

  componentDidUpdate(prevProps) {
    const { co2e, history, location } = this.props
    const [co2eState] = co2e

    const { co2e: prevCo2e } = prevProps
    const [prevCo2eState] = prevCo2e

    if (prevProps.location !== this.props.location) {
      this._getActivePageByPath()
    }

    if (!co2eState.errors) {
      if (co2eState.calculate_success !== prevCo2eState.calculate_success) {
        // TODO: put the history push for next here
      }
      if (co2eState.submit_success) {
        setTimeout(() => {
          history.push('/results' + location.search)
        }, 1000)
      }
    }
    if (!Helpers.isInfinity()) {
      document.querySelector(".app-qnnre__category").classList.add('type-web')
    }
  }

  componentDidMount() {
    document.body.classList.add('__questionnaire')
    this._checkFlow()
  }

  componentWillUnmount() {
    document.body.classList.remove('__questionnaire')
  }

  getPageIdx(pathname) {
    const pageIdx = this.pages.findIndex(pg => pg.path === pathname)
    return pageIdx
  }

  getActivePage(pathname) {
    const activePage = this.pages.filter(page => {
      return page.path === pathname
      // return page.active
    })
    return activePage
  }

  render() {
    const { active, showTnc } = this.state
    const { co2e } = this.props
    const [co2eState] = co2e
    const { componentShouldHide } = this.context
    return (
      <Page className='page-questionnaire' >
        <h3> What is my Carbon Footprint? </h3>
        <ProgressBar
          items={this._getPages()}
        />
        {this._renderPages()}
        {
          !componentShouldHide?.gfx &&
          <>
            <CO2QnBg />
            <CO2QnGfx activeCategory={active.page} />
          </>
        }

        <ConfirmationDialog
          visible={co2eState.errors && co2eState.errors.length > 0}
          confirmOkHandler={this._handleErrorAlertContinue}
          onCloseHandler={this._handleErrorAlertContinue}
          processing={false}
          style={{
            alignItems: "center"
          }}
          actionConfig={{
            ok: {
              label: 'Continue',
            },
            cancel: false
          }}
          content={
            <>
              {
                co2eState.errors && (
                  <>
                    <figure className='alert-icon'>
                      <Icons.Fail width={240} />
                    </figure>
                    <h2 datatype={co2eState.errors[0].error}>
                      {Helpers.userFriendlyErrorMsg(co2eState.errors[0].error_description)}
                    </h2>
                  </>
                )
              }
            </>
          }
        />

        <ConfirmationDialog
          visible={showTnc}
          confirmOkHandler={this._handleAcceptTnc}
          onCloseHandler={this._closeTnc}
          processing={false}
          actionConfig={{
            ok: {
              label: 'Accept',
            },
            cancel: false
          }}
          content={
            <div className='app-tnc'>
              <h2> Terms &amp; Conditions </h2>
              <ol>
                <li>
                  By accessing and using my Carbon Footprint, you agree to be bound by the SP Group&apos;s <a href="/terms-of-use" target="_blank" rel="noreferrer">Terms and Conditions of Use</a>.
                </li>
                <li>
                  You consent that your personal information (including without limitation your email) may be collected, used, disclosed, stored, transferred and/or otherwise processed by SP Impact Pte Ltd, its affiliates and partners to calculate your carbon footprint and in accordance with SP Group&apos;s  <a href="/personal-data-protection-policy" target="_blank" rel="noreferrer">Personal Data Protection Policy</a>.
                </li>
                <li>
                  SP Impact Pte Ltd reserves the right to amend these Terms and Conditions or to discontinue/terminate my Carbon Footprint without prior notice to you.
                </li>
              </ol>
            </div>
          }
        />
        <ScreenSizeHook />
      </Page>
    )
  }

  _renderPages = () => {
    return (
      <Switch>
        <Route exact path="/questionnaire" render={() => (<Redirect to={this.pages[0].path} />)} />
        {
          this.pages.map((page, idx) => {
            const Component = page.component
            return (
              <Route key={`qnpage--${idx}`} path={page.path} render={props => {
                if (!Component) return null
                return <Component {...props} next={this._handleNext} prev={this._handlePrev} />
              }} />
            )
          })
        }
      </Switch>
    )
  }

  _checkFlow = () => {
    const { history, location } = this.props
    const { pathname } = location
    const pageIdx = this.getPageIdx(pathname)
    const currPageIdx = parseInt(StorageServices.Retrieve(Constants.STORAGE.page))

    if (currPageIdx === this.pages.length) {
      history.push('/' + window.location.search)
      return
    }

    if (pageIdx > 0) {
      if (!currPageIdx) {
        StorageServices.Save(Constants.STORAGE.page, 0)
        history.push(this.pages[0].path + location.search)
      } else {
        if (pageIdx <= currPageIdx) {
          StorageServices.Save(Constants.STORAGE.page, pageIdx)
          this._processFlow()
        } else {
          StorageServices.Save(Constants.STORAGE.page, 0)
          history.push(this.pages[0].path + location.search)
          this._processFlow()
        }
      }
    } else {
      // If there's no pageIdx or if its 0, set it to the first page
      StorageServices.Save(Constants.STORAGE.page, 0)
      this._processFlow()
    }
  }

  _processFlow = () => {
    this._getActivePageByPath()
    this._fetchQuestionnaire()
  }

  _handleNext = (payload) => {
    const { history, location } = this.props
    const currentPathIndex = this.pages.findIndex((pg) => pg.path === location.pathname)
    if (currentPathIndex > -1 && currentPathIndex < (this.pages.length - 1)) {
      const nextPageIdx = currentPathIndex + 1
      const nextPage = this.pages[nextPageIdx]?.path + location.search
      // Calculate
      this._calculate(payload)
      history.push(nextPage)
      StorageServices.Save(Constants.STORAGE.page, nextPageIdx)
    } else {
      // Submission, show TnC first
      this._showTnc(payload)
    }
  }

  _handlePrev = () => {
    const { history } = this.props
    history.goBack()
  }

  _handleErrorAlertContinue = () => {
    const { co2e } = this.props
    const [, dispatcher] = co2e

    dispatcher(
      Co2eActions.CONTINUE()
    )
  }

  _handleAcceptTnc = () => {
    const { submitPayload } = this.state
    this._closeTnc()
    StorageServices.Save(Constants.STORAGE.page, this.pages.length)
    this._submission(submitPayload)
  }

  _closeTnc = () => {
    const { co2e } = this.props
    const [, dispatcher] = co2e

    this.setState(prevState => ({
      ...prevState,
      showTnc: false,
    }))
    dispatcher(
      Co2eActions.CONTINUE()
    )
  }

  _showTnc = (payload) => {
    this.setState(prevState => ({
      ...prevState,
      showTnc: true,
      submitPayload: payload
    }))
  }

  _setData = (key, data) => {
    this.setState(prevState => ({
      ...prevState,
      [key]: data
    }))
  }

  _getActivePageByPath() {
    const { location } = this.props
    if (!location.pathname) {
      return
    }
    const activePage = this.getActivePage(location.pathname)
    if (activePage) {
      this.setState(prevState => ({
        ...prevState,
        active: {
          ...prevState.active,
          page: activePage
        }
      }))
    }
  }

  _getPages() {
    const { active: { page: activePage } } = this.state
    const result = this.pages.map(page => {
      let active = false

      if (activePage && activePage[0]) {
        active = activePage[0].path === page.path
      }

      return {
        ...page,
        active
      }
    })
    return result
  }

  _fetchQuestionnaire() {
    const [, dispatch] = this.props.co2e
    dispatch(
      Co2eActions.FETCH_QUESTIONNAIRE()
    )
  }

  _calculate(values) {
    const [, dispatch] = this.props.co2e
    dispatch(
      Co2eActions.CALCULATE(values)
    )
  }

  _submission(values) {
    const [, dispatch] = this.props.co2e
    dispatch(
      Co2eActions.SUBMIT(values)
    )
  }

}

export default withDyipne(QuestionnairePage)
