import React from 'react'
import classnames from 'classnames'

import Bar from './charts/Bar'

import ChartData from './ChartData'

export const ChartBreakdown = ({ children }) => {
  return (
    <div className='app-chart_breakdown'>
      { children }
    </div>
  )
}

const Chart = ({ children, className }) => {
  const _Chart = classnames('app-chart', className)
  return (
    <div className={_Chart}>
      { children }
    </div>
  )
}

Chart.Bar = Bar
Chart.Data = ChartData

export default Chart
