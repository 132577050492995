import Constants from 'spd-gds/constants/'
import Co2eConstants from 'spd-gds/constants/co2e.constants'
import { StorageServices } from 'spd-gds/services'

const co2eThreshold = 3000

function getPreSavedFields() {

  let preSavedFields = StorageServices.Retrieve(Constants.STORAGE.fields)
  if (!preSavedFields) {
    return null
  }

  return JSON.parse(preSavedFields)
}

function getPreSavedAnswers() {

  let preSavedAnswers = StorageServices.Retrieve(Constants.STORAGE.answers)
  if (!preSavedAnswers) {
    return []
  }

  return JSON.parse(preSavedAnswers)
}

function getSavedData() {
  /**
   * data: {
      total: {
        value: 892,
        unit: 'KG',
        sgAverage: 0,
        sustainableAverage: 0,
        groups: []
      },
      trees: 0,
      groups: []
    },
   */

  let preSavedData = StorageServices.Retrieve(Constants.STORAGE.data)
  let preSavedGuessRTs = StorageServices.Retrieve(Constants.STORAGE.guess_raintrees)
  if (!preSavedData) {
    return {
      threshold: co2eThreshold,
      total: {
        value: 0,
        unit: 'kg',
        sgAverage: 0,
        sustainableAverage: 0,
        groups: [],
        pastResults: [],
        target: 0
      },
      trackingType: "",
      trees: 0,
      guessRaintrees: 0
    }
  }

  const preData = JSON.parse(preSavedData)
  const preGuessRTs = JSON.parse(preSavedGuessRTs)

  const result = {
    threshold: co2eThreshold,
    total: {
      value: preData.totalKG,
      unit: 'kg',
      sgAverage: preData.sgAverageKG,
      sustainableAverage: preData.sustainableAverageKG || preData.sustainableAverage,
      groups: preData.groups.edges || [],
      pastResults: preData?.pastResults?.edges || preData?.pastResults || [],
      target: preData.target || 0
    },
    trackingType: preData.trackingType || "",
    trees: preData.trees,
    guessRaintrees: preGuessRTs ?? 0
  }

  return result
}

export const initialState = {
  type: 'co2e',

  // FETCH QUESTIONNAIRE
  fetching_qnnre: false,
  fetch_qnnre_success: false,
  fetch_qnnre_fail: false,

  // CALCULATE
  calculating: false,
  calculate_success: false,
  calculate_fail: false,

  // SUBMISSION
  submitting: false,
  submit_success: false,
  submit_fail: false,

  // GET RESULT
  fetching_result: false,
  fetch_result_success: false,
  fetch_result_fail: false,

  data: getSavedData(),
  fields: getPreSavedFields(),
  answers: getPreSavedAnswers(),
  questionnnaire: null,
  errors: null,
}

export const co2eReducer = (state, action) => {
  switch (action.type) {
    case Co2eConstants.FETCH_QUESTIONNAIRE: {
      return {
        ...state,
        fetching_qnnre: true,
        fetch_qnnre_success: false,
        fetch_qnnre_fail: false,
        errors: null,
      }
    }

    case Co2eConstants.FETCH_QUESTIONNAIRE_SUCCESS: {
      return {
        ...state,
        fetching_qnnre: false,
        fetch_qnnre_success: true,
        fetch_qnnre_fail: false,
        questionnnaire: action.payload,
        errors: null,
      }
    }

    case Co2eConstants.FETCH_QUESTIONNAIRE_FAIL: {
      return {
        ...state,
        fetching_qnnre: false,
        fetch_qnnre_success: false,
        fetch_qnnre_fail: true,
        data: null,
        questionnnaire: null,
        errors: action.payload
      }
    }

    case Co2eConstants.CALCULATE: {
      return {
        ...state,
        calculating: true,
        calculate_success: false,
        calculate_fail: false,
      }
    }

    case Co2eConstants.CALCULATE_SUCCESS: {
      return {
        ...state,
        calculating: false,
        calculate_success: true,
        calculate_fail: false,
        data: {
          ...state.data,
          total: {
            ...state.data.total,
            value: action.payload.results.totalKG,
            sgAverage: action.payload.results.sgAverageKG,
            sustainableAverage: action.payload.results.sustainableAverageKG,
            groups: action.payload.results.groups
          },
          trees: action.payload.results.trees,
        },
        fields: {
          ...state.fields,
          ...action.payload.fields
        },
        answers: action.payload.answers
      }
    }

    case Co2eConstants.CALCULATE_FAIL: {
      return {
        ...state,
        calculating: false,
        calculate_success: false,
        calculate_fail: true,
        errors: action.payload
      }
    }

    case Co2eConstants.SUBMIT: {
      return {
        ...state,
        submitting: true,
        submit_success: false,
        submit_fail: false,
      }
    }

    case Co2eConstants.SUBMIT_SUCCESS: {
      return {
        ...state,
        submitting: false,
        submit_success: true,
        submit_fail: false,
        data: {
          ...state.data,
          total: {
            ...state.data.total,
            value: action.payload.results.totalKG,
            sgAverage: action.payload.results.sgAverageKG,
            sustainableAverage: action.payload.results.sustainableAverageKG,
            groups: action.payload.results.groups.edges,
            pastResults: action?.payload?.results?.pastResults?.edges || [],
            target: action?.payload?.results?.target || 0
          },
          trees: action.payload.results.trees,
        },
        fields: {
          ...state.fields,
          ...action.payload.fields
        },
      }
    }

    case Co2eConstants.SUBMIT_FAIL: {
      return {
        ...state,
        submitting: false,
        submit_success: false,
        submit_fail: true,
        errors: action.payload
      }
    }

    case Co2eConstants.GET_RESULT: {
      return {
        ...state,
        fetching_result: true,
        fetch_result_success: false,
        fetch_result_fail: false,
      }
    }

    case Co2eConstants.GET_RESULT_SUCCESS: {
      return {
        ...state,
        fetching_result: false,
        fetch_result_success: true,
        fetch_result_fail: false,
        data: {
          ...state.data,
          total: {
            ...state.data.total,
            value: action.payload.results.totalKG,
            sgAverage: action.payload.results.sgAverageKG,
            sustainableAverage: action.payload.results.sustainableAverageKG,
            groups: action.payload.results.groups.edges,
            pastResults: action?.payload?.results?.pastResults?.edges || [],
            target: action?.payload?.results?.target || 0
          },
          trackingType: action.payload.results.trackingType,
          trees: action.payload.results.trees,
        }
      }
    }

    case Co2eConstants.GET_RESULT_FAIL: {
      return {
        ...state,
        fetching_result: false,
        fetch_result_success: false,
        fetch_result_fail: true,
        errors: action.payload
      }
    }

    case Co2eConstants.SUBMIT_TARGET: {
      return {
        ...state,
        submitting: true,
        submit_success: false,
        submit_fail: false,
      }
    }

    case Co2eConstants.SUBMIT_TARGET_SUCCESS: {
      return {
        ...state,
        submitting: false,
        submit_success: true,
        submit_fail: false,
        data: {
          ...state.data,
          total: {
            ...state.data.total,
            target: action?.payload?.target || 0
          },
        },
        fields: {
          ...state.fields,
          ...action.payload.fields
        },
      }
    }

    case Co2eConstants.SUBMIT_TARGET_FAIL: {
      return {
        ...state,
        submitting: false,
        submit_success: false,
        submit_fail: true,
        errors: action.payload
      }
    }

    case Co2eConstants.GUESS_RAINTREES_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          total: {
            ...state.data.total
          },
          guessRaintrees: action.payload["guess_raintrees"]
        },
        fields: {
          ...state.fields,
          ...action.payload.fields
        },
      }
    }

    case Co2eConstants.SAVE_PAST_RESULT_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          total: {
            ...state.data.total,
            value: action.payload.results.totalKG,
            sgAverage: action.payload.results.sgAverageKG,
            sustainableAverage: action.payload.results.sustainableAverage,
            groups: action.payload.results.groups.edges,
            pastResults: action.payload.results.pastResults.edges,
            target: action.payload.results.target
          },
          trees: action.payload.results.trees,
        },
        fields: {
          ...state.fields,
          ...action.payload.fields
        },
      }
    }

    case Co2eConstants.SAVE_PAST_RESULT_FAIL: {
      return {
        ...state,
        fetching_result: false,
        fetch_result_success: false,
        fetch_result_fail: true,
        errors: action.payload.error
      }
    }

    case Co2eConstants.CONTINUE: {
      return {
        ...state,
        fetching_qnnre: false,
        fetch_qnnre_success: false,
        fetch_qnnre_fail: false,
        calculating: false,
        calculate_success: false,
        calculate_fail: false,
        submitting: false,
        submit_success: false,
        submit_fail: false,
        errors: null
      }
    }

    case Co2eConstants.RESET: {
      return {
        ...initialState,
        data: {
          threshold: co2eThreshold,
          total: {
            value: 0,
            unit: 'kg',
            sgAverage: 0,
            sustainableAverage: 0,
            groups: [],
            pastResults: [],
            target: 0
          },
          trackingType: "",
          trees: 0,
          guessRaintrees: 0
        },
        fields: null,
        answers: [],
        errors: null,
      }
    }

    default:
      return {
        ...state
      }
  }
}
