import React from "react"

const OtherTrees = () => {
  return (
    <svg
      className="gfx-othertrees"
      width="904"
      height="174"
      viewBox="0 0 904 174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8445 43C13.8307 43.2361 -2.58319 98.2275 0.347125 119.942C1.62118 129.314 6.28438 137.166 18.6507 138.256C19.4908 140.515 20.0512 143.284 20.0512 146.704C20.0512 160.98 20.7738 169.228 20.7738 169.228C20.7738 169.228 14.384 171.14 14.5007 171.886C14.5381 172.136 17.6608 171.917 19.1746 171.782C21.6549 171.561 23.6516 171.624 25.1742 171.688C27.2029 171.772 28.3887 171.855 28.7495 171.264C29.3919 170.208 24.0703 170.364 23.8591 169.087C22.9718 163.724 22.0496 144.731 24.048 138.331C24.0489 138.327 24.0498 138.325 24.0507 138.322C44.0221 136.936 45.4271 116.733 43.2318 103.24C43.1142 102.509 42.9735 101.734 42.8211 100.94C42.7766 100.703 42.7267 100.459 42.6786 100.216C42.5592 99.6159 42.4291 99.0007 42.2919 98.3714C42.2393 98.1292 42.1894 97.8923 42.1342 97.6465C41.9435 96.8014 41.7413 95.9369 41.5239 95.0479C41.4883 94.9092 41.4508 94.7644 41.4161 94.6249C41.221 93.8403 41.0161 93.0408 40.8022 92.229C40.7719 92.1158 40.747 92.0087 40.7167 91.8964C40.7158 91.8981 40.7158 91.9008 40.7149 91.9025C35.2561 71.3593 23.983 43.0018 19.8757 43C19.865 43 19.8552 43 19.8445 43Z"
        fill="url(#qnnre-othertrees1_paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M813.846 43.0002C807.832 43.2363 791.418 98.2277 794.348 119.943C795.622 129.315 800.286 137.166 812.652 138.257C813.492 140.515 814.052 143.285 814.052 146.704C814.052 160.98 814.775 169.228 814.775 169.228C814.775 169.228 808.385 171.14 808.502 171.886C808.539 172.136 811.662 171.917 813.176 171.783C815.656 171.561 817.653 171.625 819.175 171.688C821.204 171.772 822.39 171.855 822.751 171.265C823.393 170.208 818.072 170.364 817.86 169.087C816.973 163.724 816.051 144.731 818.049 138.331C818.05 138.327 818.051 138.325 818.052 138.323C838.023 136.936 839.428 116.733 837.233 103.241C837.115 102.509 836.975 101.734 836.822 100.94C836.778 100.703 836.728 100.46 836.68 100.216C836.56 99.6161 836.43 99.0009 836.293 98.3717C836.241 98.1295 836.191 97.8925 836.135 97.6468C835.945 96.8016 835.742 95.9372 835.525 95.0481C835.489 94.9095 835.452 94.7647 835.417 94.6251C835.222 93.8405 835.017 93.041 834.803 92.2292C834.773 92.116 834.748 92.009 834.718 91.8966C834.717 91.8984 834.717 91.901 834.716 91.9028C829.257 71.3595 817.984 43.002 813.877 43.0002C813.866 43.0002 813.856 43.0002 813.846 43.0002Z"
        fill="url(#qnnre-othertrees2_paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.709 0.000887023C70.9176 0.318441 49.6545 74.4931 53.4494 103.783C55.1022 116.424 61.1414 127.015 77.1618 128.486C78.2498 131.533 78.9763 135.267 78.9763 139.878C78.9763 159.135 79.9112 170.261 79.9112 170.261C79.9112 170.261 71.6346 172.841 71.7859 173.846C71.8335 174.184 75.8801 173.887 77.8398 173.707C81.0536 173.407 83.6412 173.493 85.6131 173.578C88.2405 173.692 89.7756 173.805 90.2426 173.008C91.0755 171.582 84.1826 171.794 83.9085 170.072C82.7591 162.837 81.5639 137.219 84.1532 128.586C84.1541 128.581 84.1558 128.578 84.1576 128.573C110.029 126.705 111.849 99.4548 109.005 81.2549C108.853 80.2667 108.67 79.2227 108.474 78.1529C108.414 77.831 108.351 77.5036 108.289 77.1763C108.133 76.3656 107.965 75.5362 107.788 74.6865C107.719 74.3609 107.654 74.0416 107.582 73.7116C107.336 72.5691 107.074 71.4036 106.791 70.2043C106.747 70.0163 106.698 69.8229 106.653 69.634C106.401 68.5749 106.134 67.4962 105.857 66.4008C105.819 66.2491 105.786 66.1045 105.747 65.9519C105.746 65.9555 105.745 65.959 105.745 65.9626C98.6731 38.252 84.0693 0.00354809 78.7488 0C78.735 0 78.722 0 78.709 0.000887023Z"
        fill="url(#qnnre-othertrees3_paint2_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M872.709 0.000887023C864.917 0.318441 843.654 74.4931 847.449 103.783C849.102 116.424 855.141 127.015 871.161 128.486C872.249 131.533 872.976 135.267 872.976 139.878C872.976 159.135 873.911 170.26 873.911 170.26C873.911 170.26 865.634 172.841 865.785 173.846C865.833 174.184 869.88 173.887 871.839 173.707C875.053 173.407 877.641 173.493 879.613 173.578C882.24 173.692 883.775 173.805 884.242 173.008C885.075 171.582 878.182 171.794 877.908 170.072C876.759 162.837 875.563 137.219 878.153 128.586C878.154 128.581 878.155 128.578 878.157 128.573C904.028 126.705 905.849 99.4548 903.004 81.2548C902.853 80.2667 902.669 79.2227 902.473 78.1529C902.413 77.8309 902.35 77.5036 902.288 77.1763C902.132 76.3656 901.965 75.5362 901.787 74.6864C901.719 74.3609 901.653 74.0416 901.581 73.7116C901.336 72.5691 901.074 71.4036 900.791 70.2043C900.747 70.0163 900.698 69.8229 900.653 69.634C900.4 68.5749 900.134 67.4962 899.857 66.4008C899.818 66.2491 899.785 66.1045 899.746 65.9519C899.745 65.9555 899.744 65.959 899.744 65.9626C892.673 38.252 878.069 0.00354809 872.748 0C872.734 0 872.721 0 872.709 0.000887023Z"
        fill="url(#qnnre-othertrees4_paint3_linear)"
      />
      <defs>
        <linearGradient
          id="qnnre-othertrees1_paint0_linear"
          x1="0"
          y1="43"
          x2="0"
          y2="172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4EBF2" />
          <stop offset="1" stopColor="#F8FCFF" />
        </linearGradient>
        <linearGradient
          id="qnnre-othertrees2_paint1_linear"
          x1="794.001"
          y1="43.0002"
          x2="794.001"
          y2="172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4EBF2" />
          <stop offset="1" stopColor="#F8FCFF" />
        </linearGradient>
        <linearGradient
          id="qnnre-othertrees3_paint2_linear"
          x1="53"
          y1="0"
          x2="53"
          y2="174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4EBF2" />
          <stop offset="1" stopColor="#F8FCFF" />
        </linearGradient>
        <linearGradient
          id="qnnre-othertrees4_paint3_linear"
          x1="847"
          y1="0"
          x2="847"
          y2="174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E4EBF2" />
          <stop offset="1" stopColor="#F8FCFF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default OtherTrees
