import React from 'react'
import { Button } from 'spd-gds/components/common'

const TermsBox = ({
  content,
  onAccept,
  onReject
}) => {
  return (
    <>
      <div className='app-termsbox'>
        <div className='app-termsbox__content'>
          {content.map((c, key) => (
            <div key={`termscontent--${key}`}>
              {c}
            </div>
          ))}
        </div>
      </div>
      <div className='app-cta'>
        <Button
          variant='secondary'
          className='app-button'
          onClickHandler={onReject}
          type='button'
        >
          {`Reject`}
        </Button>
        <Button
          className='app-button'
          onClickHandler={onAccept}
          type='button'
        >
          {`Accept`}
        </Button>
      </div>
    </>
  )
}

TermsBox.defaultProps = {
  content: [],
  onAccept: () => {},
  onReject: () => {}
}

export default TermsBox
