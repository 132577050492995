import React from 'react'
const shoppingList = [
  {
    id: 1,
    type: "Recreational and cultural activities",
    stuffs: `Movies, media subscriptions, museums, other entertainment and gym memberships`
  },
  {
    id: 2,
    type: "Furnishing and household maintenance",
    stuffs: `Furniture, air-con maintenance, household equipment`
  },
  {
    id: 3,
    type: "Health product and services",
    stuffs: `Medication, medical appointments (including for pets)`
  },
  {
    id: 4,
    type: "Communication services",
    stuffs: `Phone, internet, postmail, etc.`
  },
  {
    id: 5,
    type: "Educational services",
    stuffs: `Kindergarten to post graduate education, tuition, sports class, art class and driving class`
  },
  {
    id: 6,
    type: "Other purchased goods and services",
    stuffs: `Laundry, cleaning, hairdressing, insurance, expenditure on alcoholic beverages, and tobacco`
  },

]
const ShoppingList = () => {

  const styles = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  }

  return shoppingList.map((shopping, idx, arr) => {
    return (
      <div key={shopping.id} style={{ ...styles, marginBottom: shopping.id === arr.length ? 0 : "1.5rem" }}>
        <b style={{ marginBottom: "0.25rem", fontSize: "1.025rem" }}>{shopping.type}</b>
        <p>{shopping.stuffs}</p>
      </div>
    )
  })
}

export default ShoppingList
