const co2eConstants = {
  questionnaire: {
    ENERGY: 'ENERGY',
    RECYCLE: 'WASTE',
    FOOD: 'FOOD',
    SPENDING: 'SHOPPING',
    COMMUTE: 'DAILY_TRAVELS',
    TRAVEL: 'HOLIDAY_TRAVELS',
    HOME_MOBILE: 'HOME_MOBILE',
  },

  FETCH_QUESTIONNAIRE: 'CO2E_FETCH_QUESTIONNAIRE',
  FETCH_QUESTIONNAIRE_SUCCESS: 'CO2E_FETCH_QUESTIONNAIRE_SUCCESS',
  FETCH_QUESTIONNAIRE_FAIL: 'CO2E_FETCH_QUESTIONNAIRE_FAIL',

  CALCULATE: 'CO2E_CALCULATE',
  CALCULATE_SUCCESS: 'CO2E_CALCULATE_SUCCESS',
  CALCULATE_FAIL: 'CO2E_CALCULATE_FAIL',

  SUBMIT: 'CO2E_SUBMIT',
  SUBMIT_SUCCESS: 'CO2E_SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'CO2E_SUBMIT_FAIL',

  GET_RESULT: 'CO2E_GET_RESULT',
  GET_RESULT_SUCCESS: 'CO2E_GET_RESULT_SUCCESS',
  GET_RESULT_FAIL: 'CO2E_GET_RESULT_FAIL',

  SUBMIT_TARGET: 'CO2E_SUBMIT_TARGET',
  SUBMIT_TARGET_SUCCESS: 'CO2E_SUBMIT_TARGET_SUCCESS',
  SUBMIT_TARGET_FAIL: 'CO2E_SUBMIT_TARGET_FAIL',

  GUESS_RAINTREES_SUCCESS: "CO2E_GUESS_RAINTREES_SUCCESS",
  SAVE_PAST_RESULT_SUCCESS: "CO2E_SAVE_PAST_RESULT_SUCCESS",
  SAVE_PAST_RESULT_FAIL: "CO2E_SAVE_PAST_RESULT_FAIL",

  CONTINUE: 'CO2E_CONTINUE',
  RESET: 'CO2E_RESET',

  ERR_MSG_NOT_AUTHORIZE: 'You are not authorized for this request'
}
export default co2eConstants
