import React from 'react'
import classnames from 'classnames'

const ToggleButtons = ({ label, buttons, handler, active  }) => {

  const handleBtnClick = btn => e => {
    const { id, handler: btnHandler } = btn
    if (handler) {
      handler(id)
    } else {
      btnHandler(id)
    }
  }

  const renderBtns = () => {
    return buttons.map((btn, key) => {
      const _btn = classnames('app-button',{
        active: btn.id === active
      })
      return (
        <button 
          key={`tglbtns-btn--${key}`}
          id={btn.id}
          className={_btn}
          onClick={handleBtnClick(btn)}
        >
          {btn.label}
        </button>
      )
    })
  }

  return (
    <div className='toggle-btns flex col'>
      {
        label && (
          <h3>{label}</h3>
        )
      }
      <div className='btns-list flex row'>
        {renderBtns()}
      </div>
    </div>
  )
}

ToggleButtons.defaultProps = {
  handler: () => {},
  buttons: []
}

export default ToggleButtons
