import React from 'react' 

const StumpBranches = () => {
  return (
    <svg className='gfx-stumpbranches' width="285" height="96" viewBox="0 0 285 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Branches">
        <g id="Stump1" clipPath="url(#gfx-stumpbranches-clip0)">
          <path id="Oval Copy 6" fillRule="evenodd" clipRule="evenodd" d="M151 96.0001C181.376 96.0001 206 93.2421 206 89.8401C206 86.438 181.376 83.6801 151 83.6801C120.625 83.6801 96.0004 86.438 96.0004 89.8401C96.0004 93.2421 120.625 96.0001 151 96.0001Z" fill="url(#gfx-stumpbranches-paint0_linear)"/>
          <path id="Fill 245" fillRule="evenodd" clipRule="evenodd" d="M104.871 63.4518L105.541 87.2001L120.017 86.8332L126.162 86.5387H131.562L166.374 85.107L191.807 83.6763L184.71 60.8L104.871 63.4518Z" fill="#4A4A7C"/>
          <path id="Fill 246" fillRule="evenodd" clipRule="evenodd" d="M180.426 58.5887L131.798 59.9944L116.459 60.439L113.573 52L109.057 60.6519C106.367 61.3257 104.853 62.0402 104.872 62.75C104.916 64.5328 121.362 67.5036 138.825 68.7092L143.187 62.5522L144.231 69.0257C148.802 69.2386 153.335 69.3108 157.555 69.1886C160.542 69.1025 163.211 68.921 165.608 68.6684L164.529 64.0161C164.529 64.0161 168.698 65.7278 173.086 67.4885C182.21 65.4497 184.752 62.1484 184.71 60.4401C184.691 59.7303 183.144 59.1042 180.426 58.5887Z" fill="#FFDEB3"/>
          <path id="Fill 247" fillRule="evenodd" clipRule="evenodd" d="M173.757 76.64L155.483 84.6282L143.904 96L173.22 87.4541L200.678 87.5626L192.493 80.8495L173.757 76.64Z" fill="#FFDEB3"/>
        </g>
        <g id="Stump2" clipPath="url(#gfx-stumpbranches-clip1)">
          <path id="Vector" d="M36.0004 59C55.8826 59 72.0004 55.3735 72.0004 50.9C72.0004 46.4265 55.8826 42.8 36.0004 42.8C16.1181 42.8 0.000366211 46.4265 0.000366211 50.9C0.000366211 55.3735 16.1181 59 36.0004 59Z" fill="url(#gfx-stumpbranches-paint1_linear)"/>
          <path id="Union" d="M33.0356 38.1044L46.9753 35.6001L67.1481 45.3047L69.3004 47.3988H46.9753L34.8721 51.8436L24.7863 50.0955L15.1051 53.6001L9.90036 52.5942L17.567 45.3291L19.8004 43.7001L20.7004 21.2001H27.3826L33.3004 28.671L33.0356 38.1044Z" fill="#4A4A7C"/>
          <path id="Fill 250" fillRule="evenodd" clipRule="evenodd" d="M26.1954 50H44.2699L47.7004 43.3989V14L42.2907 19.2707L31.736 21.1495L28.7024 26.7134L25.9321 24.5871L22.5004 38.9445L26.1954 50Z" fill="#FFDEB3"/>
          <path id="Fill 251" fillRule="evenodd" clipRule="evenodd" d="M42.3004 50.0001V21.2001C42.3004 21.2001 45.7804 30.7864 45.8976 35.8561C46.0159 40.9258 42.3004 50.0001 42.3004 50.0001Z" fill="#C1904F"/>
        </g>
        <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M211.523 21.1407L220.911 23.0852L227.985 28.9358H236.873L236.685 27.5L227.011 1L229.745 2.47131L235.519 15.567L239.651 10.2001L240.281 12.1984L237.63 20.3543L241.414 28.9358H257.953L268.886 17.8771L268.98 22.5468L264.906 28.9358H276.159C280.223 28.9358 283.602 31.9915 284.134 35.9168H194.508C195.039 31.9915 198.419 28.9358 202.486 28.9358H221.374L218.177 26.2387L197.865 21.3965L190.644 24.6082V23.452L193.495 20.3567L182 17.6173L185.367 15.7243L207.537 20.315L206.786 18.5374L201.266 15.7243V14.0916L207.104 14.6175C207.104 14.6175 210.73 2.10265 210.625 1.57781C210.52 1.05078 212.307 0 212.307 0L208.944 14.9338L211.523 21.1407Z" fill="#E4EBF2"/>
      </g>
      <defs>
        <linearGradient id="gfx-stumpbranches-paint0_linear" x1="207.553" y1="88.0901" x2="204.382" y2="78.0109" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F8FCFF"/>
          <stop offset="1" stopColor="#E4EBF2"/>
        </linearGradient>
        <linearGradient id="gfx-stumpbranches-paint1_linear" x1="73.0164" y1="48.5989" x2="52.4861" y2="20.521" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F8FCFF"/>
          <stop offset="1" stopColor="#E4EBF2"/>
        </linearGradient>
        <clipPath id="gfx-stumpbranches-clip0">
          <rect width="110" height="44" fill="white" transform="translate(96.0004 52)"/>
        </clipPath>
        <clipPath id="gfx-stumpbranches-clip1">
          <rect width="72" height="45" fill="white" transform="translate(0.000366211 14)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default StumpBranches
