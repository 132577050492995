import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Icons, Modal } from 'spd-gds/components/common'

import './styles.scss'

class Tooltip extends PureComponent {
  state = {
    show: false
  }
  render() {
    const { label, withIcon, arrow, children, ...rest } = this.props
    const { show } = this.state
    return (
      <Fragment>
        <div className='cui-tooltip' {...rest}>
          <button
            className='cui-tooltip_target'
            ref={el => {
              this._target = el
            }}
            onClick={this._tooltipOpenHandler}
            type='button'
          >
            {withIcon && <Icons.Info width={14} height={14} />}
            <span>{label}</span>
          </button>
        </div>
        {show && (
          <Modal
            visible
            target={this._target}
            className={`cui-tooltip_pop`}
            onClose={this._tooltipCloseHandler}
            onEscapeKeyDown={this._tooltipOnEscKeyDownHandler}
            withBackdrop={false}
            arrow={arrow}
          >
            {children}
          </Modal>
        )}
      </Fragment>
    )
  }
  _tooltipCloseHandler = () => {
    const { onPopClose } = this.props
    this.setState(
      prevState => ({
        ...prevState,
        show: false
      }),
      () => {
        if (onPopClose) {
          onPopClose()
        }
      }
    )
  }

  _tooltipOpenHandler = () => {
    const { onPopOpen } = this.props
    this.setState(
      prevState => ({
        ...prevState,
        show: true
      }),
      () => {
        if (onPopOpen) {
          onPopOpen()
        }
      }
    )
  }

  _tooltipOnEscKeyDownHandler = () => {
    this._tooltipCloseHandler()
  }
}

Tooltip.propTypes = {
  label: PropTypes.string,
  withIcon: PropTypes.bool
}

Tooltip.defaultProps = {
  withIcon: true
}

export default Tooltip
