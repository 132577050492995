import React from 'react'

const SaveResultIcon = () => {
  return (
    <svg width="96" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1877_9736)">
        <circle cx="48.333" cy="48" r="48" fill="#FAFDFF" />
        <path fillRule="evenodd" clipRule="evenodd" d="M34.5255 22.5515C34.694 21.4929 35.6878 20.7705 36.7468 20.9367L74.1892 26.8152C75.2515 26.982 75.9766 27.9795 75.8075 29.0414L67.2812 82.5871C67.1126 83.6457 66.1189 84.3681 65.0599 84.2019L27.6175 78.3234C26.5552 78.1566 25.8301 77.1591 25.9992 76.0972L34.5255 22.5515Z" fill="#3277DE" />
        <path d="M22.833 19.1559C22.833 18.0823 23.7034 17.2119 24.777 17.2119H65.844C66.9176 17.2119 67.788 18.0823 67.788 19.1559V74.7973C67.788 75.871 66.9177 76.7413 65.844 76.7413H24.777C23.7034 76.7413 22.833 75.871 22.833 74.7973V19.1559Z" fill="white" />
        <path d="M28.9082 36.5718C28.9082 35.9079 29.4463 35.3698 30.1101 35.3698H47.1463C47.8101 35.3698 48.3482 35.9079 48.3482 36.5718C48.3482 37.2356 47.8101 37.7737 47.1463 37.7737H30.1101C29.4463 37.7737 28.9082 37.2356 28.9082 36.5718Z" fill="#E7ECEF" />
        <path d="M34.4434 43.3365C34.4434 42.6726 34.9815 42.1345 35.6453 42.1345H54.8414C55.5052 42.1345 56.0434 42.6726 56.0434 43.3365C56.0434 44.0003 55.5052 44.5384 54.8414 44.5384H35.6453C34.9815 44.5384 34.4434 44.0003 34.4434 43.3365Z" fill="#2761D9" />
        <path d="M28.9082 52.8783C28.9082 52.2144 29.4463 51.6763 30.1101 51.6763H61.7263C62.3901 51.6763 62.9282 52.2144 62.9282 52.8783C62.9282 53.5421 62.3901 54.0802 61.7263 54.0802H30.1101C29.4463 54.0802 28.9082 53.5421 28.9082 52.8783Z" fill="#E7ECEF" />
        <path d="M28.9082 59.643C28.9082 58.9791 29.4463 58.441 30.1101 58.441H61.7263C62.3901 58.441 62.9282 58.9791 62.9282 59.643C62.9282 60.3068 62.3901 60.8449 61.7263 60.8449H30.1101C29.4463 60.8449 28.9082 60.3068 28.9082 59.643Z" fill="#E7ECEF" />
        <path d="M28.9082 66.4077C28.9082 65.7438 29.4463 65.2057 30.1101 65.2057H61.7263C62.3901 65.2057 62.9282 65.7438 62.9282 66.4077C62.9282 67.0715 62.3901 67.6096 61.7263 67.6096H30.1101C29.4463 67.6096 28.9082 67.0715 28.9082 66.4077Z" fill="#E7ECEF" />
        <path d="M27.6934 29.807C27.6934 29.1432 28.2315 28.6051 28.8953 28.6051H36.2114C36.8752 28.6051 37.4134 29.1432 37.4134 29.807C37.4134 30.4709 36.8752 31.009 36.2114 31.009H28.8953C28.2315 31.009 27.6934 30.4709 27.6934 29.807Z" fill="#00B0B2" />
      </g>
      <defs>
        <clipPath id="clip0_1877_9736">
          <path d="M0.333008 48C0.333008 21.4903 21.8233 0 48.333 0C74.8427 0 96.333 21.4903 96.333 48C96.333 74.5097 74.8427 96 48.333 96C21.8233 96 0.333008 74.5097 0.333008 48Z" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default SaveResultIcon
