import React from 'react'
import OtpInput from 'react-otp-input'
import classnames from 'classnames'

import FormErrorBlock from './FormErrorBlock'

const FormOtpInput = ({
  numberOfInputs,
  isInline,
  error,
  note,
  label,
  preOtp,
  autoFocus,
  handleChange: propHandleChange,
  setFieldValue,
  ...props
}) => {

  const _OtpInput = classnames('lm--formItem form-item field-otp', {
    'lm--formItem--inline': isInline,
    'is-error': error,
    'with-note': note
  })

  const handleChange = (val) => {
    if (setFieldValue) {
      setFieldValue(props.id, val)
      return
    }
    propHandleChange(val)
  }

  return (
    <div id={`${props.id}-form-item`} className={_OtpInput}>
      {
        label && label !== ''
        && (
          <div className='lm--formItem-label label'>{label}</div>
        )}
      <div className='lm--formItem-control control'>
        <div className='control-container'>
          {
            preOtp && (
              <span>
                {`${preOtp} -`}
              </span>
            )
          }
          <OtpInput
            numInputs={numberOfInputs}
            inputStyle='field-otp__input'
            containerStyle='field-otp__cont'
            onChange={handleChange}
            shouldAutoFocus={autoFocus}
            {...props}
          />
        </div>
        <FormErrorBlock id={`${props.id}-field_error`} error={error} />
        {
          note && (
            <span className='field-note'>
              {note}
            </span>
          )
        }
      </div>
    </div>
  )
}

FormOtpInput.defaultProps = {
  isInline: true,
  numberOfInputs: 4,
  id: '',
  autoFocus: false
}

export default FormOtpInput
