import React from 'react'

import Modal from './Modal'
import Frame from './Frame'

const PopSite = ({
  source,
  pageNumber,
  numPages,
  onCloseHandler,
  ...props
}) => {
  const handleClose = e => {
    onCloseHandler(e)
  }

  return (
    <Modal
      className="popsite"
      visible={true}
      onClose={handleClose}
      closeBtn
      {...props}
    >
      <Frame source={source} />
    </Modal>
  )
}

PopSite.defaultProps = {
  onCloseHandler: () => {},
}

export default PopSite
