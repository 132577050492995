import React, { useState, useEffect } from 'react'
import { Document, Page } from 'react-pdf'
import Viewer from 'react-pinch-and-zoom'

import Modal from './Modal'
import Icons from './Icons'

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
}

const DocViewer = ({ doc, pageNumber, numPages, onCloseHandler, onLoadHandler, ...props }) => {
  const [docLoad, setDocLoad] = useState(false)
  const [docLoaded, setDocLoaded] = useState(false)
  function handleLoadSuccess(res) {
    onLoadHandler(res)
    setDocLoaded(true)
  }
  const handleClose = (e) => {
    onCloseHandler(e)
  }

  useEffect(() => {
    let loadTimeout = setTimeout(() => {
      setDocLoad(true)
    }, 1000)
    return () => {
      clearTimeout(loadTimeout)
      loadTimeout = null
    }
  }, [])

  return (
    <Modal
      className='docviewer'
      visible={true}
      onClose={handleClose}
      closeBtn
    >
      {
        !docLoaded && (
          <div className='docviewer-loader'>
            <Icons.SpinLoader width={30} height={30} color={['#4f5a60']} shouldRotate={false} />
            <span>
              Loading document..
            </span>
          </div>
        )
      }
      <Viewer>
        {
          docLoad && (
            <Document
              file={doc}
              options={options}
              onLoadSuccess={handleLoadSuccess}
              {...props}
            >
              {
                Array.from(
                  new Array(numPages),
                  (p, idx) => (
                    <Page
                      key={`docview_page-${idx}`}
                      pageNumber={idx + 1}
                      loading={<span></span>}
                    />
                  )
                )
              }
            </Document>
          )
        }
      </Viewer>
    </Modal>
  )
}

DocViewer.defaultProps = {
  pageNumber: 1,
  numPages: 1,
  onLoadHandler: () => { },
  onCloseHandler: () => { }
}

export default DocViewer
