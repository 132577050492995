import React, { PureComponent } from 'react'

import Utils from 'spd-gds/utils'
import { Tooltip } from 'spd-gds/components/common'
import FormErrorBlock from './FormErrorBlock'

class FormSelectOption extends PureComponent {
  state = {
    error: null,
  }
  render() {
    const {
      label,
      id,
      name,
      note,
      info,
      error,
      value,
      handleChange,
      handleBlur,
      children,
      options,
      ...props
    } = this.props
    // const { error: stateError } = this.state
    let val = value
    // if (typeof value === 'string') {
    //   val = val.toLowerCase()
    // }
    return (
      <div
        className={
          'lm--formItem lm--formItem--inline field-select' +
          (error ? ' is-error' : '')
        }
      >
        <div className="lm--formItem-label label">
          {label}
          {info && (
            <Tooltip
              style={{
                marginLeft: '0.5rem',
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: Utils.parseStrTemplate(info.text, info.params),
                }}
              />
            </Tooltip>
          )}
        </div>
        <div className="lm--formItem-control control">
          <select
            className="lm--select"
            id={id}
            name={name}
            value={val}
            onChange={handleChange}
            onBlur={this._blurHandler}
            disabled={props.disabled}
          >
            {options.map(option => {
              let op_v = option.value
              if (typeof option.value === 'string') {
                op_v = op_v.toLowerCase()
              }
              return (
                <option
                  disabled={option.disabled}
                  value={option.id || op_v}
                  key={option.value}
                >
                  {option.text || option.value}
                </option>
              )
            })}
          </select>
          {note && <span className="field-note">{note}</span>}
          {/* {stateError && <FormErrorBlock id={`${id}-field_error`} error={stateError} />} */}
          <FormErrorBlock id={`${id}-field_error`} error={error} />
        </div>
      </div>
    )
  }

  _blurHandler = e => {
    const { handleBlur, error } = this.props

    if (handleBlur) {
      handleBlur(e)
    }
    if (error) {
      this.setState(prevState => ({
        ...prevState,
        error,
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        error: null,
      }))
    }
  }
}

export default FormSelectOption
