import React, { PureComponent } from 'react'

import isEqual from 'lodash.isequal'
import classnames from 'classnames'

import Utils from '../../utils'
import FormErrorBlock from './FormErrorBlock'

const { trailWithZero } = Utils

class FormInputSplit extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (!isEqual(props.value, state.value)) {
      let modified1 = state.modified.value1
      let modified2 = state.modified.value2
      if (props.value[0] !== state.value[0]) {
        let v1 = state.tempValue[0] !== props.value[0]
        if (v1) {
          modified1 = true
        } else {
          modified1 = false
        }
      }

      if (props.value[1] !== state.value[1]) {
        let v2 = state.tempValue[1] !== props.value[1]
        if (v2) {
          modified2 = true
        } else {
          modified2 = false
        }
      }

      return {
        value: props.value,
        modified: {
          value1: modified1,
          value2: modified2,
        },
      }
    }
    if (props.error && props.error.length > 0) {
      return {
        errorInput1: props.error[0] || false,
        errorInput2: props.error[1] || false,
      }
    }
    return null
  }

  state = {
    errorInput1: false,
    errorInput2: false,
    value: this.props.value,
    tempValue: this.props.value,
    modified: {
      value1: false,
      value2: false,
    },
  }

  render() {
    const {
      id,
      name,
      label,
      value,
      valuePrefix,
      error,
      handleChange,
      handleBlur,
      placeholder,
      prefixMaxLength,
      setFieldValue,
      trailZero,
      capsOnChange,
      params,
      prefix,
      separator,
      showModified,
      onlyNumber,
      ...props
    } = this.props
    const { value: stateValue, errorInput1, errorInput2, modified } = this.state

    const _FormSplitInput = classnames(
      'lm--formItem lm--formItem--inline field-split split',
      {
        'is-modified-1': showModified && modified.value1,
        'is-modified-2': showModified && modified.value2,
        'is-error': errorInput1 || errorInput2,
      }
    )

    return (
      <div className={_FormSplitInput}>
        <div className="lm--formItem-label label">{label}</div>
        <div
          className={
            'lm--formItem-control control' + (separator ? ' prices' : '')
          }
        >
          <div className="control-wrap">
            {prefix.value && (
              <div className="lm--inputGroup">
                <div className="lm--inputGroup-prefix">
                  <input
                    id={prefix.id}
                    type="text"
                    value={prefix.value}
                    onChange={prefix.handleChange}
                    disabled={prefix.disabled}
                    required
                  />
                </div>
              </div>
            )}
            {separator && (
              <>
                <input
                  id={id[0]}
                  className="split-input first"
                  type="text"
                  value={stateValue[0]}
                  onChange={this._inputFirstChangeHandler}
                  placeholder={placeholder[0]}
                  onBlur={this._blurHandler}
                  {...props}
                />
                <span>{separator}</span>
                <input
                  id={id[1]}
                  className="split-input second"
                  type="text"
                  value={stateValue[1]}
                  onChange={this._inputSecondChangeHandler}
                  placeholder={placeholder[1]}
                  onBlur={this._blurHandler}
                  {...props}
                />
              </>
            )}
            {!separator && (
              <>
                <input
                  id={id[0]}
                  className="split-input first"
                  type="text"
                  value={stateValue[0]}
                  onChange={this._inputFirstChangeHandler}
                  placeholder={placeholder[0]}
                  onBlur={this._blurHandler}
                  {...props}
                />
                <input
                  id={id[1]}
                  className="split-input second"
                  type="text"
                  value={stateValue[1]}
                  onChange={this._inputSecondChangeHandler}
                  placeholder={placeholder[1]}
                  onBlur={this._blurHandler}
                  {...props}
                />
              </>
            )}
          </div>
          <div className="error-wrap">
            <FormErrorBlock id={`${id[0]}-field_error`} error={errorInput1} />
            <FormErrorBlock id={`${id[1]}-field_error`} error={errorInput2} />
          </div>
        </div>
      </div>
    )
  }
  _inputFirstChangeHandler = e => {
    const {
      handleChange,
      setFieldValue,
      onlyNumber,
      capsOnChange,
      params,
      id,
    } = this.props
    if (setFieldValue) {
      let v = e.target.value
      if (onlyNumber) {
        let re = /[a-zA-Z!@#$%^&*,=~`;:{}|"'<>?[\]/\\ ]/g
        if (onlyNumber.decimal) {
          let decimal = onlyNumber.decimal
          let decimalRegex = new RegExp(`\\d+(\\.\\d{0,${decimal}})?`)
          if (v) {
            v = v.match(decimalRegex)[0]
          }
        }
        if (onlyNumber.integer) {
          re = /[a-zA-Z!@#$%^&*,=~`;:{}|"'<>?[\]/\\ .]/g
        }
        v = v.replace(re, '')
      }
      if (capsOnChange) {
        if (typeof v === 'string') {
          v = v.toUpperCase()
        }
      }
      setFieldValue(id[0], v)
      handleChange(id[0], v, params.type)
    } else {
      handleChange(e, params.type)
    }
  }

  _inputSecondChangeHandler = e => {
    const {
      handleChange,
      setFieldValue,
      onlyNumber,
      capsOnChange,
      params,
      id,
    } = this.props
    if (setFieldValue) {
      let v = e.target.value
      if (onlyNumber) {
        let re = /[a-zA-Z!@#$%^&*,=~`;:{}|"'<>?[\]/\\ ]/g
        if (onlyNumber.decimal) {
          let decimal = onlyNumber.decimal
          let decimalRegex = new RegExp(`\\d+(\\.\\d{0,${decimal}})?`)
          if (v) {
            v = v.match(decimalRegex)[0]
          }
        }
        if (onlyNumber.integer) {
          re = /[a-zA-Z!@#$%^&*,=~`;:{}|"'<>?[\]/\\ .]/g
        }
        v = v.replace(re, '')
      }
      if (capsOnChange) {
        if (typeof v === 'string') {
          v = v.toUpperCase()
        }
      }
      setFieldValue(id[1], v)
      handleChange(id[1], v, params.type)
    } else {
      handleChange(e, params.type)
    }
  }

  _blurHandler = e => {
    const {
      handleBlur,
      error,
      trailZero,
      setFieldValue,
      handleChange,
      id,
      params,
    } = this.props
    if (handleBlur) {
      handleBlur(e)
    }
    if (trailZero && trailZero.length > 0) {
      const tId = e.target.id
      let v = e.target.value
      trailZero.forEach((tz, i) => {
        if (tz) {
          if (tId === id[i]) {
            v = trailWithZero(v)
            setFieldValue(id[i], v)
            handleChange(id[i], v, params.type)
          }
        }
      })
    }
    if (error) {
      if (error[0] || error[1]) {
        this.setState(prevState => ({
          ...prevState,
          errorInput1: error[0],
          errorInput2: error[1],
        }))
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        errorInput1: null,
        errorInput2: null,
      }))
    }
  }
}

FormInputSplit.defaultProps = {
  id: '',
  label: '',
  value: ['', ''],
  valuePrefix: '#',
  prefixMaxLength: '1',
  error: '',
  handleChange: () => {},
  placeholder: '',
  autoComplete: 'off',
  autoCorrect: 'off',
  autoCapitalize: 'off',
  prefix: {},
  showModified: false,
}

export default FormInputSplit
