import React from 'react'
import classnames from 'classnames'
import ProgressIcons from 'spd-gds/images/ProgressIcons'
import Helpers from 'spd-gds/helpers'

const ProgressBarItem = ({ children, active, idx }) => {
  const _ProgressItem = classnames('cui-progress-v2__item', {
    'is-active': active,
  })
  const ArrIcons = [
    ProgressIcons.HomeCate,
    ...(Helpers.isInfinity()
      ? []
      : [ProgressIcons.WasteCate]),
    ProgressIcons.FoodCate,
    ProgressIcons.SpendingsCate,
    ProgressIcons.CommuteCate,
    ProgressIcons.HolidayCate,
  ]
  return <div className={_ProgressItem} style={{ '--bgColor': active ? "#00B0B2" : "#CED5DC" }}>
    <div className='cui-progress-v2__item--icon' style={{
      mask: `url(${ArrIcons[idx]}) no-repeat center`,
      WebkitMask: `url(${ArrIcons[idx]}) no-repeat center`
    }}></div>
    {children}
  </div>
}

export default ProgressBarItem
