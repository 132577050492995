// organize-imports-ignore
import React, { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Visiblity from 'react-visibility-sensor'
import html2canvas from 'html2canvas'
import moment from 'moment'
import { saveAs } from 'file-saver'

import { Page } from 'spd-gds/components/pages'
import { Blade, Article } from 'spd-gds/components'
import {
  Button,
  Portal,
  ConfirmationDialog,
  Drawer,
  HorizontalChart,
  SelectionBox,
} from 'spd-gds/components/common'
import {
  CO2Cloud,
  CO2Qn as CO2QnGfx,
  CO2Bg as CO2QnBg,
  SaveResultIcon,
  SocialShareIcon,
  MentionAppIcon,
} from 'spd-gds/components/gfx'
import Chart, { ChartDetail, ChartBreakdown } from 'spd-gds/components/Chart'
import { Chart as CUIChart } from '@casper-ui/components'
import { Co2eActions } from 'spd-gds/actions'
import Helpers from 'spd-gds/helpers'

import { useDyipne } from 'spd-gds/Dyipne'
import { useScreenSize } from 'spd-gds/components/ScreenSize'
import Leaf from 'spd-gds/images/leaf.svg'
import * as Icons from 'spd-gds/images/TipsIcons'
import alertInformation from 'spd-gds/images/alertInformation.svg'
import { useMode } from 'spd-gds/components/hooks'
import SpotLight from 'spd-gds/components/common/SpotLight'
import Utils from 'spd-gds/utils'
import SetTarget from './SetTarget'
import { bridge } from 'spd-gds/components/MobileBridge'

const DEEP_LINK = process.env.REACT_APP_MENTION_APP_URL

const CATEGORIES = {
  ENERGY: {
    color: '#00B0B2',
  },
  WASTE: {
    color: '#48DCC0',
  },
  FOOD: {
    color: '#FFD049',
  },
  SHOPPING: {
    color: '#F291B0',
  },
  DAILY_TRAVELS: {
    color: '#586EC7',
  },
  HOLIDAY_TRAVELS: {
    color: '#3384E1',
  },
}
// mentioned in CFW-312
const tipsList = {
  ENERGY: [
    {
      title: 'Take shorter showers',
      desc: 'Try taking shorter showers! Taking 5-minute showers instead of 10-minute showers can save around 30kg of CO2 every year',
      image: Icons.Shower,
      reduction: 30,
    },
    {
      title: 'Draw your curtains',
      desc: 'Having direct sunlight shining into the room will lower the effectiveness of the aircon to cool the room down. Draw the curtains or blinds to maximize the efficiency of your aircon!',
      image: Icons.Curtain,
    },
    {
      title: 'Switch off!',
      desc: "Remember to switch off and unplug any appliances that are not in use at the power socket. Some of your appliances continue to utilize electricity even when you don't use them.",
      image: Icons.SwitchOff,
      reduction: 37,
    },
    {
      title: 'Take advantage of the sun',
      desc: 'Singapore has a hot and sunny climate! How about sun drying your clothes instead of using the dryer!',
      image: Icons.Sun,
      reduction: 340,
    },
    {
      title: 'Cold water laundry',
      desc: "Wash your clothes with room temperature water. Singapore's tap water is warm enough for wash to be effective!",
      image: Icons.Thermometer,
    },
    {
      title: 'Clean your air-conditioner’s filter',
      desc: "Check and clean your air-conditioner's air filter once a month so that it would run efficiently. Dirty air filters impede airflow and will cause energy wastage.",
      image: Icons.Aircon,
    },
    {
      title: 'LED bulbs',
      desc: 'Change your incandescent light bulbs to LED (light-emitting diodes) ones. Residential LEDs use at least 75% less energy and last up to 25 times longer than incandescent lighting.',
      image: Icons.LightBulb,
    },
    {
      title: 'BYO bottle',
      desc: 'Bring a bottle of water when you go out of the house instead of buying bottled water.',
      image: Icons.WaterBottle,
    },
  ],
  WASTE: [
    {
      title: 'Recycled bag',
      desc: 'Remember to bring a recycled bag when you go shopping! It takes 20 years for one plastic bag to decompose.',
      image: Icons.ShoppingBag,
    },
    {
      title: 'Reuse plastic bags',
      desc: 'Reuse your plastic bags from the local grocery stores to line your trash bins.',
      image: Icons.PlasticBag,
    },
    {
      title: 'Reusable container',
      desc: 'Bring a reusable container when you buy food-to-go to reduce plastic wastage',
      image: Icons.FoodContainer,
    },
  ],
  FOOD: [
    {
      title: 'More vegetables',
      desc: 'Try incorporating more vegetables in your diet.! Livestock accounts for around 14.5 percent of the world’s greenhouse gases each year!',
      image: Icons.Vegetable,
      reduction: 105,
    },
    {
      title: 'Cut down on red meat',
      desc: 'Cut down on eating red meat. Beef is responsible for the most emissions, contributing 41% of the sector’s overall Greenhouse gas outputs.',
      image: Icons.RedMeat,
      reduction: 80,
    },
    {
      title: 'Alternatives to cow’s milk',
      desc: "Instead of drinking cow's milk, switch to alternatives such as soy milk. A cup of soy milk has lesser saturated fat and sugar compared with the same amount of cow's milk.",
      image: Icons.SoyMilk,
    },
    {
      title: 'Get organised',
      desc: "Take stock and organize your fridge regularly to prevent buying things that you don't need.",
      image: Icons.Frigde,
    },
  ],
  SHOPPING: [
    {
      title: 'Stay away from fast fashion',
      desc: 'Stay away from “fast fashion” and invest in quality products that last. It takes about 3,781 liters of water just to make a pair of jeans!',
      image: Icons.PairOfJeans,
    },
    {
      title: 'Support local',
      desc: 'Support local stores even when shopping online. This saves on shipping fees and your items will be delivered much faster!',
      image: Icons.OnlineShopping,
    },
    {
      title: 'Energy labels',
      desc: 'When shopping for appliances or electronics, look for ones with Energy Labels that are certified to be more energy efficient. The more tick ratings, the more energy you save.',
      image: Icons.EnergySavingLabel,
    },
  ],
  DAILY_TRAVELS: [
    {
      title: 'Take public transport',
      desc: 'Public transport is the most efficient and sustainable form of motorized transport. A car carrying only the driver uses 9 times the energy used by a bus and 12 times that used by a train on a per passenger-kilometer traveled basis.',
      image: Icons.Train,
      reduction: 2000,
    },
    {
      title: 'Go for a walk',
      desc: "If you're taking the bus, get off one stop earlier and walk to your destination. Walking will lower your carbon emissions and also help to improve or maintain your overall health.",
      image: Icons.Bus,
      reduction: 30,
    },
    {
      title: 'Electric vehicles',
      desc: "If you're shopping for a new car, consider purchasing a hybrid or electric car. SP Group currently has 340 charging points at 71 locations and this list of charging stations is still growing.",
      image: Icons.EV,
      reduction: 1250,
    },
  ],
  HOLIDAY_TRAVELS: [
    {
      title: 'Travel nearby',
      desc: 'Consider traveling to countries around the region. A round flight trip from Singapore to London emits 3.8 times more CO2 than to Bali.',
      image: Icons.Plane,
    },
    {
      title: 'Fly budget',
      desc: "When traveling overseas, fly budget or economy. A business class passenger's carbon footprint is typically double that of those traveling in the economy.",
      image: Icons.Plane,
    },
    {
      title: 'Pack light',
      desc: 'Pack light! Consider renting heavy gears on location rather than packing your own or buying a new one.',
      image: Icons.SurfBoard,
    },
  ],
}
const cateInfo = [
  {
    name: 'Home',
    desc: 'Usage of electricity, gas and water contributes to our carbon footprint. This is because energy generated to produce them emits carbon dioxide in the environment.',
  },
  {
    name: 'Waste',
    desc: 'Recycling converts waste into useful products. This conserves resources taken to manufacture the product. When you recycle, you save energy needed to incinerate the product.',
  },
  {
    name: 'Food',
    desc: 'The food we eat impacts our carbon emissions. Food such as meat has a significantly bigger impact than plant- based due to animal agriculture through deforestation.',
  },
  {
    name: 'Spending',
    desc: 'Every product you buy uses resources and energy to be produced. The more you spend, the bigger your carbon footprint is.',
  },
  {
    name: 'Commute',
    desc: 'Usage of diesel and petrol releases carbon dioxide in the air. Your carbon footprint will definitely be higher when commuting with a car instead of public transport.',
  },
  {
    name: 'Holiday',
    desc: 'Travelling business class emits 3 times more than economy due to the higher legroom space. Your hotel stay definitely contributes to your carbon emissions too.',
  },
]

// const pxToMm = (px) => {
//   return Math.floor(px / document.getElementById('myMm').offsetHeight)
// }
// const mmToPx = (mm) => {
//   return document.getElementById('myMm').offsetHeight * mm
// }
// const range = (start, end) => {
//   return Array(end - start).join(0).split(0).map(function (val, id) { return id + start })
// }

const Results = () => {
  const { co2e } = useDyipne()
  const { componentShouldHide, featureShouldHide } = useMode()
  const [co2eState, dispatcher] = co2e
  const history = useHistory()
  const screenSize = useScreenSize()

  const [openAbout, setOpenAbout] = useState(false)
  const [openAboutAnnual, setOpenAboutAnnual] = useState(false)

  const SPAverage = co2eState?.data?.total?.sgAverage
  const raintreesGuessed = co2eState?.data?.guessRaintrees
  const pastResults = co2eState?.data?.total?.pastResults
  const resultsDataChart = Helpers.parseChartData(pastResults)
  const [userTarget, setUserTarget] = useState(
    co2eState?.data?.total?.target ?? 0
  )

  const [annuallyTotalKG] = Helpers.transformMonthlyData(
    co2eState?.data?.total?.pastResults
  )
  const [tips, setTips] = useState([])
  const [handler, setHandler] = React.useState({
    openModal: false,
    process: false,
  })
  const [FDMore, setFDMore] = useState(false)

  useEffect(() => {
    if (co2eState?.data?.total?.target) {
      setUserTarget(co2eState?.data?.total?.target)
    } else {
      setUserTarget(0)
    }
  }, [co2eState?.data?.total?.target])

  const openAboutChart = () => {
    setOpenAbout(!openAbout)
  }

  const openAboutAnnualChart = () => {
    setOpenAboutAnnual(!openAboutAnnual)
  }
  const onChangeHandler = React.useCallback(
    type => {
      switch (type) {
        case 'open':
          return setHandler(prev => ({
            ...prev,
            openModal: !handler.openModal,
          }))
        case 'process':
          return setHandler(prev => ({ ...prev, process: !handler.process }))
        case 'close':
        case 'cancel':
          return setHandler(prev => ({
            ...prev,
            openModal: false,
            process: false,
          }))
        default:
          break
      }
    },
    [handler]
  )

  const maxChartValue = Math.max(
    ...resultsDataChart.map(el => el.total),
    SPAverage
  )

  const createChartData = {
    type: 'xy',
    x: 'date',
    y: ['total'],
    data: resultsDataChart,
  }

  const legends = [
    { color: '#4F5A60', field: 'dashed', value: 'Singapore Average' },
  ]
  const chartResultsData = new CUIChart.Data(createChartData)

  const [scrolledBlade, setScrolledBlade] = useState({
    blade1: false,
    blade2: false,
    blade3: false,
    blade4: false,
    blade5: false,
    blade6: false,
  })

  const [downloading, setDownloading] = useState(false)
  const [prepSaveResults, setPrepSaveResults] = useState(false)

  useEffect(() => {
    document.body.classList.add('__results')
    if (co2eState.data.total.value === 0) {
      history.push('/' + window.location.search)
    }
    return () => {
      document.body.classList.remove('__results')
    }
  }, [])

  const base = co2eState.data.total
    ? Math.max(
        co2eState.data.total.value,
        SPAverage,
        co2eState.data.total.sustainableAverage
      )
    : 0

  const handleBladeVisiblity = blade => isVisible => {
    if (isVisible) {
      setScrolledBlade(prevState => ({
        ...prevState,
        [blade]: isVisible,
      }))
    }
  }

  const timestamp = (format = 'YYYY-MM-DD hh:mmA') => {
    const mDate = moment()
    const str = mDate.format(format)
    return str
  }

  const handleSaveImage = () => {
    if (downloading) return null
    setDownloading(true)
    setPrepSaveResults(true)

    setTimeout(() => {
      const printable = document.querySelector('.printable-results')
      html2canvas(printable, {
        width: printable.offsetWidth,
        height: printable.offsetHeight,
        useCORS: true,
      }).then(canvas => {
        setPrepSaveResults(false)

        const imgData = canvas.toDataURL('image/png')
        const ts = timestamp('YYYY-MM-DD[-]HHmm')
        const filename = `carbonfootprint-${ts}`

        if (Helpers.isInfinityAndroid()) {
          bridge.saveReportAndroid(imgData, filename)
          setDownloading(false)
          window.scrollTo(0, 0)
          return
        }

        if (Helpers.isInfinityIOS()) {
          bridge.saveReportIOS(imgData, filename)
          setDownloading(false)
          window.scrollTo(0, 0)
          return
        }

        // Split the base64 string in data and contentType
        const block = imgData.split(';')
        // Get the content type
        const mimeType = block[0].split(':')[1] // In this case "image/png"
        // get the real base64 content of the file
        const realData = block[1].split(',')[1] // For example:  iVBORw0KGgouqw23....
        // Convert b64 to blob and store it into a variable (with real base64 as value)
        const canvasBlob = Utils.b64toBlob(realData, mimeType)

        // Generate file download
        setDownloading(false)
        window.scrollTo(0, 0)
        saveAs(canvasBlob, filename)
      })
    }, 1000)
  }

  const handleShare = () => {
    const { width, height } = screenSize
    const popupWidth = 670
    const popupHeight = 283
    const popupX = width / 2 - popupWidth / 2
    const popupY = height / 2 - popupHeight / 2
    const totalCO2 = Utils.formatNumber(co2eState.data.total.value)
    const quote = `My carbon footprint is ${totalCO2} kgCO2. What's yours? Calculate your carbon footprint now.`

    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=https://mycarbonfootprint.spgroup.com.sg/&quote=' +
        quote,
      '_blank',
      `width=${popupWidth},height=${popupHeight},top=${popupY},left=${popupX}`
    )
  }

  const getHighestSavings = () => {
    if (!co2eState.data.total || co2eState.data.total.groups.length === 0)
      return {}

    const highestGroup = co2eState.data.total.groups.reduce((acc, curr) => {
      return acc.savings > curr.node.savings ? acc : curr.node
    }, {})
    return highestGroup
  }

  const renderChartBreakpoints = useCallback(() => {
    if (!co2eState.data) return null
    if (!co2eState.data.total) return null

    const breakpoints = []
    const sustainableVal = co2eState.data.total.sustainableAverage
    const sgaverageVal = co2eState.data.total.sgAverage

    const sustainable = (
      <Chart.Bar.Breakpoint
        key="chartbp-sustainable-goal"
        label="Sustainable Goal"
        value={(sustainableVal / base) * 100}
      >
        {`${Utils.formatNumber(co2eState.data.total.sustainableAverage)}kg `}CO
        <sub>2</sub>/month
      </Chart.Bar.Breakpoint>
    )
    breakpoints.push(sustainable)

    const sgaverage = (
      <Chart.Bar.Breakpoint
        key="chartbp-singaporean-avg"
        label="Singaporean Average"
        value={(sgaverageVal / base) * 100}
      >
        {`${Utils.formatNumber(sgaverageVal)}kg `}CO<sub>2</sub>/month
      </Chart.Bar.Breakpoint>
    )

    if (sgaverageVal > co2eState.data.total.sustainableAverage) {
      breakpoints.push(sgaverage)
    } else {
      breakpoints.unshift(sgaverage)
    }

    return breakpoints
  }, [co2eState.data])

  const renderChartBreakdown = useCallback(() => {
    if (!co2eState.data) return null
    if (!co2eState.data.total) return null
    if (!co2eState.data.total.groups) return null
    if (co2eState.data.total.groups.length === 0) return null

    return co2eState.data.total.groups.map((grp, idx) => {
      const { node } = grp
      const color = CATEGORIES[node.id] ? CATEGORIES[node.id].color : '#ffffff'
      return (
        <ChartDetail
          key={`${idx}--chartdetail-${node.id}`}
          id={`chartdetail-${node.id}`}
          color={color}
        >
          <ChartDetail.Label> {node.name} </ChartDetail.Label>
          <ChartDetail.Value> {`${node.percentage}%`} </ChartDetail.Value>
          <ChartDetail.Description>
            {' '}
            {`${Utils.formatNumber(node.totalKG)}kg `}CO<sub>2</sub>/month{' '}
          </ChartDetail.Description>
        </ChartDetail>
      )
    })
  }, [co2eState.data])

  const renderChartData = useCallback(() => {
    if (!co2eState.data) return null
    if (!co2eState.data.total) return null
    if (!co2eState.data.total.groups) return null
    if (co2eState.data.total.groups.length === 0) return null

    return co2eState.data.total.groups.map((grp, idx) => {
      const { node } = grp
      const color = CATEGORIES[node.id] ? CATEGORIES[node.id].color : '#ffffff'
      return (
        <Chart.Data
          key={`${idx}--chartdata-${node.id}`}
          id={`chartdata-${node.id}`}
          color={color}
          value={(node.totalKG / base) * 100}
        />
      )
    })
  }, [co2eState.data])

  const shufflePos = array => {
    let currentIdx = array.length,
      randomIdx

    // While there remain elements to shuffle...
    while (randomIdx !== 0) {
      // Pick a remaining element...
      randomIdx = Math.floor(Math.random() * currentIdx)
      currentIdx--

      // And swap it with the current element.
      ;[array[currentIdx], array[randomIdx]] = [
        array[randomIdx],
        array[currentIdx],
      ]
    }
    return array.slice(0, 2)
  }
  useEffect(() => {
    if (!co2eState?.data?.total?.groups?.length) {
      setTips([])
      return
    }
    let prevNum
    const data = co2eState.data.total.groups
    let getData = data.filter(group => group.node.savings > 0)
    getData = shufflePos(getData)
    const getRandomTips = arr => {
      let rand = Math.floor(Math.random() * arr.length)
      if (rand === prevNum) return getRandomTips(arr)
      prevNum = rand

      return rand
    }
    const length = Math.floor(Math.random() * data.length)
    if (getData.length === 1 || getData.length === 0) {
      getData = Array(2).fill(data[length])
    }
    getData = getData.map((tips, i) => {
      const { node } = tips
      const Tips =
        tipsList[node.id] && tipsList[node.id][getRandomTips(tipsList[node.id])]
      let totalKG = node.totalKG === 0 ? 1 : node.totalKG
      const co2Reduction =
        node.savings > 0
          ? ((((Tips && Tips.reduction) || 0) / totalKG) * 100).toFixed(2)
          : 0
      return {
        ...Tips,
        co2Reduction,
      }
    })
    setTips(getData)
  }, [co2eState.data])
  const resetTarget = values => {
    dispatcher(Co2eActions.SUBMIT_TARGET(values))
  }
  const setTarget = () => {
    return userTarget <= 0 ? (
      <div className="set-target">
        <Button onClickHandler={() => onChangeHandler('open')}>
          Set Target
        </Button>
      </div>
    ) : (
      <></>
    )
  }
  const renderMonthTracking = useCallback(
    width => {
      const default_width = screenSize.width <= 807 ? screenSize.width : 807

      return (
        <div>
          <div className="top-chart-content">
            <p>Do you know?</p>
            Singapore will raise our climate ambition to achieve net zero
            emissions by or around mid-century. Play a part by setting a monthly
            target.
            <br />
          </div>
          {setTarget()}
          {userTarget > 0 && (
            <div style={{ margin: '0 auto 1.5rem', width: width ?? '100%' }}>
              <SpotLight
                target={userTarget}
                result={(co2eState.data.total.value || 0).toFixed(2)}
              />
              <Button
                className="reset-target"
                styles={{
                  float: 'right',
                  padding: '0.2rem 1rem',
                  minWidth: 'unset',
                  fontSize: '0.875rem',
                }}
                variant="secondary"
                onClickHandler={() => resetTarget({ target: '0' })}
              >
                {' '}
                Reset target &#10142;{' '}
              </Button>
            </div>
          )}
          {resultsDataChart.length ? (
            <div
              className="bar-chart print"
              style={{
                width: width ?? default_width,
                height: screenSize.width <= 540 ? 250 : 540,
              }}
            >
              <CUIChart data={chartResultsData}>
                <CUIChart.XY
                  direction="vertical"
                  interval={4}
                  isRounded
                  unit={{ y: 'kg' }}
                  legends={legends}
                  labelSize={{ x: 20, y: 40 }}
                  barWidth={60}
                  maxVal={maxChartValue}
                >
                  <CUIChart.Label axis="y" />
                  <CUIChart.Label axis="x" />
                  <CUIChart.Bar
                    fields={['total']}
                    resolveValue={e => {
                      return Utils.formatNumber(e)
                    }}
                    options={{
                      color: { total: '#FF8B00' },
                      rounded: 0,
                    }}
                  />
                  <CUIChart.ConstantLine
                    value={SPAverage}
                    color="#4F5A60"
                    width="1"
                    dashed
                    strokeDashArray="5"
                  />
                </CUIChart.XY>
              </CUIChart>
            </div>
          ) : (
            <></>
          )}
        </div>
      )
    },
    [
      chartResultsData,
      co2eState.data.total?.sgAverage,
      co2eState.data.total.value,
      userTarget,
    ]
  )

  const DownloadAppLink = ({ className, content }) => {
    return !componentShouldHide?.downloadApp ? (
      <a
        className={className}
        target="_blank"
        href={DEEP_LINK}
        rel="noreferrer"
      >
        Download the SP App now {screenSize.width <= 768 ? '' : content}{' '}
        &#10142;
      </a>
    ) : (
      <></>
    )
  }

  const SPAverageAnnually = SPAverage * 12
  const AnnualChartData = [
    {
      content: 'Your CO2',
      totalKG: annuallyTotalKG,
      color: '#FFE8CC',
    },
    {
      content: 'Singapore Avg.',
      totalKG: SPAverageAnnually,
      color: '#FF8B00',
    },
  ]
  const transformMonthSelection = (pastResult = []) => {
    if (pastResult.length === 0) return []
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    pastResult = pastResult.map(value => {
      return {
        cre: value.node.createdAt,
        type: value.node.trackingType,
      }
    })

    const latestMonth = +pastResult
      .map(e => e.cre)
      .sort()
      .reverse()[0]
      .slice(5, 7) //Ex: 2021-05-10T00:00:00Z.slice(5,7) => 05
    const defaultYear = new Date().getFullYear()

    let dataOutput = [...Array(12)]
      .map((_, i) => {
        const month = ((i + latestMonth) % 12) + 1
        const year = month > latestMonth ? defaultYear - 1 : defaultYear
        return {
          label: `${months[month - 1]} ${year}`,
          disabled: true,
          value: month,
        }
      })
      .reverse()

    const transfromLabel = pastResult.map(el => {
      const date = new Date(el.cre)
      const year = date.getFullYear()
      const monthNum = date.getMonth()
      return {
        label: `${months[monthNum]} ${year}`,
        value: monthNum + 1,
        disabled: el.type === 'ANNUAL',
      }
    })

    dataOutput = dataOutput.map(v => {
      const cur = transfromLabel.map(el => el.value).indexOf(v.value)
      return cur >= 0 ? transfromLabel[cur] : v
    })

    return [dataOutput, latestMonth]
  }

  const saveMonthFiltered = useCallback(
    month => {
      let pastRsltFiltered = pastResults.find(
        value => +value.node.createdAt.slice(5, 7) === +month
      )?.node
      if (!pastRsltFiltered) return
      dispatcher(
        Co2eActions.SAVE_CO2_PAST_RESULT(co2eState.data.total, pastRsltFiltered)
      )
    },
    [co2eState.data]
  )

  const selectionData = transformMonthSelection(pastResults)
  if (!co2eState || co2eState.data.total.groups.length === 0) return null

  return (
    <>
      <Page className="page-results" type="landing">
        <Blade
          className={`results-main printable ${
            prepSaveResults ? 'invisible' : ''
          }`}
          withContainer={false}
          title="My Carbon Footprint result"
        >
          {pastResults.length > 0 && (
            <SelectionBox
              title="Result For"
              data={selectionData[0]}
              latest={selectionData[1]}
              handleChange={saveMonthFiltered}
              className="animate-appear"
            />
          )}
          <CO2QnBg />
          <div className="co2-cloud total appear">
            <CO2Cloud>
              {`${Utils.formatNumber(co2eState.data.total.value)}${
                co2eState.data.total.unit
              }`}
            </CO2Cloud>
            <span>
              CO<sub>2</sub>/month
            </span>
          </div>
          <div className="co2-ground appear">
            <span>
              {raintreesGuessed > 0 && (
                <>
                  You’ve guessed{' '}
                  <span className="highlight">
                    {Utils.formatNumber(raintreesGuessed)}
                  </span>{' '}
                  rain trees but...
                  <br />
                </>
              )}
              <span className="highlight">
                {' '}
                {Utils.formatNumber(co2eState.data.trees)}{' '}
              </span>{' '}
              rain trees are needed to absorb your emissions monthly.
            </span>
            <span className="annotate">Scroll down to see more details</span>
          </div>

          <CO2QnGfx fullWidth />
        </Blade>

        <Blade
          className={`results-breakdown printable ${
            prepSaveResults ? 'invisible' : ''
          }`}
        >
          <div className="results-breakdown-title">
            <h1>Monthly emissions by categories</h1>
            <img
              className="results-breakdown-info"
              onClick={() => setFDMore(!FDMore)}
              src={alertInformation}
              alt="Chart Information"
            />
          </div>
          <Visiblity
            onChange={handleBladeVisiblity('blade2')}
            scrollCheck
            partialVisibility
            offset={{ top: 50 }}
          >
            <div>
              <Chart className={`${scrolledBlade.blade2 ? 'appear' : ''}`}>
                <Chart.Bar
                  pre="Your CO<sub>2</sub>"
                  post={`${Utils.formatNumber(co2eState.data.total.value)}${
                    co2eState.data.total.unit
                  } CO<sub>2</sub>/month`}
                  breakpoint={renderChartBreakpoints()}
                  dataPercentage={(co2eState.data.total.value / base) * 100}
                >
                  {renderChartData()}
                </Chart.Bar>
                <ChartBreakdown>{renderChartBreakdown()}</ChartBreakdown>
              </Chart>
            </div>
          </Visiblity>
          <ConfirmationDialog
            visible={FDMore}
            closeBtn={true}
            processing={false}
            onCloseHandler={() => setFDMore(false)}
            confirmOkHandler={() => setFDMore(false)}
            className="find-out-more"
            style={{
              maxHeight: '90%',
              overflowY: 'scroll',
              justifyContent: 'flex-start',
            }}
            actionConfig={{
              cancel: false,
              ok: { label: 'Close' },
            }}
            content={
              <div className="FDMore">
                <div className="cateInfor-single">
                  <h4>CARBON EMISSION CATEGORIES</h4>
                  <span>
                    {' '}
                    Every little action in our daily lives contributes to our
                    carbon footprint. Here is a breakdown of how carbon
                    emissions are derived for each category:
                  </span>
                </div>
                {cateInfo.map((cate, idx) => {
                  return (
                    <div className="cateInfor-single" key={idx}>
                      <h5>{cate.name}</h5>
                      <span>{cate.desc}</span>
                    </div>
                  )
                })}
              </div>
            }
          />
        </Blade>

        {Helpers.isInfinity() && !featureShouldHide?.monthlyTracking && (
          <Blade
            className={`results-monthly-tracking bar-chart-v2 ${
              scrolledBlade.blade3 ? 'animate-appear' : ''
            }`}
          >
            <Visiblity
              onChange={handleBladeVisiblity('blade3')}
              scrollCheck
              scrollThrottle={100}
              partialVisibility
              offset={{ top: 50 }}
            >
              <div>
                <div className="chart-title">
                  <h4>Monthly tracking</h4>
                  <img
                    onClick={() => setOpenAbout(!openAbout)}
                    src={alertInformation}
                    alt="Chart Information"
                  />
                </div>
                {renderMonthTracking()}
                <SetTarget
                  change={onChangeHandler}
                  handler={handler}
                  userTarget={userTarget}
                />
                <Drawer
                  title="About this chart"
                  open={openAbout}
                  handleChange={openAboutChart}
                >
                  <div>
                    Monthly tracking
                    <br />
                    Please note that your monthly tracking is based on the quiz
                    results.
                  </div>
                </Drawer>
                <Drawer
                  title="About this chart"
                  open={openAboutAnnual}
                  handleChange={openAboutAnnualChart}
                >
                  <div>
                    Annual projection
                    <br />
                    Your annual projection result is based on the trend of past
                    months’ saved emissions.
                  </div>
                </Drawer>
              </div>
            </Visiblity>
          </Blade>
        )}
        {Helpers.isInfinity() && !featureShouldHide?.annualProjection && (
          <Blade
            className={`result-annual ${
              scrolledBlade.blade4 ? 'animate-appear' : ''
            }`}
          >
            <Visiblity
              onChange={handleBladeVisiblity('blade4')}
              scrollCheck
              scrollThrottle={100}
              partialVisibility
              offset={{ top: 50 }}
            >
              <HorizontalChart
                title="Annual projection"
                data={AnnualChartData}
                handleChange={openAboutAnnualChart}
              />
            </Visiblity>
          </Blade>
        )}
        <Blade
          className={`results-tips printable ${
            prepSaveResults ? 'invisible' : ''
          }`}
        >
          <h4>Tips to reduce CO2 emissions</h4>
          <Visiblity
            onChange={handleBladeVisiblity('blade5')}
            scrollCheck
            scrollThrottle={100}
            partialVisibility
            offset={{ top: 50 }}
          >
            <div>
              {tips.map((tip, idx) => {
                return (
                  <Article
                    key={idx}
                    id="tips-to-reduce-carbon-emission"
                    className={`animate ${
                      scrolledBlade.blade5 ? 'appear' : ''
                    }`}
                    isCrosswise
                  >
                    <Article.Hero>
                      <img src={tip.image} alt="Tips for reduce co2" />
                    </Article.Hero>
                    <Article.Content>
                      <Article.Header>{tip.title}</Article.Header>
                      {tip.co2Reduction > 1 && (
                        <div className="app-article__saving">
                          <img src={Leaf} alt="Tips for reduce co2" />
                          <span>{tip.co2Reduction}% LESS CO2</span>
                        </div>
                      )}
                      <Article.Description>{tip.desc}</Article.Description>
                    </Article.Content>
                  </Article>
                )
              })}
            </div>
          </Visiblity>
          <DownloadAppLink
            className="results-tips__download"
            content="to find out how to reduce your CO2 emissions!"
          />
        </Blade>

        <Blade className={`results-share ${downloading ? 'invisible' : ''}`}>
          <Visiblity
            onChange={handleBladeVisiblity('blade6')}
            scrollCheck
            scrollThrottle={100}
            partialVisibility
            offset={{ top: 50 }}
          >
            <div
              className={`social-media animate animate-stagger ${
                scrolledBlade.blade6 ? 'appear' : ''
              }`}
            >
              <Article id="save-my-result" align="center">
                <Article.Hero>
                  <SaveResultIcon />
                </Article.Hero>
                <Article.Header title="Save my result" />
                <Article.Content>
                  Keep track on your footprint so you can refer back anytime
                  later.
                  <Button
                    onClickHandler={handleSaveImage}
                    disabled={downloading}
                  >
                    {' '}
                    {downloading ? 'Saving...' : 'Save Report'}{' '}
                  </Button>
                </Article.Content>
              </Article>
              <Article id="share-my-result" align="center">
                <Article.Hero>
                  <SocialShareIcon />
                </Article.Hero>
                <Article.Header title="Share my results" />
                <Article.Content>
                  Share your results on Facebook with your friends and family so
                  they can calculate their footprint too.
                  <Button onClickHandler={handleShare}> Share </Button>
                </Article.Content>
              </Article>
              {!componentShouldHide?.downloadApp && (
                <Article id="download-app" align="center">
                  <Article.Hero>
                    <MentionAppIcon />
                  </Article.Hero>
                  <Article.Header title="SP App" />
                  <Article.Content>
                    Check out more ways to contribute to sustainability and get
                    rewarded.
                    <Button
                      className="lm--button lm--button--solid"
                      link
                      to={DEEP_LINK}
                    >
                      {' '}
                      Download App{' '}
                    </Button>
                  </Article.Content>
                </Article>
              )}
            </div>
          </Visiblity>
        </Blade>
      </Page>

      <PrintableResults
        visible={prepSaveResults}
        base={base}
        co2eState={co2eState}
        renderChartBreakpoints={renderChartBreakpoints}
        renderChartData={renderChartData}
        renderChartBreakdown={renderChartBreakdown}
        getHighestSavings={getHighestSavings}
        timestamp={timestamp}
        tips={tips}
        isInfinity={Helpers.isInfinity()}
        renderMonthTracking={renderMonthTracking}
        hideFeature={featureShouldHide}
        guessRTs={raintreesGuessed}
        annualData={AnnualChartData}
      />

      <ConfirmationDialog
        visible={downloading}
        closeBtn={false}
        actionConfig={{
          ok: false,
          cancel: false,
        }}
        content={
          <h3>
            Please wait while your results are being processed and getting ready
            for download.
          </h3>
        }
      />

      <div
        id="myMm"
        style={{ height: '1mm', position: 'absolute', top: '0', left: '0' }}
      />
    </>
  )
}

const PrintableResults = ({
  visible,
  co2eState,
  base,
  renderChartBreakpoints,
  renderChartData,
  renderChartBreakdown,
  timestamp,
  tips,
  isInfinity,
  renderMonthTracking,
  hideFeature,
  guessRTs,
  annualData,
}) => {
  return (
    <Portal>
      <div
        className={`page-results printable-results ${
          !visible ? 'invisible' : ''
        }`}
        style={{ fontFamily: 'Arial', fontSize: '20px' }}
      >
        <div className="results-main-bg">
          <img src="/images/HeroBanner.png" alt="Results Hero Banner" />
        </div>
        <div className="results">
          <h2>My Carbon Footprint result</h2>
          <div className="total">
            {`${Utils.formatNumber(co2eState.data.total.value)}${
              co2eState.data.total.unit
            }`}
            <span>
              CO<sub>2</sub>/year
            </span>
          </div>
          <div className="">
            <span>
              {guessRTs > 0 && (
                <>
                  {' '}
                  You’ve guessed{' '}
                  <span className="highlight">
                    {Utils.formatNumber(guessRTs)}
                  </span>{' '}
                  rain trees but...
                  <br />
                </>
              )}
              <span className="highlight">
                {' '}
                {Utils.formatNumber(co2eState.data.trees)}{' '}
              </span>{' '}
              rain trees are needed to absorb your emissions annually.
            </span>
          </div>
        </div>
        <Blade className="results-breakdown" title="Contribution by categories">
          <div>
            <Chart>
              <Chart.Bar
                pre="Your CO<sub>2</sub>"
                post={`${Utils.formatNumber(co2eState.data.total.value)}${
                  co2eState.data.total.unit
                } CO<sub>2</sub>/year`}
                breakpoint={renderChartBreakpoints()}
                dataPercentage={(co2eState.data.total.value / base) * 100}
              >
                {renderChartData()}
              </Chart.Bar>
              <ChartBreakdown>{renderChartBreakdown()}</ChartBreakdown>
            </Chart>
          </div>
        </Blade>
        {isInfinity && !hideFeature.monthlyTracking && (
          <Blade
            className="results-monthly-tracking bar-chart-v2 print"
            title="Monthly tracking"
          >
            {renderMonthTracking('80%')}
          </Blade>
        )}
        {isInfinity && !hideFeature.annualProjection && (
          <Blade className="result-annual printable">
            <HorizontalChart title="Annual projection" data={annualData} />
          </Blade>
        )}
        <Blade className="results-tips printable">
          <h4>Tips to reduce CO2 emissions</h4>
          <div>
            {tips.map((tip, idx) => {
              return (
                <Article
                  key={idx}
                  id="tips-to-reduce-carbon-emission"
                  isCrosswise
                >
                  <Article.Hero>
                    <img
                      className="results-tips__img-download"
                      src={tip.image}
                      alt="Tips for reduce co2"
                    />
                  </Article.Hero>
                  <Article.Content>
                    <Article.Header title={tip.title} titleVariant="medium" />
                    {tip.co2Reduction > 0 && (
                      <div className="app-article__saving">
                        <img src={Leaf} alt="Tips for reduce co2" />
                        <span>{tip.co2Reduction} % LESS CO2</span>
                      </div>
                    )}
                    <Article.Description>{tip.desc}</Article.Description>
                  </Article.Content>
                </Article>
              )
            })}
          </div>
        </Blade>
        <div className="timestamp">{timestamp()}</div>
      </div>
    </Portal>
  )
}

export default Results
