import React from 'react'
import PropTypes from 'prop-types'

const Stump2 = ({ id }) => {
  return (
    <svg id={id} className="gfx-tree__stump qnnre-Stump2" width="124" height="77" viewBox="0 0 124 77" fill="none" 
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#qnnre-stomp2_clip0)">
        <path d="M61.5643 76.9553C95.5652 76.9553 123.129 70.7536 123.129 63.1033C123.129 55.4531 95.5652 49.2514 61.5643 49.2514C27.5633 49.2514 0 55.4531 0 63.1033C0 70.7536 27.5633 76.9553 61.5643 76.9553Z" fill="url(#qnnre-stomp2_paint0_linear)"/>
        <path d="M56.4942 41.2212L80.3327 36.9386L114.831 53.5346L118.511 57.1158H80.3327L59.6348 64.717L42.3869 61.7275L25.8308 67.7207L16.9302 66.0005L30.041 53.5763L33.8603 50.7905L35.3995 12.3129H46.8269L56.9469 25.0891L56.4942 41.2212Z" fill="#4A4A7C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M44.7966 61.5643H75.7061L81.5726 50.2756V0L72.3215 9.01353L54.2718 12.2264L49.0839 21.7415L44.3463 18.1052L38.4777 42.658L44.7966 61.5643Z" fill="#FFDEB3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M72.338 61.5643V12.3129C72.338 12.3129 78.2893 28.7066 78.4897 37.3764C78.692 46.0462 72.338 61.5643 72.338 61.5643Z" fill="#C1904F"/>
      </g>
      <defs>
        <linearGradient id="qnnre-stomp2_paint0_linear" x1="124.866" y1="59.1682" x2="89.7567" y2="11.1516" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F8FCFF"/>
          <stop offset="1" stopColor="#E4EBF2"/>
        </linearGradient>
        <clipPath id="qnnre-stomp2_clip0">
          <path d="M0 0H123.128V76.9553H0V0Z" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

Stump2.propTypes = {
  id: PropTypes.string
}

Stump2.defaultProps = {
  id: ''
}

export default Stump2
