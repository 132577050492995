import React from 'react'

import Cloud1 from './Cloud1'
import Cloud2 from './Cloud2'
import Cloud3 from './Cloud3'

const CO2Bg = () => {
 
  return (
    <section className='app-qnnre__bg app-qnnre__gfx'>
      {/* BG */}
      <div className='gfx-sky'>
        <Cloud1 id="bg-cloud-1" x={20} y={190}/>
        <Cloud2 id="bg-cloud-2" x={50} y={399}/>
        <Cloud3 id="bg-cloud-3" x={1140} y={243}/>
        <Cloud2 id="bg-cloud-4" x={1210} y={410}/>
        <Cloud3 id="bg-cloud-5" x={434} y={122}/>
        <Cloud2 id="bg-cloud-6" x={1120} y={649}/>
      </div>
    </section>
  )
}

export default CO2Bg
