import React, { useState, useEffect } from 'react'

import { useDyipne } from 'spd-gds/Dyipne'
import Utils from 'spd-gds/utils'

import { useScreenSize } from 'spd-gds/components/ScreenSize'

import Podium from './Podium'
import TreeGroup from './TreeGroup'
import Buildings from './Buildings'
import OtherTrees from './OtherTrees'

const clamp = (val, min = 0, max = 1) => {
  return Math.max(min, Math.min(max, val))
}

const baseScreenWidth = 1024

const DEFAULT_TREE_CONFIG = {
  trees: 4,
  tree1Width: 157,
  tree2Width: 141,
  treeOffset: 40,
  podiumWidth: 500,
  ratio: 1,
  numOfAffected: 0,
}

// Unscaled, num of trees increase according to width
function getNoTreesForLargerScreen(
  baseScreenWidth,
  basePodiumWidth,
  baseTrees,
  width
) {
  var realEstateWidth = width - basePodiumWidth
  var ratio = realEstateWidth / (baseScreenWidth - basePodiumWidth)
  var trees = Math.floor(ratio * baseTrees)
  return trees
}

// Everything scaled down, num of trees remain at 4
function getBaseForSmallerScreen(
  baseWidth,
  basePodiumWidth,
  baseTree1Width,
  baseTree2Width,
  baseOffsetWidth,
  width
) {
  var ratio = width / baseWidth
  var tree1Width = baseTree1Width * ratio
  var tree2Width = baseTree2Width * ratio
  var podium = basePodiumWidth * ratio
  var offset = baseOffsetWidth * ratio
  return {
    tree1Width,
    tree2Width,
    treeOffset: offset,
    podiumWidth: podium,
    ratio: ratio * 1.3, // With factor 1.3 for small screen
  }
}

const CO2Qn = ({ activeCategory, fullWidth }) => {
  const initialTreeConfig = {
    ...DEFAULT_TREE_CONFIG,
  }

  if (fullWidth) {
    // Set initial tree config's podium to 0 and increase num of trees per row when fullWidth is available
    initialTreeConfig.podiumWidth = 0
    initialTreeConfig.trees = 6
  }

  function getTreeConfig(config) {
    let newTreeConfig = { ...config }
    if (screenSize.width > baseScreenWidth) {
      // Recalculate the number of trees if screen is greater than the base screen width >> TABLET to DESKTOP MODE
      newTreeConfig.trees = getNoTreesForLargerScreen(
        baseScreenWidth,
        initialTreeConfig.podiumWidth,
        initialTreeConfig.trees,
        screenSize.width
      )
    } else if (screenSize.width < baseScreenWidth) {
      // Get new tree config when its lower than the base screen width >> MOBILE MODE
      const newTreeBase = getBaseForSmallerScreen(
        baseScreenWidth,
        initialTreeConfig.podiumWidth,
        157,
        141,
        initialTreeConfig.treeOffset,
        screenSize.width
      )
      newTreeConfig = {
        ...newTreeConfig,
        ...newTreeBase,
      }
    }
    newTreeConfig.xMax = (screenSize.width - newTreeConfig.podiumWidth) / 2
    return newTreeConfig
  }

  const { co2e } = useDyipne()
  const [co2eState] = co2e
  const screenSize = useScreenSize()
  const treeSectionSize = screenSize.width / 2
  // const maxScreenWidth = Math.max(screenSize.width, baseScreenWidth)
  const [treeConfig, setTreeConfig] = useState(getTreeConfig(initialTreeConfig))

  // const isMounted = useRef(false)

  const Hero =
    activeCategory && activeCategory.length > 0 ? activeCategory[0].hero : null

  const processAffectedTrees = () => {
    const value = Math.round(
      clamp(co2eState.data.total?.value / co2eState.data.threshold) *
        treeConfig.trees
    )
    setTreeConfig(prevState => ({
      ...prevState,
      numOfAffected: value,
    }))
  }

  useEffect(() => {
    const newTreeConfig = getTreeConfig(treeConfig)
    setTreeConfig(newTreeConfig)
    // if (!isMounted.current) {
    //   isMounted.current = true
    // }
  }, [screenSize.width])

  useEffect(() => {
    processAffectedTrees()
  }, [co2eState.data?.total.value, co2eState.submit_success, treeConfig.trees])

  return (
    <section className="app-qnnre__gfx">
      {/* BG */}
      <Buildings />
      <OtherTrees />

      {/* Active Page */}
      {activeCategory && (
        <>
          <Podium>{Hero && <Hero show />}</Podium>
          {co2eState.data && co2eState.data.total && (
            <div className="app-qnnre-counter">
              <span className="app-qnnre__title">
                {' '}
                Current Carbon Footprint{' '}
              </span>
              <span className="app-qnnre__value">
                {' '}
                {`${Utils.formatNumber(co2eState.data.total.value)}${
                  co2eState.data.total.unit
                } CO`}
                <sub>2</sub>{' '}
              </span>
            </div>
          )}
        </>
      )}

      {/* Trees Here */}
      <div className="gfx-trees">
        <div className="gfx-trees__section" id="trees__section1">
          <TreeGroup
            id="tree-group1-4"
            numOfTrees={treeConfig.trees}
            numOfAffected={treeConfig.numOfAffected}
            xMin={0}
            xMax={treeConfig.xMax}
            yMin={1}
            yMax={50}
            opacityMax={0.7}
            scaleMax={treeConfig.ratio * 0.7}
            offset={{ x: 0, y: 140, tree: treeConfig.treeOffset }}
          />
          <TreeGroup
            id="tree-group1-3"
            numOfTrees={treeConfig.trees}
            numOfAffected={treeConfig.numOfAffected}
            xMin={0}
            xMax={treeConfig.xMax}
            yMin={1}
            yMax={50}
            opacityMax={0.4}
            scaleMax={treeConfig.ratio * 0.8}
            offset={{ x: 0, y: 110, tree: treeConfig.treeOffset }}
          />
          <TreeGroup
            id="tree-group1-2"
            numOfTrees={treeConfig.trees}
            numOfAffected={treeConfig.numOfAffected}
            xMin={0}
            xMax={treeConfig.xMax}
            yMin={1}
            yMax={50}
            opacityMax={0.2}
            scaleMax={treeConfig.ratio * 0.9}
            offset={{ x: 0, y: 80, tree: treeConfig.treeOffset }}
          />
          <TreeGroup
            id="tree-group1-1"
            numOfTrees={treeConfig.trees}
            numOfAffected={treeConfig.numOfAffected}
            xMin={0}
            xMax={treeConfig.xMax}
            yMin={1}
            yMax={50}
            opacityMax={0}
            scaleMax={treeConfig.ratio * 1}
            offset={{ x: 0, y: 50, tree: treeConfig.treeOffset }}
          />
        </div>
        <div className="gfx-trees__section" id="trees__section2">
          <TreeGroup
            id="tree-group2-4"
            numOfTrees={treeConfig.trees}
            numOfAffected={treeConfig.numOfAffected}
            xMin={treeSectionSize - treeConfig.xMax}
            xMax={treeConfig.xMax}
            yMin={1}
            yMax={50}
            opacityMax={0.7}
            scaleMax={treeConfig.ratio * 0.7}
            offset={{ x: 0, y: 140, tree: treeConfig.treeOffset }}
          />
          <TreeGroup
            id="tree-group2-3"
            numOfTrees={treeConfig.trees}
            numOfAffected={treeConfig.numOfAffected}
            xMin={treeSectionSize - treeConfig.xMax}
            xMax={treeConfig.xMax}
            yMin={1}
            yMax={50}
            opacityMax={0.4}
            scaleMax={treeConfig.ratio * 0.8}
            offset={{ x: 0, y: 110, tree: treeConfig.treeOffset }}
          />
          <TreeGroup
            id="tree-group2-2"
            numOfTrees={treeConfig.trees}
            numOfAffected={treeConfig.numOfAffected}
            xMin={treeSectionSize - treeConfig.xMax}
            xMax={treeConfig.xMax}
            yMin={1}
            yMax={50}
            opacityMax={0.2}
            scaleMax={treeConfig.ratio * 0.9}
            offset={{ x: 0, y: 80, tree: treeConfig.treeOffset }}
          />
          <TreeGroup
            id="tree-group2-1"
            numOfTrees={treeConfig.trees}
            numOfAffected={treeConfig.numOfAffected}
            xMin={treeSectionSize - treeConfig.xMax}
            xMax={treeConfig.xMax}
            yMin={1}
            yMax={50}
            opacityMax={0}
            scaleMax={treeConfig.ratio * 1}
            offset={{ x: 0, y: 50, tree: treeConfig.treeOffset }}
          />
        </div>
      </div>
    </section>
  )
}

CO2Qn.defaultProps = {
  fullWidth: false,
}

export default CO2Qn
