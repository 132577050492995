import React, { useState } from 'react'
import classnames from 'classnames'


const Card = ({ image, title, subtitle, children, toHandler, toParams, className, external, ...props }) => {
  
  const [active, setActive] = useState(false)
  
  const _card = classnames('app-card', className,{
    'is-active': active
  })

  const handleClick = () => {
    if (!toHandler) return null
    setTimeout(() => {
      toHandler(toParams, external)
    }, 400)
  }

  const handleDown = () => {
    setActive(true)
  }

  const handleUp = () => {
    setTimeout(() => {
      setActive(false)
    }, 200)
  }

  return (
    <div className={_card} 
      onMouseDown={handleDown} 
      onMouseUp={handleUp} 
      onTouchStart={handleDown} 
      onTouchEnd={handleUp} 
      onClick={handleClick} {...props}>
      {
        image && (
          <figure>
            {image}
          </figure>
        )
      }
      {
        title && (
          <h2 dangerouslySetInnerHTML={{__html: title}} />
        )
      }    
      {
        subtitle && (
          <h3 dangerouslySetInnerHTML={{__html: subtitle}} />
        )
      }  
      
      {
        children
      }
    </div>
  )
}

export default Card
