import React from 'react'
import classnames from 'classnames'
import Helpers from 'spd-gds/helpers'

const Page = ({ className, children, type, title }) => {
  const _page = classnames('app-page', className, {
    'type-profile': type === 'profile',
    'type-generic': type === 'generic',
    'type-frame': type === 'frame',
    'type-landing': type === 'landing',
  })

  return (
    <>
      <div className={_page}>
        <div
          className={
            'app-page__container' +
            (Helpers.isInfinity() ? ' type-infinity' : '')
          }
        >
          {title && <h1>{title}</h1>}
          {children}
        </div>
      </div>
    </>
  )
}

Page.defaultProps = {
  type: 'generic',
}

export { Page }
export { default as WelcomePage } from './Welcome'
export { default as LandingPage } from './Landing'
export { default as QuestionnairePage } from './Questionnaire'
export { default as ResultsLatestPage } from './ResultsLatest'
export { default as LoadingPage } from './Loading'
export { default as ResultsPage } from './Results'
export { default as StartQuestionnairePage } from './StartQuestionnaire'
export { default as MobileMockLoginPage } from './MobileMockLogin'
export { default as MaintenancePage } from './Maintenance'
