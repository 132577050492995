import React from 'react'

import { Icons } from 'spd-gds/components/common'

const Loader = ({
  children
}) => {
  return (
    <div className='app-loader'>
      <Icons.SpinLoader color={['#00b0b2']} />
      { children }
    </div>
  )
}

export default Loader
