import { version } from '../../package.json'
const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME
const API_BASE = process.env.REACT_APP_API_BASE
const API_BASE_AUTHENTICATED = process.env.REACT_APP_API_BASE_AUTHENTICATED
const API_BASE_PUBLIC = process.env.REACT_APP_API_BASE_PUBLIC
const VERSION = version

const constants = {
  PROJECT_NAME,
  ENV: process.env.REACT_APP_ENV || 'dev',
  AUTH0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    client_id: process.env.REACT_APP_AUTH0_CLIENTID || '',
    redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI || '',
    logout_uri: process.env.REACT_APP_AUTH0_LOGOUT_URI || '',
    connection: process.env.REACT_APP_AUTH0_CONNECTION || '',
    target_uri: process.env.REACT_APP_AUTH0_TARGET_URI || '',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  },
  STORAGE: {
    fields: 'dyipne:fields',
    answers: 'dyipne:answers',
    data: 'dyipne:data',
    page: 'dyipne:page_flow',
    target: 'dyipne:target',
    guess_raintrees: 'dyipne:guess_raintrees',
  },
  API_ENDPOINTS: {
    base: API_BASE,
    isAuth: API_BASE_AUTHENTICATED,
    isPublic: API_BASE_PUBLIC,
    authGraphQL: '/authenticated/graphql',
    graphQL: '/graphql',
  },
  SURVEY: {
    name: 'survey_v1',
    uri: '/v1/survey/survey_v1',
  },
  URLS: {
    floormap_images: `${API_BASE}/static/floormaps`,
  },
  MESSAGES: {
    generic_error: 'Sorry, an error has occured. Please try again later.',
  },
  ENABLE_MAINTENANCE: {
    PAGE: false,
    BANNER: false,
  },
  RUM: {
    dev: {
      service: PROJECT_NAME,
      version: VERSION,
      sampleRate: 100,
      premiumSampleRate: 0,
      trackInteractions: true,
      allowedTracingOrigins: [
        /^https:\/\/.*\.spdigital\.sg/,
        'http://localhost:3000',
        'http://localhost:4001',
      ],
    },
    qa: {
      service: PROJECT_NAME,
      version: VERSION,
      sampleRate: 100,
      premiumSampleRate: 0,
      trackInteractions: true,
      allowedTracingOrigins: [
        /^https:\/\/.*\.spdigital\.sg/,
        'http://localhost:3000',
        'http://localhost:4001',
      ],
    },
    prod: {
      service: PROJECT_NAME,
      version: VERSION,
      sampleRate: 50,
      premiumSampleRate: 0,
      trackInteractions: true,
      allowedTracingOrigins: [
        /^https:\/\/.*\.spdigital\.sg/,
        /^https:\/\/.*\.spgroup\.com\.sg/,
      ],
    },
  },
}

export default constants
