import Helpers from 'spd-gds/helpers'
import Constants from 'spd-gds/constants'
import { getUserSessionToken } from 'spd-gds/components/hooks'
import API from './api.services'

const getInitialParams = () => {
  const userToken = getUserSessionToken()
  const API_ENDPOINT = !!userToken ? Constants.API_ENDPOINTS.isAuth : Constants.API_ENDPOINTS.isPublic
  return {userToken, API_ENDPOINT}
}

const FetchQuestionnaire = () => {
  const {userToken, API_ENDPOINT} = getInitialParams()
  const payload = Helpers.generateGqlQuery({ version: "2" }, [], 'questionnaire:fetch')
  return new Promise((resolve, reject) => {
    API.graphQLRequest({
      method: 'post',
      url: API_ENDPOINT,
      headers: userToken && {
        Authorization: `Bearer ${userToken}`
      }
    },
      payload.query,
      null
    ).then(res => {
      if (!res.data.errors) {
        if (res.data.data) {
          resolve(res.data.data)
        }
      } else {
        reject(res.data.errors.map(err => ({
          error: err.extensions.error,
          error_description: err.extensions.error_description,
          status: err.extensions.status
        })))
      }
    }).catch(err => {
      reject(err.errors)
    })
  })
}

const CalculateCO2Results = ({ answers }) => {
  const {userToken, API_ENDPOINT} = getInitialParams()
  const payload = Helpers.generateGqlQuery({ version: "2", answers }, [], 'questionnaire:calculate')
  return new Promise((resolve, reject) => {
    API.graphQLRequest({
      method: 'post',
      url: API_ENDPOINT,
      headers: userToken && {
        Authorization: `Bearer ${userToken}`
      }
    },
      payload.query,
      payload.variables
    ).then(res => {
      if (!res.data.errors) {
        if (res.data.data) {
          resolve({
            ...res.data.data,
            answers: payload.variables.input.answers
          })
        }
      } else {
        reject(res.data.errors.map(err => ({
          error: err.extensions.error,
          error_description: err.extensions.error_description,
          status: err.extensions.status
        })))
      }
    }).catch(err => {
      reject(err.errors)
    })
  })
}

const FetchCO2Result = () => {
  const {userToken, API_ENDPOINT} = getInitialParams()
  const payload = Helpers.generateGqlQuery({ version: "1" }, [], 'questionnaire:result')
  return new Promise((resolve, reject) => {
    API.graphQLRequest({
      method: 'post',
      url: API_ENDPOINT,
      headers: userToken && {
        Authorization: `Bearer ${userToken}`
      }
    },
      payload.query,
      null
    ).then(res => {
      if (!res.data.errors) {
        if (res.data.data) {
          resolve(res.data.data)
        }
      } else {
        reject(res.data.errors.map(err => ({
          error: err.extensions.error,
          error_description: err.extensions.error_description,
          status: err.extensions.status
        })))
      }
    }).catch(err => {
      reject(err.errors)
    })
  })
}

const SubmitCO2Mobile = ({ email, answers }) => {
  return SubmitCO2({ email, answers, version: '1' })
}

const SubmitCO2 = ({ email, answers, version }) => {
  const {userToken, API_ENDPOINT} = getInitialParams()
  const payload = Helpers.generateGqlQuery({ version: version ?? '2', answers }, [], 'questionnaire:submit')
  return new Promise((resolve, reject) => {
    API.graphQLRequest({
      method: 'post',
      url: API_ENDPOINT,
      headers: userToken && {
        Authorization: `Bearer ${userToken}`
      }
    },
      payload.query,
      payload.variables
    ).then(res => {
      if (!res.data.errors) {
        if (res.data.data) {
          resolve({
            ...res.data.data,
            answers: payload.variables.input.answers
          })
        }
      } else {
        reject(res.data.errors.map(err => ({
          error: err.extensions.error,
          error_description: err.extensions.error_description,
          status: err.extensions.status
        })))
      }
    }).catch(err => {
      reject(err.errors)
    })
  })
}

const SubmitCO2Target = ({ target }) => {
  const {userToken, API_ENDPOINT} = getInitialParams()
  const payload = Helpers.generateGqlQuery({ version: "1", target: target }, [], 'questionnaire:target')
  return new Promise((resolve, reject) => {
    API.graphQLRequest({
      method: 'post',
      url: API_ENDPOINT,
      headers: userToken && {
        Authorization: `Bearer ${userToken}`
      }
    },
      payload.query,
      payload.variables
    ).then(res => {
      if (!res.data.errors) {
        if (res.data.data) {
          resolve(res.data.data)
        }
      } else {
        reject(res.data.errors.map(err => ({
          error: err.extensions.error,
          error_description: err.extensions.error_description,
          status: err.extensions.status
        })))
      }
    }).catch(err => {
      reject(err.errors)
    })
  })
}

const services = {
  FetchQuestionnaire,
  FetchCO2Result,
  CalculateCO2Results,
  SubmitCO2,
  SubmitCO2Mobile,
  SubmitCO2Target
}

export default services
