import React from 'react'

const Cloud3 = ({x, y}) => {
  return (
    <svg style={{ '--cloudX': x, '--cloudY': y}} className="gfx-skycloud qnnre-cloud3 gfx-cloud3" width="136" height="67" viewBox="0 0 136 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5165 32.4186C19.3793 32.4186 19.2386 32.4155 19.1014 32.4155C8.55167 32.4155 0 40.1586 0 49.7077C0 59.26 8.55167 67 19.1014 67C20.1954 67 21.2754 66.9172 22.3202 66.7547H117.757C124.81 66.7547 130.892 62.8242 133.478 57.2629C135.085 54.6288 136 51.5997 136 48.3795C136 40.4261 130.417 33.6513 122.611 31.0936C122.988 29.415 123.185 27.6759 123.185 25.8954C123.185 11.594 110.38 0 94.5854 0C85.4111 0 77.2499 3.91139 72.0155 9.99187C68.0932 7.3864 63.2528 5.84797 58.0148 5.84797C47.444 5.84797 38.4913 12.1132 35.4308 20.7673C34.8047 20.6877 34.1609 20.6463 33.5102 20.6463C26.1053 20.6463 20.0407 25.8381 19.5165 32.4186Z" fill="white"/>
    </svg>
  )
}

export default Cloud3
