import React from 'react'
import { Portal, Icons } from 'spd-gds/components/common'
import classnames from 'classnames'
import "./styles.scss"
const Drawer = ({ children, className, title, open, handleChange }) => {
  const _Drawer = classnames('drawer', className, {
    'open-drawer': open,
    'close-drawer': !open
  })

  return (
    <Portal>
      <div className={open ? 'drawer-bg open' : 'drawer-bg close'} onClick={handleChange}></div>
      <div className={_Drawer}>
        <button
          className='drawer-close-btn'
          onClick={handleChange}
        >
          <Icons.Close width={12} height={12} />
        </button>
        <h4>{title}</h4>
        {children}
      </div>
    </Portal>
  )
}

export default Drawer
