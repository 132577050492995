import React from 'react'
import PropTypes from 'prop-types'

const Stump1 = ({ id }) => {
  return (
    <svg id={id} className="gfx-tree__stump qnnre-Stump1" width="176" height="62" viewBox="0 0 191 77" fill="none" 
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#qnnre-stomp1_clip0)">
        <path fillRule="evenodd" clipRule="evenodd" d="M95.4246 76.9553C148.126 76.9553 190.849 72.1318 190.849 66.1816C190.849 60.2314 148.126 55.4078 95.4246 55.4078C42.723 55.4078 0 60.2314 0 66.1816C0 72.1318 42.723 76.9553 95.4246 76.9553Z" fill="url(#qnnre-stomp1_paint0_linear)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.3911 20.0289L16.5527 61.5642L41.6694 60.9226L52.3297 60.4075H61.6987L122.097 57.9035L166.224 55.4012L153.91 15.3911L15.3911 20.0289Z" fill="#4A4A7C"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M146.478 11.5236L62.1088 13.9821L35.4948 14.7596L30.4891 0L22.6533 15.132C17.9859 16.3104 15.3596 17.5601 15.3914 18.8016C15.468 21.9196 44.0016 27.1156 74.2998 29.2241L81.8683 18.4556L83.6795 29.7777C91.6106 30.1501 99.4744 30.2763 106.796 30.0626C111.979 29.912 116.61 29.5945 120.769 29.1528L118.897 21.0159C118.897 21.0159 126.129 24.0098 133.743 27.0891C149.573 23.5234 153.983 17.7494 153.91 14.7616C153.878 13.5201 151.194 12.4252 146.478 11.5236Z" fill="#FFDEB3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M134.907 43.095L103.202 57.0661L83.1117 76.9553L133.975 62.0086L181.614 62.1985L167.414 50.4572L134.907 43.095Z" fill="#FFDEB3"/>
      </g>
      <defs>
        <linearGradient id="qnnre-stomp1_paint0_linear" x1="193.542" y1="63.1209" x2="187.96" y2="45.5183" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F8FCFF"/>
          <stop offset="1" stopColor="#E4EBF2"/>
        </linearGradient>
        <clipPath id="qnnre-stomp1_clip0">
          <path d="M0 0H190.849V76.9553H0V0Z" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

Stump1.propTypes = {
  id: PropTypes.string
}

Stump1.defaultProps = {
  id: ''
}

export default Stump1
