import React from 'react'

const Cloud1 = ({x, y}) => {
  return (
    <svg style={{ '--cloudX': x, '--cloudY': y}} className='gfx-skycloud qnnre-cloud1 gfx-cloud1' width="155" height="77" viewBox="0 0 155 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M132.507 37.353C132.663 37.353 132.822 37.3494 132.978 37.3494C144.934 37.3494 154.625 46.1068 154.625 56.9067C154.625 67.7102 144.934 76.464 132.978 76.464C131.738 76.464 130.514 76.3703 129.33 76.1866H21.1744C13.1813 76.1866 6.28851 71.7413 3.35837 65.4515C1.53651 62.4724 0.5 59.0465 0.5 55.4045C0.5 46.4094 6.8267 38.7471 15.6729 35.8544C15.2463 33.9559 15.0231 31.989 15.0231 29.9753C15.0231 13.8006 29.5342 0.687988 47.434 0.687988C57.8309 0.687988 67.0798 5.11171 73.0118 11.9886C77.4569 9.0419 82.9424 7.30195 88.8784 7.30195C100.858 7.30195 111.004 14.3878 114.472 24.1755C115.182 24.0854 115.911 24.0386 116.649 24.0386C125.041 24.0386 131.913 29.9105 132.507 37.353Z" fill="white"/>
    </svg>
  )
}

export default Cloud1
