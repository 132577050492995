import React from 'react'
import classnames from 'classnames'

const Form = ({
  title,
  description,
  children,
  className,
  ...props
}) => {
  const _Form = classnames(`app-form`, className)
  return (
    <div className={_Form}>
      {
        title && (
          <h1>{title}</h1>
        )
      }

      {
        description && (
          <p>{description}</p>
        )
      }

      <form
        {...props}
      >
        {children}
      </form>

    </div>
  )
}

export { Form }
export { default as FormInput } from './FormInput'
export { default as FormOtpInput } from './FormOtpInput'
export { default as FormPrefixInput } from './FormPrefixInput'
export { default as FormCTA } from './FormCTA'
export { default as FormErrorBlock } from './FormErrorBlock'
export { default as FormCheckBox } from './FormCheckBox'
export { default as FormChoose } from './FormChoose'
export { default as FormDate } from './FormDate'
export { default as FormField } from './FormField'
export { default as FormGroup } from './FormGroup'
export { default as FormMobileNumber } from './FormMobileNumber'
export { default as FormSelectOption } from './FormSelectOption'
export { default as FormSplitInput } from './FormSplitInput'
export { default as FormSwitchRadio } from './FormSwitchRadio'
export { default as FormSwitchRadioGroup } from './FormSwitchRadioGroup'
