import React, { useState, useEffect } from 'react'

import Tree1 from './Tree1'
import Tree2 from './Tree2'

function getRandomValues(min, max) {
  return Math.floor(Math.random() * max) + min
}

function getMinMax(val, min, max) {
  return Math.min(Math.max(min, val), max)
}

function getDepth(val, yMax) {
  return getMinMax(Math.floor((val / yMax * 100)), 1, 100)
}

const TreeGroup = ({ id, numOfTrees, numOfAffected, opacityMin, opacityMax, scaleMin, scaleMax, xMin, xMax, yMin, yMax, offset }) => {
  
  const [trees, setTrees] = useState([])
  
  useEffect(() => {
    const cachedPos = []
    const posOffset = offset.tree || 3 // Set this back to 50 for desktop and dynamically set in responsive
    function generatePosition(item, xMin, xMax, yMin, yMax) {
      const result = { x: getRandomValues(xMin, xMax), y: getRandomValues(yMin, yMax)}
      
      const posIdx = cachedPos.findIndex(pos => (result.x >= (pos.x - posOffset)) && (result.x <= (pos.x + posOffset)) )

      if (posIdx > -1) {
        return generatePosition(item, xMin, xMax, yMin, yMax)
      } else {
        cachedPos.push(result)
      }
      return result
    }

    function generateTrees() {
      const nominated = getNominatedTrees()
      const _trees = Array.from(
        Array(numOfTrees).keys()
      ).map((item, idx) => {
        const treeNo = getRandomValues(1, 2)
        const treeId = `${id}-tree-${item}-${treeNo}`
        const pos = generatePosition(id, xMin, xMax, yMin, yMax)
        return {
          treeNo,
          id: treeId,
          isAffected: !!nominated[idx],
          overlayOpacity: opacityMax,
          x: pos.x,
          y: 50,
          scale: scaleMax,
          depth: getDepth(50, yMax)
        }
      })
      return _trees
    }

    const treesToRender = generateTrees()

    setTrees(treesToRender)
  }, [numOfTrees, scaleMax, offset.tree, xMax])

  useEffect(() => {
    // When numOfAffected is changed
    affectTrees()
  }, [numOfAffected])

  function getNominatedTrees() {
    if (numOfTrees === 0 ) return {}
    if (numOfAffected === 0) return {}

    // Affect trees randomly
    const nominated = {}
    for(let i = 0; i < numOfAffected; i++) {
      while(true) {
        const randomTree = getRandomValues(0, numOfTrees)
        if (!nominated[randomTree]) {
          nominated[randomTree] = true
          break
        } 
      }
    }
    return nominated
  }

  function affectTrees() {
    if (trees.length === 0 ) return 
    if (numOfAffected === 0) return

    const nominated = getNominatedTrees()

    const newAffectedTrees = trees.map((tree, idx) => {
      return {
        ...tree,
        isAffected: !!nominated[idx]
      }
    })
    setTrees(newAffectedTrees)
  }

  function renderTrees() {
    if (!trees || trees.length === 0) return null 

    return trees.map( tree => {
      if (tree.treeNo === 1) {
        return <Tree1 key={tree.id} {...tree} />
      }
      return <Tree2 key={tree.id} {...tree} />
    })
  }

  return (
    <div id={id} className='gfx-treegroup' style={{'--treeGroupOffsetX': offset.x, '--treeGroupOffsetY': offset.y }}>
      { renderTrees() }
    </div>
  )
}

TreeGroup.defaultProps = {
  id: '',
  numOfTrees: 1,
  numOfAffected: 0,
  opacityMin: 1,
  opacityMax: 1,
  scaleMin: 1,
  scaleMax: 1,
  xMin: 0,
  xMax: 500,
  yMin: 0,
  yMax: 100,
  offset: { x: 0, y: 0, tree: 40}
}

export default TreeGroup
