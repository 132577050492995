import React from 'react'

const Airplane = ({ x, y}) => {
  return (
    <svg className="gfx-airplane" style={{
      '--airplaneX': x,
      '--airplaneY': y
    }} width="103" height="35" viewBox="0 0 103 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M99.4653 1.6669C99.4653 1.6669 98.4025 0.638082 97.9567 0.445934C91.92 -2.17058 66.7002 7.26335 47.1615 14.7593C47.1516 14.7626 47.1439 14.7659 47.1351 14.7692C38.786 14.0786 26.9925 13.6833 21.7957 13.6416C21.1007 13.635 18.5984 14.3772 19.2198 14.6836C23.2044 16.6468 28.3869 18.5803 33.2434 20.1197C27.4394 22.3508 23.2835 23.8573 22.0801 23.9901L22.0768 23.9912C19.9741 22.3739 8.53748 13.6657 4.20371 12.234C3.30116 11.9364 0.779078 13.6888 0.81641 13.9622C0.917425 14.7077 8.09828 25.9325 10.4227 29.5515C7.34177 29.5998 4.08074 29.703 2.55453 29.703C1.62343 29.703 -0.704306 30.6989 0.208124 30.8888C1.73982 31.2061 4.44197 31.6508 6.9948 31.9769C6.54572 32.2745 6.2284 32.505 6.18119 32.5896C6.03186 32.8663 6.48643 34.1059 6.85975 34.5177C7.17268 34.8679 26.8596 33.1067 27.1319 33.1089H27.133C32.9711 33.1507 46.6553 28.7236 60.4175 23.6256C77.858 17.1672 95.4214 9.63391 97.288 8.76101C97.2902 8.75991 97.2957 8.75552 97.3056 8.74893C98.6726 8.10551 100.367 6.9087 101.449 5.67456C101.452 5.67127 101.452 5.67017 101.454 5.66688C103.011 3.88703 103.284 2.03912 99.4653 1.6669Z" fill="#E4EBF2"/>
    </svg>
  )
}

Airplane.defaultProps = {
  x: 0,
  y: 0,
}

export default Airplane
