import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Stump2 from './Stump2'
import CO2Cloud from './CO2Cloud'

const Tree2 = ({ id, x, y, overlayOpacity, depth, scale, isAffected }) => {

  const _Tree2 = classnames('gfx-tree', {
    'is-affected': isAffected
  })

  return (
    <figure className={_Tree2} id={id} style={{'--treeX': `${x}px`, '--treeY': `${y}px`, '--treeOverlayOpacity': overlayOpacity, '--treeScale': scale, zIndex: depth }}>
      <CO2Cloud onlyMain/>
      <svg className="gfx-tree2" width="349" height="438" viewBox="0 0 349 438" fill="none" 
    xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#gfx-tree2_clip0)">
          <path fillRule="evenodd" clipRule="evenodd" d="M222.663 28.526C227.367 25.3899 232.594 23.5605 238.343 23.5605C253.24 23.5605 266.046 36.3663 271.795 54.6602C275.454 52.0467 279.635 50.74 283.817 50.74C297.145 50.74 308.383 64.3298 311.258 82.6237H311.78C332.165 82.6237 348.63 106.667 348.63 136.199C348.63 151.618 344.187 165.208 337.131 175.139C335.824 203.102 319.882 225.316 300.281 225.316C299.759 225.316 299.236 225.316 298.975 225.316C296.1 243.61 284.862 257.461 271.272 257.461C265 257.461 258.989 254.325 254.285 249.098C247.752 258.507 238.605 264.517 228.412 264.517C223.708 264.517 219.004 263.211 214.823 260.859C209.073 266.085 202.54 268.96 195.222 268.96C189.995 268.96 185.03 267.392 180.587 264.517C174.315 272.358 165.952 277.062 157.066 277.062C146.09 277.062 136.159 270.006 129.364 258.768C124.921 261.643 119.956 263.211 114.729 263.211C104.798 263.211 95.651 257.461 88.8561 247.792C86.7654 248.314 84.936 248.576 82.8453 248.576C72.1303 248.576 62.722 242.042 55.9271 231.588C39.9852 230.804 26.6568 215.385 22.214 194.217C12.5444 187.683 5.74951 173.309 5.74951 156.845C5.74951 151.357 6.53354 146.13 7.84025 141.426C3.1361 133.585 0 123.393 0 112.155C0 89.4186 12.5444 71.1247 28.2249 71.1247C30.3156 71.1247 32.4063 71.386 34.4971 72.17C38.9399 48.388 53.8363 31.1394 71.6076 31.1394C78.1411 31.1394 84.4133 33.4915 89.9015 37.9343C96.6964 25.9126 106.889 18.3337 118.388 18.3337C124.66 18.3337 130.671 20.6858 135.898 24.8672C142.17 9.70943 153.669 0.0397949 166.736 0.0397949C179.28 0.0397949 190.257 8.9254 196.79 22.7765C199.665 21.2085 202.801 20.4244 205.937 20.4244C212.209 20.4244 218.22 23.5605 222.663 28.526Z" fill="#5C9A00"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M337.392 175.398C336.085 203.361 320.143 225.314 300.543 225.314C300.02 225.314 299.497 225.314 299.236 225.314C296.361 243.608 285.124 257.459 271.534 257.459C265.262 257.459 259.251 254.323 254.547 249.096C248.013 258.504 238.866 264.515 228.674 264.515C223.97 264.515 219.266 263.209 215.084 260.856C204.892 270.265 191.825 271.571 180.848 264.515C165.691 283.07 142.692 280.457 129.364 258.504C124.921 261.379 119.956 262.947 114.729 262.947C104.798 262.947 95.651 257.198 88.8561 247.528C86.7654 248.051 84.936 248.312 82.8453 248.312C72.1303 248.312 62.722 241.779 55.9271 231.325C39.9852 230.541 26.6568 215.122 22.214 193.953C12.5444 187.419 5.74951 173.046 5.74951 156.581C5.74951 153.445 6.01085 150.57 6.53354 147.696C11.7604 166.251 43.1213 212.247 83.8906 203.623C95.9123 219.042 113.945 222.962 128.841 214.599C147.658 247.528 204.892 242.301 223.97 216.69C238.605 224.269 255.853 220.087 267.614 204.929C287.998 226.621 323.018 197.873 337.392 175.398Z" fill="#4E8600"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M337.392 175.398C336.085 203.361 320.143 225.314 300.543 225.314C300.02 225.314 299.497 225.314 299.236 225.314C296.361 243.608 285.124 257.459 271.534 257.459C265.262 257.459 259.251 254.323 254.547 249.096C248.013 258.504 238.866 264.515 228.674 264.515C223.97 264.515 219.266 263.209 215.084 260.856C204.892 270.265 191.825 271.571 180.848 264.515C165.691 283.07 142.692 280.457 129.364 258.504C124.921 261.379 119.956 262.947 114.729 262.947C104.798 262.947 95.651 257.198 88.8561 247.528C86.7654 248.051 84.936 248.312 82.8453 248.312C72.1303 248.312 62.722 241.779 55.9271 231.325C39.9852 230.541 26.6568 215.122 22.214 193.953C12.5444 187.419 5.74951 173.046 5.74951 156.581C5.74951 153.445 6.01085 150.57 6.53354 147.696C11.7604 166.251 43.1213 212.247 83.8906 203.623C95.9123 219.042 113.945 222.962 128.841 214.599C147.658 247.528 204.892 242.301 223.97 216.69C238.605 224.269 255.853 220.087 267.614 204.929C287.998 226.621 323.018 197.873 337.392 175.398Z" fill="#86995E"/>
          <path d="M172.38 437.106C206.381 437.106 233.944 430.904 233.944 423.254C233.944 415.604 206.381 409.402 172.38 409.402C138.379 409.402 110.816 415.604 110.816 423.254C110.816 430.904 138.379 437.106 172.38 437.106Z" fill="url(#gfx-tree2_paint0_linear)"/>
          <path d="M192.258 363.356C191 343.277 190.8 321.386 193.915 306.031C200.187 275.454 209.073 262.649 223.185 249.059C224.522 247.856 237.618 235.372 250.479 223.112C262.763 211.402 274.833 199.897 276.238 198.62L243.309 205.415C243.309 205.415 208.027 245.4 196.528 248.536C184.768 251.672 178.496 230.242 178.496 208.028L163.861 207.506C163.861 207.506 166.735 229.981 160.463 242.787C157.327 249.32 148.442 246.184 140.34 241.741C134.405 238.467 115.809 219.646 107.77 211.511C105.543 209.257 104.127 207.824 104.013 207.767L65.335 193.393C70.5618 196.529 128.841 254.024 132.761 258.467C142.692 269.705 147.919 284.863 148.703 300.543C150.039 325.047 147.663 358.412 145.361 382.655L141.598 414.02L140.857 416.805L127.746 429.23L136.646 430.95L153.203 424.957L170.45 427.946L179.035 424.793H186.522L188.288 421.395L191.148 420.345H229.327L225.646 416.764L195.986 402.495C194.85 393.412 193.357 379.954 192.388 365.378V363.229L192.258 363.356Z" fill="url(#gfx-tree2_paint1_linear)"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M222.663 28.524C244.616 13.6275 272.318 35.0575 271.795 54.6581C291.657 40.807 317.269 67.2025 311.519 82.3603C349.936 81.8376 357.777 146.65 337.392 174.875C337.131 181.147 337.131 178.534 337.392 175.137C323.018 197.873 287.999 226.36 267.614 204.668C255.854 219.826 238.605 224.007 223.97 216.429C205.153 242.04 147.92 247.005 128.842 214.338C113.945 222.701 95.9126 218.781 83.8908 203.361C43.1216 212.247 11.7606 166.251 6.79511 147.696V148.218C7.05645 145.866 7.57914 143.514 8.36316 141.162C-8.62403 116.335 3.92036 62.237 34.7587 72.168C31.8839 48.1246 63.7676 17.0249 90.163 37.9323C100.094 20.4224 118.911 11.2754 136.159 24.8652C142.17 -4.66641 183.985 -10.9386 196.79 22.7745C206.199 18.0703 215.868 20.9451 222.663 28.524Z" fill="url(#gfx-tree2_paint2_linear)"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M93.0373 51.2602C74.7434 53.6123 53.5747 75.0423 50.9613 104.574C33.7128 60.9299 66.3805 29.8302 93.0373 51.2602Z" fill="#D4EDA7"/>
        </g>
        <g className='tree-overlay'>
          <path data-id="tree-overlay_shadow" fillRule="evenodd" clipRule="evenodd" d="M337.392 175.398C336.085 203.361 320.143 225.314 300.543 225.314C300.02 225.314 299.497 225.314 299.236 225.314C296.361 243.608 285.124 257.459 271.534 257.459C265.262 257.459 259.251 254.323 254.547 249.096C248.013 258.504 238.866 264.515 228.674 264.515C223.97 264.515 219.266 263.209 215.084 260.856C204.892 270.265 191.825 271.571 180.848 264.515C165.691 283.07 142.692 280.457 129.364 258.504C124.921 261.379 119.956 262.947 114.729 262.947C104.798 262.947 95.651 257.198 88.8561 247.528C86.7654 248.051 84.936 248.312 82.8453 248.312C72.1303 248.312 62.722 241.779 55.9271 231.325C39.9852 230.541 26.6568 215.122 22.214 193.953C12.5444 187.419 5.74951 173.046 5.74951 156.581C5.74951 153.445 6.01085 150.57 6.53354 147.696C11.7604 166.251 43.1213 212.247 83.8906 203.623C95.9123 219.042 113.945 222.962 128.841 214.599C147.658 247.528 204.892 242.301 223.97 216.69C238.605 224.269 255.853 220.087 267.614 204.929C287.998 226.621 323.018 197.873 337.392 175.398Z" fill="#ffffff" fillOpacity={overlayOpacity}/>
          <path data-id="tree-overlay_trunk" d="M192.258 363.356C191 343.277 190.8 321.386 193.915 306.031C200.187 275.454 209.073 262.649 223.185 249.059C224.522 247.856 237.618 235.372 250.479 223.112C262.763 211.402 274.833 199.897 276.238 198.62L243.309 205.415C243.309 205.415 208.027 245.4 196.528 248.536C184.768 251.672 178.496 230.242 178.496 208.028L163.861 207.506C163.861 207.506 166.735 229.981 160.463 242.787C157.327 249.32 148.442 246.184 140.34 241.741C134.405 238.467 115.809 219.646 107.77 211.511C105.543 209.257 104.127 207.824 104.013 207.767L65.335 193.393C70.5618 196.529 128.841 254.024 132.761 258.467C142.692 269.705 147.919 284.863 148.703 300.543C150.039 325.047 147.663 358.412 145.361 382.655L141.598 414.02L140.857 416.805L127.746 429.23L136.646 430.95L153.203 424.957L170.45 427.946L179.035 424.793H186.522L188.288 421.395L191.148 420.345H229.327L225.646 416.764L195.986 402.495C194.85 393.412 193.357 379.954 192.388 365.378V363.229L192.258 363.356Z" fill="#ffffff" fillOpacity={overlayOpacity}/>
          <path data-id="tree-overlay_canopy" fillRule="evenodd" clipRule="evenodd" d="M222.663 28.524C244.616 13.6275 272.318 35.0575 271.795 54.6581C291.657 40.807 317.269 67.2025 311.519 82.3603C349.936 81.8376 357.777 146.65 337.392 174.875C337.131 181.147 337.131 178.534 337.392 175.137C323.018 197.873 287.999 226.36 267.614 204.668C255.854 219.826 238.605 224.007 223.97 216.429C205.153 242.04 147.92 247.005 128.842 214.338C113.945 222.701 95.9126 218.781 83.8908 203.361C43.1216 212.247 11.7606 166.251 6.79511 147.696V148.218C7.05645 145.866 7.57914 143.514 8.36316 141.162C-8.62403 116.335 3.92036 62.237 34.7587 72.168C31.8839 48.1246 63.7676 17.0249 90.163 37.9323C100.094 20.4224 118.911 11.2754 136.159 24.8652C142.17 -4.66641 183.985 -10.9386 196.79 22.7745C206.199 18.0703 215.868 20.9451 222.663 28.524Z" fill="#ffffff" fillOpacity={overlayOpacity}/>
          <path data-id="tree-overlay_highlight" fillRule="evenodd" clipRule="evenodd" d="M93.0373 51.2602C74.7434 53.6123 53.5747 75.0423 50.9613 104.574C33.7128 60.9299 66.3805 29.8302 93.0373 51.2602Z" fill="#ffffff" fillOpacity={overlayOpacity}/>
        </g>
        <defs>
          <linearGradient id="gfx-tree2_paint0_linear" x1="235.682" y1="419.319" x2="200.572" y2="371.302" gradientUnits="userSpaceOnUse">
            <stop stopColor="#E4EBF2"/>
            <stop offset="1" stopColor="#F8FCFF"/>
          </linearGradient>
          <linearGradient id="gfx-tree2_paint1_linear" x1="169.302" y1="241.64" x2="170.786" y2="430.95" gradientUnits="userSpaceOnUse">
            <stop stopColor="#C1904F"/>
            <stop offset="1" stopColor="#FFC466"/>
          </linearGradient>
          <linearGradient id="gfx-tree2_paint2_linear" x1="213.8" y1="139.526" x2="181.69" y2="10.7397" gradientUnits="userSpaceOnUse">
            <stop stopColor="#B1D07B"/>
            <stop offset="1" stopColor="#D2EBA4"/>
          </linearGradient>
          <clipPath id="gfx-tree2_clip0">
            <path d="M0 0H348.63V437.106H0V0Z" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <Stump2 id={`${id}-Stump2`} />
    </figure>
  )
}

Tree2.propTypes = {
  id: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  overlayOpacity: PropTypes.number,
  depth: PropTypes.number,
  scale: PropTypes.number,
  isAffected: PropTypes.bool,
}

Tree2.defaultProps = {
  id: '',
  x: 0,
  y: 0,
  overlayOpacity: 0,
  depth: 1,
  scale: 1,
  isAffected: false
}

export default Tree2
