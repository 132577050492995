import React from 'react'

const MentionAppIcon = () => {
  return (
    <svg width="96" height="96" viewBox="0 0 110 107" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M82.6956 29.6513C76.1465 29.1721 69.6263 28.1683 63.2513 26.3121C58.839 25.0269 54.3207 24.2653 49.7828 24.0474C39.0083 23.5295 27.6237 26.4115 19.612 35.0311C11.6007 43.6507 8.10952 58.8989 13.6329 69.959C19.1235 80.9551 30.9348 84.5107 41.5924 85.8967C51.1856 87.1437 60.864 87.4457 70.498 86.7969C78.9913 86.2259 88.1818 84.4978 94.0362 77.1276C98.7718 71.1659 100.41 62.2912 99.2874 54.1837C98.1645 46.0759 94.5432 38.6956 89.9927 32.5313C88.5423 30.5672 85.7705 29.8763 82.6956 29.6513Z" fill="#FAFDFF" />
      <mask id="mask0_3181_16517" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="13" width="110" height="86">
        <path fillRule="evenodd" clipRule="evenodd" d="M82.6956 29.6513C76.1465 29.1721 69.6263 28.1683 63.2513 26.3121C58.839 25.0269 54.3207 24.2653 49.7828 24.0474C39.0083 23.5295 27.6237 26.4115 19.612 35.0311C11.6007 43.6507 8.10952 58.8989 13.6329 69.959C19.1235 80.9551 30.9348 84.5107 41.5924 85.8967C51.1856 87.1437 60.864 87.4457 70.498 86.7969C78.9913 86.2259 88.1818 84.4978 94.0362 77.1276C98.7718 71.1659 100.41 62.2912 99.2874 54.1837C98.1645 46.0759 94.5432 38.6956 89.9927 32.5313C88.5423 30.5672 85.7705 29.8763 82.6956 29.6513Z" fill="#FAFDFF" />
      </mask>
      <g mask="url(#mask0_3181_16517)">
        <path fillRule="evenodd" clipRule="evenodd" d="M73.911 92.0004H36.9214C32.3137 92.0004 28.5439 89.1782 28.5439 85.7289V22.0804C28.5439 18.6308 32.3137 15.8086 36.9214 15.8086H73.911C78.5183 15.8086 82.2881 18.6308 82.2881 22.0804V85.7289C82.2881 89.1782 78.5183 92.0004 73.911 92.0004Z" fill="url(#paint0_linear_3181_16517)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M43.3859 19.9066C43.3859 20.7983 44.3518 21.5214 45.543 21.5214H65.2892C66.4807 21.5214 67.4466 20.7983 67.4466 19.9066V19.0654H74.5557C76.7627 19.0654 78.5515 20.4046 78.5515 22.0566V85.753C78.5515 87.4048 76.7627 88.7442 74.5557 88.7442H36.2767C34.0697 88.7442 32.2806 87.4048 32.2806 85.753V22.0566C32.2806 20.4046 34.0697 19.0654 36.2767 19.0654H43.3859V19.9066Z" fill="white" />
      </g>
      <mask id="mask1_3181_16517" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="18" y="11" width="75" height="60">
        <path d="M18.6576 11.7305H92.5802V70.0194H18.6576V11.7305Z" fill="white" />
      </mask>
      <g mask="url(#mask1_3181_16517)">
        <path fillRule="evenodd" clipRule="evenodd" d="M73.911 117.584H36.9214C32.3137 117.584 28.5439 113.693 28.5439 108.938V21.1912C28.5439 16.4357 32.3137 12.5449 36.9214 12.5449H73.911C78.5183 12.5449 82.2881 16.4357 82.2881 21.1912V108.938C82.2881 113.693 78.5183 117.584 73.911 117.584Z" fill="url(#paint1_linear_3181_16517)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M43.3859 18.1938C43.3859 19.4232 44.3518 20.42 45.543 20.42H65.2892C66.4807 20.42 67.4466 19.4232 67.4466 18.1938V17.0342H74.5557C76.7627 17.0342 78.5515 18.8803 78.5515 21.1578V108.97C78.5515 111.248 76.7627 113.094 74.5557 113.094H36.2767C34.0697 113.094 32.2806 111.248 32.2806 108.97V21.1578C32.2806 18.8803 34.0697 17.0342 36.2767 17.0342H43.3859V18.1938Z" fill="white" />
      </g>
      <g filter="url(#filter0_dd_3181_16517)">
        <path d="M74 43C74 38.5817 70.4183 35 66 35H46C41.5817 35 38 38.5817 38 43V63C38 67.4183 41.5817 71 46 71H66C70.4183 71 74 67.4183 74 63V43Z" fill="white" />
      </g>
      <path d="M67.8749 54.0453C67.6691 56.4085 66.7709 58.6573 65.2938 60.5073C63.8168 62.3573 61.8272 63.7255 59.5767 64.4387C57.3262 65.152 54.9159 65.1783 52.6505 64.5143C50.3851 63.8504 48.3664 62.526 46.8498 60.7087C45.3331 58.8914 44.3865 56.6627 44.1297 54.3046C43.8729 51.9464 44.3174 49.5646 45.4071 47.4605C46.4967 45.3563 48.1826 43.6242 50.2514 42.4833C52.3202 41.3423 54.6792 40.8437 57.0298 41.0506C58.5907 41.1878 60.1094 41.6328 61.4992 42.3601C62.889 43.0875 64.1226 44.0829 65.1297 45.2896C66.1368 46.4963 66.8976 47.8906 67.3686 49.3929C67.8396 50.8952 68.0117 52.4761 67.8749 54.0453Z" fill="white" />
      <path d="M51.0948 63.9413C53.2397 64.9098 55.6213 65.2198 57.9408 64.8324C60.2603 64.4451 62.4143 63.3775 64.1325 61.7639C65.8507 60.1502 67.0565 58.0622 67.5985 55.7621C68.1406 53.462 67.9948 51.0521 67.1794 48.835C66.9022 51.4153 65.618 57.2272 59.5161 58.6403C52.3371 60.2918 52.3611 62.2872 51.0948 63.9413Z" fill="url(#paint2_radial_3181_16517)" />
      <path d="M57.0299 41.0513C55.0092 40.874 52.977 41.2174 51.125 42.0489C49.273 42.8805 47.6624 44.1728 46.4452 45.804C45.228 47.4351 44.4443 49.3512 44.1682 51.3713C43.8921 53.3914 44.1327 55.449 44.8672 57.3497C45.4447 55.2106 47.3723 50.4395 53.2286 49.4426C60.8311 48.1525 62.769 45.8809 63.3242 43.703L63.3576 43.5706C61.5378 42.1285 59.3383 41.2528 57.0299 41.0513Z" fill="url(#paint3_radial_3181_16517)" />
      <path d="M46.1403 46.2625C48.4084 42.7807 52.8214 40.4429 57.7947 41.1396C50.1375 41.1267 44.7954 47.9156 48.3639 53.3731C50.1999 56.1797 54.0594 58.1425 55.2768 59.2168C56.7852 60.5465 56.928 61.81 56.792 62.6211C56.6389 63.5354 55.8902 64.1547 55.4334 64.362C54.2296 64.9099 52.8667 64.5727 52.835 64.5641C45.5688 62.6538 41.1977 53.8556 46.1403 46.2625Z" fill="url(#paint4_radial_3181_16517)" />
      <path d="M65.8618 59.7363C63.5946 63.2181 59.1713 65.6178 54.2074 64.86C61.8638 64.8729 67.2067 58.084 63.6382 52.6265C61.8022 49.82 57.9427 47.8572 56.7244 46.7829C55.2152 45.4531 55.0715 44.1905 55.2075 43.3785C55.3607 42.4642 56.1084 41.8449 56.5661 41.6376C57.7699 41.0897 59.1166 41.4561 59.1499 41.4656C66.4162 43.3759 70.8026 52.144 65.8618 59.7363Z" fill="url(#paint5_radial_3181_16517)" />
      <path d="M46.0984 46.2829C48.3647 42.8011 52.8299 40.3816 57.7947 41.1394C49.1322 41.1204 44.3334 48.1605 48.1312 54.0102C49.9578 56.8228 54.3716 58.7409 55.4394 59.6303C56.6431 60.6314 56.9246 61.8098 56.792 62.6209C56.723 63.0136 56.5569 63.3826 56.309 63.694C56.0612 64.0054 55.7395 64.2492 55.3735 64.4031C54.1192 64.9191 52.9403 64.5906 52.7931 64.551C45.5534 62.6106 41.1652 53.8606 46.0984 46.2829ZM65.902 59.7163C63.6373 63.1989 59.1696 65.6176 54.2057 64.8598C62.8673 64.8787 67.667 57.8386 63.8683 51.9889C62.0417 49.1763 57.6287 47.2583 56.5602 46.3689C55.3572 45.3677 55.0758 44.1902 55.2075 43.3783C55.2766 42.9852 55.4428 42.6159 55.6908 42.3042C55.9388 41.9925 56.2606 41.7484 56.6269 41.5944C57.8803 41.0783 59.0592 41.4069 59.2073 41.4464C66.447 43.3886 70.8343 52.1386 65.902 59.7163Z" fill="url(#paint6_radial_3181_16517)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.3568 61.0977L33.103 68.5189L29.1751 70.7954L34.9676 78.9793L22.007 71.1028L25.1493 69.2816L19.3568 61.0977Z" fill="#FFD049" />
      <path d="M86.1262 34.5639C86.1262 34.5639 87.0836 37.4331 81.9898 38.9112C79.222 39.7141 76.9654 42.1507 79.1481 46.3584C79.4818 44.5801 80.6439 40.6926 84.4825 40.0853C84.4825 40.0853 80.8775 41.0994 80.1476 46.9102C81.9345 47.3002 86.1552 47.7987 87.6712 44.2919C89.6731 39.6584 86.1262 34.5639 86.1262 34.5639Z" fill="#90C734" />
      <path d="M89.6731 67.4131C89.6731 67.4131 89.0182 66.0548 87.6427 66.4664C86.828 66.7102 86.2319 67.8181 86.5436 68.9743C87.1671 71.2866 91.0587 72.6057 91.0587 72.6057C91.0587 72.6057 93.7072 69.3143 93.0876 67.016C92.7758 65.8598 91.7293 65.2435 90.9013 65.4913C89.5524 65.895 89.6731 67.4131 89.6731 67.4131Z" fill="#F65B7D" />
      <g opacity="0.8">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.713 48.4261C24.316 48.4261 26.4261 46.316 26.4261 43.713C26.4261 41.1101 24.316 39 21.713 39C19.1101 39 17 41.1101 17 43.713C17 46.316 19.1101 48.4261 21.713 48.4261Z" fill="#3384E1" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.6721 40.7529C22.526 40.6318 22.3238 40.5713 22.0653 40.5713C21.8518 40.5713 21.6579 40.6134 21.4838 40.6976C21.3096 40.7818 21.1733 40.8937 21.0751 41.0331C20.9767 41.1726 20.9276 41.3265 20.9276 41.4949C20.9276 41.6844 21.0005 41.8397 21.1467 41.9607C21.2927 42.0818 21.4978 42.1423 21.7619 42.1423C21.9755 42.1423 22.1678 42.1016 22.3393 42.0199C22.5106 41.9384 22.6455 41.8265 22.7438 41.6844C22.8421 41.5423 22.8913 41.3897 22.8913 41.2265C22.8913 41.0318 22.8183 40.874 22.6721 40.7529Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.3936 46.0964C22.3936 46.0964 21.789 46.3531 21.8438 46.0223L22.2579 43.5244C22.3095 43.2132 22.0846 42.9277 21.7876 42.9277H21.1304H21.0649H20.6363L20.6017 43.0834C20.6017 43.0834 21.0349 43.1083 20.9689 43.5067C20.8185 44.4136 20.5443 46.0678 20.5441 46.0693C20.5072 46.2914 20.5817 46.522 20.7385 46.6734C20.9731 46.9001 21.2952 46.8852 21.5794 46.7887C21.6742 46.7565 22.4998 46.3633 22.4985 46.3601L22.3936 46.0964Z" fill="white" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M91.9939 22.8027C91.5848 23.5226 90.9043 23.5541 90.3607 23.5793C89.8368 23.6039 89.5341 23.6332 89.3481 23.9608C89.1621 24.2879 89.2765 24.5964 89.4863 25.1152C89.7116 25.6584 89.9889 26.3346 89.5836 27.0535C89.1744 27.7734 88.4939 27.8049 87.9465 27.8304C87.4263 27.8543 87.1236 27.8836 86.9376 28.2108C86.8262 28.4084 86.5834 28.4687 86.399 28.345C86.2145 28.2212 86.1586 27.9602 86.27 27.7626C86.6793 27.0431 87.3597 27.0116 87.9033 26.9868C88.4273 26.9622 88.73 26.9329 88.916 26.6053C89.102 26.2786 88.9914 25.9691 88.7778 25.4509C88.5525 24.9077 88.2752 24.2315 88.6805 23.5126C89.0897 22.7927 89.7701 22.7608 90.3176 22.7353C90.8377 22.7114 91.1443 22.6814 91.3302 22.3539C91.5124 22.0274 91.4017 21.7174 91.1881 21.1992C90.9666 20.6554 90.6892 19.9788 91.0945 19.2595C91.2098 19.0617 91.4488 19.002 91.6332 19.1257C91.8177 19.2495 91.8736 19.5105 91.7621 19.7077C91.5762 20.0353 91.6868 20.3448 91.9005 20.8634C92.1258 21.4065 92.4031 22.0828 91.9939 22.8027Z" fill="#65A1DB" />
      <defs>
        <filter id="filter0_dd_3181_16517" x="14" y="11" width="84" height="96" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3181_16517" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_3181_16517" result="effect2_dropShadow_3181_16517" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3181_16517" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_3181_16517" x1="22.3637" y1="26.383" x2="41.1172" y2="37.3436" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7C8084" />
          <stop offset="1" stopColor="#5E5E70" />
        </linearGradient>
        <linearGradient id="paint1_linear_3181_16517" x1="22.3637" y1="27.1229" x2="43.6902" y2="36.1642" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7C8084" />
          <stop offset="1" stopColor="#5E5E70" />
        </linearGradient>
        <radialGradient id="paint2_radial_3181_16517" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(47.2806 68.4513) rotate(5.9374) scale(9.81812 9.86935)">
          <stop stopColor="white" />
          <stop offset="0.26" stopColor="#FCFEFE" />
          <stop offset="0.48" stopColor="#F2F9FB" />
          <stop offset="0.67" stopColor="#E1F2F6" />
          <stop offset="0.85" stopColor="#C9E7EF" />
          <stop offset="1" stopColor="#B0DCE7" />
        </radialGradient>
        <radialGradient id="paint3_radial_3181_16517" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(414912 329554) scale(11021.1 9332.83)">
          <stop stopColor="white" />
          <stop offset="0.26" stopColor="#FCFEFE" />
          <stop offset="0.48" stopColor="#F2F9FB" />
          <stop offset="0.67" stopColor="#E1F2F6" />
          <stop offset="0.85" stopColor="#C9E7EF" />
          <stop offset="1" stopColor="#B0DCE7" />
        </radialGradient>
        <radialGradient id="paint4_radial_3181_16517" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(70.6177 28.0745) rotate(-174.063) scale(15.6014 15.6828)">
          <stop stopColor="#86CCD2" />
          <stop offset="1" stopColor="#2A376C" />
        </radialGradient>
        <radialGradient id="paint5_radial_3181_16517" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(72079.7 149631) rotate(5.93741) scale(2507.59 4312.66)">
          <stop stopColor="#86CCD2" />
          <stop offset="1" stopColor="#2A376C" />
        </radialGradient>
        <radialGradient id="paint6_radial_3181_16517" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(61.0779 43.8411) scale(20.7454 20.8561)">
          <stop offset="0.01" stopColor="#EAF5F5" />
          <stop offset="0.5" stopColor="#00B0B2" />
          <stop offset="0.75" stopColor="#2D779E" />
          <stop offset="1" stopColor="#063266" />
        </radialGradient>
      </defs>
    </svg>

  )
}

export default MentionAppIcon
