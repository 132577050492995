import React from 'react'
import { useDyipne } from 'spd-gds/Dyipne'
import { Co2eActions } from 'spd-gds/actions'
import { Icons, ConfirmationDialog } from 'spd-gds/components/common'
import Helpers from 'spd-gds/helpers'
import { Page } from 'spd-gds/components/pages'
import { Loader } from 'spd-gds/components/common'

const Loading = () => {
  const { co2e } = useDyipne()
  const [co2eState, dispatcher] = co2e

  const handleErrorAlertContinue = () => {
    dispatcher(
      Co2eActions.CONTINUE()
    )
  }

  return (
    <Page className='page-results' type='generic'>
      <Loader />
      <ConfirmationDialog
        visible={co2eState.errors && co2eState.errors.length > 0}
        confirmOkHandler={handleErrorAlertContinue}
        onCloseHandler={handleErrorAlertContinue}
        processing={false}
        style={{
          display: "flex",
          alignItems: "center"
        }}
        actionConfig={{
          ok: {
            label: 'Continue',
          },
          cancel: false
        }}
        content={
          <>
            {
              co2eState.errors && (
                <>
                  <figure className='alert-icon'>
                    <Icons.Fail width={240} />
                  </figure>
                  <h2 datatype={co2eState.errors[0].error}>
                    {Helpers.userFriendlyErrorMsg(co2eState.errors[0].error_description)}
                  </h2>
                </>
              )
            }
          </>
        }
      />
    </Page>
  )
}

export default Loading