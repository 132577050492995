import React, { useEffect, useState } from 'react'
import Constants from 'spd-gds/constants'
import './styles.scss'

export default function MaintenanceBanner() {
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    const lastVisitDate = localStorage.getItem('lastVisitDate')
    const currentDate = new Date().toLocaleDateString()
    if (lastVisitDate !== currentDate) {
      setShowBanner(true)
    }
  }, [])

  const handleBannerClose = () => {
    setShowBanner(false)
    const currentDate = new Date().toLocaleDateString()
    localStorage.setItem('lastVisitDate', currentDate)
  }

  if (!showBanner || !Constants.ENABLE_MAINTENANCE.BANNER) return null
  return (
    <div className="maintenance-banner">
      <div className="maintenance-banner__text">
        <strong>Scheduled Maintenance</strong> - Carbon Footprint Tracker will
        be undergoing scheduled maintenance from 12 Aug, 10pm to 13 Aug, 12pm.
        We apologize for any inconvenience caused during this time.
      </div>
      <span
        className="maintenance-banner__close-icon"
        onClick={handleBannerClose}
      >
        x
      </span>
    </div>
  )
}
