import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import { ScreenSizeProvider } from 'spd-gds/components/ScreenSize'

import Dyipne from './Dyipne'
import App from './App'

import './index.scss'
import Constants from 'spd-gds/constants'
import { CO2ModeProvider } from 'spd-gds/components/hooks/CO2Mode'
import { UserSessionTokenProvider } from 'spd-gds/components/MobileBridge'

import { DataDogProvider } from 'spd-gds/components/common/DataDog'
ReactDOM.render(
  <DataDogProvider rum rumConfig={Constants.RUM[Constants.ENV]}>
    <Router>
      <CO2ModeProvider>
        <UserSessionTokenProvider>
          <Dyipne>
            <ScreenSizeProvider>
              <App />
            </ScreenSizeProvider>
          </Dyipne>
        </UserSessionTokenProvider>
      </CO2ModeProvider>
    </Router>
  </DataDogProvider>
  , document.getElementById('root'))
