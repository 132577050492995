import React from 'react'
import * as Icons from 'spd-gds/images/TipsIcons'
import { useScreenSize } from 'spd-gds/components/ScreenSize'
import { useMode } from 'spd-gds/components/hooks/CO2Mode'

const funFactsData = {
  'ENERGY': [
    {
      fact: "Singapore generated about 52 million tonnes of greenhouse gases in 2018. The bulk of it comes from the burning of fossil fuels to generate energy for industries, buildings, households, and transportation.",
      image: Icons.IndustrialFactory
    },
    {
      fact: "By using the fan instead of the air-conditioner, you can save about $400 a year!",
      image: Icons.ElectricFan
    },
    {
      fact: "Over 40% of energy-related carbon emissions are due to the burning of fossil fuels for electricity generation.",
      image: Icons.IndustrialFactory
    },

  ],
  'WASTE': [
    {
      fact: "Domestic recycling rates dropped from 17% to 13% from 2019 to 2020.",
      image: Icons.TrashIncinerated
    },
    {
      fact: "Singapore's one and only landfill is projected to reach capacity by 2035.",
      image: Icons.TrashFull
    },
    {
      fact: "Singapore's one and only landfill is projected to reach capacity by 2035.",
      image: Icons.TrashFull
    },
    {
      fact: "40% of trash placed into recycling bins is contaminated and is thus incinerated.",
      image: Icons.IndustrialFactory
    },
  ],
  'FOOD': [
    {
      fact: "Singapore imports over 90% of its food. Even though less than 10% of food items imported into Singapore are by air, they contribute to more than half of the carbon emissions in the transportation stage!",
      image: Icons.FoodImported
    },
    {
      fact: "Red meats represent about 11% of consumption per capita by weight yearly, yet they contribute to a bulk of about 40% of greenhouse gas emissions!",
      image: Icons.RedMeat
    },
    {
      fact: "Food waste makes up half of the 1.5kg of waste discarded daily by Singaporean households.",
      image: Icons.FoodWaste
    }
  ],
  'SHOPPING': [
    {
      fact: "A single pair of jeans requires a kilogram of cotton, which is about 7,500–10,000 liters of water. That’s about 10 years’ worth of drinking water for one person.",
      image: Icons.PairOfJeans
    },
    {
      fact: "By using recycled paper, you can save up to 47% of the energy needed to produce a new one.",
      image: Icons.RecyclePaper
    },
    {
      fact: "Households spend an average of $4,906 a month on goods and services. This is an increase of 0.8% per annum from $4,724 in 2012/2013",
      image: Icons.Delivery
    }
  ],
  'DAILY_TRAVELS': [
    {
      fact: "Private transport is one of the world’s biggest sources of greenhouse gases. Globally, transport accounts for 1/4 of CO2 emissions.",
      image: Icons.PrivateTransport
    },
    {
      fact: "Even though carbon emissions dropped in 2020 due to Covid 19 restrictions, they were still around the same levels as in 2012!",
      image: Icons.Virus
    },
    {
      fact: "Singapore’s rail network is set to expand to about 360km by 2030. This means that 8 in 10 households will soon live within 10 minutes walk of a train station.",
      image: Icons.Train
    },
  ],
  'HOLIDAY_TRAVELS': [
    {
      fact: "Aviation accounts for 2.5% of global CO2 emissions and emissions have doubled since the mid-1980s.",
      image: Icons.Plane
    },
    {
      fact: "Due to Covid 19 restrictions, global greenhouse gas emissions plunged by roughly 2.4 billion tonnes in 2020!",
      image: Icons.Virus
    },
    {
      fact: "Transport takes up 49% of the carbon footprint of Global Tourism.",
      image: Icons.Car
    },
  ],
}
const FunFacts = ({ type }) => {
  const screenSize = useScreenSize()
  const { breakpoints, width } = screenSize
  const { componentShouldHide, paramsObj } = useMode()

  const renderFunFactsComponent = React.useCallback(() => {
    const factsList = funFactsData[type]
    const randomIdx = (arr) => Math.floor(Math.random() * arr.length)
    const getFact = factsList[randomIdx(factsList)]
    if (breakpoints['MOBILE'].width >= width) return <></>
    if (componentShouldHide?.funfacts && paramsObj?.source === "infinity") return <></>
    return (
      <div className='app-qnnre__fun-facts'>
        <img src={getFact.image} alt='fun facts' />
        <div className='app-qnnre__fun-facts-fact'>
          <h5>Do you know?</h5>
          <p>{getFact.fact}</p>
        </div>
      </div>
    )
  }, [screenSize, type])

  return (
    <>
      {renderFunFactsComponent()}
    </>
  )
}

export default FunFacts
