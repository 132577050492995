import React from 'react'
import classnames from 'classnames'

const Shopping = ({show}) => {
  const _Shopping = classnames('co2-category_shopping', {
    show
  })
  return (
    <svg className={_Shopping} width="88" height="147" viewBox="0 0 88 147" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#co2-category_shopping_clip0)">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.4802 24.5311C21.5749 21.4369 24.5365 19.3434 27.8988 19.3434C31.23 19.3434 34.1911 21.438 36.2928 24.5303C38.3996 27.6299 39.6985 31.8017 39.6985 36.1446C39.6985 40.488 38.415 44.4426 36.3059 47.3248C34.1967 50.2071 31.2338 52.0464 27.8988 52.0464C24.595 52.0464 21.6326 50.2061 19.5165 47.3257C17.3996 44.4443 16.0991 40.4896 16.0991 36.1446C16.0991 31.8033 17.3812 27.6315 19.4802 24.5311ZM20.3914 25.148C18.4244 28.0534 17.1995 32.0071 17.1995 36.1446C17.1995 40.2783 18.4382 43.9994 20.4033 46.6742C22.3691 49.3499 25.0315 50.946 27.8988 50.946C30.7994 50.946 33.4611 49.3489 35.4179 46.675C37.3746 44.0011 38.5982 40.2799 38.5982 36.1446C38.5982 32.0086 37.358 28.055 35.3827 25.1488C33.4025 22.2353 30.7388 20.4438 27.8988 20.4438C25.0257 20.4438 22.3625 22.2364 20.3914 25.148Z" fill="#585C5E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M28.2226 24.5311C30.3174 21.4369 33.2789 19.3434 36.6413 19.3434C39.9724 19.3434 42.9335 21.438 45.0352 24.5303C47.142 27.6299 48.441 31.8017 48.441 36.1446C48.441 40.488 47.1575 44.4426 45.0483 47.3248C42.9392 50.2071 39.9762 52.0464 36.6413 52.0464C33.3375 52.0464 30.3751 50.2061 28.2589 47.3257C26.142 44.4443 24.8416 40.4896 24.8416 36.1446C24.8416 31.8033 26.1237 27.6315 28.2226 24.5311ZM29.1338 25.148C27.1669 28.0534 25.9419 32.0071 25.9419 36.1446C25.9419 40.2783 27.1806 43.9994 29.1457 46.6742C31.1115 49.3499 33.7739 50.946 36.6413 50.946C39.5418 50.946 42.2036 49.3489 44.1603 46.675C46.1171 44.0011 47.3406 40.2799 47.3406 36.1446C47.3406 32.0086 46.1004 28.055 44.1252 25.1488C42.1449 22.2353 39.4813 20.4438 36.6413 20.4438C33.7682 20.4438 31.1049 22.2364 29.1338 25.148Z" fill="#585C5E"/>
        <path d="M6.11335 88.7256L54.197 88.7256L54.1971 35.6692L6.11342 35.6692L6.11335 88.7256Z" fill="#FFC466"/>
        <path d="M54.1906 35.6307H44.6768V88.6871H54.1906V35.6307Z" fill="#F7B14A"/>
        <path d="M50.9765 61.0669H31.563V92.8623H50.9765V61.0669Z" fill="#F65B7D"/>
        <path d="M81.3181 61.0669H50.9766V92.8623H81.3181V61.0669Z" fill="#C64E73"/>
        <path d="M81.318 61.0669H31.563V64.0217H81.318V61.0669Z" fill="#F291B0"/>
        <path d="M52.1337 53.2305H30.1489V61.0669H52.1337V53.2305Z" fill="#F65B7D"/>
        <path d="M82.4752 53.2305H52.0693V61.0669H82.4752V53.2305Z" fill="#C64E73"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M51.3645 3.9661C52.9802 1.59388 55.2489 -1.52588e-05 57.7906 -1.52588e-05C60.3323 -1.52588e-05 62.601 1.59388 64.2167 3.9661C65.8357 6.34313 66.8356 9.54736 66.8356 12.8922C66.8356 16.2056 65.8512 19.238 64.2338 21.4509C62.6164 23.6638 60.3465 25.0779 57.7906 25.0779C55.2675 25.0779 52.9979 23.6806 51.3724 21.4762C49.7465 19.2714 48.7456 16.2385 48.7456 12.8922C48.7456 9.54736 49.7455 6.34313 51.3645 3.9661ZM51.9708 4.37905C50.4399 6.6268 49.4792 9.68529 49.4792 12.8922C49.4792 16.0976 50.4389 18.9742 51.9629 21.0409C53.4872 23.108 55.5567 24.3443 57.7906 24.3443C60.0559 24.3443 62.1251 23.0927 63.6415 21.018C65.1579 18.9434 66.102 16.0663 66.102 12.8922C66.102 9.68529 65.1413 6.6268 63.6104 4.37905C62.0762 2.12651 60.0058 0.733567 57.7906 0.733567C55.5754 0.733567 53.505 2.12651 51.9708 4.37905Z" fill="#585C5E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M58.1142 3.9661C59.7299 1.59388 61.9987 -1.52588e-05 64.5404 -1.52588e-05C67.0821 -1.52588e-05 69.3508 1.59388 70.9665 3.9661C72.5854 6.34313 73.5854 9.54736 73.5854 12.8922C73.5854 16.2056 72.601 19.238 70.9836 21.4509C69.3662 23.6638 67.0963 25.0779 64.5404 25.0779C62.0173 25.0779 59.7477 23.6806 58.1222 21.4762C56.4963 19.2714 55.4954 16.2385 55.4954 12.8922C55.4954 9.54736 56.4953 6.34313 58.1142 3.9661ZM58.7206 4.37905C57.1896 6.6268 56.2289 9.68529 56.2289 12.8922C56.2289 16.0976 57.1887 18.9742 58.7126 21.0409C60.237 23.108 62.3065 24.3443 64.5404 24.3443C66.8057 24.3443 68.8749 23.0927 70.3913 21.018C71.9077 18.9434 72.8518 16.0663 72.8518 12.8922C72.8518 9.68529 71.8911 6.6268 70.3602 4.37905C68.826 2.12651 66.7556 0.733567 64.5404 0.733567C62.3251 0.733567 60.2547 2.12651 58.7206 4.37905Z" fill="#585C5E"/>
        <path d="M40.9971 53.392L78.0884 53.3921L78.0884 12.4757L40.9972 12.4756L40.9971 53.392Z" fill="#00B0B2"/>
        <path d="M78.0397 12.5068H70.7114V53.4232H78.0397V12.5068Z" fill="#008587"/>
        <path d="M57.662 99.0928H2.37866V146.625H57.662V99.0928Z" fill="#6B68B3"/>
        <path d="M85.3033 99.0928H57.6616V146.625H85.3033V99.0928Z" fill="#4A4A7C"/>
        <path d="M85.3037 99.0928H2.37866V103.782H85.3037V99.0928Z" fill="#A09DEA"/>
        <path d="M59.5261 88.6871H0V99.0286H59.5261V88.6871Z" fill="#6B68B3"/>
        <path d="M87.2964 88.6871H59.5261V99.0286H87.2964V88.6871Z" fill="#4A4A7C"/>
      </g>
      <defs>
        <clipPath id="co2-category_shopping_clip0">
          <rect width="87.2963" height="146.625" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Shopping
