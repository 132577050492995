import React from 'react'

export const getUserSessionToken = () => window?.infinity?.token ?? ''

/**
 *
 * @param {string} path - A url with params "url/uri?params1=value1&params2=value2".
 * @returns {object} - for all the params with key as object properties.
 */
export const getQueryParams = (path = '') => {
  if (path === '') return null
  const query = path.split('?')
  // changed from object to map to fix Remote property injection issue
  // ref: https://code.in.spdigital.sg/sp-digital/dyipne/security/code-scanning/136
  const params = new Map()

  // example value for getparams ["urlparam1=value1", "urlparam2=value2"]
  const getParams = query?.[1].split('&')
  if (getParams && getParams.length > 0) {
    getParams.forEach(paramPair => {
      const param = paramPair.split('=')
      params.set(
        param[0],
        paramPair.substring(param[0].length + 1, paramPair.length)
      )
    })
  }
  return params
}
export const queryParams = () => getQueryParams(window.location.search)
export const modeContext = React.createContext()

export const CO2ModeProvider = ({ children }) => {
  const [paramsObj] = React.useState({})
  const mode = queryParams()?.get('source')
  const hideCompByMode = {
    infinity: {
      funfacts: true,
      gfx: true,
      downloadApp: true,
    },
    feature: {
      monthlyTracking: false,
      annualProjection: false,
    },
  }
  const componentShouldHide = hideCompByMode[mode] || {}
  const featureShouldHide = hideCompByMode['feature'] || {}

  return (
    <modeContext.Provider
      value={{ componentShouldHide, featureShouldHide, paramsObj }}
    >
      {children}
    </modeContext.Provider>
  )
}

export const useMode = () => React.useContext(modeContext)
