const getToken = () => (
  new Promise((resolve, reject) => {
    const cbFunctionString = 'window.infinity.sessionTokenResolve'
    const sessionTokenResolve = (token) => {
      window.infinity.token = token
      return resolve(token)
    }

    if(!window.infinity) {
      window.infinity = {
        sessionTokenResolve,
        token: ''
      }
    }

    // android
    window?.spdigitalUtilitiesAndroid?.getToken(cbFunctionString)

    // ios
    window?.webkit?.messageHandlers?.spdigitalSessionToken?.postMessage(
      {
        callback: cbFunctionString
      }
    )
  })
)

const saveReportAndroid = (data, filename) => {
  window?.spdigitalUtilitiesAndroid?.saveResult(data, filename)
}
const saveReportIOS = (data, filename) => {
  window?.webkit?.messageHandlers?.spdigitalCO2?.postMessage(
    {
      data,
      filename,
      action: 'save_co2_result',
    }
  )
}

export {
  getToken,
  saveReportAndroid,
  saveReportIOS,
}
