import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'

import { useScreenSize } from 'spd-gds/components/ScreenSize'

const Breakpoint = ({ label, children, value }) => {
  const screenSize = useScreenSize()

  const [origin, setOrigin] = useState('left')
  const bp = useRef(null)
  const bpNode = useRef(null)

  const _Breakpoint = classnames('graph-breakpoint', {
    'origin-left': origin === 'left',
    'origin-right': origin === 'right',
  })

  useEffect(() => {
    if (bp.current && bpNode.current) {
      const center = screenSize.width / 2
      const bpPos = bp.current.getBoundingClientRect()
      const bpNodePos = bpNode.current.getBoundingClientRect()
      if (bpPos.left > center) {
        if ((bpNodePos.width + bpPos.left) > screenSize.width) {
          setOrigin('right')
        }
      } else {
        setOrigin('left')
      }
    }
  }, [screenSize, value])

  return (
    <div className={_Breakpoint} style={{ '--chartBreakpointValue': value }} ref={bp}>
      <span className='breakpoint-stem'></span>
      <span className='breakpoint-node' ref={bpNode}>
        <h4> {label}</h4>
        {children}
      </span>
    </div>
  )
}

const Bar = ({ breakpoint, children, pre, post, dataPercentage }) => {
  const screenSize = useScreenSize()
  const [postToBottom, setPostToBottom] = useState(false)
  const pNode = useRef(null)

  const _Post = classnames('graph-bar__post', {
    'to-bottom': postToBottom
  })

  useEffect(() => {
    if (pNode.current) {
      const center = screenSize.width / 2
      const postPos = pNode.current.getBoundingClientRect()
      if (postPos.left > center) {
        if ((postPos.width + postPos.left) > screenSize.width) {
          setPostToBottom(true)
        }
      }
    }
  }, [screenSize, dataPercentage])

  return (
    <div className='app-chart_graph graph-bar'>
      <div className='graph-bar__container'>
        {
          pre && (
            <span className='graph-bar__pre' dangerouslySetInnerHTML={{ __html: pre }} />
          )
        }
        <div className='bar-breakpoint'>
          {breakpoint}
        </div>
        <div className='bar-wrap'>
          {children}
        </div>
        {
          post && (
            <span className={_Post} ref={pNode} style={{ '--chartDataPercentage': dataPercentage }} dangerouslySetInnerHTML={{ __html: post }} />
          )
        }
      </div>
    </div>
  )
}

Bar.Breakpoint = Breakpoint

export default Bar
