import React from 'react'

const ChartDetailLabel = ({ children }) => {
  return (
    <div className='app-chart_detail__label'>
      {children}
    </div>
  )
}

const ChartDetailValue = ({ children }) => {
  return (
    <div className='app-chart_detail__value'>
      {children}
    </div>
  )
}

const ChartDetailDescription = ({ children }) => {
  return (
    <div className='app-chart_detail__description'>
      {children}
    </div>
  )
}

const ChartDetail = ({ children, color }) => {
  return (
    <div className='app-chart_detail' style={{'--chartDetailColor': color}}>
      { children }
    </div>
  )
}

ChartDetail.Label = ChartDetailLabel
ChartDetail.Value = ChartDetailValue
ChartDetail.Description = ChartDetailDescription

export default ChartDetail
