import React from 'react'
import { Page } from 'spd-gds/components/pages'

export default function MaintenancePage() {
  return (
    <Page className="page-maintenance" type="landing">
      <div className="wrapper">
        <div className="img-wrapper">
          <img src="/images/maintenance.png" alt="Maintenance" />
        </div>
        <div className="title">
          This site is currently down for maintenance from 12 Aug, 10pm to 13
          Aug, 12pm
        </div>
        <div className="content">
          We apologise that our service is currently unavailable due to system
          upgrade.
          <br />
          Thank you for your understanding and patience.
        </div>
      </div>
    </Page>
  )
}
