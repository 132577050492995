import React from 'react'
import classnames from 'classnames'
import alertInformation from 'spd-gds/images/alertInformation.svg'
import Utils from 'spd-gds/utils'
import "./styles.scss"

const HorizontalChart = ({ children, title, className, handleChange, data }) => {
  const _ChartHorizontal = classnames('chart-horizontal', className)
  const [maxVal, setMaxVal] = React.useState(() => Math.max(...data.map(el => el.totalKG)))

  React.useEffect(() => {
    setMaxVal(Math.max(...data.map(el => el.totalKG)))
  }, [data])

  return (
    <div className={_ChartHorizontal}>
      <div className='chart-horizon__top-content' >
        <div className="chart-horizon-header">
          <span className="title">
            {title}
          </span>
          <span className="mark" onClick={handleChange}>
            <img src={alertInformation} alt={title} />
          </span>
        </div>
        <div className="chart-horizon-chart">
          <ChartNodeLabel data={data} />
          <ChartNodeBar data={data} maxVal={maxVal} />
          <ChartNodeDesc data={data} />
        </div>

      </div>
      <div className='chart-horizon__child-content'>
        {children}
      </div>
    </div>
  )
}

const ChartNodeLabel = ({ data = [] }) => {
  return (
    <div className="chart-label">
      {data.map((el, id) => (<span className='node-label' key={id} >{el.content}</span>))}
    </div>
  )
}

const ChartNodeDesc = ({ data = [] }) => {
  return (
    <div className="chart-desc">
      {data.map((el, id) => (<span className='node-desc' key={id}>{Utils.formatNumber(el.totalKG)} kg CO2</span>))}
    </div>
  )
}

const ChartNodeBar = ({ data, maxVal }) => {
  data = data.map(value => {
    let width = 0
    if (value.totalKG === maxVal) {
      width = 100
    } else {
      width = value.totalKG * 100 / maxVal
    }
    return { ...value, width }
  })

  return (
    <div className="chart-bar">
      {data.map((el, id) => {
        return (
          <div key={id} className='wrapper'>
            <div className='bar' style={{ '--ChartHorizonBgColor': el.color, '--ChartHorizonWidth': el.width + "%" }} />
          </div>
        )
      })}
    </div>
  )
}
export default HorizontalChart
