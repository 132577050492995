import React, { PureComponent } from 'react'

class FormField extends PureComponent {
  render() {
    const { 
      label, 
      field,
      children,
    } = this.props
    return (
      <div className={`lm--formItem lm--formItem--inline field-item ${field}`}>
        <div className='lm--formItem-label label' dangerouslySetInnerHTML={{__html: label}} />
        <div className='lm--formItem-control control'>
          {children}
        </div>
      </div>
    )
  }
}

FormField.defaultProps = {
  label: '',
  field: ''
}

export default FormField
