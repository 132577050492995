import Constants from 'spd-gds/constants/'

const DEFAULT_KEY = Constants.STORAGE.data

const Retrieve = (key = DEFAULT_KEY) => {
  const data = window.sessionStorage.getItem(key)
  return data
}

const Save = (key = DEFAULT_KEY, value) => {
  const storeValue = JSON.stringify(value)
  window.sessionStorage.setItem(key, storeValue)
}

const Delete = (key = DEFAULT_KEY) => {
  const data = Retrieve(key)
  if (!data) return false

  window.sessionStorage.removeItem(key)
  return true
}

const Clear = () => {
  window.sessionStorage.clear()
}

const storage = {
  Retrieve,
  Save,
  Delete,
  Clear,
}

export default storage
