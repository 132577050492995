import { useReducer } from 'react'

const useReducerThunk = (reducer, initialState) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  let customDispatch = (action) => {
    if (typeof action === 'function') {
      action(customDispatch, state)
    } else {
      dispatch(action)
    }
  }
  return [state, customDispatch]
}

export {
  useReducerThunk
}
