import React from 'react'
const listCountries = [
  {
    id: 1,
    area: "Southeast Asia",
    countries: `Brunei, Cambodia, Indonesia, Laos, Malaysia, Myanmar, Philippines, Thailand, East Timor, Vietnam`
  },
  {
    id: 2,
    area: "Asia Pacific",
    countries: `Eastern Asia (China, Hong Kong, Japan, Macao, Mongolia, North Korea, South Korea, Taiwan)
Southern Asia (Afghanistan, Bangladesh, Bhutan, India, Iran, Maldives, Nepal, Pakistan, Sri Lanka)
Western Asia (Armenia, Azerbaijan, Bahrain, Cyprus, Georgia, Iraq, Israel, Jordan, Kuwait, Lebanon, Oman, Palestine, Qatar, Saudi Arabia, Syria, Turkey, United Arab Emirates, Yemen)`},
  {
    id: 3,
    area: "Africa",
    countries: `Northern Africa (Algeria, Egypt, Libya, Morocco, Sudan, Tunisia, Western Sahara)
    Eastern Africa (British Indian Ocean Territory, Burundi, Comoros, Djibouti, Eritrea, Ethiopia, French Southern Territories, Kenya, Madagascar, Malawi, Mauritius, Mayotte, Mozambique, Réunion, Rwanda, Seychelles, Somalia, South Sudan, Uganda, Tanzania, Zambia, Zimbabwe)
    Middle Africa (Angola, Cameroon, Central African Republic, Chad, Congo, Democratic Republic of the Congo, Equatorial Guinea, Gabon, Sao Tome and Principe)
    Southern Africa (Botswana, Eswatini, Lesotho, Namibia, South Africa)
    Western Africa (Benin, Burkina Faso, Cape Verde, Gambia, Ghana, Guinea, Guinea-Bissau, Ivory Coast, Liberia, Mali, Mauritania, Niger, Nigeria, Saint Helena, Senegal, Sierra Leone, Togo)`},
  {
    id: 4,
    area: "Europe",
    countries: `Eastern Europe (Benin, Belarus, Bulgaria, Czech Republic, Hungary, Poland, Moldova, Romania, Russian Federation, Slovakia, Ukraine)
    Northern Europe (Åland Islands, Channel Islands, Denmark, Estonia, Faroe Islands, Finland, Iceland, Ireland, Isle of Man, Latvia, Lithuania, Norway, Svalbard and Jan Mayen Islands, Sweden, United Kingdom)
    Southern Europe (Albania, Andorra, Bosnia and Herzegovina, Croatia, Gibraltar, Greece, Holy See, Italy, Malta, Montenegro, North Macedonia, Portugal, San Marino, Serbia, Slovenia, Spain)
    Western Europe (Austria, Belgium, France, Germany, Liechtenstein, Luxembourg, Monaco, Netherlands, Switzerland)`},
  {
    id: 5,
    area: "Oceania",
    countries: `Australia, New Zealand (New Zealand, Norfolk Island)
    Melanesia (Fiji, New Caledonia, Papua New Guinea, Solomon Islands, Vanuatu)
    Micronesia (Guam, Kiribati, Marshall Islands, Micronesia, Nauru, Northern Mariana Islands, Palau, United States Minor Outlying Islands)
    Polynesia (American Samoa, Cook Islands, French Polynesia, Niue, Pitcairn, Samoa, Tokelau, Tonga, Tuvalu)`},
  {
    id: 6,
    area: "Americas",
    countries: `Caribbean (Anguilla, Antigua and Barbuda, Aruba, Bahamas, Barbados, Bonaire, Sint Eustatius and, Saba, British Virgin Islands, Cayman Islands, Cuba, Curaçao, Dominica, Dominican Republic, Grenada, Guadeloupe, Haiti, Jamaica, Martinique, Montserrat, Puerto Rico, Saint Barthélemy, Saint Kitts and Nevis, Saint Lucia, Saint Martin, Saint Vincent and the Grenadines, Sint Maarten, Trinidad and Tobago, Turks and Caicos Islands, United States Virgin Islands)
    Central America (Belize, Costa Rica, El Salvador, Guatemala, Honduras, Mexico, Nicaragua, Panama)
    South America (Argentina, Bolivia, Bouvet Island, Brazil, Chile, Colombia, Ecuador, Falkland Islands, French Guiana, Guyana, Paraguay, Peru, South Georgia and the South Sandwich Islands, Suriname, Uruguay, Venezuela)
    Northern America (Bermuda, Canada, Greenland, Saint Pierre and Miquelon, United States of America)`},
]
const CountriesList = () => {

  return listCountries.map((countries, idx, arr) => {
    return (
      <div key={countries.id} style={{ marginBottom: countries.id === arr.length ? 0 : "1.5rem" }}>
        <b style={{ marginBottom: "0.25rem", fontSize: "1.025rem" }}>{countries.area}</b>
        <p>{countries.countries}</p>
      </div>
    )
  })
}

export default CountriesList
