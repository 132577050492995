import React from 'react'

const ChartData = ({ color, value }) => {
  return (
    <div className='chart-data graph-data' style={{ '--chartDataColor': color, '--chartDataValue': value }}>

    </div>
  )
}

export default ChartData
