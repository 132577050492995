import React, { useState, useEffect, createContext } from 'react'
import { datadogRum } from '@datadog/browser-rum'

const DataDogContext = createContext({})

const DataDogProvider = ({ children, rum, rumConfig }) => {
  const [dataDogRumState, setDataDogRumState] = useState(datadogRum)
  const defaultRumConfig = {
    applicationId: process.env.REACT_APP_DD_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    site: process.env.REACT_APP_DD_SITE,
    env: process.env.REACT_APP_ENV || 'qa',
    service: (process.env.REACT_APP_PROJECT_NAME || 'bobcat'),
    allowedTracingOrigins: [
      process.env.REACT_APP_API_BASE || '',
      process.env.REACT_APP_API_BASE_EXT || '',
      process.env.REACT_APP_AUTH0_BASE || ''
    ],
    silentMultipleInit: true,
  }

  const combinedRumConfig = { ...defaultRumConfig, ...rumConfig }
  useEffect(() => {
    if (rum) {
      datadogRum.init(combinedRumConfig)
      setDataDogRumState(datadogRum)
    }
  }, [rum]) // eslint-disable-line react-hooks/exhaustive-deps

  return <DataDogContext.Provider value={{ datadogRum: dataDogRumState }}>
    {children}
  </DataDogContext.Provider>
}

export {
  DataDogContext,
  DataDogProvider,
}
