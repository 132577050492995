import React, { PureComponent } from 'react'
import classnames from 'classnames'

import { Button, Icons, Tooltip } from 'spd-gds/components/common'
import Utils from 'spd-gds/utils'
import FormErrorBlock from './FormErrorBlock'

class FormInput extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      let modified = state.tempValue !== props.value
      if (modified) {
        return {
          value: props.value,
          modified: true,
        }
      }
      return {
        value: props.value,
        modified: false,
      }
    }
    return null
  }

  state = {
    value: this.props.value,
    tempValue: this.props.value,
    error: null,
    modified: false,
  }

  componentDidMount() {
    const { capsOnBlur } = this.props
    if (capsOnBlur) {
      this._capsAllVals()
    }
  }

  render() {
    const {
      id,
      label,
      error,
      value,
      name,
      maxLength,
      handleChange,
      handleBlur,
      isInline,
      ctaHandler,
      ctaLabel,
      note,
      loading,
      capsOnBlur,
      setFieldValue,
      onlyNumber,
      showError,
      capsOnChange,
      unit,
      info,
      autoCapitalize,
      showModified,
      trailZero,
      ...props
    } = this.props
    const { value: stateValue, modified } = this.state

    const _FormInput = classnames('lm--formItem form-item field-input', {
      'lm--formItem--inline': isInline,
      'is-modified': !error && showModified && modified,
      'is-error': error,
      'with-cta': ctaHandler,
      'with-note': note,
    })

    return (
      <div id={`${id}-form-item`} className={_FormInput}>
        {label && label !== '' && (
          <div className="lm--formItem-label label">
            {label}
            {info && (
              <Tooltip
                style={{
                  marginLeft: '0.5rem',
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: Utils.parseStrTemplate(info.text, info.params),
                  }}
                />
              </Tooltip>
            )}
          </div>
        )}
        <div className="lm--formItem-control control">
          <input
            aria-label={label}
            id={id}
            type="text"
            value={stateValue}
            name={name}
            maxLength={maxLength}
            onChange={this._changeHandler}
            onBlur={this._blurHandler}
            autoCapitalize={autoCapitalize === 'off' ? 'off' : null}
            {...props}
          />
          {note && <span className="field-note">{note}</span>}
          {ctaHandler && (
            <div className="field-cta">
              <Button
                link={true}
                onClickHandler={ctaHandler}
                variant={'secondary'}
              >
                {ctaLabel || 'Continue'}
              </Button>
            </div>
          )}
          {unit && <span className="field-unit">{unit}</span>}
          {loading && (
            <figure className="field-loader">
              <Icons.SpinLoader width={25} height={25} color={['#333333']} />
            </figure>
          )}
          <FormErrorBlock id={`${id}-field_error`} error={error} />
        </div>
      </div>
    )
  }

  _changeHandler = e => {
    const {
      handleChange,
      setFieldValue,
      id,
      onlyNumber,
      capsOnChange,
      autoCapitalize,
    } = this.props
    if (setFieldValue) {
      let value = e.target.value
      if (onlyNumber) {
        let re = /[^0-9]/gi
        if (onlyNumber.decimal) {
          re = /[^0-9.]/gi
          let decimal = onlyNumber.decimal
          let decimalRegex = new RegExp(`\\d+(\\.\\d{0,${decimal}})?`)
          if (value) {
            value = value.replace(re, '')
            if (value !== '') {
              value = value.match(decimalRegex)[0]
            }
          }
        }
        if (onlyNumber.integer) {
          value = '0' + value
          if (value === '') {
            value = '0'
          }
          value = `${parseInt(value.replace(re, ''))}`
        } else {
          value = value.replace(re, '')
        }
      }
      if (capsOnChange) {
        if (typeof value === 'string') {
          value = value.toUpperCase()
        }
      }
      if (autoCapitalize) {
        if (autoCapitalize === 'words' || autoCapitalize === 'sentences') {
          const splitBy = autoCapitalize === 'words' ? ' ' : '. '
          value = Utils.capitalise(value, false, splitBy)
        } else if (autoCapitalize === 'characters') {
          value = value.toUpperCase()
        } else if (autoCapitalize === true) {
          value = Utils.capitalise(value)
        }
      }
      setFieldValue(id, value)
      if (handleChange) {
        handleChange(e)
      }
    } else {
      if (handleChange) {
        handleChange(e)
      }
    }
  }

  _blurHandler = e => {
    const {
      handleBlur,
      handleChange,
      setFieldValue,
      capsOnBlur,
      trailZero,
      error,
      params,
    } = this.props
    if (capsOnBlur) {
      this._capsAllVals()
    }
    if (handleBlur) {
      handleBlur(e)
    }
    if (trailZero) {
      const tId = e.target.id
      let v = e.target.value
      v = Utils.trailWithZero(v)
      console.log('# trailZero', v)
      setFieldValue(tId, v)
      if (handleChange) {
        handleChange(tId, v, params.type)
      }
    }
    if (error) {
      this.setState(prevState => ({
        ...prevState,
        error,
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        error: null,
      }))
    }
  }

  _capsAllVals = () => {
    const { id, value, setFieldValue } = this.props
    if (setFieldValue) {
      const val = value.toUpperCase()
      setFieldValue(id, val)
    }
  }
}

FormInput.defaultProps = {
  isInline: true,
  showModified: false,
}

export default FormInput
