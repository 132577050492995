import React, { memo } from 'react'
import { useDyipne } from 'spd-gds/Dyipne'
import { Co2eActions } from 'spd-gds/actions'
import * as GDSForm from "spd-gds/components/form"
import EnergyInsight from "spd-gds/images/energyInsight.svg"
import Helpers from 'spd-gds/helpers'
import { Button, ConfirmationDialog } from 'spd-gds/components/common'

import { Formik } from "formik"
import * as yup from "yup"

const SetTarget = ({ change, handler, userTarget }) => {
  const { co2e } = useDyipne()
  const [, dispatcher] = co2e

  let items = [
    {
      id: "target",
      mandatory: true,
      question: "Monthly Target",
      answerUnit: "kg",
      answerType: "INTEGER",
      defaults: [(userTarget === 0 ? "" : userTarget) + ""],
      validation: {
        validations: [
          {
            type: "required",
            params: ["Required field"]
          },
          {
            type: "max",
            params: [2147483647, "Number too big"]
          },
          {
            type: "min",
            params: [-2147483648, "Number too small"]
          }
        ],
        validationType: "number"
      }
    }
  ]

  const targetRef = React.useRef(null)

  const validations = items.reduce(Helpers.createSchema, {})
  const ValidationSchema = yup.object().shape(validations)
  const TargetData = Helpers.GetQuestionnaireData(items)

  function renderTargetInputField(formProps) {
    if (!items) {
      return null
    }
    const fields = Helpers.getFormFields(items, formProps)
    return fields
  }

  const renderSetTarget = () => {

    const submitSetTarget = (values) => {
      dispatcher(Co2eActions.SUBMIT_TARGET(values))
      setTimeout(() => {
        change("close")
      }, 1500)
    }

    return (
      <>
        <img src={EnergyInsight} alt="set user target" />
        <h4 style={{ margin: "1rem auto", fontWeight: "600" }}>Set Target</h4>
        <p style={{ textAlign: "center" }}>
          You can help by setting a monthly target of less than <b>250kg</b> and
          work towards it!
        </p>
        <Formik
          innerRef={targetRef}
          enableReinitialize
          validationSchema={ValidationSchema}
          initialValues={TargetData}
          onSubmit={submitSetTarget}
          validateOnChange
        >
          {(formProps) => {
            return (
              <GDSForm.Form
                className="target-model"
                onSubmit={formProps.handleSubmit}
              >
                {renderTargetInputField(formProps)}
                <GDSForm.FormCTA>
                  <Button
                    className="cta-button"
                    variant="outlined"
                    onClickHandler={() => change("close")}
                    disabled={formProps.isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="cta-button"
                    type="submit"
                    loading={formProps.isSubmitting}
                    disabled={Helpers.isSubmitDisabled(
                      formProps.errors,
                      formProps.isValid,
                      formProps.isSubmitting
                    )}
                  >
                    Submit
                  </Button>
                </GDSForm.FormCTA>
              </GDSForm.Form>
            )
          }}
        </Formik>
      </>
    )
  }
  return (
    <ConfirmationDialog
      visible={handler.openModal}
      closeBtn={true}
      onCloseHandler={() => change("close")}
      className="target-confirm"
      actionConfig={{
        cancel: false,
        ok: false
      }}
      content={renderSetTarget()}
    />
  )
}

export default memo(SetTarget)
