import React from 'react'
import * as yup from 'yup'

import Utils from 'spd-gds/utils'
import { useDyipne } from 'spd-gds/Dyipne'
import * as GDSForm from 'spd-gds/components/form'
import Co2eConstants from 'spd-gds/constants/co2e.constants'

const defaultType = {
  ANNUAL: "ANNUAL",
  MONTHLY: "MONTHLY"
}

const generateSchemaValidator = (validationType, validations) => {
  if (!yup[validationType]) {
    return null
  }
  let validator = yup[validationType]()
  validations.forEach(validation => {
    const { params, type } = validation
    if (!validator[type]) {
      return
    }
    if (typeof params[0] === 'object') {

      if (params[0].type === 'ref') {
        let ref = params[0]
        let _params = params.slice(1, params.length)
        _params.unshift(yup[ref.type](...ref.params))
        validator = validator[type](..._params)

      } else if (params[0].type === 'when' || type === 'when') {
        let when = params[0]

        if (!when.target && !when.is && !when.then && !when.otherwise) {
          console.error(`Check the object param of validation type WHEN, it should have .target, .is, .then and .otherwise`)
        } else {
          let isParam = when.is
          if (typeof isParam === 'object') {
            if (isParam.type === 'function') {
              const isFunc = (value) => {
                if (isParam.condition === 'NON_ZERO') {
                  return parseInt(value) > 0
                }
                return false
              }
              isParam = isFunc
            }
          }
          let whenParams = [
            when.target,
            {
              is: isParam,
              then: generateSchemaValidator(when.then.validationType, when.then.validations),
              otherwise: generateSchemaValidator(when.otherwise.validationType, when.otherwise.validations),
            }
          ]
          validator = validator['when'](...whenParams)
        }
      }

    } else {
      validator = validator[type](...params)
    }
  })

  return validator
}

const createSchema = (schema, field) => {
  const { id, validation } = field
  if (!validation) return schema

  const { validationType, validations = [] } = validation
  if (!validationType) return schema

  if (Array.isArray(id) && id.length > 1) {
    id.forEach((key, idx) => {
      if (!yup[validationType[idx]]) {
        return schema
      }
      const validator = generateSchemaValidator(validationType[idx], validations[idx])
      if (!validator) {
        return schema
      }
      schema[key] = validator
    })
  } else {
    const validator = generateSchemaValidator(validationType, validations)
    if (!validator) {
      return schema
    }
    schema[id] = validator
  }

  return schema
}

const getFormFields = (fields = [], formProps = {}) => {
  if (fields.length === 0) {
    return null
  }

  const shouldDisplayField = (field, values) => {
    if (!field.parentID) return true

    const condition = field.parentCondition
    const parentId = field.parentID
    const parentValues = field.parentAnswerValues

    switch (condition) {
      case "ANSWER_OPTION_EQ": {
        return parentValues[0].toLowerCase() === values[parentId].toLowerCase()
      }
      case "NON_ZERO": {
        return parseInt(values[parentId]) > 0
      }
      case "EXISTS": {
        return !!values[parentId]
      }
      default: {
        return false
      }
    }
  }

  const checkIfFieldIsRequired = (field) => {
    if (field.validations && Array.isArray(field.validations)) {
      if (field.validations.length > 0) {
        let validations = field.validations
        if (Array.isArray(field.validations[0]) && field.validations[0].length > 0) {
          validations = field.validations[0]
        }
        return validations.some(v => v.type === 'required')
      }
    }
    return field.mandatory
  }

  return (
    <GDSForm.FormGroup
      groupTitle={fields.groupTitle}
    >
      {fields.map((field, fieldKey) => {
        const isFieldRequired = checkIfFieldIsRequired(field)
        const inputAttr = field.inputAttr || {}
        let fieldLabel = field.label || field.question
        let fieldInfo = field.info && field.info !== "" ? { text: field.info } : null
        if (!isFieldRequired) {
          fieldLabel = (
            <>
              {field.label} <br />
              <i>(optional)</i>
            </>
          )
        }

        if (!field.answerType) {
          if (field.content) {
            return (
              <GDSForm.FormField
                key={`${field.id}_${fieldKey}`}
                field="field-item--content"
                label={fieldLabel}
              >
                <p>{field.content}</p>
              </GDSForm.FormField>
            )
          }
        }

        if (field.answerType === "CHECKBOX" || field.answerType === "CHECKBOX_ALL") {
          if (shouldDisplayField(field, formProps.values)) {
            return (
              <GDSForm.FormChoose
                key={`${field.id}_${fieldKey}`}
                id={field.id}
                title={fieldLabel}
                onlyElem={false}
                selected={formProps.values[field.id]}
                error={formProps.touched[field.id] && formProps.errors[field.id]}
                setFieldValue={formProps.setFieldValue}
                // handleChange={formProps.handleChange}
                choices={field.choices || field.answerOptions}
                multiple
                chooseAll={field.chooseAll || field.answerType === "CHECKBOX_ALL"}
              />
            )
          }
        }

        if (field.answerType === "mobile") {
          return (
            <GDSForm.FormMobileNumber
              key={`${field.id}_${fieldKey}`}
              label={fieldLabel}
              id={field.id}
              errorPrefix={null}
              errorNumber={
                formProps.touched[field.id] && formProps.errors[field.id]
              }
              valuePrefix={formProps.values[field.id + "_prefix"]}
              value={formProps.values[field.id]}
              prefixMaxLength={formProps.prefixMaxLength}
              maxLength={formProps.maxLength}
              setFieldValue={formProps.setFieldValue}
              handleChange={formProps.handleChange}
              handleBlur={formProps.handleBlur}
              {...inputAttr}
            />
          )
        }

        if (field.answerType === "SELECT") {
          if (shouldDisplayField(field, formProps.values)) {
            let selectOptions = field.options
            if (!selectOptions) {
              selectOptions = field.answerOptions
            }
            return (
              <GDSForm.FormSelectOption
                key={`${field.id}_${fieldKey}`}
                id={field.id}
                name={field.id}
                label={fieldLabel}
                note={field.note || field.description}
                info={fieldInfo}
                error={formProps.touched[field.id] && formProps.errors[field.id]}
                value={formProps.values[field.id]}
                options={
                  [
                    {
                      value: "",
                      text: "Select One",
                    },
                    ...selectOptions
                  ]
                }
                handleChange={formProps.handleChange}
                handleBlur={formProps.handleBlur}
              />
            )
          }
        }

        if (field.answerType === "date") {
          return (
            <GDSForm.FormDate
              key={`${field.id}_${fieldKey}`}
              id={field.id}
              label={fieldLabel}
              error={
                (formProps.touched[`${field.id}-btn`] ||
                  formProps.touched[`${field.id}`]) &&
                formProps.errors[field.id]
              }
              setFieldValue={formProps.setFieldValue}
              disablePrev={field.disablePrev} // false
              disableNext={field.disableNext} // true
              value={formProps.values[field.id]}
              showLegend={field.legend} // false
              handleBlur={formProps.handleBlur}
              setFieldTouched={formProps.setFieldTouched}
            />
          )
        }
        if (field.answerType === "split") {
          let error = null

          if (formProps.touched[field.id[0]] && formProps.errors[field.id[0]]) {
            error = error || []
            error.push(formProps.errors[field.id[0]])
          }
          if (formProps.touched[field.id[1]] && formProps.errors[field.id[1]]) {
            error = error || []
            error.push(formProps.errors[field.id[1]])
          }

          return (
            <GDSForm.FormSplitInput
              key={`${field.id}_${fieldKey}`}
              id={field.id}
              label={fieldLabel}
              params={field.params}
              error={error}
              // error={[formProps.touched[field.id[0]] && formProps.errors[field.id[0]], formProps.touched[field.id[1]] && formProps.errors[field.id[1]]]}
              value={[
                formProps.values[field.id[0]],
                formProps.values[field.id[1]],
              ]}
              valuePrefix={formProps.valuePrefix}
              prefixMaxLength={formProps.prefixMaxLength}
              handleChange={formProps.handleChange}
              capsOnChange={field.capsOnChange}
              handleBlur={(e) => {
                formProps.handleBlur(e)
              }}
              onKeyPress={formProps.handleKeyPress}
              setFieldValue={formProps.setFieldValue}
              trailZero={formProps.trailZero}
              placeholder={field.placeholder}
              prefix={{
                ...field.prefix,
                handleChange: formProps.handleChange,
              }}
              separator={field.separator}
              showModified={field.showModified}
              onlyNumber={field.onlyNumber}
              {...inputAttr}
            />
          )
        }

        if (shouldDisplayField(field, formProps.values)) {
          let maxLength = ""
          let minLength = ""
          let onlyNumber = field.onlyNumber
          let trailZero = field.trailZero

          if (!onlyNumber) {
            if (field.answerType === 'INTEGER') {
              onlyNumber = {
                integer: true
              }
            }
          }

          if (field.validations) {
            let max = field.validations.filter((v) => v.type === "max")
            if (max.length > 0) {
              maxLength = max[0].params[0]
            }

            let min = field.validations.filter((v) => v.type === "min")
            if (min.length > 0) {
              minLength = min[0].params[0]
            }
          }
          return (
            <GDSForm.FormInput
              key={`${field.id}_${fieldKey}`}
              id={field.id}
              label={fieldLabel}
              name={field.name || field.id}
              className={field.className}
              error={formProps.touched[field.id] && formProps.errors[field.id]}
              value={formProps.values[field.id]}
              maxLength={maxLength}
              minLength={minLength}
              onlyNumber={onlyNumber}
              trailZero={trailZero}
              capsOnChange={field.capsOnChange}
              setFieldValue={formProps.setFieldValue}
              disabled={field.disabled}
              handleBlur={formProps.handleBlur}
              unit={field.unit || field.answerUnit}
              info={fieldInfo}
              note={field.description}
              placeholder={field.placeholder}
              type={field.type || 'text'}
              showModified={field.showModified}
              {...inputAttr}
            >
              {field.content && (
                <div dangerouslySetInnerHTML={{ __html: field.content }} />
              )}
            </GDSForm.FormInput>
          )
        }
        return ''
      })}
    </GDSForm.FormGroup>
  )
}

const isSubmitDisabled = (errors, isValid, isSubmitting) => {
  if (isSubmitting) {
    return true
  }

  if (!isValid) {
    return true
  }

  if (!Utils.isEmpty(errors)) {
    return true
  }

  return false
}

const generateGqlQuery = (data, options, type) => {

  const gqlVariables = {
    input: {}
  }
  const getPastresult = isInfinity() ? `
    target
    pastResults {
      edges {
        node {
          trackingType
          totalKG
          createdAt
          trees
          groups
        }
      }
    }` : ``

  let gqlQuery = ''
  let gqlResponse = `
      version
      groups{
        edges{
          node{
            id
            name
            description
            question
            items{
              edges{
                node{
                  id
                  parentID
                  parentCondition
                  parentAnswerValues
                  mandatory
                  question
                  description
                  info
                  answerUnit
                  answerType
                  answerOptions{
                    id
                    value
                    description
                  }
                  defaults
                  validationsJSON
                }
              }
            }
          }
        }
      }
  `

  if (type === 'questionnaire:fetch') {
    gqlQuery = `
    {
      co2eQuestionnaire(){
        ${gqlResponse}
      }
    }`
  }

  if (type === 'questionnaire:calculate') {

    gqlResponse = `
      results {
        totalKG
        trees
        sgAverageKG
        sustainableAverageKG
        groups {
          edges {
            node {
              id
              name
              totalKG
              percentage
              tips
              savings
              questID
            }
          }
        }
      }
    `

    gqlQuery = `
    query CalculateCO2Result($input:CO2eQuestionnaireInput!) {
      calculateCO2eQuestionnaireResults(
        input:$input 
      ) {
        ${gqlResponse}
      }
    }
    `

    gqlVariables.input = {
      version: `${data.version}`,
      answers: data.answers
    }
  }
  if (type === 'questionnaire:result') {
    gqlResponse = `
    edges{
      node{
        version
        trackingType
        results{
          totalKG
          trees
          sgAverageKG
          sustainableAverageKG
          groups{
            edges{
              node{
                id
                name
                totalKG
                percentage
                questID
                tips
                savings
              }
            }
          }
          ${getPastresult}
        }
      }
    }
    `

    gqlQuery = `
    query CompletedCO2eQuestionnaires() {
      completedCO2eQuestionnaires(
        limit: 1
      ) {
        ${gqlResponse}
      }
    }
    `

    gqlVariables.input = {
      version: `${data.version}`,
      answers: data.answers
    }
  }

  if (type === 'questionnaire:target') {
    gqlResponse = `
      iamID
      target
    `

    gqlQuery = `
    mutation SetTarget($target: Int!){
      submitCO2eTarget(target: $target) {
        ${gqlResponse}
      }
    }
    `
    gqlVariables.target = data.target
  }


  if (type === 'questionnaire:submit') {
    gqlResponse = `
      results {
        totalKG
        trees
        sgAverageKG
        sustainableAverageKG
        groups {
          edges {
            node {
              id
              name
              totalKG
              percentage
              tips
              savings
              questID
            }
          }
        }  
        ${getPastresult}
      }
    `

    gqlQuery = `
    mutation SubmitCO2($input:CO2eQuestionnaireInput!) {
      submitCO2eQuestionnaireAnswers(
        input:$input
      ) {
        ${gqlResponse}
      }
    }
    `
    gqlVariables.input = {
      version: `${data.version}`,
      answers: data.answers
    }
  }

  // Sample conditionals for gqlVariables using options
  if (options.some(o => o === 'id')) {
    gqlVariables.id = data.application_id
  }

  if (options.some(o => o === 'details')) {
    gqlVariables.details = {
      "email": data.SAMPLE.email,
      "mobileNumber": data.SAMPLE.mobile_number,
    }
  }

  return {
    query: gqlQuery,
    variables: gqlVariables
  }
}

const getYearLabel = (year, withS = true) => {
  let result = {}
  if (!year) return result
  let suffix = 'Year'

  if (withS) {
    suffix += `s`
  }

  let yearParts = year.split('year')
  if (yearParts.length > 1) {
    result.label = `${yearParts[1]} ${suffix}`
  }
  return result
}

const GetQuestionnaire = (groupId = '') => {
  const { co2e } = useDyipne()
  const [co2eState] = co2e
  if (!co2eState.questionnnaire) {
    return null
  }
  let result = co2eState.questionnnaire.groups.edges.map(group => {
    let items = group.node.items?.edges
    if (items) {
      items = items.map(item => {
        const validation = item.node.validationsJSON !== '' ? JSON.parse(item.node.validationsJSON) : null
        if (item.mandatory) {
          if (validation) {
            const requiredValidationIdx = validation.validations.findIndex(v => v.type === 'required')
            if (requiredValidationIdx === -1) {
              validation.validations = [
                {
                  type: 'required',
                  params: []
                },
                ...validation.validations,
              ]
            }
          }
        }
        const newItem = {
          ...item.node,
          validation
        }
        return newItem
      })
    }
    return {
      ...group.node,
      items
    }
  })
  if (groupId !== '') {
    if (result.length > 0) {
      const filtered = result.filter(grp => grp.id === groupId.toUpperCase())
      if (filtered.length === 0) {
        return null
      }
      return filtered[0]
    }
  }
  return result
}

const GetQuestionnaireData = (items) => {
  const { co2e } = useDyipne()
  const [co2eState] = co2e
  let preSavedFields = co2eState.fields

  const result = {}
  if (!items) return result

  items.forEach(item => {
    if (item.id) {
      let preValue
      if (preSavedFields) {
        if (preSavedFields[item.id]) {
          preValue = preSavedFields[item.id]
        }
      }
      if (item.answerType === 'CHECKBOX_ALL') {
        result[item.id] = preValue || item.defaults
      } else {
        result[item.id] = preValue || (item.defaults.length > 0 ? item.defaults[0] : '')
      }
    }
  })

  return result
}
const isInfinity = () => {
  return /source=infinity/.test(window.location.search)
}

const isInfinityAndroid = () => {
  return isInfinity() && window?.spdigitalUtilitiesAndroid?.getToken
}

const isInfinityIOS = () => {
  return isInfinity() && window?.webkit?.messageHandlers?.spdigitalSessionToken?.postMessage
}

const idMapping = {
  HOME: 'ENERGY',
  HOME_MOBILE: 'ENERGY',
  FOOD_MOBILE: 'FOOD',
  SHOPPING_MOBILE: 'SHOPPING',
  DAILY_TRAVELS_MOBILE: 'DAILY_TRAVELS',
  HOLIDAY_TRAVELS_MOBILE: 'HOLIDAY_TRAVELS'
}

const transformMobileResults = (mobileResults) => {
  mobileResults.groups.edges.map(edge => edge.node.id = idMapping[edge.node.id] ?? edge.node.id)
  if (mobileResults.pastResults) {
    mobileResults.pastResults.edges = transformMobilePastResults(mobileResults.pastResults)
  }
  return mobileResults
}

const transformMobilePastResults = (pastResults) => {
  return pastResults?.edges.map(edge => {
    return {
      node: {
        ...edge.node,
        groups: edge.node.groups.map(group => ({ ...group, id: idMapping[group.id] ?? group.id }))
      }
    }
  })
}

const userFriendlyErrorMsg = (systemMessage) => {
  switch (systemMessage) {
    case Co2eConstants.ERR_MSG_NOT_AUTHORIZE:
      return "Your session has expired, please go back to the apps and try again."
    default:
      return systemMessage
  }
}

const decodedBase64 = (base64Token = "") => {
  let decoded = Buffer.from(base64Token, "base64").toString()
  return decoded
}

const parseChartData = (resultsData) => {
  if (!resultsData?.length) return []
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  // check for the latest month
  const latest = +resultsData.map((e) => e.node.createdAt).sort().reverse()[0].slice(5, 7)
  // for above, Ex: 2021-05-10T00:00:00Z.slice(5,7) => 05 
  let chartOutput = [...Array(12)].map((_, i) => ({
    label: months[(i + latest) % 12],
    total: 0,
    month: ((i + latest) % 12) + 1
  }))
  // map data to make it fit for chart ({total:0,label:months[i + 1],...})
  const rsltData = resultsData.map(({ node }, id) => {
    const month = +node.createdAt.slice(5, 7)
    const tracker = node.trackingType === defaultType.ANNUAL ? 0 : node.totalKG
    return {
      total: tracker,
      label: months[month - 1],
      month,
    }
  })
  chartOutput = chartOutput.map((value) => {
    const cur = rsltData.map((el) => el.label).indexOf(value.label)
    return cur >= 0 ? rsltData[cur] : value
  })
  return chartOutput
}

const transformMonthlyData = (pastResult = []) => {
  pastResult = pastResult.filter(({ node }) => node.trackingType === defaultType.MONTHLY)

  if (pastResult.length === 0) {
    return [0, 0]
  }
  const monthlytTotalKG = pastResult.reduce((pre, cur) => pre + cur.node.totalKG, 0)

  const annualTotalKG = (monthlytTotalKG / pastResult.length) * 12
  const monthlyAverageTotalKG = monthlytTotalKG / pastResult.length

  return [
    annualTotalKG,
    monthlyAverageTotalKG,
  ]
}

const helpers = {
  createSchema,
  decodedBase64,
  generateSchemaValidator,
  // createSurveySchema,
  // getFields,
  isInfinity,
  isInfinityAndroid,
  isInfinityIOS,
  isSubmitDisabled,
  generateGqlQuery,
  getYearLabel,
  GetQuestionnaire,
  GetQuestionnaireData,
  getFormFields,
  transformMobileResults,
  transformMobilePastResults,
  transformMonthlyData,
  userFriendlyErrorMsg,
  parseChartData
}

export default helpers
