import React from 'react'

const CO2 = () => {
  return (
    <svg className="gfx-co2logo" width="245" height="145" viewBox="0 0 245 145" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Co2">
        <g id="Co">
          <mask id="gfx-co2logo-mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="95" y="0" width="150" height="144">
            <path id="Combined Shape" fillRule="evenodd" clipRule="evenodd" d="M244.529 71.7928C244.529 112.116 215.578 143.392 170.084 143.392C124.591 143.392 95.6399 112.116 95.6399 71.7928C95.6399 32.0594 124.591 0.58728 170.084 0.58728C215.578 0.58728 244.529 32.0594 244.529 71.7928ZM136.604 71.7929C136.604 92.4464 149.996 107.592 170.084 107.592C190.173 107.592 203.565 92.4464 203.565 71.7929C203.565 51.7295 190.173 36.3868 170.084 36.3868C149.996 36.3868 136.604 51.7295 136.604 71.7929Z" fill="#008587"/>
          </mask>
          <g mask="url(#gfx-co2logo-mask0)">
            <path id="Combined Shape_2" fillRule="evenodd" clipRule="evenodd" d="M244.529 71.7928C244.529 112.116 215.578 143.392 170.084 143.392C124.591 143.392 95.6399 112.116 95.6399 71.7928C95.6399 32.0594 124.591 0.58728 170.084 0.58728C215.578 0.58728 244.529 32.0594 244.529 71.7928ZM136.604 71.7929C136.604 92.4464 149.996 107.592 170.084 107.592C190.173 107.592 203.565 92.4464 203.565 71.7929C203.565 51.7295 190.173 36.3868 170.084 36.3868C149.996 36.3868 136.604 51.7295 136.604 71.7929Z" fill="#008587"/>
            <g id="Ellipse 14" className="gfx-co2logo-shadow" style={{mixBlendMode:'multiply'}}>
              <ellipse cx="121.114" cy="13.788" rx="136.686" ry="39.7624" fill="#C4C4C4"/>
            </g>
            <g id="O">
              <mask id="gfx-co2logo-mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="84" y="0" width="150" height="144">
                <path id="Combined Shape_3" fillRule="evenodd" clipRule="evenodd" d="M233.041 71.7928C233.041 112.116 204.09 143.392 158.596 143.392C113.102 143.392 84.1517 112.116 84.1517 71.7928C84.1517 32.0594 113.102 0.58728 158.596 0.58728C204.09 0.58728 233.041 32.0594 233.041 71.7928ZM125.063 71.6433C125.063 92.2968 138.455 107.443 158.543 107.443C178.631 107.443 192.024 92.2968 192.024 71.6433C192.024 51.5798 178.631 36.2372 158.543 36.2372C138.455 36.2372 125.063 51.5798 125.063 71.6433Z" fill="url(#gfx-co2logo-paint0_linear)"/>
              </mask>
              <g mask="url(#gfx-co2logo-mask1)">
                <path id="Combined Shape_4" fillRule="evenodd" clipRule="evenodd" d="M233.041 71.7928C233.041 112.116 204.09 143.392 158.596 143.392C113.102 143.392 84.1517 112.116 84.1517 71.7928C84.1517 32.0594 113.102 0.58728 158.596 0.58728C204.09 0.58728 233.041 32.0594 233.041 71.7928ZM125.063 71.6433C125.063 92.2968 138.455 107.443 158.543 107.443C178.631 107.443 192.024 92.2968 192.024 71.6433C192.024 51.5798 178.631 36.2372 158.543 36.2372C138.455 36.2372 125.063 51.5798 125.063 71.6433Z" fill="url(#gfx-co2logo-paint1_linear)"/>
                <g id="Ellipse 13" className="gfx-co2logo-shadow" style={{mixBlendMode:'multiply'}}>
                  <ellipse cx="122.441" cy="21.7405" rx="136.686" ry="39.7624" fill="#C4C4C4"/>
                </g>
              </g>
            </g>
          </g>
          <mask id="gfx-co2logo-mask2" mask-type="alpha" maskUnits="userSpaceOnUse" x="10" y="0" width="116" height="144">
            <path id="Path" d="M84.0533 143.392C100.596 143.392 115.761 138.474 125.805 131.59L114.973 97.3639C110.454 97.3639 105.126 97.3639 105.126 97.3639C105.126 97.3639 94.5951 107.592 86.0227 107.592C65.1467 107.592 51.1638 92.2497 51.1638 71.7928C51.1638 51.7294 65.1467 36.3868 86.0227 36.3868C94.5882 36.3868 105.126 46.6152 105.126 46.6152C108.456 46.6152 112.096 46.6152 114.973 46.6152L125.805 12.3893C115.761 5.50479 100.596 0.58728 84.0533 0.58728C39.3472 0.58728 10.1996 32.2561 10.1996 71.7928C10.1996 111.723 39.3472 143.392 84.0533 143.392Z" fill="#00649A"/>
          </mask>
          <g mask="url(#gfx-co2logo-mask2)">
            <path id="Path_2" d="M84.0533 143.392C100.596 143.392 115.761 138.474 125.805 131.59L114.973 97.3639C110.454 97.3639 105.126 97.3639 105.126 97.3639C105.126 97.3639 94.5951 107.592 86.0227 107.592C65.1467 107.592 51.1638 92.2497 51.1638 71.7928C51.1638 51.7294 65.1467 36.3868 86.0227 36.3868C94.5882 36.3868 105.126 46.6152 105.126 46.6152C108.456 46.6152 112.096 46.6152 114.973 46.6152L125.805 12.3893C115.761 5.50479 100.596 0.58728 84.0533 0.58728C39.3472 0.58728 10.1996 32.2561 10.1996 71.7928C10.1996 111.723 39.3472 143.392 84.0533 143.392Z" fill="#00649A"/>
            <g id="Ellipse 13_2" className="gfx-co2logo-shadow" style={{mixBlendMode:'multiply'}}>
              <ellipse cx="121.114" cy="13.788" rx="136.686" ry="39.7624" fill="#C4C4C4"/>
            </g>
            <g id="C">
              <mask id="gfx-co2logo-mask3" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="116" height="144">
                <path id="Path_3" d="M74.206 143.392C90.7493 143.392 105.914 138.474 115.958 131.59L105.126 97.3639C98.627 102.675 89.1737 107.592 76.1755 107.592C55.2995 107.592 41.3165 92.2497 41.3165 71.7928C41.3165 51.7294 55.2995 36.3868 76.1755 36.3868C89.1737 36.3868 98.627 41.3043 105.126 46.6152L115.958 12.3893C105.914 5.50479 90.7493 0.58728 74.206 0.58728C29.4999 0.58728 0.352356 32.2561 0.352356 71.7928C0.352356 111.723 29.4999 143.392 74.206 143.392Z" fill="#3579DE"/>
              </mask>
              <g mask="url(#gfx-co2logo-mask3)">
                <path id="Path_4" d="M74.206 143.392C90.7493 143.392 105.914 138.474 115.958 131.59L105.126 97.3639C98.627 102.675 89.1737 107.592 76.1755 107.592C55.2995 107.592 41.3165 92.2497 41.3165 71.7928C41.3165 51.7294 55.2995 36.3868 76.1755 36.3868C89.1737 36.3868 98.627 41.3043 105.126 46.6152L115.958 12.3893C105.914 5.50479 90.7493 0.58728 74.206 0.58728C29.4999 0.58728 0.352356 32.2561 0.352356 71.7928C0.352356 111.723 29.4999 143.392 74.206 143.392Z" fill="#3579DE"/>
                <g id="Ellipse 12" className="gfx-co2logo-shadow" style={{mixBlendMode:'multiply'}}>
                  <ellipse rx="136.676" ry="39.7653" transform="matrix(0.969565 0.244835 -0.245404 0.969421 121.114 34.9947)" fill="#C4C4C4"/>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="2">
          <path id="2_2" d="M227.756 144.261V130.574H206.01C209.293 123.525 227.264 117.05 227.264 103.937C227.264 92.217 218.155 85.8242 206.667 85.8242C193.291 85.8242 187.383 96.065 185.741 102.458L199.454 106.625C200.357 103.92 202.892 100.495 205.928 100.495C208.882 100.495 210.688 102.134 210.688 104.593C210.688 112.789 186.48 121.804 186.48 139.015V144.261H227.756Z" fill="#C1904F"/>
          <path id="2_3" d="M222.833 144.261V130.574H201.087C204.369 123.525 222.34 117.05 222.34 103.937C222.34 92.217 213.232 85.8242 201.743 85.8242C188.367 85.8242 182.623 94.7577 180.982 101.15L195.096 105.248C195.999 102.544 197.968 100.495 201.005 100.495C203.959 100.495 205.764 102.134 205.764 104.593C205.764 112.789 181.557 121.804 181.557 139.015V144.261H222.833Z" fill="#FFC466"/>
        </g>
      </g>
      <defs>
        <linearGradient id="gfx-co2logo-paint0_linear" x1="9.70717" y1="71.9896" x2="152.387" y2="220.749" gradientUnits="userSpaceOnUse">
          <stop stopColor="#52CFD1"/>
          <stop offset="1" stopColor="#00B0B2"/>
        </linearGradient>
        <linearGradient id="gfx-co2logo-paint1_linear" x1="9.70717" y1="71.9896" x2="152.387" y2="220.749" gradientUnits="userSpaceOnUse">
          <stop stopColor="#52CFD1"/>
          <stop offset="1" stopColor="#00B0B2"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default CO2
