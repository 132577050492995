import React, { Component } from 'react'

export default class PDPA extends Component {
  render() {
    const { children } = this.props
    const alignCenter = {'textAlign': 'center'}

    return (<div className="container pdpa-container" data-swiftype-name="body" data-swiftype-type="text" data-swiftype-index="true">

      <div className="row"><h2 className="col-sm-12 content-title">Personal Data Protection Policy</h2></div>

      <div className="row">
        <div className="table col-sm-12">
          <div className="table-cell text-only">

            <h3>Introduction</h3>
            <p>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Singapore Power Limited and its Singapore-incorporated
              subsidiaries (<b>SP Group</b>) own and operate the national electricity and gas transmission and
              distribution networks, providing electricity and gas services to more than one million industrial,
              commercial and residential customers in Singapore. SP Group also provides billing services for various
              principals in the water and waste industries, e.g., The Public Utilities Board, City Gas Pte Ltd, Veolia ES
              Singapore Pte Ltd, Colex Environmental Pte Ltd, SembWaste Pte Ltd and 800 Super Waste Management Pte Ltd.
              (collectively, <b>Third
                Party Utilities</b>).</p>
            <p>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; By continuing to use the services of SP Group you
              signify that you have read, understood and agree to SP Group’s collection use and disclosure of your
              personal data as described in this Policy.</p>

            <h3>Information we may collect from you</h3>
            <p>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SP Group may collect the following data about you either
              directly from you, from your authorised representatives (i.e. persons who you have authorised and persons
              who have been validly identified as being you or your authorised representative) from third parties, or from
              publicly available sources:</p>
            <ul>
              <li>Personal information (such as your full name, NRIC, ethnicity, telephone numbers, address).
              </li>
              <li>Records from discussions or correspondences, including details obtained through customer satisfaction or
                other surveys.
              </li>
              <li>Details of your usage of SP Group or Third Party Utility services (including consumption and usage data
                and reports prepared by electricity or gas meter-readers).
              </li>
              <li>Information derived from cookies (small text files which uniquely identify your browser) where you visit
                any website or use any application created by or on behalf of the SP Group.
              </li>
              <li>Information you provide to any SP Group or any Third Party Utility in relation to the provision of any
                services from SP Group or a Third Party Utility to you.
              </li>
            </ul>
            <h3>Use and disclosure of personal data collected</h3>
            <p>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; You agree that SP Group may use your personal data where
              permitted by applicable law and for the following purposes:</p>
            <ul>
              <li>To provide and improve SP Group and Third Party Utility services including fulfilling audit
                requirements, performing creditworthiness checks and billing for services (including debt recovery).
              </li>
              <li>To compile information for analysis and in reports to our regulatory agencies and to the Third Party
                Utilities.
              </li>
              <li>To conduct surveys, obtain feedback, provide you with information, products or services from SP Group or
                third parties or which we feel may interest you, where you have consented to be contacted for such
                purposes.
              </li>
              <li>To carry out your instructions or to respond to enquiries.
              </li>
              <li>To comply with any requirements obligations or arrangements for using or disclosing data that applies to
                any member of the SP Group or any Third Party Utility due to applicable laws, regulations or industry
                codes.
              </li>
              <li>For internal management of the services being provided to you.
              </li>
              <li>To maintain the security of our premises.
              </li>
              <li>To provide training and apply for grants.
              </li>
              <li>To carry out analytics, research, planning and statistical assessment.
              </li>
              <li>To develop new services and products.
              </li>
              <li>For other purposes as reasonably required to provide services to you.</li>
            </ul>
            <p>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In addition, you agree that SP Group may disclose your
              personal data where permitted by applicable law and for the following purposes:</p>
            <ul>
              <li>If we are under a duty to disclose or share your personal data where we believe that we are under a
                legal obligation to disclose; in order to enforce or apply agreed terms and conditions or other
                agreements; or to protect the rights, property, security or safety of SP Group members, our customers, or
                premises, or others. The foregoing includes exchanging information with other companies and organisations
                for the purposes of fraud protection and credit risk reduction.
              </li>
              <li>To persons who have been validly identified as being you or your authorised representative(s) pursuant,
                for the purpose of the relevant transaction or enquiry.
              </li>
              <li>To authorised proxies such as your Member of Parliament or a social welfare organisation representing
                you.
              </li>
              <li>To third parties with whom we have contracted to provide services to us (such as analysis on our behalf)
                or within the SP Group for any of the purposes described above. Where SP Group discloses your personal
                data to third parties SP Group shall ensure that such data is used only to provide services to SP Group.
              </li>
            </ul>
            <h3>Storage of data, information security and applicability to third parties</h3>

            <p>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The data we collect from you may be transferred to,
              processed, and stored at, multiple destinations worldwide. SP Group will take all steps as required by
              mandatory Singapore law in connection with any transfer of such data overseas.</p>
            <p>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SP Group will make reasonable security arrangements to
              prevent unauthorised access, collection, use, disclosure, copying, modification, disposal or similar
              activities to protect your personal data in SP Group’s possession or under SP Group’s control.</p>
            <p>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SP Group will require these data protection standards to
              be observed by external parties who provide services to SP Group and/or to whom we disclose your personal
              data, but we cannot and do not assume any responsibility for any actions or omissions of third parties.</p>
            <h3>Use of Cookies</h3>
            <p>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; When you use and access our services on our website or
              applications, you agree that SP Group may place a number of cookies files in your web browser.&nbsp; We use
              cookies for the purposes as set out in this Policy, including without limitation enabling certain functions
              of the services, to provide analytics, to store your preferences and to enable advertisements
              delivery.&nbsp; In addition to SP Group’s cookies, we may also use various third-parties cookies to report
              usage statistics of the services, deliver advertisements on and through the services, and so on.</p>
            <p>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If you would like to delete cookies or instruct your web
              browser to delete or refuse cookies, please visit the help pages of your web browser.&nbsp; Please note,
              however, that if you delete cookies or refuse to accept them, you might not be able to use all of the
              features SP Group offers, you may not be able to store your preferences, and some of our pages might not
              display properly.</p>
            <h3>Access to personal information</h3>
            <p>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; At your request, we will provide access to your personal
              data, save that in specified circumstances your access may be subject to fees to meet our costs.</p>
            <h3>Amendments</h3>
            <p>12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SP Group may modify this Policy at any time by giving you
              written notice, such as together with your monthly bill. Continued use of any SP Group or Third Party
              Utility service after such written notification has been sent, signifies acceptance of any such
              modification.</p>
            <h3>Questions, Application for Access/Correction, Withdrawal of consent, Personal Data Retention and Disposal</h3>
            <p>13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; If you have any questions, comments or suggestions regarding
              this Policy, we would be glad to hear from you. Please contact our SP Data Protection Office at:</p>
            <p style={alignCenter}><strong>SP Data Protection Office
            </strong></p>
            <p style={alignCenter}><strong>SP Group</strong></p>
            <p style={alignCenter}><strong>2 Kallang Sector</strong></p>
            <p style={alignCenter}><strong>Singapore 349277</strong></p>
            <p style={alignCenter}><strong>Email: <span style={{color: '#00b0b2'}}>dpo@spgroup.com.sg</span></strong></p>
            <p>14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Please also contact our SP Data Protection Office if you
              would like to apply for access/correction of your own personal data or if you would like us to cease use of
              your personal data for any purpose.</p>
            <p>15.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; We will try to respond to your question or request promptly.
              We may however, depending on the nature of the question or request, need up to 30 days to provide you an
              estimate of when we will be able to respond to you.</p>
          </div>
        </div>
        { children }
      </div>
    </div>)

  }
}
