import axios from 'axios'

const GRAPHQL_DEFAULT_HEADERS = {
  'Content-Type': 'application/json'
}

const graphQLRequest = ({ headers, ...restPayload }, query, variables) => {
  const options = {
    ...restPayload,
    headers: {
      ...GRAPHQL_DEFAULT_HEADERS,
      ...headers,
    },
    validateStatus: function (status) {
      return status >= 200 && status < 300
    }
  }

  return new Promise((resolve, reject) => {
    axios({
      ...options,
      data: {
        query,
        variables
      }
    }).then(response => {
      resolve(response)
    }).catch(err => {
      const errObj = {}
      if (err.response && err.response.status >= 400) {
        let error_description = ''
        switch (err.response.status) {
          case 401:
            error_description = 'You are not authorized for this request'
            break
          default:
            error_description = `An error has occurred. Please try again later.`
        }
        errObj.errors = [{
          error: err.response.data,
          status: err.response.status,
          error_description
        }]
      }
      reject(errObj)
    })
  })
}

const api = {
  graphQLRequest
}

export default api
