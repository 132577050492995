import React from 'react'

const Cloud2 = ({ x, y}) => {
  return (
    <svg style={{ '--cloudX': x, '--cloudY': y}} className="gfx-skycloud qnnre-cloud2 gfx-cloud2" width="168" height="70" viewBox="0 0 168 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M36.2238 14.5542C42.3808 5.80189 53.1639 0 65.437 0C74.8628 0 83.4111 3.42361 89.6565 8.975C92.3302 8.22657 95.1705 7.82142 98.1129 7.82142C102.685 7.82142 107.008 8.79871 110.842 10.5275C116.73 6.68022 123.952 4.41018 131.758 4.41018C151.569 4.41018 167.625 19.0108 167.625 37.0195C167.625 55.0314 151.569 69.632 131.758 69.632C130.976 69.632 130.197 69.6042 129.428 69.5609C128.755 69.6073 128.071 69.632 127.38 69.632H27.2436C12.2594 69.632 0 58.4828 0 44.8564C0 32.2908 10.426 21.8344 23.8011 20.285C27.3014 17.5201 31.5466 15.5191 36.2238 14.5542Z" fill="white"/>
    </svg>
  )
}

export default Cloud2
