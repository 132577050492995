import React from "react"
import classnames from 'classnames'

const Podium = ({ className, children }) => {
  const _Podium = classnames('gfx-podium', className)
  return (
    <div className={_Podium}>
      <svg className="gfx-bush1" width="75" height="27" viewBox="0 0 75 27" fill="none" 
    xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M49.886 23.2047C51.4511 22.819 54.1875 23.2162 54.0644 20.6166C53.9404 18.2771 50.1648 16.5772 48.2111 16.0517C49.5163 15.5351 50.6936 13.9789 50.046 12.4172C49.1388 10.4655 45.4893 10.3257 44.0588 9.02181C42.4981 7.71796 43.6685 8.76096 43.2817 7.3301C41.473 1.21666 38.7275 4.19854 33.5154 3.27485C34.7044 -2.44089 25.1736 2.99176 23.2178 3.1162C22.3051 3.11406 21.3954 2.20192 20.3523 2.19893C19.3096 2.19594 18.1348 2.84295 17.2204 3.48995C15.5223 4.78525 14.9922 7.90312 13.5567 8.54928C12.6427 8.93671 11.9942 7.765 10.8185 8.67158C10.2957 9.1903 9.90066 10.6186 9.63817 11.2677C9.11404 12.306 8.71902 13.8648 7.6742 14.3818C6.89101 14.7692 5.85006 14.1166 4.93648 14.5041C3.36968 15.4094 4.01819 16.7106 3.88309 18.2707C3.8788 19.8298 4.0109 19.3107 3.35724 20.0885C2.70316 20.7368 1.66006 20.9938 1.13593 21.9021C0.327871 23.2633 0.20263 24.7493 0.536749 26.1891L51.4391 26.3281C50.8317 25.0717 50.1395 23.918 49.886 23.2047Z" fill="#D4EDA7"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M69.8471 23.2046C71.4121 22.8193 74.1486 23.2162 74.0255 20.6166C73.9015 18.277 70.1258 16.5772 68.1722 16.0516C69.4773 15.535 70.6543 13.9789 70.007 12.4171C69.0999 10.4654 65.4503 10.3256 64.0199 9.02217C62.4592 7.71789 63.6296 8.76131 63.2428 7.33045C61.4341 1.21658 58.6882 4.19847 53.4766 3.27478C54.6655 -2.44097 45.1348 2.99168 43.1785 3.11655C42.2663 3.11442 41.3561 2.20185 40.3135 2.19885C39.2708 2.19629 38.096 2.84287 37.1812 3.48988C35.4835 4.78518 34.9534 7.90305 33.5174 8.5492C32.6039 8.93664 31.9554 7.76493 30.7797 8.67151C30.2569 9.19023 29.8619 10.6185 29.5994 11.2677C29.0753 12.3064 28.6798 13.8647 27.6354 14.3817C26.8522 14.7691 25.8113 14.1166 24.8977 14.504C23.3309 15.4093 23.979 16.711 23.8443 18.2706C23.84 19.8297 23.9717 19.311 23.3181 20.0885C22.6644 20.7367 21.6209 20.9938 21.0972 21.902C20.2891 23.2632 20.1635 24.7497 20.498 26.1891L71.3997 26.3281C70.7928 25.0717 70.1005 23.9183 69.8471 23.2046Z" fill="#D4EDA7"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M58.9221 23.5519C60.3073 23.2087 62.7286 23.5621 62.6196 21.2515C62.5098 19.1715 59.1686 17.6601 57.4394 17.1937C58.5944 16.7342 59.6365 15.3512 59.0635 13.9631C58.2607 12.228 55.0314 12.1034 53.7654 10.9447C52.3836 9.78553 53.4197 10.7128 53.0771 9.44064C51.4765 4.00677 49.0471 6.6576 44.4352 5.83603C45.4871 0.755602 37.0524 5.58486 35.322 5.69568C34.5144 5.69354 33.7086 4.88225 32.7862 4.87968C31.8633 4.87711 30.8233 5.45178 30.0145 6.02773C28.5113 7.17878 28.0426 9.95027 26.7719 10.5245C25.9631 10.8685 25.3897 9.82746 24.3489 10.6336C23.8862 11.094 23.5371 12.364 23.3047 12.9408C22.8407 13.8638 22.4907 15.2494 21.5666 15.7089C20.8735 16.0538 19.9523 15.4736 19.1435 15.818C17.757 16.6229 18.3309 17.7795 18.2117 19.1659C18.2078 20.5519 18.324 20.0906 17.7459 20.7816C17.1674 21.358 16.2437 21.5865 15.7801 22.394C15.0653 23.6036 14.9542 24.9254 15.2503 26.2048L60.2962 26.3281C59.7587 25.2117 59.146 24.1856 58.9221 23.5519Z" fill="url(#qnnre-bush1_paint0_linear)"/>
        <defs>
          <linearGradient id="qnnre-bush1_paint0_linear" x1="-8.66584" y1="9.21869" x2="-2.19483" y2="36.1813" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D4EDA7"/>
            <stop offset="1" stopColor="#B0CF7A"/>
          </linearGradient>
        </defs>
      </svg>
      
      <div className='gfx-podium_star'>
        { children }
      </div>

      <svg className="gfx-bush2" width="75" height="27" viewBox="0 0 75 27" fill="none" 
    xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M49.886 23.2047C51.4511 22.819 54.1875 23.2162 54.0644 20.6166C53.9404 18.2771 50.1648 16.5772 48.2111 16.0517C49.5163 15.5351 50.6936 13.9789 50.046 12.4172C49.1388 10.4655 45.4893 10.3257 44.0588 9.02181C42.4981 7.71796 43.6685 8.76096 43.2817 7.3301C41.473 1.21666 38.7275 4.19854 33.5154 3.27485C34.7044 -2.44089 25.1736 2.99176 23.2178 3.1162C22.3051 3.11406 21.3954 2.20192 20.3523 2.19893C19.3096 2.19594 18.1348 2.84295 17.2204 3.48995C15.5223 4.78525 14.9922 7.90312 13.5567 8.54928C12.6427 8.93671 11.9942 7.765 10.8185 8.67158C10.2957 9.1903 9.90066 10.6186 9.63817 11.2677C9.11404 12.306 8.71902 13.8648 7.6742 14.3818C6.89101 14.7692 5.85006 14.1166 4.93648 14.5041C3.36968 15.4094 4.01819 16.7106 3.88309 18.2707C3.8788 19.8298 4.0109 19.3107 3.35724 20.0885C2.70316 20.7368 1.66006 20.9938 1.13593 21.9021C0.327871 23.2633 0.20263 24.7493 0.536749 26.1891L51.4391 26.3281C50.8317 25.0717 50.1395 23.918 49.886 23.2047Z" fill="#D4EDA7"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M69.8471 23.2046C71.4121 22.8193 74.1486 23.2162 74.0255 20.6166C73.9015 18.277 70.1258 16.5772 68.1722 16.0516C69.4773 15.535 70.6543 13.9789 70.007 12.4171C69.0999 10.4654 65.4503 10.3256 64.0199 9.02217C62.4592 7.71789 63.6296 8.76131 63.2428 7.33045C61.4341 1.21658 58.6882 4.19847 53.4766 3.27478C54.6655 -2.44097 45.1348 2.99168 43.1785 3.11655C42.2663 3.11442 41.3561 2.20185 40.3135 2.19885C39.2708 2.19629 38.096 2.84287 37.1812 3.48988C35.4835 4.78518 34.9534 7.90305 33.5174 8.5492C32.6039 8.93664 31.9554 7.76493 30.7797 8.67151C30.2569 9.19023 29.8619 10.6185 29.5994 11.2677C29.0753 12.3064 28.6798 13.8647 27.6354 14.3817C26.8522 14.7691 25.8113 14.1166 24.8977 14.504C23.3309 15.4093 23.979 16.711 23.8443 18.2706C23.84 19.8297 23.9717 19.311 23.3181 20.0885C22.6644 20.7367 21.6209 20.9938 21.0972 21.902C20.2891 23.2632 20.1635 24.7497 20.498 26.1891L71.3997 26.3281C70.7928 25.0717 70.1005 23.9183 69.8471 23.2046Z" fill="#D4EDA7"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M58.9221 23.5519C60.3073 23.2087 62.7286 23.5621 62.6196 21.2515C62.5098 19.1715 59.1686 17.6601 57.4394 17.1937C58.5944 16.7342 59.6365 15.3512 59.0635 13.9631C58.2607 12.228 55.0314 12.1034 53.7654 10.9447C52.3836 9.78553 53.4197 10.7128 53.0771 9.44064C51.4765 4.00677 49.0471 6.6576 44.4352 5.83603C45.4871 0.755602 37.0524 5.58486 35.322 5.69568C34.5144 5.69354 33.7086 4.88225 32.7862 4.87968C31.8633 4.87711 30.8233 5.45178 30.0145 6.02773C28.5113 7.17878 28.0426 9.95027 26.7719 10.5245C25.9631 10.8685 25.3897 9.82746 24.3489 10.6336C23.8862 11.094 23.5371 12.364 23.3047 12.9408C22.8407 13.8638 22.4907 15.2494 21.5666 15.7089C20.8735 16.0538 19.9523 15.4736 19.1435 15.818C17.757 16.6229 18.3309 17.7795 18.2117 19.1659C18.2078 20.5519 18.324 20.0906 17.7459 20.7816C17.1674 21.358 16.2437 21.5865 15.7801 22.394C15.0653 23.6036 14.9542 24.9254 15.2503 26.2048L60.2962 26.3281C59.7587 25.2117 59.146 24.1856 58.9221 23.5519Z" fill="url(#qnnre-bush2_paint0_linear)"/>
        <defs>
          <linearGradient id="qnnre-bush2_paint0_linear" x1="-8.66584" y1="9.21869" x2="-2.19483" y2="36.1813" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D4EDA7"/>
            <stop offset="1" stopColor="#B0CF7A"/>
          </linearGradient>
        </defs>
      </svg>

      <svg
        className="gfx-podium-platform"
        width="216"
        height="43"
        viewBox="0 0 216 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="gfx-podium_base"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 9.82849V34.2196C0 39.0689 48.1538 43 107.555 43C166.957 43 215.111 39.0689 215.111 34.2196V9.82849H0Z"
          fill="url(#qnnre-podium_base_paint0_linear)"
        />
        <path
          className="gfx-podium_top"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215.111 9.5324C215.111 14.3817 166.957 18.3127 107.555 18.3127C48.1538 18.3127 0 14.3817 0 9.5324C0 4.68309 48.1538 0.752075 107.555 0.752075C166.957 0.752075 215.111 4.68309 215.111 9.5324Z"
          fill="#CED5DC"
        />
        <g
          className="gfx-podium_topshadow"
          style={{ mixBlendMode: "multiply" }}
        >
          <ellipse
            cx="107.377"
            cy="9.49532"
            rx="66.3354"
            ry="5.58614"
            fill="url(#paint1_linear)"
          />
        </g>
        <defs>
          <linearGradient
            id="qnnre-podium_base_paint0_linear"
            x1="17.4567"
            y1="-18.4619"
            x2="69.1284"
            y2="24.1324"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.160937" stopColor="#A09DEA" />
            <stop offset="1" stopColor="#6B68B3" />
          </linearGradient>
          <linearGradient
            id="qnnre-podium_top_paint1_linear"
            x1="87.7226"
            y1="23.6077"
            x2="87.4763"
            y2="0.96797"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C4C4C4" />
            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>

    </div>
  )
}

export default Podium
