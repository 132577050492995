import Constants from 'spd-gds/constants/'
import Co2eConstants from 'spd-gds/constants/co2e.constants'
import { Co2eServices, StorageServices } from 'spd-gds/services'
import Helpers from 'spd-gds/helpers'

const DEFAULT_EMPTY_PAYLOAD = {
  totalKG: 0,
  sgAverageKG: 0,
  sustainableAverageKG: 0,
  groups: { edges: [] },
}

const FETCH_QUESTIONNAIRE = (payload = {}) => {
  return async dispatch => {
    dispatch({
      type: Co2eConstants.FETCH_QUESTIONNAIRE,
    })
    try {
      const result = await Co2eServices.FetchQuestionnaire(payload)
      dispatch({
        type: Co2eConstants.FETCH_QUESTIONNAIRE_SUCCESS,
        payload: result.co2eQuestionnaire,
      })
    } catch (err) {
      dispatch({
        type: Co2eConstants.FETCH_QUESTIONNAIRE_FAIL,
        payload: err,
      })
    }
  }
}

const CALCULATE = (payload = {}) => {
  return async (dispatch, state) => {
    dispatch({
      type: Co2eConstants.CALCULATE,
    })
    try {
      const calculatePayload = {
        answers: prepareAnswersPayload(payload, state.answers),
      }
      let fields = StorageServices.Retrieve(Constants.STORAGE.fields)
      if (fields) {
        fields = JSON.parse(fields)
      }
      StorageServices.Save(Constants.STORAGE.fields, { ...fields, ...payload })
      StorageServices.Save(Constants.STORAGE.answers, calculatePayload.answers)

      let data = []
      if (!Helpers.isInfinity()) {
        const response = await Co2eServices.CalculateCO2Results(
          calculatePayload
        )
        data = response.calculateCO2eQuestionnaireResults.results
        StorageServices.Save(Constants.STORAGE.data, data)
      }

      dispatch({
        type: Co2eConstants.CALCULATE_SUCCESS,
        payload: {
          results: data,
          answers: calculatePayload.answers,
          fields: payload,
        },
      })
    } catch (err) {
      dispatch({
        type: Co2eConstants.CALCULATE_FAIL,
        payload: err,
      })
    }
  }
}

const SUBMIT = (payload = {}) => {
  return async (dispatch, state) => {
    dispatch({
      type: Co2eConstants.CALCULATE,
    })
    try {
      const { email = 'test@email.com', ...answerPayload } = payload
      const submitPayload = {
        email,
        answers: prepareAnswersPayload(answerPayload, state.answers),
      }
      let response
      if (Helpers.isInfinity()) {
        response = await Co2eServices.SubmitCO2Mobile(submitPayload)
      } else {
        response = await Co2eServices.SubmitCO2(submitPayload)
      }
      let data = response.submitCO2eQuestionnaireAnswers.results
      if (Helpers.isInfinity()) {
        data = Helpers.transformMobileResults(data)
      }
      StorageServices.Save(Constants.STORAGE.data, data)

      dispatch({
        type: Co2eConstants.SUBMIT_SUCCESS,
        payload: {
          results: data,
        },
      })
    } catch (err) {
      dispatch({
        type: Co2eConstants.SUBMIT_FAIL,
        payload: err,
      })
    }
  }
}

const SUBMIT_TARGET = (payload = {}) => {
  return async (dispatch, state) => {
    dispatch({
      type: Co2eConstants.SUBMIT_TARGET,
    })
    try {
      const { target } = payload
      const submitPayload = { target }
      let response = await Co2eServices.SubmitCO2Target(submitPayload)

      let data = response.submitCO2eTarget.target
      StorageServices.Save(Constants.STORAGE.target, data)

      dispatch({
        type: Co2eConstants.SUBMIT_TARGET_SUCCESS,
        payload: {
          target: data,
        },
      })
    } catch (err) {
      dispatch({
        type: Co2eConstants.SUBMIT_TARGET_FAIL,
        payload: err,
      })
    }
  }
}

const FETCH_RESULT = () => {
  return async dispatch => {
    dispatch({
      type: Co2eConstants.GET_RESULT,
    })
    try {
      const response = await Co2eServices.FetchCO2Result()
      let resultsResponse =
        response.completedCO2eQuestionnaires.edges?.[0]?.node
      let data = { ...DEFAULT_EMPTY_PAYLOAD }
      if (resultsResponse) {
        let trackingType = resultsResponse.trackingType
        data = { ...resultsResponse.results, trackingType }
      }

      if (Helpers.isInfinity()) {
        data = Helpers.transformMobileResults(data)
      }
      StorageServices.Save(Constants.STORAGE.data, data)

      dispatch({
        type: Co2eConstants.GET_RESULT_SUCCESS,
        payload: {
          results: data,
        },
      })
    } catch (err) {
      dispatch({
        type: Co2eConstants.GET_RESULT_FAIL,
        payload: err,
      })
    }
  }
}

const SAVE_GUESS_RAINTREES = (payload = {}) => {
  return async dispatch => {
    dispatch({
      type: Co2eConstants.GUESS_RAINTREES_SUCCESS,
      payload: {
        guess_raintrees: payload['guess_raintrees'],
      },
    })
    StorageServices.Save(
      Constants.STORAGE.guess_raintrees,
      +payload['guess_raintrees']
    )
  }
}

const SAVE_CO2_PAST_RESULT = (payload = {}, result) => {
  return async dispatch => {
    try {
      const objectTransformation = {
        ...DEFAULT_EMPTY_PAYLOAD,
        ...result,
        sgAverageKG: payload.sgAverage,
        sustainableAverage: payload.sustainableAverage,
        pastResults: {
          edges: payload.pastResults,
        },
        target: payload.target,
        groups: {
          edges: result.groups?.map(value => ({
            node: { ...value, totalKG: value.total_kg },
          })),
        },
      }
      let data = objectTransformation
      if (Helpers.isInfinity()) {
        data = Helpers.transformMobileResults(data)
      }
      dispatch({
        type: Co2eConstants.SAVE_PAST_RESULT_SUCCESS,
        payload: {
          results: data,
        },
      })
      StorageServices.Save(Constants.STORAGE.data, data)
    } catch (error) {
      dispatch({
        type: Co2eConstants.SAVE_PAST_RESULT_FAIL,
        payload: {
          error: [{ error: 'An Unexpected Error Occurred!' }],
        },
      })
    }
  }
}
const RESET = () => {
  return dispatch => {
    StorageServices.Clear()
    dispatch({
      type: Co2eConstants.RESET,
    })
  }
}

const CONTINUE = () => {
  return dispatch => {
    dispatch({
      type: Co2eConstants.CONTINUE,
    })
  }
}

function prepareAnswersPayload(answers = {}, prevAnswers = []) {
  if (!answers) return []
  const answersKeys = Object.keys(answers)
  const answersPayload = answersKeys.map(key => {
    let values = answers[key]
    if (!Array.isArray(values)) {
      values = [values]
    }
    return {
      itemID: key,
      values,
    }
  })

  const fromPrev = prevAnswers.filter(ans => {
    const isInPayload = answersPayload.some(ansP => {
      return ansP.itemID === ans.itemID
    })
    return !isInPayload
  })
  return [...fromPrev, ...answersPayload]
}
const co2eActions = {
  FETCH_QUESTIONNAIRE,
  CALCULATE,
  SUBMIT,
  CONTINUE,
  RESET,
  FETCH_RESULT,
  SUBMIT_TARGET,
  SAVE_GUESS_RAINTREES,
  SAVE_CO2_PAST_RESULT,
}

export default co2eActions
