import React, { useContext } from 'react'

import { useReducerThunk } from 'spd-gds/reducers'
import { co2eReducer, initialState } from 'spd-gds/reducers/co2e.reducer'

export const DyipneCtx = React.createContext()

export const DyipneProvider = DyipneCtx.Provider

export const useDyipne = () => {
  const dyipneCtx = useContext(DyipneCtx)
  return dyipneCtx
}

export const withDyipne = (Component, otherProps) => {
  return props => (
    <DyipneCtx.Consumer>
      {context => (
        <div>
          <Component {...props} {...otherProps} {...context} />
        </div>
      )}
    </DyipneCtx.Consumer>
  )
}

const Dyipne = ({ children }) => {
  const useCo2eState = useReducerThunk(co2eReducer, initialState)
  return (
    <DyipneProvider
      value={{
        co2e: useCo2eState,
        actions: {},
      }}
    >
      {children}
    </DyipneProvider>
  )
}

export default Dyipne
