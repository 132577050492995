import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useScreenSize } from 'spd-gds/components/ScreenSize'
import Utils from 'spd-gds/utils'
import classnames from 'classnames'
import "./styles.scss"

const SpotLight = ({ target, result, arrColor, className }) => {
  const screenSize = useScreenSize()
  const [origin, setOrigin] = useState('left')
  const _SpotLight = classnames('spot-light-bar', className)
  const _Line = classnames('sp-wrapper', {
    'origin-left': origin === 'left',
    'origin-right': origin === 'right'
  })
  const colored = {
    BGrisk: "#ff6b6b",
    BGmain: "#E7EBEE",
    BGprimary: "#FF8B00",
  }
  const [targetHigher, setCheckValue] = useState(() => target < result)

  const targetColor = targetHigher ? colored.BGprimary : colored.BGmain
  const resultColor = targetHigher ? colored.BGrisk : colored.BGprimary
  const textColor = targetHigher ? colored.BGrisk : null
  const targetWidth = useCallback(() => {
    if (targetHigher) {
      return target * 100 / result
    }
    return 100
  }, [result, target, targetHigher])

  const resultWidth = useCallback(() => {
    if (!targetHigher) {
      return result * 100 / target
    }
    return 100
  }, [result, target, targetHigher])

  useEffect(() => {
    setCheckValue(target < result)
  }, [result, target])
  //label for the target
  const bpLine = useRef(null)
  const bpNode = useRef(null)

  useEffect(() => {
    if (bpLine.current && bpNode.current) {
      const center = screenSize.width / 2
      const bpPos = bpLine.current.getBoundingClientRect()
      const bpNodePos = bpNode.current.getBoundingClientRect()
      if (bpPos.left > center) {
        if ((bpNodePos.width + bpPos.left) > screenSize.width) {
          setOrigin('right')
        }
      } else {
        setOrigin('left')
      }
    }

  }, [screenSize.width])
  return (
    <div className={_SpotLight} style={{
      '--mainColor': colored.BGmain,
      '--targetColor': targetColor,
      '--resultColor': resultColor,
      '--targetWidth': targetWidth() + "%",
      '--resultWidth': resultWidth() + "%"
    }}>

      <div className={_Line} >
        <SpotLightLabel bpLineRef={bpLine} bpNodeRef={bpNode} target={target} />
        <div className="spot-light__wrap-line">
          <div className='line--result' style={{
            '--resultColor': resultColor,
            '--resultWidth': resultWidth() + "%"
          }} ></div>
        </div>
      </div>
      <SpotLightContent textColor={textColor} result={result} />
    </div>
  )
}

const SpotLightContent = ({ textColor, result }) => {
  return (
    <div className="spot-light-bar__content">
      <span>Used <b style={{ color: textColor }}>{Utils.formatNumber(result)} kg</b> this month</span>
      <span>{Utils.formatNumber(result)} kg</span>
    </div>
  )
}

const SpotLightLabel = ({ bpLineRef, bpNodeRef, target }) => {
  return (
    <div className='sp-wrapper__label' ref={bpLineRef}>
      <span className='label-stem'></span>
      <span className='label-node' ref={bpNodeRef}>
        <h4 style={{ fontSize: 14, lineHeight: "12px" }}> {Utils.formatNumber(target)} kg</h4>
        <p style={{ fontSize: 12 }}>target</p>
      </span>
    </div>
  )
}
export default SpotLight
