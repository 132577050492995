import React, { useState, useEffect, useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'
import classnames from 'classnames'
import { useDyipne } from 'spd-gds/Dyipne'
import { Co2eActions } from 'spd-gds/actions'

import { Button } from 'spd-gds/components/common'
import { CO2Bg1, CO2Bg2, Airplane, WasteSite, DrumCans, StumpBranches, Grass1, Biker, Cloud1, Cloud2, Cloud3, HeroTree } from 'spd-gds/components/gfx'

import { useScreenSize } from 'spd-gds/components/ScreenSize'
import Helpers from 'spd-gds/helpers'
import { UserSessionTokenContext } from 'spd-gds/components/MobileBridge'

import * as GDSForm from 'spd-gds/components/form'
import { Formik } from "formik"

import { useMode } from 'spd-gds/components/hooks'
const DEEP_LINK = process.env.REACT_APP_MENTION_APP_URL

const HeroLanding = ({ animate }) => {

  const history = useHistory()
  const screenSize = useScreenSize()
  const GROUND_HEIGHT = 285
  const SKY_HEIGHT = screenSize.height - GROUND_HEIGHT

  const { co2e } = useDyipne()
  const { componentShouldHide } = useMode()
  const [co2eState, dispatcher] = co2e
  const [isFetchResult, setIsFetchResult] = useState(false)
  const [showResultButton, setShowResultButton] = useState(false)
  const userSessionToken = useContext(UserSessionTokenContext)

  const _HeroLanding = classnames('app-herolanding', {
    animate
  })

  function handleGoToQuestionnaire(values) {
    if (+values["guess_raintrees"] > 0) {
      dispatcher(Co2eActions.SAVE_GUESS_RAINTREES(values))
    }
    return history.push('/questionnaire/start' + window.location.search)
  }

  useEffect(() => {
    if (Helpers.isInfinity() && userSessionToken && !isFetchResult) {
      dispatcher(Co2eActions.FETCH_RESULT())
      setIsFetchResult(true)
    }
    setShowResultButton(!!co2eState.data.total.value && co2eState.data.trackingType === "MONTHLY")
  }, [dispatcher, isFetchResult, userSessionToken, co2eState.data])

  const showLastResult = () => (
    <p>
      <Link className='hero-link-results' to={{
        pathname: "/results/latest",
        search: window.location.search,
      }}>View Past Results</Link>
    </p>
  )

  return (
    <div id='co2e-herolanding' className={_HeroLanding}>
      <div className='hero-content'>
        <h1> Track your carbon footprint </h1>
        <p> An average Singaporean emits around <span className='hightlight-txt'>730</span> kg of CO2 each month. This means that each person would require around <span className='hightlight-txt'>36</span> rain trees to absorb their carbon emissions!</p>
        <p> Before calculating your footprint, take a quick guess! <br /><span className='txt-bold'>How many rain trees do you think it takes to absorb your carbon emissions every month?</span></p>
        {
          <Formik
            onSubmit={handleGoToQuestionnaire}
            initialValues={{ "guess_raintrees": "" }}
            enableReinitialize
            validateOnChange
          >
            {(formProps) => {
              return (
                <GDSForm.Form
                  className="co2e-guessRaintrees"
                  onSubmit={formProps.handleSubmit}
                >
                  <GDSForm.FormInput
                    id="guess_raintrees"
                    className='input-guess-raintrees'
                    onlyNumber={{ integer: true }}
                    value={formProps.values['guess_raintrees']}
                    setFieldValue={formProps.setFieldValue}
                    handleBlur={formProps.handleBlur}
                    note={`[Optional]`}
                  />
                  <Button className='hero-btn' type="submit"> Get Started </Button>
                </GDSForm.Form>
              )
            }}
          </Formik>
        }
        {
          !componentShouldHide?.downloadApp && (
            <a
              className='text-lighting landing-app__download'
              target="_blank"
              href={DEEP_LINK}
              rel="noreferrer"
            >
              Download the SP App now to keep track of your CO2
            </a>
          )
        }
        {showResultButton && showLastResult()}
      </div>

      <div className='hero-gfx'>
        <div className='hero-gfx__bg'>

          <Cloud1 x={50} y={20} />
          <Cloud2 x={100} y={140} />
          <Cloud3 x={1000} y={40} />
          <Cloud2 x={900} y={220} />
          <Cloud3 x={500} y={SKY_HEIGHT - 400} />
          <Cloud2 x={screenSize.width / 2} y={SKY_HEIGHT - 400} />

          <CO2Bg1 />
          <CO2Bg2 />

          <Airplane x={-120} y={(SKY_HEIGHT / 2) - 50} />
        </div>
        <div className='hero-gfx__fg'>
          <div className='good-side'>
            <Grass1 />
            <Biker />
          </div>
          <HeroTree />
          <div className='bad-side'>
            <WasteSite />
            <DrumCans />
            <StumpBranches />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroLanding
